import _get from 'lodash/get'

import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import grey from '@material-ui/core/colors/grey'

import Api from '../../dataSource/api'

import { callApi, reportApiError } from '../../actions/apiCallActions'
import { login, logout } from '../../actions/loginActions'

import * as logger from '../../lib/logger'
import helpers from '../../lib/helpers'

import config from '../../lib/config'
import Analytics from '../../lib/analytics'

import WhatsNewDialog from './whats_new_dialog'

import '../../styles/progress.css'
import { showNotification } from '../../actions/appNotificationActions'

const styles = {
	dialog: {
		width: '80%%',
		maxWidth: 'none',
	},
	partner_logo: {
		display: 'inline-block',
		maxHeight: 35,
		marginLeft: 30,
		marginRight: -12,
		zIndex: 50,
	},
}


export class PartnerInfo extends React.Component {
	constructor( props ) {
		super( props )

		this.state = { didEmail: null, showAuthError: false, showTimeout: false, whatsNewDialog: false }

		this.checkAndHandleTimeout = this.checkAndHandleTimeout.bind( this )

		this.kickoffLogin = this.kickoffLogin.bind( this )
		this.kickoffLogout = this.kickoffLogout.bind( this )
	}


	// called once, after rest of DOM has loaded. Should have OneID object by now
	componentDidMount() {
		if ( window.OneID ) {
			try {
				this.oneIDLibrary = window.OneID.get( this.configOneId() )
				this.setupEventHandlers()

				this.oneIDLibrary.init()
			}
			catch ( err ) {
				this.oneIDLibrary = window.OneID.get()
				// because we don't get the init event (library already initialized),
				// manually fire off init / did login

				this.oneIDLibrary.getGuest().then( ( guest ) => {
					this.handleOIDLogin( guest )
				} )
			}
		}
	}


	componentWillUnmount() {
		window.clearTimeout( this.timeoutID )
	}


	generateUUID() {
		let d = new Date().getTime()
		let uuid = 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace( /[xy]/g, ( c ) => {
			let r = ( d + Math.random()*16 )%16 | 0
			d = Math.floor( d/16 )
			return ( c=='x' ? r : ( r&0x3|0x8 ) ).toString( 16 )
		} )
		return uuid
	}


	configOneId() {
		let responderPage = `${ config.clientHost }/one_id_responder_v4.html`

		let oneIdConfig = {
			debug			: true,
			clientId		: config.oidClientId,
			correlationId	: this.generateUUID(),
			responderPage	: responderPage,
			cssOverride		: 'https://cdn.secure.espn.go.com/combiner/c?css=disneyid/core.css'
		}

		return oneIdConfig
	}


	handleOIDInit( data ) {
		if ( data.loggedIn ) {
			if ( Analytics.sessionInfo.isExpired ) {
				this.checkAndHandleTimeout()
			}
			else {
				this.handleOIDLogin( data.guest )
			}
		}
	}


	handleOIDLogin( guest ) {
		const accessToken = guest.token.access_token
		const swid = guest.token.swid

		this.oneIDLibrary.getGuest( true, {} )
			.then( ( res ) => {
				const email = res.profile.email

				this.props.dispatch(
					callApi(
						() => {
							return ( ( email, swid, accessToken ) => {
								return Api.loginWithOneId( email, swid, accessToken )
							} )( email, swid, accessToken )
						},
						this.handleApiLogin.bind( this )
					)
				)

				this.setState( { didEmail: email } )
			} )
			.catch( ( e ) => {
				this.props.dispatch( reportApiError( e ) )
			} )
	}


	handleApiLogin( err, user ) {
		if ( helpers.doesExist( err ) ) return this.kickoffLogout( false )
		if ( user.isShortstop ) return this.kickoffLogout( false )

		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, params ) => {
						return Api.getSelectableMetadata( currentUser, params )
					} )( user, {} )
				},
				( err, metadata ) => {
					if ( helpers.doesExist( err ) ) return this.props.dispatch( reportApiError( err ) )

					this.props.dispatch(
						callApi(
							() => {
								return ( ( currentUser ) => {
									return Api.getGroup( currentUser, currentUser.group.group_id )
								} )( user, {} )
							},
							( err, group ) => {
								if ( helpers.doesExist( err ) ) return this.props.dispatch( reportApiError( err ) )

								Analytics.recordUserActivity()
								this.setTimer()

								if ( helpers.doesExist( user ) ) {
									if ( helpers.doesExist( user.group ) ) {
										if ( user.group.type === 'international' ) {
											// international users should be redirected here
											this.props.history.replace( '/international' )
										}
										else if ( config.isShortstop || ( user.group.type === 'shortstop' && config.environment === 'production' ) ) {
											// shortstop users should be redirected here
											this.props.history.replace( '/shortstop-activity' )
										}
										else if ( user.group.type !== 'administrator' && location.pathname.indexOf( '/international' ) > -1 ) {
											// if you're not an admin or an int'l user, make sure you don't see the international page
											this.props.history.replace( '/' )
										}
										else if ( location.pathname.indexOf( '/' ) === -1 ) {
											let redirectRoute = `/${ location.pathname }`
											this.props.history.replace( redirectRoute )
										}
									}
									else {
										this.props.history.replace( '/' )
									}
								}
								else {
									this.props.history.replace( '/' )
								}

								this.props.dispatch( login( user, group, metadata ) )
							}
						)
					)
				}
			)
		)

		this.handleUserMetadata( user )
	}


	handleOIDLogout( data ) {
		this.props.dispatch( logout() )
	}


	setupEventHandlers() {
		this.oneIDLibrary.on( 'init', ( d ) => { this.handleOIDInit( d ) } )
		this.oneIDLibrary.on( 'logout', ( d ) => { this.handleOIDLogout( d ) } )
		this.oneIDLibrary.on( 'login', ( d ) => { this.handleOIDLogin( d ) } )
		this.oneIDLibrary.on( 'error', ( d ) => {
			logger.error( d )
			if ( d === 'Session already established' ) {
				this.kickoffLogout( false )
			}
		} )
	}


	kickoffLogin() {
		Analytics.clearUserActivity()
		this.oneIDLibrary.launchLogin()
	}


	kickoffLogout( userPrompted ) {
		Analytics.clearUserActivity()

		this.setState( () => {
			this.props.loggedOutCallback()
		} )

		// without this check, after international user logs out activity page will flash for a sec before the welcome screen
		if ( helpers.doesExist( this.props.authorizationInfo.user ) ) {
			if ( this.props.authorizationInfo.user.group.type !== 'international' && !config.isShortstop ) {
				this.props.history.replace( '/' )
			}
		}
		else if ( config.isShortstop ) {
			this.props.history.replace( '/shortstop-activity' )
		}
		else {
			this.props.history.replace( '/' )
		}

		this.oneIDLibrary.logout().then( () => {
			if ( !userPrompted ) {
				this.setState( { showAuthError: true } )
			}
		} ).catch( ( err ) => {
		} )
	}


	checkAndHandleTimeout() {
		let sessionInfo = Analytics.sessionInfo

		if ( sessionInfo.isExpired ) {
			// only show the session timeout dialog if it's been under an hour
			if ( sessionInfo.minutesSinceLastAction < 60 ) {
				this.setState( { showTimeout: true } )
			}

			this.kickoffLogout( true )

		}
		else {
			this.setTimer()
		}
	}


	setTimer() {
		let checkTimeInMS = 1000 * 60 // every 60 seconds check to see if we've gone idle
		this.timeoutID = window.setTimeout( this.checkAndHandleTimeout, checkTimeInMS )
	}


	handleCloseAuthError() {
		this.setState( { showAuthError: false } )
	}


	handleCloseTimeout() {
		this.setState( { showTimeout: false } )
	}


	closeWhatsNewDialog() {
		this.setState( { whatsNewDialog: false } )
	}


	async handleUserMetadata( user ) {
		try {
			this.setState( { whatsNewDialog: false } )

			let metadata = await Api.getUserMetadata( user )

			if ( !metadata ) {
				// create a new metadata if user doesn't have metadata

				let userMetadata = {
					user: user,
					admin: {
						version: config.appVersion
					}
				}

				await Api.createUserMetadata( user, userMetadata )

				this.setState( { whatsNewDialog: true } )
			}
			else if ( helpers.doesExist( metadata ) && ( helpers.doesNotExist( metadata.admin ) ) ) {
				// update existing metadata document with admin sub doc
				metadata.admin = {
					version: config.appVersion
				}

				await Api.updateUserMetadata( user, metadata )

				this.setState( { whatsNewDialog: true } )
			}
			else if ( helpers.doesExist( metadata ) && ( helpers.doesExist( metadata.admin ) ) ) {
				if ( metadata.admin.version !== config.appVersion ) {

					metadata.admin.version = config.appVersion

					await Api.updateUserMetadata( user, metadata )

					this.setState( { whatsNewDialog: true } )
				}
			}
		}
		catch ( error ) {
			// display Server/Api error
			this.props.dispatch( showNotification( `Error in server for : ${ user }  metadata`, error, 'error' ) )
		}
	}


	renderPartnerLogo( user ) {
		if ( helpers.doesNotExist( user ) ) return ( <div /> )
		if ( helpers.doesNotExist( user.group ) ) return ( <div /> )
		if ( helpers.doesNotExist( user.group.logo_url ) ) return ( <div /> )

		return (
			<img className={ this.props.classes.partner_logo } src={ user.group.logo_url } alt={ user.group.name }/>
		)
	}


	render() {
		let appName = 'ESPN Media Exchange'
		if ( config.environment !== 'production' ) appName = `${ appName } (${ config.environment.toUpperCase() })`

		let authorizationButton = ( <Button style={ { marginLeft: 10, marginRight: -20, color: grey[ 50 ] } } onClick={ this.kickoffLogin } >Login</Button> )

		let username = _get( this.props.authorizationInfo, 'user.username', '' )
		if ( username !== '' ) {
			username = `Logged in as ${ username }`
			authorizationButton = ( <Button style={ { marginLeft: 10, marginRight: -20, color: grey[ 50 ] } } onClick={ () => { this.kickoffLogout( true ) } } >Logout</Button> )
		}

		if ( config.isShortstop ) appName = 'ESPN Shortstop'

		let errorContact = ( <p>Please contact&nbsp;<a href="mailto:chip.diffendaffer@espn.com">Chip Diffendaffer</a>&nbsp;for assistance.</p> )
		if ( config.isShortstop ) {
			errorContact = ( <p>Please contact&nbsp;<a href="mailto:lauren.k.reynolds@espn.com">Lauren Reynolds</a>&nbsp;for assistance.</p> )
		}

		return (
			<div>
				<Toolbar variant="dense" style={ { backgroundColor: grey[ 900 ] } }>
					<div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' } }>
						<Typography variant="body1" style={ { marginLeft: -8, color: grey[ 50 ], flexGrow: 1, fontWeight: 400 } }>
							{ appName }
						</Typography>

						<div style={ { flexGrow: 2 } } />

						<Typography variant="subtitle2" style={ { color: grey[ 50 ], flexGrow: 1, textAlign: 'right' } }>
							{ username }
						</Typography>

						{ authorizationButton }

						{ this.renderPartnerLogo( this.props.authorizationInfo.user ) }
					</div>
				</Toolbar>

				<Dialog
					open={ this.state.showAuthError }
					onClose={ this.handleCloseAuthError.bind( this ) }
					Width={ styles.dialog.width }
				>
					<DialogTitle id="Authorization Failed">AuthorizationFailed</DialogTitle>

					<DialogContent>
						<DialogContentText>
							{ `User ${ this.state.didEmail } is not authorized for this application.` }
							<br /><br />
							{ errorContact }
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={ this.handleCloseAuthError.bind( this ) } color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={ this.state.showTimeout }
					onClose={ this.handleCloseTimeout.bind( this ) }
					Width={ styles.dialog.width }
				>
					<DialogTitle id="Session Timeout">User Session Timeout</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Session has timed out due to inactivity.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={ this.handleCloseTimeout.bind( this ) } color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>

				<WhatsNewDialog
					isOpen={ this.state.whatsNewDialog }
					closeDialog={ this.closeWhatsNewDialog.bind( this ) }
				/>
			</div>
		)
	}
}


PartnerInfo.propTypes = {
	authorizationInfo	: PropTypes.object.isRequired,
	history				: PropTypes.object.isRequired,
	loggedOutCallback	: PropTypes.func.isRequired,
	dispatch			: PropTypes.func.isRequired,
	classes				: PropTypes.object.isRequired,
}


export default withStyles( styles )( PartnerInfo )
