export default class PccCategory {
	static get friendlyName() { return 'PCC Category' }
	static get modelName() { return PccCategory.friendlyName.replace( ' ', '' ) }
	static get workItemType() { return PccCategory.friendlyName.toString().replace( ' ', '_' ).toLowerCase() }


	constructor( json = {} ) {
		this.id = json.id
		this.href = json.href
		this.artworkUrl = json.artworkUrl
		this.type = json.type
		this.name = json.name
	}


	get modelName() { return PccCategory.modelName }
	get friendlyName() { return PccCategory.friendlyName }
	get workItemType() { return PccCategory.workItemType }
}
