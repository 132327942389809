const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL'
const END_AJAX_CALL = 'END_AJAX_CALL'
const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR'
const CLEAR_AJAX_CALL_ERROR = 'CLEAR_AJAX_CALL_ERROR'

const AUTHORIZATION_LOGIN = 'AUTHORIZATION_LOGIN'
const AUTHORIZATION_LOGOUT = 'AUTHORIZATION_LOGOUT'

const GET_SELECTABLE_METADATA_SUCCESS = 'GET_SELECTABLE_METADATA_SUCCESS'

const GET_PCC_NETWORKS_SUCCESS = 'GET_PCC_NETWORKS_SUCCESS'
const GET_PCC_CATEGORIES_SUCCESS = 'GET_PCC_CATEGORIES_SUCCESS'

const ADD_NEW_UPLOAD_SUCCESS = 'ADD_NEW_UPLOAD_SUCCESS'
const REMOVE_UPLOAD_SUCCESS = 'REMOVE_UPLOAD_SUCCESS'
const REMOVE_UPLOADS_SUCCESS = 'REMOVE_UPLOADS_SUCCESS'

const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'

function isActionTypeSuccess( type ) {
	return type.substring( type.length - 8 ) == '_SUCCESS'
}


module.exports = {
	isActionTypeSuccess,
	BEGIN_AJAX_CALL,
	END_AJAX_CALL,
	AJAX_CALL_ERROR,
	CLEAR_AJAX_CALL_ERROR,
	AUTHORIZATION_LOGIN,
	AUTHORIZATION_LOGOUT,
	GET_SELECTABLE_METADATA_SUCCESS,
	GET_PCC_NETWORKS_SUCCESS,
	GET_PCC_CATEGORIES_SUCCESS,
	ADD_NEW_UPLOAD_SUCCESS,
	REMOVE_UPLOAD_SUCCESS,
	REMOVE_UPLOADS_SUCCESS,
	SHOW_NOTIFICATION,
	HIDE_NOTIFICATION
}
