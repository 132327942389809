import React from 'react'
import PropTypes from 'prop-types'
import Downshift from 'downshift'

import _get from 'lodash/get'

import { withStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import grey from '@material-ui/core/colors/grey'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import Category from '../../models/category'


const styles = ( theme ) => ( {
	input_container		: {
		[theme.breakpoints.down( 'sm' )] : {
			minWidth: 250
		},
		position	: 'relative',
		minWidth	: 250
	},
	results_container	: {
		position	: 'absolute',
		zIndex		: 1,
		marginTop	: '5px',
		left		: 0,
		right		: 0,
		maxHeight	: '200px',
		overflow	: 'auto',
	},
	type_control		: {
		marginTop	: '10px',
		width		: '25%',
		minWidth	: '100px'
	},
} )


class CategorySearch extends React.Component {
	constructor( props ) {
		super( props )

		this.onChange = this.onChange.bind( this )
		this.onInputValueChange = this.onInputValueChange.bind( this )
		this.onCategoryTypeChange = this.onCategoryTypeChange.bind( this )
	}


	onInputValueChange( inputValue, stateAndHelpers ) {
		if ( !this.props.clearOnSelection && stateAndHelpers.selectedItem !== null && inputValue !== stateAndHelpers.selectedItem.name ) {
			stateAndHelpers.clearSelection()
		}

		this.props.updateSearchTerm( inputValue )
	}


	onChange( selectedItem, stateAndHelpers ) {
		if ( selectedItem === null && this.props.clearOnSelection ) return
		this.props.categorySelected( selectedItem )

		if ( this.props.clearOnSelection ) stateAndHelpers.clearSelection()
	}


	onCategoryTypeChange( event ) {
		this.props.updateCategoryType( event.target.value )
	}


	renderCategory( { category, index, itemProps, highlightedIndex, selectedItem } ) {
		let isHighlighted = highlightedIndex === index

		let isSelected = false
		if ( selectedItem !== null && selectedItem.now_id === category.now_id ) isSelected = true

		let imageUrl = _get( category, 'context.image', null )

		let avatarStyle = {}
		if ( imageUrl === null ) {
			avatarStyle = { backgroundColor: grey[ 400 ] }
		}
		else if ( category.type === 'player' ) {
			avatarStyle = { border: `1px solid ${ grey[ 400 ] }` }
		}
		else if ( category.type === 'team' ) {
			avatarStyle = { height: 40, width: 40 }
		}

		let avatar = ( <Avatar alt={ category.name } src={ imageUrl } style={ avatarStyle } /> )
		if ( category.type === 'team' && imageUrl !== null ) {
			avatar = ( <img alt={ category.name } src={ imageUrl } style={ avatarStyle } /> )
		}

		return (
			<MenuItem
				{ ...itemProps }
				key={ category.now_id }
				selected={ isHighlighted }
				component="div"
				style={ {
					fontWeight		: isSelected ? 500 : 400,
					marginTop		: 5,
					marginBottom	: 5
				} }
			>
				{ avatar }
				<ListItemText primary={ category.name } secondary={ Category.getCategoryContextText( category ) } />
			</MenuItem>
		)
	}


	renderInput( inputProps ) {
		const { InputProps, ...other } = inputProps

		let endAdornment = ( <div /> )
		if ( this.props.searching ) {
			endAdornment = (
				<InputAdornment position="end">
					<IconButton>
						<CircularProgress size={ 20 } />
					</IconButton>
				</InputAdornment>
			)
		}

		let isError = false
		let helperText = ( <div /> )

		if ( this.props.searchResults.error ) {
			isError = true
			helperText = ( <FormHelperText>{ this.props.searchResults.error.message }</FormHelperText> )
		}

		return (
			<FormControl
				{ ...other }
			>
				<Input
					type="text"
					error={ isError }
					endAdornment={ endAdornment }
					placeholder={ this.props.placeholderText }
					inputProps={ {
						...InputProps,
					} }
					{ ...other }
				/>
				{ helperText }
			</FormControl>
		)
	}


	render() {
		let typeSelector = ( <div /> )
		if ( this.props.displayTypeSelector ) {
			typeSelector = (
				<FormControl className={ this.props.classes.type_control }>
					<Select
						value={ this.props.categoryType }
						onChange={ this.onCategoryTypeChange }
						inputProps={ {
							name: 'category-type',
							id: 'category-type',
						} }
					>
						<MenuItem value="any">Any</MenuItem>
						<MenuItem value="team">Team</MenuItem>
					</Select>
					<FormHelperText>Category Type</FormHelperText>
				</FormControl>
			)
		}

		return (
			<div>
				<Downshift
					itemToString={ ( category ) => { return category ? category.name : '' } }
					initialSelectedItem={ this.props.initialSelection }
					onInputValueChange={ this.onInputValueChange }
					onChange={ this.onChange }
				>
					{ ( { getInputProps, getItemProps, isOpen, inputValue, selectedItem, highlightedIndex } ) => {
						return (
							<div className={ this.props.classes.input_container }>
								{ this.renderInput( {
									fullWidth	: true,
									InputProps	: getInputProps(),
								} ) }
								{ isOpen || this.props.isOpen ? (
									<Paper square className={ this.props.classes.results_container } elevation={ 2 }>
										{
											this.props.searchResults.categories.map( ( category, index ) => {
												return this.renderCategory( {
													category,
													index,
													itemProps: getItemProps( { item: category } ),
													highlightedIndex,
													selectedItem,
												} )
											} )
										}
									</Paper>
								) : null }
							</div>
						)
					} }
				</Downshift>

				{ typeSelector }
			</div>
		)
	}
}


CategorySearch.propTypes = {
	// component specific
	clearOnSelection	: PropTypes.bool.isRequired,
	placeholderText		: PropTypes.string.isRequired,
	searchTerm			: PropTypes.string.isRequired,
	categoryType		: PropTypes.string.isRequired,
	searching			: PropTypes.bool.isRequired,
	displayTypeSelector	: PropTypes.bool.isRequired,
	initialSelection	: PropTypes.object,
	searchResults		: PropTypes.object.isRequired,
	updateSearchTerm	: PropTypes.func.isRequired,
	updateCategoryType	: PropTypes.func.isRequired,
	categorySelected	: PropTypes.func.isRequired,

	// injected by material-ui
	classes				: PropTypes.object.isRequired,

	// test hooks
	isOpen				: PropTypes.bool,
}


export default withStyles( styles )( CategorySearch )
