import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from '../reducers'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import thunk from 'redux-thunk'


export default function configureStore( initialState ) {
	let composeMiddlewares = [ applyMiddleware( thunk, reduxImmutableStateInvariant() ) ]

	if ( window.__REDUX_DEVTOOLS_EXTENSION__ ) {
		composeMiddlewares.push( window.__REDUX_DEVTOOLS_EXTENSION__() )
	}

	return createStore (
		rootReducer,
		initialState,
		compose( ...composeMiddlewares )
	)
}
