import _cloneDeep from 'lodash/cloneDeep'

const types = require( '../actions/actionTypes' )
const initialState = require( './initialState' )


export default function metadataReducer( state = initialState.selectableMetadata, action ) {
	if ( action.type === types.GET_SELECTABLE_METADATA_SUCCESS ) {
		return action.metadata
	}
	else if ( action.type === types.AUTHORIZATION_LOGIN ) {
		let clonedModels = []
		action.metadata.map( ( item ) => {
			clonedModels.push( _cloneDeep( item ) )
		} )

		return clonedModels
	}
	else if ( action.type === types.AUTHORIZATION_LOGOUT ) {
		return initialState.selectableMetadata
	}

	return state
}
