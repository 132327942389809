export default class Activity {
	static get friendlyName() { return 'Activity' }
	static get modelName() { return Activity.friendlyName.replace( ' ', '' ) }
	static get workItemType() { return Activity.friendlyName.replace( ' ', '_' ).toLowerCase() }


	constructor( activityPresenterJSON={} ) {
		this._id 			= activityPresenterJSON._id
		this.name 			= activityPresenterJSON.name
		this.work_item 		= activityPresenterJSON.work_item
		this.is_test		= activityPresenterJSON.is_test
		this.current_state 	= activityPresenterJSON.current_state
		this.current_tasks 	= activityPresenterJSON.current_tasks
		this.created 		= activityPresenterJSON.created
		this.started 		= activityPresenterJSON.started
		this.completed 		= activityPresenterJSON.completed
		this.states 		= activityPresenterJSON.states
		this.sort_fields 	= activityPresenterJSON.sort_fields

		this.error 			= activityPresenterJSON.error
		this.warnings 		= activityPresenterJSON.warnings
		this.content_destination_info = activityPresenterJSON.content_destination_info

		this.owner = activityPresenterJSON.owner
	}


	get modelName() { return Activity.modelName }
	get friendlyName() { return Activity.friendlyName }
	get workItemType() { return Activity.workItemType }
}
