import * as types from '../actions/actionTypes'
import * as initialState from './initialState'


export default function ajaxStatusReducer( state = initialState.ajaxCallsInProgress, action ) {
	if ( action.type === types.BEGIN_AJAX_CALL ) {
		return [ ...state, {} ]
	}
	else if ( action.type === types.END_AJAX_CALL || types.isActionTypeSuccess( action.type ) ) {
		// TODO: better way to identify which ajax request is in flight, currently: hope.

		let newState = [ ...state ]
		newState.pop()

		return newState
	}
	else if ( action.type === types.AUTHORIZATION_LOGOUT ) {
		return initialState.ajaxCallsInProgress
	}

	return state
}
