export default class PartnerRequest {
	static get friendlyName() { return 'Partner Request' }
	static get modelName() { return PartnerRequest.friendlyName.replace( ' ', '' ) }
	static get workItemType() { return PartnerRequest.friendlyName.replace( ' ', '_' ).toLowerCase() }


	constructor( json = {} ) {
		this._id = json._id
		this.api_version = json.api_version
		this.is_test = json.is_test
		this.video_id = json.video_id
		this.language = json.language
		this.sport = json.sport
		this.league = json.league
		this.media_type = json.media_type
		this.video_url = json.video_url
		this.thumbnail_url = json.thumbnail_url
		this.duration = json.duration
		this.aspect = json.aspect
		this.caption = json.caption
		this.headline = json.headline
		this.publish_date = json.publish_date
		this.expiration_date = json.expiration_date
		this.teams = json.teams
		this.people = json.people
		this.competition = json.competition
		this.game_id = json.game_id
		this.categories = json.categories
		this.keywords = json.keywords
		this.created = json.created
		this.user = json.user
		this.group_organization = json.group_organization
		this.workflow = json.workflow
		this.media = json.media
	}


	get modelName() { return PartnerRequest.modelName }
	get friendlyName() { return PartnerRequest.friendlyName }
	get workItemType() { return PartnerRequest.workItemType }


	// the following methods are used to provide a common interface between partner_request and media
	getAttachment( kind ) { return null }
	getHeadline() { return this.headline }
	getCaption() { return this.caption }
	getOrganization() { return this.group_organization[ 0 ] }
	getUserName() { return this.user.name }
	getVideoUrl() { return this.video_url }
	getThumbnailUrl() { return this.thumbnail_url }
	getThumbnailWidth() { return null }
	getThumbnailHeight() { return null }
	getLanguage() { return this.language }
	getVideoDuration() { return this.duration }
	getVideoAspectRatio() { return this.aspect }
	getPublishDate() { return this.publish_date }
	getExpirationDate() { return this.expiration_date }
	getMediaType() { return this.media_type }
	getSport() { return this.sport }
	getLeague() { return this.league }
	getCategories() { return this.categories }
	getTeams() { return this.teams }
	getPeople() { return this.people }
	getCompetition() { return this.competition }
	getGameId() { return this.game_id }
	getKeywords() { return this.keywords }
	getPartnerProvidedId() { return this.video_id }
}
