import React from 'react'
import PropTypes from 'prop-types'
import Downshift from 'downshift'

import _get from 'lodash/get'

import { withStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import ListItemText from '@material-ui/core/ListItemText'
import grey from '@material-ui/core/colors/grey'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import MenuItem from '@material-ui/core/MenuItem'

import ShortstopAvatar from '../common/shortstop_avatar'

import API from '../../dataSource/api'


const styles = ( theme ) => ( {
	container	: {
		position	: 'relative',
	},
	paper		: {
		position	: 'absolute',
		zIndex		: 1,
		marginTop	: theme.spacing( 1 ),
		left		: 0,
		right		: 0,
		maxHeight	: '200px',
		overflow	: 'auto',
	},
	inputRoot	: {
		flexWrap	: 'wrap',
	},
	avatarNoImage: {
		border			: `1px solid ${ grey[ 400 ] }`,
		backgroundColor	: grey[ 300 ]
	},
	avatar: {
		border			: `1px solid ${ grey[ 400 ] }`,
	},
	selectedCategories	: {
		marginTop	: '10px'
	},
	categoryChip: {
		marginRight: '5px',
	},
} )


class UserSearch extends React.Component {
	constructor( props ) {
		super( props )

		this.state = this.initialState
	}


	get initialState() {
		return { search_term: '', search_results: [], searching: false, search_error: null }
	}


	async onInputValueChange( inputValue, stateAndHelpers ) {
		if ( inputValue === '' ) {
			if ( stateAndHelpers.selectedItem === null ) {
				return this.setState( this.initialState )
			}

			await this.resetSearchResults( stateAndHelpers )
			return
		}
		else {
			if ( stateAndHelpers.selectedItem !== null && inputValue !== stateAndHelpers.selectedItem.name ) {
				stateAndHelpers.clearSelection()
			}
		}

		this.setState( { search_term: inputValue, search_error: null }, async () => {
			if ( this.state.search_term.length < 3 ) return this.setState( { search_results: [] } )

			this.searchUsers()
		} )
	}


	onChange( selectedItem, stateAndHelpers ) {
		this.props.userSelected( selectedItem )
	}


	async resetSearchResults( stateAndHelpers ) {
		return new Promise( ( resolve, reject ) => {
			this.setState( this.initialState, () => {
				stateAndHelpers.clearSelection()
				resolve()
			} )
		} )
	}


	searchUsers() {
		if ( this.state.searchTerm === '' ) return

		this.setState( { searching: true }, () => {
			return API.searchUsers( this.props.authorizationInfo.user, { name: this.state.search_term, sort: 'name' } )
				.then( async ( searchResults ) => {
					if ( searchResults.searchTerm !== this.state.search_term ) return

					for ( let user of searchResults.models ) {
						let columnistId = _get( user, 'shortstop.columnist_id', null )
						if ( columnistId !== null ) {
							try {
								let columnist = await API.getScribeColumnist( columnistId )
								user.image_url = _get( columnist, 'image_url', null )
							}
							catch ( e ) {
								// DO NOTHING
							}
						}
					}

					this.setState( { search_results: searchResults.models, searching: false } )
				} )
				.catch( ( err ) => {
					this.setState( { search_results: [], searching: false, search_error: err } )
				} )
		} )
	}


	renderInput( inputProps ) {
		const { InputProps, classes, ...other } = inputProps

		let endAdornment = ( <div /> )
		if ( this.state.searching ) {
			endAdornment = (
				<InputAdornment position="end">
					<IconButton>
						<CircularProgress size={ 20 } />
					</IconButton>
				</InputAdornment>
			)
		}

		let isError = false
		let helperText = ( <div /> )

		if ( this.state.search_error ) {
			isError = true
			helperText = ( <FormHelperText>{ this.state.search_error.message }</FormHelperText> )
		}

		return (
			<FormControl
				{ ...other }
			>
				<Input
					type="text"
					error={ isError }
					endAdornment={ endAdornment }
					placeholder="Search Users"
					inputProps={ {
						classes		: {
							root		: classes.inputRoot,
						},
						...InputProps,
					} }
					{ ...other }
				/>
				{ helperText }
			</FormControl>
		)
	}


	renderUser( { user, index, itemProps, highlightedIndex, selectedItem } ) {
		let isHighlighted = highlightedIndex === index

		let isSelected = false
		if ( selectedItem !== null && selectedItem._id === user._id ) isSelected = true

		return (
			<MenuItem
				{ ...itemProps }
				key={ user._id }
				selected={ isHighlighted }
				component="div"
				style={ {
					fontWeight		: isSelected ? 500 : 400,
					marginTop		: 5,
					marginBottom	: 5
				} }
			>
				<ShortstopAvatar
					authorizationInfo={ this.props.authorizationInfo }
					activity={ {} }
					cardAvatar={ false }
					currentUser={ user }
					getScribeColumnist={ this.props.getScribeColumnist }
				/>

				<ListItemText primary={ user.contact.name } />
			</MenuItem>
		)
	}


	render() {
		return (
			<Downshift
				itemToString={ ( user ) => { return user ? user.contact.name: '' } }
				initialSelectedItem={ this.props.initialSelection }
				onInputValueChange={ this.onInputValueChange.bind( this ) }
				onChange={ this.onChange.bind( this ) }
			>
				{
					( { getInputProps, getItemProps, isOpen, inputValue, selectedItem, highlightedIndex } ) => {
						return (
							<div className={ this.props.classes.container }>
								{ this.renderInput( {
									fullWidth	: true,
									classes		: this.props.classes,
									InputProps	: getInputProps(),
								} ) }
								{ isOpen ? (
									<Paper square className={ this.props.classes.paper } elevation={ 2 }>
										{
											this.state.search_results.map( ( user, index ) => {
												return this.renderUser( {
													user,
													index,
													itemProps: getItemProps( { item: user } ),
													highlightedIndex,
													selectedItem,
												} )
											} )
										}
									</Paper>
								) : <div /> }
							</div>
						)
					}
				}
			</Downshift>
		)
	}
}


UserSearch.propTypes = {
	authorizationInfo	: PropTypes.object.isRequired,
	initialSelection	: PropTypes.object,
	userSelected		: PropTypes.func.isRequired,

	// injected by material-ui
	classes				: PropTypes.object.isRequired,

	// test hook
	getScribeColumnist	: PropTypes.func,
}


export default withStyles( styles )( UserSearch )
