import _isEmpty from 'lodash/isEmpty'
import _clone from 'lodash/clone'

import React from 'react'
import PropTypes from 'prop-types'

import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import helpers from '../../lib/helpers'

import DateFnsUtils from '@date-io/moment'
import moment from 'moment'


export class ActivityFilter extends React.Component {
	constructor( props, context ) {
		super( props, context )
        
		this.handleHideTestActivity = this.handleHideTestActivity.bind( this )
		this.handleStartDateChange = this.handleStartDateChange.bind( this )
		this.handleEndDateChange = this.handleEndDateChange.bind( this )
		this.handleGroupSelect = this.handleGroupSelect.bind( this )
		this.handleLanguageSelect = this.handleLanguageSelect.bind( this )
		this.handleDateRangeReset = this.handleDateRangeReset.bind( this )
	}
	
	
	handleHideTestActivity( event ) {
		let showTests = false
		if ( event.target.checked ) {
			showTests = true
		}

		let newQuery = _clone( this.props.query )

		newQuery.showTests = showTests
		newQuery.currentPage = 0

		this.props.handleQueryChange( newQuery )
	}
    

	handleStartDateChange( newDate ) {
		let newQuery = _clone( this.props.query )

		newQuery.startDate = new Date( newDate.format( 'YYYY-MM-DDT00:00:00' ) )
		newQuery.currentPage = 0
        
		this.props.handleQueryChange( newQuery )
	}


	handleEndDateChange( newDate ) {
		let newQuery = _clone( this.props.query )

		newQuery.endDate = new Date( newDate.format( 'YYYY-MM-DDT00:00:00' ) )
		newQuery.currentPage = 0

		this.props.handleQueryChange( newQuery )
	}


	handleGroupSelect( event ) {
		let newGroupId = event.target.value
		let fullGroup

		if ( newGroupId === '' ) {
			newGroupId = null
		}
		else {
			fullGroup = this.props.groups.find( group => group.group_id === newGroupId )
			
		}

		let newQuery = _clone( this.props.query )

		newQuery.groupId = newGroupId
		newQuery.currentPage = 0

		this.props.handleGroupSelect( newQuery, fullGroup )
	}


	handleLanguageSelect( event ) {
		let newLanguage = event.target.value
		if ( newLanguage === '' ) newLanguage = null

		let newQuery = _clone( this.props.query )

		newQuery.language = newLanguage
		newQuery.currentPage = 0

		this.props.handleQueryChange( newQuery )
	}


	handleDateRangeReset( event ) {
		if ( helpers.doesNotExist( this.props.query.startDate ) && helpers.doesNotExist( this.props.query.endDate ) ) return

		let newQuery = _clone( this.props.query )

		newQuery.startDate = null
		newQuery.endDate = null
		newQuery.currentPage = 0

		this.props.handleQueryChange( newQuery )
	}
    
    
	renderToggleControls() {
		return (
			<div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center' } }>
				<Typography variant="subtitle2" color="textPrimary" noWrap>
					Tests
				</Typography>
				<div style={ { marginLeft: 'auto' } }>
					<Switch
						id="testSwitch"
						className="hide-test-activity"
						checked={ this.props.query.showTests }
						onChange={ this.handleHideTestActivity }
						color="primary"
					/>
				</div>
			</div>
		)
	}
    

	renderDatePicker() {
		let maxStartDate = new Date()
		if ( helpers.doesExist( this.props.query.endDate ) ) {
			maxStartDate = new Date( `${ moment( this.props.query.endDate ).subtract( 1, 'days' ).format( 'YYYY-MM-DD' ) }T00:00:00` )
		}

		let minEndDate = new Date( 2017, 0, 1 )
		if ( helpers.doesExist( this.props.query.startDate ) ) {
			minEndDate = new Date( `${ moment( this.props.query.startDate ).add( 1, 'days' ).format( 'YYYY-MM-DD' ) }T00:00:00` )
		}

		return (
			<div style={ { display: 'flex', flexDirection: 'column' } }>
				<MuiPickersUtilsProvider utils={ DateFnsUtils }>
					<DatePicker
						label="Start Date"
						value={ this.props.query.startDate || null }
						format="MMM Do, YYYY"
						minDate={ new Date( 2017, 0, 1 ) } maxDate={ maxStartDate }
						onChange={ this.handleStartDateChange }
					/>

					<DatePicker
						style={ { marginTop: 10 } }
						label="End Date"
						value={ this.props.query.endDate || null }
						format="MMM Do, YYYY"
						minDate={ minEndDate } maxDate={ new Date() }
						onChange={ this.handleEndDateChange }
					/>
				</MuiPickersUtilsProvider>
				<Button variant="contained" onClick={ this.handleDateRangeReset } style={ { marginTop: 10 } }>
					Reset Date Range
				</Button>
			</div>
		)
	}
    

	renderLanguageSelect() {
		let currentLanguage = this.props.query.language
		if ( helpers.doesNotExist( currentLanguage ) ) currentLanguage = ''

		return (
			<FormControl>
				<InputLabel>Language</InputLabel>
				<Select
					id="languageSelect"
					style={ { width: 200 } }
					value={ currentLanguage }
					onChange={ this.handleLanguageSelect.bind( this ) }
					autoWidth={ true }
				>
					<MenuItem key="language-all" value="">All Languages</MenuItem>
					<MenuItem key="language-en" value="en">English</MenuItem>
					<MenuItem key="language-es" value="es">Spanish</MenuItem>
					<MenuItem key="language-pt" value="pt">Portuguese</MenuItem>
				</Select>
			</FormControl>
		)
	}
    

	renderGroupSelect() {
		if ( !this.props.authorizationInfo.user.isInternal || _isEmpty( this.props.groups ) ) return ( <div /> )

		let currentGroupId = this.props.query.groupId
		if ( helpers.doesNotExist( currentGroupId ) ) currentGroupId = ''

		return (
			<FormControl style={ { marginTop: 10 } }>
				<InputLabel>Group</InputLabel>
				<Select
					style={ { width: 200 } }
					value={ currentGroupId }
					onChange={ this.handleGroupSelect }
					autoWidth={ true }
				>
					<MenuItem key="language-all" value="">All Groups</MenuItem>
					{ this.props.groups.map( ( g ) => ( <MenuItem key={ `group-${ g.group_id }` } value={ g.group_id }>{ g.name }</MenuItem> ) ) }
				</Select>
			</FormControl>
		)
	}
    

	render() {
		if ( !this.props.open ) return ( <div /> )

		return (
			<Dialog open={ this.props.open } onClose={ this.handleClose } maxWidth={ 'md' } fullWidth={ true }>
				<DialogTitle>Activity Filter</DialogTitle>
				<DialogContent>
					<div style={ { display: 'flex', flexDirection: 'row', height: 300 } }>
						<div style={ { flex: 1, padding: 10, paddingTop: '25px' } }>
							{ this.renderToggleControls() }
						</div>
						<div style={ { flex: 1, padding: 10 } }>
							{ this.renderDatePicker() }
						</div>
						<div style={ { flex: 1, padding: 10 } }>
							{ this.renderLanguageSelect() }
							{ this.renderGroupSelect() }
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={ this.props.closeFilter } color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

}

ActivityFilter.propTypes = {
	authorizationInfo	: PropTypes.object.isRequired,
	open				: PropTypes.bool.isRequired,
	handleQueryChange       : PropTypes.func.isRequired,
	handleGroupSelect		: PropTypes.func.isRequired,
	groups                  : PropTypes.array.isRequired,
	closeFilter				: PropTypes.func.isRequired,
	query                   : PropTypes.object.isRequired
}


export default ActivityFilter

