import _isArray from 'lodash/isArray'
import React from 'react'
import PropTypes from 'prop-types'
import * as Redux from 'react-redux'

import helpers from '../../lib/helpers'

import { clearAjaxCallError } from '../../actions/ajaxStatusActions'
import { showNotification } from '../../actions/appNotificationActions'


export class AjaxInfo extends React.Component {
	UNSAFE_componentWillReceiveProps( newProps ) {
		if ( helpers.doesNotExist( newProps.error ) ) return

		let message = newProps.error.message
		if ( helpers.doesExist( newProps.error ) && helpers.doesExist( newProps.error.message ) && _isArray( newProps.error.message.invalid_values ) ) {
			if ( newProps.error.message.invalid_values.length > 0 ) {
				message = `${ newProps.error.message.invalid_values[ 0 ].param } ${ newProps.error.message.invalid_values[ 0 ].message }`
			}
		}

		let title = newProps.error._apiMethod
		if ( !newProps.error._isServerException ) title = `${ title } (${ newProps.error.statusCode })`

		this.props.dispatch( showNotification( message, title, 'error', this.toastDismissed.bind( this ) ) )
	}


	toastDismissed() {
		this.props.dispatch( clearAjaxCallError() )
	}


	render() {
		return ( <div /> )
	}
}


AjaxInfo.propTypes = {
	error: PropTypes.object		//eslint-disable-line react/no-unused-prop-types
}


function mapStateToProps( state, ownProps ) {
	return { error: state.ajaxErrorInfo }
}


export default Redux.connect( mapStateToProps )( AjaxInfo )
