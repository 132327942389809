import React from 'react'
import PropTypes from 'prop-types'
import Downshift from 'downshift'

import { withStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import InputLabel from '@material-ui/core/InputLabel'
import Tooltip from '@material-ui/core/Tooltip'

import HelpIcon from '@material-ui/icons/Help'
import DownArrow from '@material-ui/icons/KeyboardArrowDown'

import List from 'react-virtualized/dist/commonjs/List'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'

const styles = ( theme ) => ( {
	container			: {
		position		: 'relative',
	},
	paper				: {
		position		: 'absolute',
		zIndex			: 1,
		marginTop		: theme.spacing( 1 ),
		left			: 0,
		right			: 0,
		maxHeight		: '300px',
		overflow		: 'auto',
	},
	inputRoot			: {
		flexWrap		: 'wrap',
	},
	inputLabelShrink	: {
		transform		: 'translate(0, -11px) scale(0.75);'
	},
	inputLabelText		: {
		fontSize		: '17px'
	},
	elevatedIconButton	: {
		paddingBottom	: '15px'
	},
} )


class CategoriesSearch extends React.Component {
	constructor( props ) {
		super( props )

		this.state = this.initialState
		this.renderRow = this.renderRow.bind( this )
	}


	get initialState() {
		return { search_term: '', search_results: this.props.categories }
	}


	async onInputValueChange( inputValue, stateAndHelpers ) {
		if ( inputValue === '' ) {
			if ( stateAndHelpers.selectedItem === null ) {
				return this.setState( this.initialState )
			}

			await this.resetSearchResults( stateAndHelpers )
			return
		}
		else {
			if ( stateAndHelpers.selectedItem !== null && inputValue !== stateAndHelpers.selectedItem.name ) {
				stateAndHelpers.clearSelection()
			}
		}

		this.setState( { search_term: inputValue }, async () => {
			this.searchUsers()
		} )
	}


	onChange( selectedItem, stateAndHelpers ) {
		this.props.userSelected( selectedItem )
	}


	async resetSearchResults( stateAndHelpers ) {
		return new Promise( ( resolve, reject ) => {
			this.setState( this.initialState, () => {
				stateAndHelpers.clearSelection()
				resolve()
			} )
		} )
	}


	searchUsers() {
		let searchTerm = this.state.search_term.toLowerCase()
		if ( this.state.searchTerm === '' ) return this.setState( { search_results: this.props.categories } )
        
		let moddedCategories = this.props.categories.map( ( cat, idx ) => Object.assign( cat, { idx: idx } ) )
        
		let filteredResults = moddedCategories.filter( item => !searchTerm || item.name.toLowerCase().includes( searchTerm ) )
        
		this.setState( { search_results: filteredResults } )

	}


	renderInput( inputProps, toggleMenu ) {
		const { InputProps, classes, ...other } = inputProps

		let endAdornment = (
			<InputAdornment position="end">
				<IconButton
					onClick={ () => toggleMenu() }
				>
					<DownArrow size={ 20 } />
				</IconButton>
			</InputAdornment>
		)

		return (
			<FormControl
				{ ...other }
			>
				<InputLabel shrink classes={ { root: this.props.classes.inputLabelText, shrink: this.props.classes.inputLabelShrink } }>
					Categories
					<Tooltip title={ 'Select a category.' } placement="right-start" enterDelay={ 500 } leaveDelay={ 200 }>
						<IconButton classes={ { root: this.props.classes.elevatedIconButton } }>
							<HelpIcon />
						</IconButton>
					</Tooltip>
				</InputLabel>

				<Input
					type="text"
					endAdornment={ endAdornment }
					placeholder="Search Categories"
					inputProps={ {
						classes		: {
							root	: classes.inputRoot,
						},
						...InputProps,
					} }
					{ ...other }
				/>
			</FormControl>
		)
	}
    

	renderRow( row ) {
		const { index, style } = row
		const category = this.state.search_results[index]
        
		return (
			<MenuItem
				style={ {
					...style,
					paddingTop: '0',
					paddingBottom: '0'
				} }
				key={ category.id }
				value={ { id: category.id, name: category.name, type: category.type, href: category.href, artworkUrl: category.artworkUrl } }
				onClick={ () => this.props.handleFieldChange( 'categories', category ) }
			>
				<Checkbox key={ category.id } checked={ this.props.currentSelected ? this.props.currentSelected.findIndex( c => c.id === category.id ) > -1 : false } />
				{ category.name }
			</MenuItem>
		)
	}


	render() {
		return (
			<Downshift
				itemToString={ ( user ) => { return user ? user.name: '' } }
				onInputValueChange={ this.onInputValueChange.bind( this ) }
				onChange={ this.onChange.bind( this ) }
			>
				{
					( { getInputProps, isOpen, toggleMenu } ) => {
						return (
							<div className={ this.props.classes.container }>

								{ this.renderInput( {
									fullWidth	: true,
									classes		: this.props.classes,
									InputProps	: getInputProps(),
								}, toggleMenu ) }
								{ isOpen ? (
									<Paper className={ this.props.classes.paper } elevation={ 2 }>
										<AutoSizer disableHeight>
											{ ( { width } ) => (
												<List
													width={ width }
													height={ 300 }
													rowCount={ this.state.search_results.length }
													rowHeight={ 48 }
													rowRenderer={ ( row ) => this.renderRow( row ) }
												/>
											) }
										</AutoSizer>
									</Paper>
								) : <div /> }
							</div>
						)
					}
				}
			</Downshift>
		)
	}
}


CategoriesSearch.propTypes = {
	userSelected		: PropTypes.func.isRequired,
	categories          : PropTypes.array.isRequired,
	handleFieldChange   : PropTypes.func.isRequired,
	currentSelected     : PropTypes.array,

	// injected by material-ui
	classes				: PropTypes.object.isRequired,

	// test hook
	getScribeColumnist	: PropTypes.func,
}


export default withStyles( styles )( CategoriesSearch )
