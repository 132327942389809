import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import PropTypes from 'prop-types'
import Menu from '@material-ui/core/Menu'
import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from '@material-ui/core/MenuItem'

import { connect } from 'react-redux'

const moment = require( 'moment' )
import { showNotification } from '../../actions/appNotificationActions'

import DashboardHelper from '../../lib/dashboard_helper'
import ExcelProcessor from '../../lib/ExcelProcessor'

import ShortstopSection1 from './ShortstopSection1'
import ShortstopSection2 from './ShortstopSection2'
import ShortstopSection3 from './ShortstopSection3'

import PartnerSection from './PartnerSection'

import config from '../../lib/config'
import helpers from '../../lib/helpers'

const styles = theme => ( {
	thinDivider: {
		height: '2px',
		backgroundColor: 'grey'
	},
} )


export class AnalyticsHome extends React.Component {
	constructor( props, context ) {
		super( props, context )

		const loadData = this.props.loadData || this.getUpdatedGraphState.bind( this )
		const isShortstop = config.isShortstop

		this.state = {
			timeFrameMoment: moment(),
			currentTimeFrame: 'year',
			raw_data: {
				lineGraphData: {
					shortstop: [],
					partner: [],
					shortstop_live: []
				},
				shortstop: [],
				partner: [],
				shortstop_live: []
			},
			errors: {
				lineGraph: null,
				shortstop: null,
				partner: null,
				shortstop_live: null
			},
			dateInfo : null,
			loading: true,
			loadData: loadData,
			isShortstop: this.props.isShortstop || isShortstop,
			startDate: ( isShortstop ) ? moment( '2015-08-03', 'YYYY-MM-DD' ).startOf( 'day' ) : moment( '2017-01-02', 'YYYY-MM-DD' ).startOf( 'day' ),
			menuAnchorEl: null
		}

		this.handleClick = this.handleClick.bind( this )
		this.saveExcelWorkbook = this.saveExcelWorkbook.bind( this )
	}


	componentDidMount() {
		this.state.loadData( this.state.currentTimeFrame, this.state.timeFrameMoment, ( err, res ) => {
			this.setState( res )
		} )
	}


	getUpdatedGraphState( currentTimeFrame, timeFrameMoment, cb ) {
		let lineGraphError = null
		let shortstopError = null

		let partnerError = null
		let shortstopLiveError = null

		let lineGraphData = {
			shortstop: [],
			partner: [],
			shortstop_live: []
		}

		let dateInfo = DashboardHelper.getChildrenDateInfo( timeFrameMoment.clone(), currentTimeFrame )
		let groupInfo = this.props.authorizationInfo.user.group

		this.getLineGraphData( dateInfo, groupInfo.type, currentTimeFrame, timeFrameMoment, ( err, res ) => {
			if ( helpers.doesExist( err ) ) lineGraphError = err
			lineGraphData = res

			let shortstopData = { data: [] }
			let shortstopLiveData = { data: [] }
			let partnerData = { data: [] }


			if ( this.state.isShortstop ) {
				let params = { type:'shortstop', time_frame: currentTimeFrame, start_metrics_date: timeFrameMoment.format( DashboardHelper.getMomentFormat( currentTimeFrame ) ), num_units: 0 }

				DashboardHelper.getAnalyticsData( params, this.props.authorizationInfo.user, ( err, res ) => {
					if ( helpers.doesExist( err ) ) shortstopError = err
					shortstopData = res

					params = { type:'shortstop_live', time_frame: currentTimeFrame, start_metrics_date: timeFrameMoment.format( DashboardHelper.getMomentFormat( currentTimeFrame ) ), num_units: 0 }

					DashboardHelper.getAnalyticsData( params, this.props.authorizationInfo.user, ( err, res ) => {
						if ( helpers.doesExist( err ) ) shortstopLiveError = err
						shortstopLiveData = res

						let result = {
							dateInfo: dateInfo,
							loading: false,
							groupInfo: groupInfo,
							errors: {
								lineGraph: lineGraphError,
								shortstop: shortstopError,
								partner: partnerError,
								shortstop_live: shortstopLiveError
							},
							raw_data: {
								shortstop: shortstopData,
								partner: partnerData,
								shortstop_live: shortstopLiveData,
								lineGraphData: {
									shortstop: lineGraphData.shortstop,
									partner: lineGraphData.partner_publishing,
									shortstop_live: lineGraphData.shortstop_live
								}
							}
						}

						cb( null, result )
					} )
				} )
			}
			else {
				let params = { type:'partner', time_frame: currentTimeFrame, start_metrics_date: timeFrameMoment.format( DashboardHelper.getMomentFormat( currentTimeFrame ) ), num_units: 0 }

				DashboardHelper.getAnalyticsData( params, this.props.authorizationInfo.user, ( err, res ) => {
					if ( helpers.doesExist( err ) ) partnerError = err
					partnerData = res

					let result = {
						dateInfo: dateInfo,
						loading: false,
						groupInfo: groupInfo,
						errors: {
							lineGraph: lineGraphError,
							shortstop: shortstopError,
							partner: partnerError,
							shortstop_live: shortstopLiveError
						},
						raw_data: {
							shortstop: shortstopData,
							partner: partnerData,
							shortstop_live: shortstopLiveData,
							lineGraphData: {
								shortstop: lineGraphData.shortstop,
								partner: lineGraphData.partner_publishing,
								shortstop_live: lineGraphData.shortstop_live
							}
						}
					}

					cb( null, result )
				} )
			}
		} )
	}


	getLineGraphData( dateInfo, groupType, currentTimeFrame, timeFrameMoment, cb ) {
		let timeFrameToUse = 'month'
		if ( currentTimeFrame === 'week' || currentTimeFrame === 'month' ) {
			timeFrameToUse = 'day'
		}

		let params = {
			timeFrameMoment: timeFrameMoment,
			user: this.props.authorizationInfo.user,
			time_frame: currentTimeFrame,
			start_metrics_date: timeFrameMoment.clone().startOf( currentTimeFrame ).format( DashboardHelper.getMomentFormat( timeFrameToUse ) )
		}

		this.getRawLineGraphData( params, groupType, dateInfo, ( err, res ) => {
			if ( helpers.doesExist( err ) ) return cb( err )

			return cb( null, res )
		} )
	}


	getRawLineGraphData( params, groupType, dateInfo, cb ) {
		let rawData = {
			shortstop: null,
			partner_publishing: null,
			shortstop_live: null
		}

		if ( this.state.isShortstop ) {
			let analyticsParams = { type: 'shortstop', time_frame: dateInfo.timeToAdd, start_metrics_date: params.start_metrics_date, num_units: dateInfo.currentTimeFrameAsNumber - 1, sort: 'metrics_ts' }

			DashboardHelper.getAnalyticsData( analyticsParams, params.user, ( err, res ) => {
				if ( helpers.doesExist( err ) ) return cb( err )
				rawData.shortstop = res

				analyticsParams = { type: 'shortstop_live', time_frame: dateInfo.timeToAdd, start_metrics_date: params.start_metrics_date, num_units: dateInfo.currentTimeFrameAsNumber - 1, sort: 'metrics_ts' }

				DashboardHelper.getAnalyticsData( analyticsParams, params.user, ( err, res ) => {
					if ( helpers.doesExist( err ) ) return cb( err )
					rawData.shortstop_live = res

					cb( null, rawData )
				} )
			} )
		}
		else {
			let analyticsParams = { type: 'partner', time_frame: dateInfo.timeToAdd, start_metrics_date: params.start_metrics_date, num_units: dateInfo.currentTimeFrameAsNumber - 1, sort: 'metrics_ts' }

			DashboardHelper.getAnalyticsData( analyticsParams, params.user, ( err, res ) => {
				if ( helpers.doesExist( err ) ) return cb( err )
				rawData.partner_publishing = res

				cb( null, rawData )
			} )
		}
	}


	handleClick( timeFrame, newTimeFrameMoment=moment() ) {
		this.state.loadData( timeFrame, newTimeFrameMoment, ( err, res ) => {
			let newState = Object.assign( {}, res, { currentTimeFrame: timeFrame, timeFrameMoment: newTimeFrameMoment, menuAnchorEl: null } )
			this.setState( newState )
		} )
	}


	saveExcelWorkbook() {
		try {
			ExcelProcessor.saveWorkbook( this.state.raw_data, this.props.authorizationInfo.user.contact.name, this.state.isShortstop )
		}
		catch ( e ) {
			this.props.dispatch( showNotification( 'Failure', 'Cannot Save', 'error' ) )
		}
	}

	
	renderMenuItems() {
		let titles = []

		let endDate = moment().endOf( 'year' )

		let currentTimeFrame = this.state.currentTimeFrame
		let format = ''
		let timeFrame = ''

		switch ( currentTimeFrame ) {

		case ( 'month' ):
			format = 'YYYY-MM'
			timeFrame = 'month'
			break
		case ( 'week' ):
			format= 'GGGG-WW'
			timeFrame = 'week'
			break
		case ( 'year' ):
			format = 'YYYY'
			timeFrame = 'year'
			break
		}

		DashboardHelper.makeArrayOfDates( { endDate: endDate.clone(), format: format, startDate: this.state.startDate.clone(), timeFrame: timeFrame, array: titles } )

		let menuItems = []
		let numYearsToAdd = 1

		for ( let title of titles ) {

			let newMoment = null
			let disabled = false
			if ( moment( title, format ).isAfter( moment() ) ) {
				disabled= true
			}

			newMoment = moment( title, format )

			menuItems.push(
				<span value={ newMoment.format() } key={ newMoment }>
					<Tooltip id="tooltip" title={ DashboardHelper.prettyDates( newMoment, currentTimeFrame ) } enterDelay={ 500 } leaveDelay={ 200 }>
						<MenuItem
							disabled={ disabled }
							onClick={
								disabled === true
									? () => {}
									: () => this.handleClick( currentTimeFrame, newMoment )
							}
						>
							{ title }
						</MenuItem>
					</Tooltip>
				</span>
			)

			if ( this.state.startDate.clone().add( numYearsToAdd, 'year' ).format( 'YYYY' ) === newMoment.clone().add( 1, currentTimeFrame ).format( 'YYYY' ) ) {
				numYearsToAdd += 1
			}
		}

		return ( menuItems )
	}


	makeGraphElement() {
		let graphs = null
		if ( !this.state.isShortstop ) {
			// Either of the errors or null
			let potentialError = this.state.errors.partner || this.state.errors.lineGraph
			graphs = <div>
				<PartnerSection
					currentTimeFrame={ this.state.currentTimeFrame }
					timeFrameMoment={ this.state.timeFrameMoment }

					lineData={ this.state.raw_data.lineGraphData.partner }
					partnerLineError={ this.state.errors.lineGraph }
					dateInfo={ this.state.dateInfo }

					groupInfo={ this.state.groupInfo }
					tableData={ this.state.raw_data.partner }
					error={ potentialError }
				/>
			</div>
		}
		else {
			graphs = <div>
				<ShortstopSection1
					currentTimeFrame={ this.state.currentTimeFrame }
					timeFrameMoment={ this.state.timeFrameMoment }

					lineGraphError={ this.state.errors.lineGraph }

					dateInfo={ this.state.dateInfo }
					lineGraphData={ this.state.raw_data.lineGraphData }
				/>

				<Typography variant="h6" style={ { marginTop: '10px' } }>Shortstop</Typography>

				<Divider classes={ { root: this.props.classes.thinDivider } }/>

				<ShortstopSection2
					currentTimeFrame={ this.state.currentTimeFrame }
					timeFrameMoment={ this.state.timeFrameMoment }
					shortstopError={ this.state.errors.shortstop }

					data={ this.state.raw_data.shortstop }
					groupInfo={ this.state.groupInfo }
				/>
				<br/>

				<Typography variant="h6">Clip Transfer</Typography>

				<Divider classes={ { root: this.props.classes.thinDivider } }/>
				<ShortstopSection3
					error={ this.state.errors.shortstop_live }

					currentTimeFrame={ this.state.currentTimeFrame }
					timeFrameMoment={ this.state.timeFrameMoment }

					data={ this.state.raw_data.shortstop_live }
					groupInfo={ this.state.groupInfo }
				/>

			</div>
		}

		return graphs
	}


	makeTitleButton( isRight ) {
		let newMoment = this.state.timeFrameMoment.clone().subtract( 1, this.state.currentTimeFrame )
		let display = '-'
		if ( isRight ) {
			display='+'
			newMoment = this.state.timeFrameMoment.clone().add( 1, this.state.currentTimeFrame )
		}
		let disabled = false
		if ( newMoment.isAfter( moment() ) || newMoment.isBefore( this.state.startDate.clone().startOf( this.state.currentTimeFrame ) ) ) {
			disabled = true
		}

		return (
			<Button
				onClick={ () => this.handleClick( this.state.currentTimeFrame, newMoment ) }
				disabled={ disabled }
				variant="outlined"
			>
				{ display }
			</Button>
		)
	}


	makeTitle() {
		let type = 'Shortstop'

		let exportDataStyle = { marginLeft: '10px' }

		if ( !this.state.isShortstop ) {
			type='Partner'
			exportDataStyle = { marginLeft: '10px', display: 'none' }
		}

		let saveDataButton = ( <Button onClick={ this.saveExcelWorkbook } style={ exportDataStyle } variant="outlined" size="large">Export Data</Button> )

		let yearEnd = `:${ this.state.timeFrameMoment.clone().format( 'YY' ) }`
		if ( this.state.currentTimeFrame === 'year' || this.state.currentTimeFrame === 'week' ) {
			yearEnd = ''
		}

		let title = `${ type } info for ${ DashboardHelper.prettyDates( this.state.timeFrameMoment, this.state.currentTimeFrame ) }${ yearEnd }`

		return <div style={ { width: '100%', margin: '0 auto', paddingBottom: '10px', textAlign: 'center' } }>
			<Typography variant="h5" style={ { marginTop: '10px', marginBottom: '10px' } }>
				{ title }
			</Typography>

			<div style={ { display: 'inline-block' } }>
				<ButtonGroup type="radio" name="options" size="large" >
					<Button onClick={ ()=>this.handleClick( 'week' ) } >Week</Button>
					<Button onClick={ ()=>this.handleClick( 'month' ) } >Month</Button>
					<Button onClick={ ()=>this.handleClick( 'year' ) } style={ { marginRight: '10px' } }>Year</Button>
				</ButtonGroup>

				<ButtonGroup size="large">
					{ this.makeTitleButton( false ) }

					<Button onClick={ ( event ) => { this.setState( { menuAnchorEl: event.currentTarget } ) } }>
						{ this.state.timeFrameMoment.format( DashboardHelper.getMomentFormat( this.state.currentTimeFrame ) ) }
						{ '  \u25BE' }
					</Button>

					{ this.makeTitleButton( true ) }
				</ButtonGroup>

				<ButtonGroup size="large">
					{ saveDataButton }
				</ButtonGroup>
				
				<Menu
					id="time-menu"
					anchorEl={ this.state.menuAnchorEl }
					keepMounted
					open={ Boolean( this.state.menuAnchorEl ) }
					onClose={ () => this.setState( { menuAnchorEl: null } ) }
				>
					{ this.renderMenuItems() }
				</Menu>
			</div>
		</div>
	}


	render() {
		if ( this.state.loading ) {
			return ( DashboardHelper.getLoadingScreen() )
		}
		let title = this.makeTitle()
		let graphs = this.makeGraphElement()

		return (
			<div>
				{ title }
				{ graphs }
			</div>
		)
	}
}


function mapStateToProps( state, ownProps ) {
	return {
		authorizationInfo: state.authorizationInfo
	}
}


AnalyticsHome.propTypes = {
	authorizationInfo	: PropTypes.object.isRequired,
	loadData			: PropTypes.func,
	isShortstop			: PropTypes.bool,

	classes				: PropTypes.object.isRequired,
}


export default connect( mapStateToProps )( withStyles( styles )( AnalyticsHome ) )
