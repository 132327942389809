import _cloneDeep from 'lodash/cloneDeep'

const types = require( '../actions/actionTypes' )
const initialState = require( './initialState' )


export default function metadataReducer( state = initialState.pccCategories, action ) {
	if ( action.type === types.GET_PCC_CATEGORIES_SUCCESS ) {
		return _cloneDeep( action.categories.data )
	}

	return state
}
