const types = require( './actionTypes' )

export function addNewUpload( mediaId, name, mediaType ) {
	return { type: types.ADD_NEW_UPLOAD_SUCCESS, mediaId: mediaId, name: name, mediaType: mediaType }
}

export function removeUpload( mediaId ) {
	return { type: types.REMOVE_UPLOAD_SUCCESS, mediaId: mediaId }
}

export function removeUploads() {
	return { type: types.REMOVE_UPLOADS_SUCCESS }
}
