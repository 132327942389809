const types = require( './actionTypes' )


export function login( user, group, metadata ) {
	return { type: types.AUTHORIZATION_LOGIN, user, group, metadata }
}


export function logout() {
	return { type: types.AUTHORIZATION_LOGOUT }
}
