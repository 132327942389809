import _pull from 'lodash/pull'
import _reject from 'lodash/reject'
import _cloneDeep from 'lodash/cloneDeep'

import React from 'react'
import PropTypes from 'prop-types'

import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'

import grey from '@material-ui/core/colors/grey'

import helpers from '../../lib/helpers'

import Group from '../../models/group'


export class GroupDetail extends React.Component {
	constructor( props, context ) {
		super( props, context )

		this.handleACLChange = this.handleACLChange.bind( this )
		this.handleParentUpdate = this.handleParentUpdate.bind( this )
		this.handleSaveGroup = this.handleSaveGroup.bind( this )
		this.handleCancelEditing = this.handleCancelEditing.bind( this )
	}


	groupInfoToState( groupInfo ) {
		if ( helpers.doesNotExist( groupInfo ) ) return groupInfo

		let newGroup = _cloneDeep( groupInfo )
		if ( helpers.doesNotExist( newGroup.acls ) ) newGroup.acls = []

		return newGroup
	}


	isACLEnabled( currentACL, acls ) {
		let acl = acls.find( ( a ) => { return a === currentACL.key } )

		let groupIsMissingACL = false
		if ( acl === undefined ) groupIsMissingACL = true

		return !groupIsMissingACL
	}


	handleCancelEditing() {
		this.props.handleCancelEdit()
	}


	handleSaveGroup() {
		this.props.handleSaveGroup( this.props.group )
	}


	handleUpdate( propertyName, event ) {
		let newGroup = _cloneDeep( this.props.group )

		let newValue = event.target.value
		if ( newValue === '' || newValue === '-' ) {
			delete newGroup[ propertyName ]
		}
		else {
			newGroup[ propertyName ] = newValue
		}

		if ( propertyName === 'type' ) {
			// reset the ACLs
			newGroup.acls = Group.defaultACLsForGroupType( newValue )
		}

		this.props.handleUpdateSelectedGroup( newGroup )
	}


	handleACLChange( evt ) {
		let currentACL = evt.target.value
		let addToList = evt.target.checked

		let newGroup = _cloneDeep( this.props.group )

		if ( addToList ) {
			newGroup.acls.push( currentACL )
		}
		else {
			_pull( newGroup.acls, currentACL )
		}

		this.props.handleUpdateSelectedGroup( newGroup )
	}


	handleParentUpdate( evt ) {
		let newGroup = _cloneDeep( this.props.group )

		let pickedParentId = evt.target.value
		let pickedParent = this.props.groups.find( ( g ) => { return g.group_id === pickedParentId } )

		if ( helpers.doesExist( pickedParent ) && pickedParent !== '-' ) {
			// the parent is already a snapshot
			newGroup.parent = pickedParent
		}
		else {
			delete newGroup.parent
		}

		this.props.handleUpdateSelectedGroup( newGroup )
	}


	renderEmptyDetail() {
		return (
			<Card>
				<CardHeader title="Group Details" titleTypographyProps={ { variant: 'subtitle1' } }></CardHeader>
			</Card>
		)
	}


	renderAcls() {
		let leftAcls = [
			{ key: 'api.publish.create', displayValue: 'Publish API' },
			{ key: 'api.activity.search', displayValue: 'Activity Log' },
			{ key: 'api.media.lookup', displayValue: 'Media Record Access' },
			{ key: 'api.pcc', displayValue: 'PCC Access' },
			{ key: 'api.now-xml', displayValue: 'Shortstop XML Feed' },
		]

		let rightAcls = [
			{ key: 'api.group-config.lookup', displayValue: 'Configuration Access' },
			{ key: 'api.partner-lookup.lookup', displayValue: 'Metadata Access' },
			{ key: 'api.partner-request.lookup', displayValue: 'Request Record Access' },
			{ key: 'api.shortstop', displayValue: 'Shortstop' },
			{ key: 'api.publish.footage', displayValue: 'Partner Footage Access' }
		]

		// unless this is a partner, don't allow the changing of ACLs
		let disabled = true
		if ( this.props.group.type === 'partner' ) disabled = false

		return (
			<div style={ { marginTop: 10, display: 'flex', flexDirection: 'row' } }>
				<div style={ { display: 'flex', flexDirection: 'column', width: '50%' } }>
					{ leftAcls.map( ( acl ) => {
						// don't allow changing the PCC ACL, no matter the group type
						let itemDisabled = disabled
						if ( acl.key === 'api.pcc' || acl.key === 'api.shortstop' || acl.key === 'api.now-xml' ) itemDisabled = true

						return (
							<FormControlLabel
								key={ acl.key }
								control={
									<Checkbox
										style={ { padding: 0, marginLeft: 10 } }
										key={ acl.key }
										checked={ this.isACLEnabled( acl, this.props.group.acls ) }
										onChange={ this.handleACLChange }
										value={ acl.key }
										disabled={ itemDisabled }
										color="primary"
									/>
								}
								label={ acl.displayValue }
							/>
						)
					} ) }
				</div>

				<div style={ { display: 'flex', flexDirection: 'column', width: '50%' } }>
					{ rightAcls.map( ( acl ) => {
						// don't allow changing the Shortstop ACL, no matter the group type
						let itemDisabled = disabled
						if ( acl.key === 'api.shortstop' ) itemDisabled = true

						return (
							<FormControlLabel
								key={ acl.key }
								control={
									<Checkbox
										style={ { padding: 0, marginLeft: 10 } }
										key={ acl.key }
										checked={ this.isACLEnabled( acl, this.props.group.acls ) }
										onChange={ this.handleACLChange }
										value={ acl.key }
										disabled={ itemDisabled }
										color="primary"
									/>
								}
								label={ acl.displayValue }
							/>
						)
					} ) }
				</div>
			</div>
		)
	}


	renderParentGroupDropdown( currentGroup, groups ) {
		let groupsWithoutUs = _reject( groups, ( g ) => { return g.group_id === currentGroup._id } )
		let parentId = this.props.group.parent ? this.props.group.parent.group_id : null

		return (
			<FormControl margin="dense" fullWidth style={ { marginTop: 10 } }>
				<InputLabel>Parent</InputLabel>
				<Select onChange={ this.handleParentUpdate } value={ parentId || '-' }>
					<MenuItem value="-">(No Parent Group)</MenuItem>
					{
						groupsWithoutUs.map( ( item ) => {
							return ( <MenuItem key={ item.group_id } value={ item.group_id }>{ item.name }</MenuItem> )
						} )
					}
				</Select>
			</FormControl>
		)
	}


	render() {
		if ( helpers.doesNotExist( this.props.group ) ) return this.renderEmptyDetail()

		return (
			<div>
				<Card>
					<CardHeader
						avatar={
							<Avatar
								src={ this.props.group.logo_url }
								style = { { height: 40, width: 40 } }
							/>
						}
						title={
							<Typography style={ { whiteSpace: 'pre-wrap' } } variant="h6">
								{ this.props.group.name }
							</Typography>
						}
						disableTypography
					/>

					<Divider />

					<CardContent>
						<TextField
							autoFocus
							margin="dense"
							label="Name"
							placehodler="Group Name"
							fullWidth
							value={ this.props.group.name || '' }
							onChange={ ( e ) => { this.handleUpdate( 'name', e ) } }
						/>

						<FormControl margin="dense" fullWidth style={ { marginTop: 10 } }>
							<InputLabel>Partner Type</InputLabel>
							<Select onChange={ ( e ) => { this.handleUpdate( 'type', e ) } } value={ this.props.group.type || '-' }>
								<MenuItem value="-">- Select Partner Type -</MenuItem>
								{
									Group.GROUP_TYPES.map( ( item ) => {
										return ( <MenuItem key={ item.key } value={ item.key }>{ item.displayValue }</MenuItem> )
									} )
								}
							</Select>
						</FormControl>

						<TextField
							margin="dense"
							label="Logo URL"
							placehodler="Logo URL"
							fullWidth
							style={ { marginTop: 10 } }
							value={ this.props.group.logo_url || '' }
							onChange={ ( e ) => { this.handleUpdate( 'logo_url', e ) } }
						/>

						{ this.renderAcls() }

						{ this.renderParentGroupDropdown( this.props.group, this.props.groups ) }
					</CardContent>

					<Divider />

					<CardActions>
						<Tooltip title="Save" enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton style={ { marginLeft: 10, color: grey[ 700 ] } } onClick={ this.handleSaveGroup }>
								<SaveIcon />
							</IconButton>
						</Tooltip>

						<Tooltip title="Cancel" enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton style={ { marginLeft: 'auto', color: grey[ 700 ] } } onClick={ this.handleCancelEditing }>
								<CancelIcon />
							</IconButton>
						</Tooltip>
					</CardActions>
				</Card>
			</div>
		)
	}
}


GroupDetail.propTypes = {
	group						: PropTypes.object,
	groups						: PropTypes.array.isRequired,
	handleSaveGroup				: PropTypes.func.isRequired,
	handleCancelEdit			: PropTypes.func.isRequired,
	handleUpdateSelectedGroup	: PropTypes.func.isRequired,

	// injected by material-ui
}


export default GroupDetail
