import config from './config'

/**
	A centralized place to collect logging statements / don't console.log in
	production / send production console logs elsewhere
*/

export function info( text ) {
	if ( config.environment !== 'production' ) console.log( text )	// eslint-disable-line no-console
}


export function warn( text ) {
	if ( config.environment !== 'production' ) console.log( text )	// eslint-disable-line no-console
}


export function error( text ) {
	if ( config.environment !== 'production' ) console.error( `Error: ${ text }` )	// eslint-disable-line no-console
}


export function log( text ) {
	if ( config.environment !== 'production' ) console.log( text )	// eslint-disable-line no-console
}


export function debug( text ) {
	if ( config.environment !== 'production' && config.environment !== 'test' ) console.log( text )	// eslint-disable-line no-console
}


export function silly( text ) {
	// do nothing
}
