import React from 'react'
import PropTypes from 'prop-types'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'

import AutoRenewIcon from '@material-ui/icons/Autorenew'


class ProgramsGrid extends React.Component {
	programSelected( program ) {
		this.props.selectedCallback( program )
	}


	renderTablePagination( total, currentPage, onPageChanged ) {
		if ( total === 0 ) return null
		
		return (
			<TablePagination
				rowsPerPageOptions={ [] }
				component="div"
				count={ total }
				rowsPerPage={ 10 }
				page={ currentPage }
				backIconButtonProps={ {
					'aria-label': 'Previous Page',
				} }
				nextIconButtonProps={ {
					'aria-label': 'Next Page',
				} }
				onChangePage={ ( e, pageNumber ) => { if ( !e ) return; onPageChanged( pageNumber ) } }
			/>
		)
	}

	
	render() {
		return (
			<Paper elevation={ 2 }>
				<AppBar position="static" color="default" style={ { boxShadow: 'none' } }>
					<Toolbar>
						<div style={ { marginLeft: 'auto' } }>
							<Tooltip className="refresh-button" title="Refresh" enterDelay={ 500 } leaveDelay={ 200 }>
								<IconButton onClick={ this.props.onRefreshList }>
									<AutoRenewIcon />
								</IconButton>
							</Tooltip>
						</div>
					</Toolbar>
				</AppBar>
				
				<Table size="small" className="pcc-programs">
					<TableHead>
						<TableRow>
							<TableCell style={ { width: '41%' } }>Title</TableCell>
							<TableCell style={ { width: '12%' } }>Language</TableCell>
							<TableCell style={ { width: '10%' } }>Duration</TableCell>
							<TableCell style={ { width: '13%' } }>Sport</TableCell>
							<TableCell style={ { width: '13%' } }>League</TableCell>
							<TableCell style={ { width: '11%' } }>Series</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{
							this.props.programs.map( ( program ) => {
								return (
									<TableRow hover className="pcc-program" onClick={ this.programSelected.bind( this, program ) } key={ `pcc-program-${ program.id }` }>
										<TableCell>{ program.title }</TableCell>
										<TableCell>{ program.language }</TableCell>
										<TableCell>{ program.getDuration( program ) }</TableCell>
										<TableCell>{ program.getCategoryValue( 'sport' ) }</TableCell>
										<TableCell>{ program.getCategoryValue( 'league' ) }</TableCell>
										<TableCell>{ program.getCategoryValue( 'franchise' ) }</TableCell>
									</TableRow>
								)
							} )
						}
					</TableBody>
				</Table>

				{ this.renderTablePagination( this.props.totalRecords, this.props.currentPage, this.props.onPageSelected ) }
			</Paper>
		)
	}
}


ProgramsGrid.propTypes = {
	programs			: PropTypes.array.isRequired,
	selectedCallback	: PropTypes.func.isRequired,
	onRefreshList		: PropTypes.func.isRequired,
	totalRecords		: PropTypes.number.isRequired,
	currentPage			: PropTypes.number.isRequired,
	onPageSelected		: PropTypes.func.isRequired
}


export default ProgramsGrid
