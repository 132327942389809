import React from 'react'
import PropTypes from 'prop-types'

import helpers from '../../lib/helpers'
import Config from '../../lib/config'
import { callApi } from '../../actions/apiCallActions'
import Api from '../../dataSource/api'
import { removeUpload } from '../../actions/uploadActions'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogButton from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

import LinearProgress from '@material-ui/core/LinearProgress'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'


class FileUploadDetails extends React.Component {
	constructor( props ) {
		super( props )

		this.state = {
			fileData: {},
			style: {},
			showAbortDialog: false,
			mediaId : null
		}
	}


	async componentDidMount() {
		let file = window.formData ? window.formData.get( this.props.file.mediaId ) : undefined

		if ( helpers.doesExist( file ) ) {
			this.setState( state => ( { fileData: { ...state.fileData, file: file } } ) )

			this.mainThread = await this.initiateWorker( file )

			this.mainThread.onmessage = ( e ) => {
				let responseType = e.data[0]
				let fileToSave = null
				let user = null
				switch ( responseType ) {
				case 'upload':
					fileToSave = e.data[1]
					user = e.data[3]
					fileToSave.progress = Math.round( ( fileToSave.document.parts.length / fileToSave.document.upload_total_parts ) * 95 + 5 )
					fileToSave.document.upload_state = 'uploading'

					this.setState( { fileData: fileToSave } )

					if ( fileToSave.document.upload_parts_left === 0 ) {
						this.mainThread.postMessage( [ 'complete', user, e.data[2], fileToSave, Config.apiHost ] )
					}
					else {
						this.mainThread.postMessage( [ 'upload', user, e.data[2], fileToSave, Config.apiHost ] )
					}

					break
				case 'complete':
					fileToSave = e.data[1]
					fileToSave.document.upload_state = 'complete'
					fileToSave.progress = 100

					this.setState( { fileData: fileToSave } )

					this.mainThread.terminate()

					return
				default :
					window.alert( `Error thrown ${ e.data[0] }` )
					return
				}
			}
		}
	}


	async initiateWorker( file ) {
		if ( window.Worker ) {
			this.setState( { mediaId : this.props.file.mediaId } )

			let blobURL = URL.createObjectURL( new Blob( [ '(',
					function() {
						onmessage = function( e ) {
							let requestType = e.data[0]
							let user = e.data[1]
							let mediaId = e.data[2]
							let uploadedFile = e.data[3]
							let apiHost = e.data[4]
							let xhttp = null
							let partNum = null
							let multipart = { Parts: [] }

							switch ( requestType ) {
							case 'upload':
								xhttp = new XMLHttpRequest()
								xhttp.open( 'POST', `${ apiHost }/api/v1/uploads/${ uploadedFile.document._id }/get-part-url`, true )
								xhttp.setRequestHeader( 'x-mex-user-id', user._id )
								xhttp.setRequestHeader( 'Authorization', `Bearer ${ user.access_tokens.bearer }` )
								xhttp.setRequestHeader( 'Content-type', 'application/json' )

								partNum = uploadedFile.document.parts.length + 1

								xhttp.send( JSON.stringify( {
									'document': uploadedFile.document,
									'upload_part_number': partNum,
									'expiration': 900
								} ) )

								xhttp.onload = function() {
									if ( xhttp.status === 200 ) {
										if ( partNum === 1 ) {
											uploadedFile.document.upload_total_parts = Math.ceil( uploadedFile.file.size / 10000000 )
										}

										let fileSize = uploadedFile.file.size
										uploadedFile.document.upload_parts_left = uploadedFile.document.upload_total_parts - partNum

										//each chunk must at least be 5 mb, uploading in 10 mb chunks
										let start = ( partNum === 1 ) ? 0 : ( ( partNum - 1 ) * 10000000 ) + 1
										let sizeLeft = ( fileSize < 10000000 ) ? fileSize : fileSize - ( partNum * 10000000 )

										let stop = null
										if ( ( uploadedFile.document.upload_parts_left === 2 && ( sizeLeft < 15000000 ) ) || fileSize < 10000000 ) {
											stop = fileSize - 1
											uploadedFile.document.upload_parts_left = 0
										}
										else {
											stop = partNum * 10000000
										}

										let blob = uploadedFile.file.slice( start, stop + 1 )
										let secondRequest = new XMLHttpRequest()
										secondRequest.open( 'PUT', JSON.parse( xhttp.response ).part_url, true )
										secondRequest.send( blob )

										secondRequest.onload = function( e ) {
											if ( secondRequest.status === 200 ) {
												uploadedFile.document.parts.push( { 'ETag' : secondRequest.getResponseHeader( 'ETag' ), 'PartNumber' : partNum } )
												postMessage( [ 'upload', uploadedFile, mediaId, user ] )
											}
											else {
												postMessage( `Error uploading part of local file mediaId: ${ mediaId } on AWS` )
											}
										}
									}
									else {
										postMessage( `Error for creating the upload on AWS with mediaId: ${ mediaId } ` )
									}
								}
								break
							case 'complete':
								xhttp = new XMLHttpRequest()
								xhttp.open( 'POST', `${ apiHost }/api/v1/uploads/${ uploadedFile.document._id }/complete`, true )
								xhttp.setRequestHeader( 'x-mex-user-id', user._id )
								xhttp.setRequestHeader( 'Authorization', `Bearer ${ user.access_tokens.bearer }` )
								xhttp.setRequestHeader( 'Content-type', 'application/json' )

								uploadedFile.document.parts.forEach( ( part ) => {
									multipart.Parts.push( part )
								} )

								xhttp.send( JSON.stringify( {
									'MultipartUpload': multipart
								} ) )

								xhttp.onload = function() {
									if ( xhttp.status === 200 ) {
										uploadedFile.document.completed_url = JSON.parse( xhttp.response ).completed_url
										postMessage( [ 'complete', uploadedFile ] )
									}
									else {
										postMessage( `Error completing the file upload on AWS with mediaId: ${ mediaId } ` )
									}
								}
								break
							}
						}
					}.toString(), ')()' ], { type: 'application/javascript' } ) ), worker = new Worker( blobURL )

			let workItem = JSON.stringify( {
				upload_total_parts	: Math.ceil( file.size / 10000000 ),
				file_content_type	: file.type,
				client_file_name	: file.name,
				media_id			: this.props.file.mediaId
			} )

			await this.props.dispatch(
				callApi(
					() => {
						return ( ( currentUser, file ) => {
							return Api.createUploadForUser( currentUser, file )
						} )( this.currentUser, workItem )
					},
					( err, response ) => {
						if ( response && response.statusCode === 200 ) {
							let fileToSave = this.state.fileData
							fileToSave.document = JSON.parse( JSON.stringify( response.body ) )
							fileToSave.progress = 5

							this.setState( { fileData: fileToSave } )

							let hostUrl = ( Config.environment === 'local' ) ? Config.apiHost : Config.getRegionSpecificApiUrl.replace( 'REGION', fileToSave.document.aws_region )
							worker.postMessage( [ 'upload', this.currentUser, this.props.file.mediaId, fileToSave, hostUrl ] )

							return
						}
						else {
							window.alert( `Unable to create upload in AWS for MediaId: ${ this.props.file.mediaId }. Please try again` )

							return
						}
					}
				)
			)

			return worker
		}
	}


	abortDialog() {
		this.setState( { showAbortDialog: true } )
		return
	}


	handleAbort( abort ) {
		this.setState( { showAbortDialog: false } )
		if ( abort ) {
			return this.abortUpload()
		}
		else {
			return
		}
	}


	abortUpload() {
		let mediaId = this.props.file.mediaId

		this.mainThread.terminate()
		this.props.dispatch ( removeUpload( mediaId ) )
		this.setState( { style :  { 'display': 'none' } } )

		let xhttp = new XMLHttpRequest()
		xhttp.open( 'POST', `${ Config.apiHost }/api/v1/uploads/${ this.state.fileData.document._id }/abort`, true )
		xhttp.setRequestHeader( 'x-mex-user-id', this.currentUser._id )
		xhttp.setRequestHeader( 'Authorization', `Bearer ${ this.currentUser.access_tokens.bearer }` )
		xhttp.setRequestHeader( 'Content-type', 'application/json' )

		xhttp.send( JSON.stringify( {
			'document': this.state.fileData.document
		} ) )

		xhttp.onload = function() {
			if ( xhttp.status === 200 ) {

				return
			}
			else {
				window.alert( `Unable to abort upload for media_id: ${ mediaId }` )
				return
			}
		}
	}


	completeUpload() {
		this.setState( { style :  { 'display': 'none' } } )

		let mediaId = this.props.file.mediaId
		this.props.dispatch ( removeUpload( mediaId ) )
		this.setState( { fileData: {} } )

		return
	}


	get currentUser() {
		return this.props.authorizationInfo.user
	}


	render() {
		let fileCheck = Boolean( this.state.fileData && this.state.fileData.file )
		let completeCheck = Boolean( this.state.fileData.document && this.state.fileData.document.completed_url )

		let abortDialog = ( <div /> )
		if ( this.state.showAbortDialog ) {
			abortDialog = (
				<Dialog open={ this.state.showAbortDialog }>
					<DialogTitle id="Abort File Upload">Abort File Upload</DialogTitle>

					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Please confirm you wish to abort your video upload.
						</DialogContentText>
					</DialogContent>

					<DialogActions>
						<DialogButton id="yes-dialog" onClick={ () => this.handleAbort( true ) } color="default">
							Yes
						</DialogButton>
						<DialogButton id="no-dialog" onClick={ () => this.handleAbort( false ) } color="primary">
							No
						</DialogButton>
					</DialogActions>
				</Dialog>
			)
		}


		return (
			<div>
				{ abortDialog }
				<div className="file-upload-detail" style={ this.state.style }>
					<ListItem className="upload-list-item">
						<ListItemText>
							<span>
								{ fileCheck ? this.state.fileData.file.name : 'myFile.mov' }
								<span className="upload-size"> { fileCheck ? `${ this.state.fileData.file.size } bytes` : '' } </span>
								&nbsp;&nbsp;&nbsp;
								<a href={ completeCheck ? this.state.fileData.document.completed_url : '' }>{ completeCheck ? 'Video Link' : '' }</a>
							</span>
						</ListItemText>

						<ListItemSecondaryAction>
							<IconButton className="upload-abort-button" onClick={ completeCheck ? this.completeUpload.bind( this ) : this.abortDialog.bind( this ) }>
								<CloseIcon />
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
					<LinearProgress variant="determinate" value={ this.state.fileData.progress ? this.state.fileData.progress : 0 } />
				</div>
			</div>
		)
	}
}


FileUploadDetails.propTypes = {
	authorizationInfo : PropTypes.object.isRequired,
	file			  : PropTypes.object.isRequired,
	dispatch		  : PropTypes.func.isRequired
}


export default FileUploadDetails
