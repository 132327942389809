import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import _cloneDeep from 'lodash/cloneDeep'

import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Radio from '@material-ui/core/Radio'

import CategorySearch from '../common/category_search'

import API from '../../dataSource/api'
import helpers from '../../lib/helpers'
import Analytics from '../../lib/analytics'


const styles = ( theme ) => ( {
	dialog_paper		: {
		minHeight			: '65%',
		minWidth			: '35%',
	},
	events_container	: {
		marginTop			: 20,
		display				: 'flex',
		flexDirection		: 'row',
		alignItems			: 'center',
	},
	events_label		: {
		marginTop			: 2,
	},
	events_progress		: {
		marginLeft			: 5,
	},
	events				: {
		marginTop			: 20,
	},
	event_logo			: {
		width				: 30,
		height				: 30,
	},
	event_label			: {
		marginLeft			: 5,
	},
	start_time_container: {
		display				: 'flex',
		flexDirection		: 'column',
		marginLeft			: 'auto',
		marginRight			: 10,
	},
	event_start_time	: {
		marginLeft			: 40,
	},
	dialog_actions 				: {
		[theme.breakpoints.down( 'sm' )] : {
			marginBottom	: 40,
			marginRight		: 40
		}
	}
} )


class EventSearch extends React.Component {
	constructor( props ) {
		super( props )

		this.state = {
			categories_search	: { current_search_term: '', type: 'team', searching: false, results: { categories: [], error: null } },
			retrieving_events	: false,
			selected_team		: null,
			team_events			: [],
			selected_event		: null,
		}

		this.updateCurrentSearchTerm = this.updateCurrentSearchTerm.bind( this )
		this.categorySelected = this.categorySelected.bind( this )
		this.eventSelected = this.eventSelected.bind( this )
		this.cancelAssociation = this.cancelAssociation.bind( this )
		this.saveAssociation = this.saveAssociation.bind( this )

		this.API = API
		if ( props.api ) this.API = props.api
		this.propsApi = props.api
	}


	async updateCurrentSearchTerm( newSearchTerm ) {
		Analytics.recordUserActivity()

		if ( newSearchTerm === '' ) {
			await this.resetSearch()
			return
		}

		let newState = _cloneDeep( this.state )
		newState.categories_search.current_search_term = newSearchTerm

		let newCategoryState = newState.categories_search.results.categories
		if ( newSearchTerm.length < 3 ) newCategoryState = []
		newState.categories_search.results = { error: null, categories: newCategoryState }

		this.setState( newState, () => {
			if ( newState.categories_search.current_search_term.length >= 3 ) {
				this.searchCategories( newState.categories_search.current_search_term, newState.categories_search.type )
			}
		} )
	}


	categorySelected( category ) {
		let newState = _cloneDeep( this.state )
		newState.retrieving_events = true

		this.setState( newState, async () => {
			try {
				await this.resetSearch()
				let events = null
				if ( this.propsApi ) {
					events = await this.API.Events.searchEvents( this.props.currentUser, category.uid, moment().subtract( 1, 'days' ).format( 'YYYY-MM-DD' ) )
				}
				else {
					events = await this.API.searchEvents( this.props.currentUser, category.uid, moment().subtract( 1, 'days' ).format( 'YYYY-MM-DD' ) )
				}


				let newState = _cloneDeep( this.state )

				newState.retrieving_events = false
				newState.selected_team = category
				newState.team_events = events

				newState.selected_event = null
				if ( events.length > 0 ) {
					for ( let event of events ) {
						if ( moment( event.start_time ).isSame( moment(), 'day' ) ) {
							newState.selected_event = event
							break
						}
					}

					if ( newState.selected_event === null ) newState.selected_event = events[ 0 ]
				}

				this.setState( newState, () => {
					if ( this.props.categorySelectedCB ) this.props.categorySelectedCB()
				} )
			}
			catch ( e ) {
				// this.props.showErrorDialog( 'Get Team Events', e )
			}
		} )
	}


	resetSearch() {
		return new Promise( ( resolve, reject ) => {
			let newState = _cloneDeep( this.state )

			newState.categories_search.searching = false
			newState.categories_search.results = { categories: [], error: null }

			this.setState( newState, () => { resolve() } )
		} )
	}


	searchCategories( searchTerm, categoryType ) {
		if ( searchTerm === '' ) return

		let newState = _cloneDeep( this.state )
		newState.categories_search.searching = true

		let searchGuruCategoryType
		if ( categoryType !== 'any' ) searchGuruCategoryType = categoryType

		this.setState( newState, () => {
			this.API.searchCategories( this.props.currentUser, searchTerm, searchGuruCategoryType )
				.then( ( searchResults ) => {
					if ( searchResults.searchTerm !== this.state.categories_search.current_search_term ) return

					let newState = _cloneDeep( this.state )

					newState.categories_search.searching = false
					newState.categories_search.results = { categories: searchResults.models, error: null }

					this.setState( newState )
				} )
				.catch( ( err ) => {
					let newState = _cloneDeep( this.state )

					newState.categories_search.searching = false
					newState.categories_search.results = { categories: [], error: err }

					this.setState( newState )
				} )
		} )
	}


	eventSelected( controlEvent ) {
		let newState = _cloneDeep( this.state )

		//there will be no searched/recent events stored in the local storage, user will search every time
		newState.selected_event = this.state.team_events.find( ( e ) => { return e.uid === controlEvent.target.value } )

		this.setState( newState )
	}


	cancelAssociation() {
		this.props.closeDialog( null )
	}


	async saveAssociation() {
		let leagueCategory = null

		if ( this.state.selected_event ) {
			try {
				if ( this.propsApi ) {
					leagueCategory = await this.API.Categories.getLeagueCategory( this.props.currentUser, this.state.selected_event.league )
				}
				else {
					leagueCategory = await this.API.getLeagueCategory( this.props.currentUser, this.state.selected_event.league )
				}

			}
			catch ( e ) {
				console.log( e ) // eslint-disable-line no-console
			}
		}

		this.props.closeDialog( this.state.selected_event, leagueCategory )
	}


	renderNoEvents() {
		return (
			<div>
				<Typography variant="caption" color="error" noWrap>
					NO EVENTS FOUND
				</Typography>
			</div>
		)
	}


	renderEvents( event, index, isRecent ) {
		let containerStyle = {
			display				: 'flex',
			alignItems			: 'center',
		}

		if ( index < this.state.team_events.length - 1 || isRecent ) {
			containerStyle.marginBottom = 20
		}

		return (
			<div style={ containerStyle } key={ event.uid }>
				<Radio
					checked={ ( this.state.selected_event ) ? this.state.selected_event.uid === event.uid : false }
					onChange={ this.eventSelected }
					value={ event.uid }
				/>

				<div style={ { display: 'flex', flexDirection: 'column' } }>
					<div style={ { display: 'flex', alignItems: 'center' } }>
						<img className={ this.props.classes.event_logo } alt={ event.vis_team ? event.vis_team.name : '' } src={ event.vis_team ? helpers.getSecureCDNLink( event.vis_team.logo_url ) : '' } />
						<Typography className={ this.props.classes.event_label } variant="subtitle1" color="textPrimary" noWrap>
							{ event.vis_team ? event.vis_team.name : '' }
						</Typography>
					</div>

					<div style={ { display: 'flex', alignItems: 'center', marginTop: 10 } }>
						<img className={ this.props.classes.event_logo } alt={ event.home_team ? event.home_team.name : '' } src={ event.home_team ? helpers.getSecureCDNLink( event.home_team.logo_url ) : '' } />
						<Typography className={ this.props.classes.event_label } variant="subtitle1" color="textPrimary" noWrap>
							{ event.home_team ? event.home_team.name : '' }
						</Typography>
					</div>
				</div>

				<div style={ { display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 10 } }>
					<Typography variant="subtitle1" color="textSecondary" style={ { marginLeft: 'auto' } }>
						{ moment( event.start_time ).format( 'ddd MMM Do' ) }
					</Typography>

					<Typography variant="subtitle1" color="textSecondary" style={ { marginLeft: 'auto' } }>
						{ moment( event.start_time ).format( 'h:mm A' ) }
					</Typography>
				</div>
			</div>
		)
	}


	renderNewEvents() {
		if ( this.state.selected_team === null ) return ( <div /> )

		if ( this.state.team_events.length === 0 ) {
			return (
				this.renderNoEvents()
			)
		}

		return (
			<div>
				{ this.state.team_events.map( ( event, index ) => {
					return this.renderEvents( event, index, false )
				} ) }
			</div>
		)
	}


	render() {
		let retrievingEventProgress = ( <div /> )
		if ( this.state.retrieving_events ) {
			retrievingEventProgress = (
				<CircularProgress className={ this.props.classes.events_progress } size={ 20 } />
			)
		}

		return (
			<Dialog
				disableBackdropClick
				open={ this.props.isOpen }
				onClose={ this.props.closeDialog }
				classes={ { paper: this.props.classes.dialog_paper } }
			>
				<DialogTitle> { 'EVENT ASSOCIATION' }</DialogTitle>
				<DialogContent>
					<CategorySearch
						displayTypeSelector={ false }
						clearOnSelection={ false }
						placeholderText={ 'Search Teams' }
						searchTerm={ this.state.categories_search.current_search_term }
						categoryType={ this.state.categories_search.type }
						searching={ this.state.categories_search.searching }
						searchResults={ this.state.categories_search.results }
						selectedCategories={ [] }
						updateSearchTerm={ this.updateCurrentSearchTerm }
						updateCategoryType={ () => {} }
						categorySelected={ this.categorySelected }
						categoryRemoved={ () => {} }
					/>

					<div className={ this.props.classes.events_container }>
						<Typography className={ this.props.classes.events_label } variant="subtitle1" color="textSecondary" noWrap>
							UPCOMING EVENTS
						</Typography>

						{ retrievingEventProgress }
					</div>

					<div className={ this.props.classes.events }>
						{ this.renderNewEvents() }
					</div>
				</DialogContent>
				<DialogActions className={ this.props.classes.dialog_actions }>
					<Button variant="contained" onClick={ this.saveAssociation } color="primary">
						OK
					</Button>
					<Button variant="contained" onClick={ this.cancelAssociation } color="secondary">
						CANCEL
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}


EventSearch.propTypes = {
	currentUser			: PropTypes.object.isRequired,

	// component specific
	isOpen				: PropTypes.bool.isRequired,
	closeDialog			: PropTypes.func.isRequired,

	// test hooks
	api					: PropTypes.object,
	categorySelectedCB	: PropTypes.func,

	// injected by material-ui
	classes				: PropTypes.object.isRequired,
}


export default withStyles( styles )( EventSearch )
