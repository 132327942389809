import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'

import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import * as Redux from 'react-redux'

import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'

import Analytics from '../../lib/analytics'
import helpers from '../../lib/helpers'

import * as logger from '../../lib/logger'

import Api from '../../dataSource/api'
import { callApi, reportApiError } from '../../actions/apiCallActions'
import { addNewUpload } from '../../actions/uploadActions'
import { showNotification } from '../../actions/appNotificationActions'

import Media from '../../models/media'

import VideoDetails from './video_details'

import { withStyles } from '@material-ui/core/styles'

import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import Checkbox from '@material-ui/core/Checkbox'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import IconButton from '@material-ui/core/IconButton'
import HelpIcon from '@material-ui/icons/Help'
import UploadIcon from '@material-ui/icons/CloudUpload'

import DateFnsUtils from '@date-io/moment'

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'


const styles = ( theme ) => ( {
	root				: {
		flexGrow		: 1,
	},
	uploadIconButtonRoot: {
		padding			: '3px'
	},
	input				: {
		marginLeft		: 8,
		flex			: 1,
	},
	inputLabelShrink	: {
		transform		: 'translate(0, -11px) scale(0.75);'
	},
	elevatedIconButton	: {
		paddingBottom	: '15px'
	},
	inputLabelText		: {
		fontSize		: '17px'
	},
	typography			: {
		padding			: theme.spacing( 1 ),
	},
	wideFormLabel		: {
		minWidth		: '125%'
	},
	dateFormLabel		: {
		marginLeft		: '-8px'
	},
	cardContentPadding	: {
		padding			: '14px'
	}
} )

const LANGUAGES = {
	en: 'English',
	es: 'Spanish',
	pt: 'Portuguese'
}

export class UploadPage extends React.Component {
	constructor( props ) {
		super( props )

		// we don't want to clear out the groups property once it's been set
		this.state = Object.assign( this.initialState, { groups: [], partnerConfig: null, partnerLookup: null } )

		this.handlePartnerFootage = this.handlePartnerFootage.bind( this )
		this.handleFieldChange = this.handleFieldChange.bind( this )
	}


	componentDidMount() {
		if ( !this.currentUser.isInternal ) {
			// a partner is logged in, get their config, otherwise this is an admin user and they need to select a group to impersonate
			this.loadConfigForGroup( this.currentUser.group.group_id )
		}
		else {
			// load the list of groups that the admin will select from
			this.props.dispatch(
				callApi(
					() => {
						return ( ( currentUser, params ) => {
							return Api.listGroups( currentUser, params )
						} )( this.currentUser, { type: 'partner' } )
					},
					this.handleListGroups.bind( this )
				)
			)
		}
	}


	get initialState() {
		return {
			users				: [],
			uploadVideoInfo		: {
				video_url					: null,
				video_upload_type			: null,
				thumbnail_url				: null,
				headline					: null,
				sport						: null,
				league						: null,
				media_type					: null,
				language					: null,
				caption						: null,
				teams						: [],
				keywords					: null,
				publish_date				: new Date().toISOString(),
				expiration_date				: moment().add( 2, 'years' ).toISOString(),
				game_id						: null,
				aspect						: '16:9',
				categories					: [],
				local_video_file			: null,
				local_video_content_type 	: null
			},
			impersonatedGroupId	: null,
			impersonatedUser	: null,
			validationState		: this.initialValidationState,
			partner_footage 	: false,
		}
	}


	get initialValidationState() {
		return {
			is_valid		: false,
			impersonated_group	: {
				state		: null,
				help_text	: ''
			},
			impersonated_user	: {
				state		: null,
				help_text	: ''
			},
			video_url		: {
				state		: null,
				help_text	: ''
			},
			video_upload_type		: {
				state		: null,
				help_text	: ''
			},
			thumbnail_url		: {
				state		: null,
				help_text	: ''
			},
			headline			: {
				state		: null,
				help_text	: ''
			},
			caption				: {
				state		: null,
				help_text	: ''
			},
			media_type			: {
				state		: null,
				help_text	: ''
			},
			language			: {
				state		: null,
				help_text	: ''
			},
			sport				: {
				state		: null,
				help_text	: ''
			},
			publish_date		: {
				state		: null,
				help_text	: ''
			},
			expiration_date		: {
				state		: null,
				help_text	: ''
			},
			local_video_content_type	: {
				state		: null,
				help_text	: ''
			}
		}
	}


	getInternalValidation() {
		let isValid = true
		let newValidationState = this.initialValidationState

		if ( helpers.doesNotExist( this.state.impersonatedGroupId ) ) {
			newValidationState.impersonated_group.state = 'error'
			newValidationState.impersonated_group.help_text = 'Select the group that this request will be submitted for'

			isValid = false
		}

		if ( helpers.doesExist( this.state.impersonatedGroupId ) && helpers.doesNotExist( this.state.partnerConfig ) ) {
			newValidationState.impersonated_group.state = 'error'
			newValidationState.impersonated_group.help_text = 'The selected group does not have any configured publishing rules'

			isValid = false
		}
		else {
			if ( helpers.doesNotExist( this.state.impersonatedUser ) ) {
				newValidationState.impersonated_user.state = 'error'
				newValidationState.impersonated_user.help_text = 'Select a user to submit this request on behalf of'

				isValid = false
			}

			if ( this.state.users.length === 0 ) {
				newValidationState.impersonated_user.state = 'error'
				newValidationState.impersonated_user.help_text = 'No users are setup for this group'

				isValid = false
			}
		}

		return { isValid: isValid, newValidationState: newValidationState }
	}


	async setValidationState() {
		let isValid = true
		let newValidationState = this.initialValidationState

		if ( this.currentUser.isInternal ) {
			const internalValidation = this.getInternalValidation()

			isValid = internalValidation.isValid
			newValidationState = internalValidation.newValidationState
		}

		if ( !this.currentUser.isInternal || ( this.currentUser.isInternal && helpers.doesExist( this.state.impersonatedGroupId ) && helpers.doesExist( this.state.impersonatedUser ) ) ) {
			if ( ( helpers.doesNotExist( this.state.uploadVideoInfo.video_url ) || this.state.uploadVideoInfo.video_url.trim() === '' ) && helpers.doesNotExist( this.state.uploadVideoInfo.local_video_file ) ) {
				newValidationState.video_url.state = 'error'
				newValidationState.video_url.help_text = 'Video URL or Local File is required'

				isValid = false
			}

			if ( helpers.doesExist( this.state.uploadVideoInfo.local_video_content_type ) && this.state.uploadVideoInfo.local_video_content_type.includes( 'mp4' ) === false ) {
				newValidationState.local_video_content_type.state = 'error'
				newValidationState.local_video_content_type.help_text = 'Improper format for local video file, please upload a .mp4'

				isValid = false
			}

			if ( helpers.doesNotExist( this.state.uploadVideoInfo.thumbnail_url ) || this.state.uploadVideoInfo.thumbnail_url.trim() === '' ) {
				newValidationState.thumbnail_url.state = 'error'
				newValidationState.thumbnail_url.help_text = 'Thumbnail URL or Local File is required'

				isValid = false
			}

			if ( helpers.doesNotExist( this.state.uploadVideoInfo.headline ) || this.state.uploadVideoInfo.headline.trim() === '' ) {
				newValidationState.headline.state = 'error'
				newValidationState.headline.help_text = 'Headline is required'

				isValid = false
			}

			if ( helpers.doesNotExist( this.state.uploadVideoInfo.caption ) || this.state.uploadVideoInfo.caption.trim() === '' ) {
				newValidationState.caption.state = 'error'
				newValidationState.caption.help_text = 'Caption is required'

				isValid = false
			}

			if ( !this.state.partner_footage ) {

				if ( helpers.doesNotExist( this.state.uploadVideoInfo.media_type ) ) {
					newValidationState.media_type.state = 'error'
					newValidationState.media_type.help_text = 'Type is required'

					isValid = false
				}

				if ( helpers.doesNotExist( this.state.uploadVideoInfo.language ) ) {
					newValidationState.language.state = 'error'
					newValidationState.language.help_text = 'Language is required'

					isValid = false
				}

				if ( helpers.doesNotExist( this.state.uploadVideoInfo.sport ) ) {
					newValidationState.sport.state = 'error'
					newValidationState.sport.help_text = 'Sport / League is required'

					isValid = false
				}

				if ( helpers.doesNotExist( this.state.uploadVideoInfo.publish_date ) ) {
					newValidationState.publish_date.state = 'error'
					newValidationState.publish_date.help_text = 'Publish Date is required'

					isValid = false
				}
				else if ( helpers.doesExist( this.state.uploadVideoInfo.expiration_date ) ) {
					// set a warning if the expiration date is < 2 years out (default)
					if ( moment( this.state.uploadVideoInfo.expiration_date ).isBefore( moment( this.state.uploadVideoInfo.publish_date ) ) ) {
						newValidationState.expiration_date = {
							state: 'warning',
							help_text: 'Expire Date is before the Publish Date'
						}
					}
					else if ( moment( this.state.uploadVideoInfo.expiration_date ).diff( moment( this.state.uploadVideoInfo.publish_date ), 'days' ) === 0 ) {
						newValidationState.expiration_date = {
							state: 'warning',
							help_text: 'Expire Date is the same day as the Publish Date'
						}
					}
					else if ( moment( this.state.uploadVideoInfo.expiration_date ).isBefore( moment( this.state.uploadVideoInfo.publish_date ).add( 2, 'years' ) ) ) {
						newValidationState.expiration_date = {
							state: 'warning',
							help_text: 'Expire Date is less than two years out'
						}
					}
				}
			}

			newValidationState.is_valid = isValid
		}

		if ( _isEqual( newValidationState, this.state.validationState ) ) {
			return
		}

		this.setState( { validationState: newValidationState } )
	}


	handleListGroups( err, groups ) {
		if ( helpers.doesExist( err ) ) return this.props.dispatch( reportApiError( err ) )

		this.setState( { groups: groups } )
	}


	loadConfigForGroup( groupId ) {
		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, groupId, params ) => {
						return Api.getGroupConfig( currentUser, groupId, params )
					} )( this.currentUser, groupId, {} )
				},
				this.handleLoadConfig.bind( this )
			)
		)
	}


	handleLoadConfig( err, config ) {
		if ( helpers.doesExist( err ) ) {
			if ( !err.statusCode === 404 ) {
				return this.props.dispatch( reportApiError( err ) )
			}

			return this.setValidationState()
		}

		this.setState( { partnerConfig: config } )

		// the config must have at least one edition with at least one cms target
		let cmsName = config.workflow.publish_to_cerebro.editions[ 0 ].cms_targets[ 0 ].name

		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, groupId, cmsName ) => {
						return Api.getPartnerMetadataForCMS( currentUser, groupId, cmsName )
					} )( this.currentUser, config.group.group_id, cmsName )
				},
				this.handleLoadLookup.bind( this )
			)
		)

		// if this is an admin user, also retrieve the list of users belonging to the selected group
		if ( this.currentUser.isInternal ) {
			this.props.dispatch(
				callApi(
					() => {
						return ( ( currentUser, groupId, params ) => {
							return Api.listGroupUsers( currentUser, groupId, params )
						} )( this.currentUser, config.group.group_id, {} )
					},
					this.handleListGroupUsers.bind( this )
				)
			)
		}
	}


	handleLoadLookup( err, lookup ) {
		if ( helpers.doesExist( err ) ) return this.props.dispatch( reportApiError( err ) )

		this.setState( { partnerLookup: lookup }, () => {
			this.setValidationState()
		} )
	}


	handleListGroupUsers( err, users ) {
		if ( helpers.doesExist( err ) ) return this.props.dispatch( reportApiError( err ) )

		this.setState( { users: users }, () => {
			this.setValidationState()
		} )
	}


	loadImpersonatedUser( userId ) {
		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, userId, params ) => {
						return Api.getUser( currentUser, userId, params )
					} )( this.currentUser, userId, {} )
				},
				this.handleLoadImpersonatedUser.bind( this )
			)
		)
	}


	handleLoadImpersonatedUser( err, impersonatedUser ) {
		if ( helpers.doesExist( err ) ) {
			this.props.dispatch( reportApiError( err ) )
			return this.setState( { impersonatedUser: null }, () => {
				this.setValidationState()
			} )
		}

		this.setState( { impersonatedUser: impersonatedUser }, () => {
			this.setValidationState()
		} )
	}


	get currentUser() {
		return this.props.authorizationInfo.user
	}


	handleSubmitVideo() {
		Analytics.recordUserActivity()

		let videoUser = this.currentUser
		if ( this.currentUser.isInternal ) {
			videoUser = this.state.impersonatedUser
		}

		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, video ) => {
						if ( this.state.partner_footage ) {
							return Api.publishPartnerFootage( currentUser, video )
						}
						else {
							return Api.uploadVideo( currentUser, video )
						}
					} )( videoUser, this.state.uploadVideoInfo )
				},
				this.handleSubmit.bind( this )
			)
		)
	}


	handleSubmit( err, res ) {
		if ( helpers.doesNotExist( err ) ) {
			if ( this.state.uploadVideoInfo.video_upload_type === 'fileUpload' ) {
				let fileList = document.getElementsByClassName( 'upload-file' )[0].files
				if ( helpers.doesExist( fileList ) ) {
					let myFile = fileList[0]

					let mediaId = res._id

					window.formData.append( mediaId, myFile )
					this.props.dispatch ( addNewUpload( mediaId, this.state.uploadVideoInfo.local_video_file, this.state.uploadVideoInfo.local_video_content_type ) )
					document.getElementsByClassName( 'upload-file' )[0].value = ''
				}

				this.props.dispatch( showNotification( 'Validated the video request. Starting to upload the file in parts', 'Upload', 'success' ) )
			}
			else {
				this.props.dispatch( showNotification( 'Successfully uploaded the video', 'Upload', 'success' ) )
			}


			let newState = _cloneDeep( this.initialState )
			if ( this.props.authorizationInfo.user.isInternal ) {
				// also clear out the config & lookup that we used since these are loaded when the impersonated user is chosen
				newState.partnerConfig = null
				newState.partnerLookup = null
			}

			return this.setState( newState, () => this.setValidationState() )
		}

		if ( err.statusCode === 400 ) {
			// the request was rejected due to bad data; highlight the fields instead of popping toast
			let newValidationState = this.initialValidationState

			for ( let invalidValue of err.message.invalid_values ) {
				newValidationState[ invalidValue.param ] = {
					state		: 'error',
					help_text	: invalidValue.message
				}
			}

			this.setState( { validationState: newValidationState } )
		}
		else {
			this.props.dispatch( reportApiError( err ) )
		}
	}


	setPublishDate( value ) {
		Analytics.recordUserActivity()

		let newPubDate = Object.assign( this.state.uploadVideoInfo, this.state.uploadVideoInfo.publish_date, { publish_date: value } )
		this.setState( { uploadVideoInfo: newPubDate }, () => {
			this.setValidationState()
		} )
	}


	setExpirationDate( newDate ) {
		Analytics.recordUserActivity()

		let newExpDate = Object.assign( this.state.uploadVideoInfo, this.state.uploadVideoInfo.expiration_date, { expiration_date: newDate } )
		this.setState( { uploadVideoInfo: newExpDate }, () => {
			this.setValidationState()
		} )
	}


	async handleFieldChange( value, event ) {
		Analytics.recordUserActivity()

		let newUploadVideoInfo = _cloneDeep( this.state.uploadVideoInfo )

		let specialValues = [ 'sport', 'video_url', 'video_file', 'thumbnail_url', 'thumbnail_file' ]

		if ( !( specialValues.find( el => el === value ) ) ) {
			newUploadVideoInfo[ value ] = event.target.value
			
			return this.setState( { uploadVideoInfo: newUploadVideoInfo }, () => {
				this.setValidationState()
			} )
		}
		else {
			if ( value === 'sport' ) {
				let leagueToMatch = event.target.value
				let sportToMatch = null
	
				if ( leagueToMatch.startsWith( 'any' ) ) {
					leagueToMatch = event.target.value.split( ':' )[ 0 ]
					sportToMatch = event.target.value.split( ':' )[ 1 ]
				}
	
				// since we display leagues in the sport dropdown, we need to set both when selecting
				newUploadVideoInfo.league = leagueToMatch
				newUploadVideoInfo.sport = null
	
				// also, wipe out any selected teams since they belonged to the previous league
				newUploadVideoInfo.teams = []
	
				if ( helpers.doesExist( this.state.partnerConfig ) ) {
					for ( let sport of this.state.partnerConfig.workflow.publish_to_cerebro.sport_whitelist ) {
						if ( leagueToMatch === sport.league_espn_value || ( helpers.doesExist( sport.league_partner_value ) && leagueToMatch === sport.league_partner_value ) ) {
							if ( sportToMatch === null ) {
								// if this is not 'any' league, then the sport is deterministic
								newUploadVideoInfo.sport = sport.sport_partner_value ? sport.sport_partner_value : sport.sport_espn_value
							}
							else if ( sportToMatch === sport.sport_espn_value || helpers.doesExist( sport.sport_partner_value ) && sportToMatch === sport.sport_partner_value ) {
								// need to match the specific sport for 'any' league
								newUploadVideoInfo.sport = sport.sport_partner_value ? sport.sport_partner_value : sport.sport_espn_value
							}
						}
					}
				}
			}
			else if ( value === 'video_url' ) {
				newUploadVideoInfo[ value ] = event.target.value.trim()
				newUploadVideoInfo[ 'video_upload_type' ] = 'urlUpload'
			}
			else if ( value === 'video_file' ) {
				let item = document.getElementsByClassName( 'upload-file' )[0].files[0]
	
				if ( helpers.doesExist( item ) ) {
					newUploadVideoInfo[ 'local_video_file' ] = item.name
					newUploadVideoInfo[ 'local_video_content_type' ] = item.type
				}
	
				newUploadVideoInfo[ 'video_url' ] = null
				newUploadVideoInfo[ 'video_upload_type' ] = 'fileUpload'
			}
			else if ( value === 'thumbnail_url' ) {
				newUploadVideoInfo[ value ] = event.target.value.trim()
			}
			else if ( value === 'thumbnail_file' ) {
				let item = document.getElementsByClassName( 'thumbnail-file' )[0].files[0]
				let typeisValid = ( item && ( item.type === 'image/png' || item.type === 'image/jpeg' || item.type === 'image/jpg' ) ) ? true : false
	
				if ( helpers.doesExist( item ) && typeisValid ) {
					let workItem = JSON.stringify( {
						'expiration': 60*15,
						'attachment_content_type': item.type,
					} )
	
					await this.props.dispatch(
						callApi(
							() => {
								return ( ( currentUser, object ) => {
									return Api.getPreSignedUrl( currentUser, object )
								} )( this.currentUser, workItem )
							},
							( err, response ) => {
								if ( response && response.statusCode === 200 ) {
									let url = JSON.parse( JSON.stringify( response.body ) ).url
									let array = url.split( '?' )
									newUploadVideoInfo[ 'thumbnail_url' ] = array[0]
	
									let request = new XMLHttpRequest()
									request.open( 'PUT', url, true )
									request.send( item )
	
									request.onload = ( ( e ) => {
										if ( request.status === 200 ) {
											this.props.dispatch( showNotification( `Thumbnail uploaded to aws: ${ item.name }`, 'Upload thumbnail to AWS success', 'success' ) )
											return request
										}
										else {
											this.props.dispatch( showNotification( `Error uploading thumbnail for file: ${ item.name } to AWS`, 'Upload thumbnail to AWS error', 'error' ) )
											return request
										}
									} )
								}
								else {
									this.props.dispatch( showNotification( `Error uploading thumbnail for file: ${ item.name }. Please try again`, 'Pre-signed url error', 'error' ) )
	
									return response
								}
							}
						)
					)
				}
				else {
					this.props.dispatch( showNotification( 'Error uploading thumbnail for file. Incorrect type, use png, jpg, or jpeg. Please try again', 'Thumbnail type error', 'error' ) )
				}
			}
		}

		this.setState( { uploadVideoInfo: newUploadVideoInfo }, () => {
			this.setValidationState()
		} )
	}


	handleTeamSelect( e ) {
		Analytics.recordUserActivity()

		let newUploadVideoInfo = _cloneDeep( this.state.uploadVideoInfo )

		newUploadVideoInfo.teams = e.target.value

		this.setState( { uploadVideoInfo: newUploadVideoInfo }, () => {
			this.setValidationState()
		} )
	}


	handleGroupSelect( selectEvent ) {
		Analytics.recordUserActivity()

		// clear out all partner-specific items from current working video

		let newUploadVideoInfo = _cloneDeep( this.state.uploadVideoInfo )
		let newValidationState = _cloneDeep( this.state.validationState )

		newUploadVideoInfo.sport = null
		newUploadVideoInfo.league = null
		newUploadVideoInfo.teams = []

		let newState = this.initialState

		newState.uploadVideoInfo = newUploadVideoInfo
		newState.validationState = newValidationState

		// reset validation for the group selection
		newValidationState.impersonated_group = {
			state		: null,
			help_text	: null
		}

		this.setState( newState )

		let selectedValue = selectEvent.target.value

		if ( selectedValue !== '' ) {
			this.setState( { impersonatedGroupId: selectedValue }, () => {
				this.loadConfigForGroup( selectedValue )
			} )
		}
	}


	handleUserSelect( selectEvent ) {
		Analytics.recordUserActivity()

		let selectedValue = selectEvent.target.value

		if ( selectedValue !== '' ) {
			this.loadImpersonatedUser( selectedValue )
		}
		else {
			this.setState( { impersonatedUser: null }, () => {
				this.setValidationState()
			} )
		}
	}


	handlePartnerFootage( event ) {
		this.setState( { partner_footage: !this.state.partner_footage }, () => {
			this.setValidationState()
		} )
	}


	areEntryFieldsDisabled() {
		let areDisabled = true

		if ( !this.currentUser.isInternal ) {
			areDisabled = false
		}
		else if ( this.currentUser.isInternal && helpers.doesExist( this.state.impersonatedGroupId ) && helpers.doesExist( this.state.impersonatedUser ) ) {
			areDisabled = false
		}

		return areDisabled
	}


	areFootageFieldsDisabled() {
		let areDisabled = false

		if ( this.state.partner_footage ) {
			areDisabled = true
		}

		return areDisabled
	}


	renderMenu( inputLabelText, selectValue, popperText, onChangeHandler, disabled, menuItems, error=false, formHelperText='', multiple=false, commaSeparateSelect=false ) {
		return (
			<FormControl fullWidth disabled={ disabled } error={ Boolean( error ) }>
				<InputLabel shrink htmlFor="impersonated-group-native-helper" classes={ { root: this.props.classes.inputLabelText, shrink: this.props.classes.inputLabelShrink } }>
					{ inputLabelText }
					<Tooltip title={ popperText } placement="right-start" enterDelay={ 500 } leaveDelay={ 200 }>
						<IconButton classes={ { root: this.props.classes.elevatedIconButton } }>
							<HelpIcon />
						</IconButton>
					</Tooltip>
				</InputLabel>

				<Select
					multiple={ multiple }
					value={ selectValue }
					input={ <Input name="impersonated-group" id="impersonated-group-native-helper" /> }
					onChange={ onChangeHandler }
					renderValue = { commaSeparateSelect ? selected => selected.join( ', ' ) : undefined }
				>
							
					<MenuItem value="" />
					{ menuItems }

				</Select>

				<FormHelperText>{ formHelperText }</FormHelperText>
			</FormControl>
		)
	}


	renderGroupSelect() {
		if ( !this.currentUser.isInternal ) return null

		let selectedGroupId = this.state.impersonatedGroupId ? this.state.impersonatedGroupId : ''
		let selectedUserId = this.state.impersonatedUser && this.state.impersonatedUser._id ? this.state.impersonatedUser._id: ''

		let userSelectDisabled = true
		if ( helpers.doesExist( this.state.impersonatedGroupId ) && this.state.users.length > 0 ) userSelectDisabled = false

		let impersonatedGroupMenuItems = this.state.groups.map( ( current ) => ( <MenuItem key={ `group-${ current.group_id }` } value={ current.group_id }>{ current.name }</MenuItem> ) )
		
		let impersonatedUserMenuItems = this.state.users.map( ( current ) => ( <MenuItem key={ `user-${ current._id }` } value={ current._id }>{ current.contact.name }</MenuItem> ) )

		return (
			<Card style={ { marginBottom: '10px' } }>
				<CardContent classes={ { root: this.props.classes.cardContentPadding } }>
					<Grid container spacing={ 3 }>
						<Grid item md={ 6 } sm={ 12 }>
							{ this.renderMenu(
								'Impersonated Group',
								selectedGroupId,
								'Select the group to impersonate.',
								this.handleGroupSelect.bind( this ),
								false,
								impersonatedGroupMenuItems
							) }
						</Grid>
						<Grid item md={ 6 } sm={ 12 }>
							{ this.renderMenu(
								'Impersonated User',
								selectedUserId,
								'Select the user to impersonate; this is whom you will submit the request as.',
								this.handleUserSelect.bind( this ),
								userSelectDisabled,
								impersonatedUserMenuItems
							) }
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		)
	}


	renderMediaTypeSelect() {
		let isDisabled = this.areEntryFieldsDisabled()
		let isFootageDisabled = this.areFootageFieldsDisabled()

		let mediaMenuItems = Media.MEDIA_TYPES.map( ( current ) => ( <MenuItem key={ `type-${ current.value }` } value={ current.value }>{ current.name }</MenuItem> ) )

		return (
			<div>
				{ this.renderMenu( 'Type',
					this.state.uploadVideoInfo.media_type || '',
					'Select the appropriate video type.',
					( event ) => this.handleFieldChange( 'media_type', event ),
					isDisabled || isFootageDisabled,
					mediaMenuItems,
					this.state.validationState.media_type.state
				) }
			</div>
		)
	}


	renderLanguageSelect() {
		let isDisabled = this.areEntryFieldsDisabled()
		let isFootageDisabled = this.areFootageFieldsDisabled()

		let languages = []
		if ( helpers.doesExist( this.state.partnerConfig ) ) {
			languages = this.state.partnerConfig.workflow.publish_to_cerebro.language_whitelist
		}

		let menuItems = languages.map( ( l ) => {
			let displayValue = l.espn_value
			if ( helpers.doesExist( l.partner_value ) ) displayValue = l.partner_value
			if ( displayValue in LANGUAGES ) displayValue = LANGUAGES[displayValue]

			return ( <MenuItem key={ `language-${ l.espn_value }` } value={ l.espn_value }>{ displayValue }</MenuItem> )
		} )

		return (
			<div>
				{ this.renderMenu(
					'Language',
					helpers.formatString( this.state.uploadVideoInfo.language || '' ),
					'Select the video language.',
					( event ) => this.handleFieldChange( 'language', event ),
					isDisabled || isFootageDisabled,
					menuItems,
					this.state.validationState.language.state
				) }
			</div>
		)
	}


	renderSportSelect() {
		let partnerLeagues = []

		if ( helpers.doesExist( this.state.partnerConfig ) ) {
			for ( let sport of this.state.partnerConfig.workflow.publish_to_cerebro.sport_whitelist ) {
				let sportName = sport.sport_espn_value
				if ( helpers.doesExist( sport.sport_partner_value ) ) sportName = sport.sport_partner_value

				if ( helpers.doesExist( sport.league_partner_value ) ) {
					partnerLeagues.push( { name: sport.league_partner_value, value: sport.league_partner_value, sport_name: sportName } )
				}
				else {
					partnerLeagues.push( { name: sport.league_espn_value, value: sport.league_espn_value, sport_name: sportName } )
				}
			}
		}

		let isDisabled = this.areEntryFieldsDisabled()
		let isFootageDisabled = this.areFootageFieldsDisabled()

		let selectedLeague = helpers.formatString( this.state.uploadVideoInfo.league )
		if ( selectedLeague === 'any' ) {
			selectedLeague = `${ this.state.uploadVideoInfo.league }:${ this.state.uploadVideoInfo.sport }`
		}

		let menuItems = partnerLeagues.map( ( league, idx ) => {
			let displayName = league.name
			let value = league.value

			if ( displayName === 'any' ) {
				// if this is 'any' league, we need to encode the sport
				displayName = `${ displayName } (${ league.sport_name })`
				value = `${ league.name }:${ league.sport_name }`
			}

			return <MenuItem key={ value } value={ value }>{ displayName }</MenuItem>
		} )

		return (
			<div>
				{ this.renderMenu(
					'Sport / League',
					selectedLeague,
					'Select the appropriate sport and league from the list below',
					( event ) => this.handleFieldChange( 'sport', event ),
					isDisabled || isFootageDisabled,
					menuItems,
					this.state.validationState.sport.state,
					this.state.validationState.sport.help_text
				) }
			</div>
		)
	}


	renderTeamsSelect() {
		let partnerTeams = []

		if ( helpers.doesExist( this.state.partnerConfig ) && helpers.doesExist( this.state.partnerLookup ) && helpers.doesExist( this.state.uploadVideoInfo.sport ) ) {
			let sport = this.state.partnerConfig.lookupEspnValueForSport( this.state.uploadVideoInfo.sport )
			let league = this.state.partnerConfig.lookupEspnValueForLeague( this.state.uploadVideoInfo.league )

			let entryGroup = this.state.partnerLookup.getEntryGroupForEdition( sport, league )

			if ( helpers.doesNotExist( entryGroup ) ) {
				logger.warn( `no lookup found for group: ${ this.currentUser.group.group_id } sport: ${ this.state.uploadVideoInfo.sport } and league: ${ this.state.uploadVideoInfo.league }` )
			}
			else {
				for ( let entry of entryGroup.entries ) {
					if ( entry.entry_type === 'team' ) {
						partnerTeams.push( { name: entry.keyword, value: entry.cms_id } )
					}
				}
			}
		}

		let isDisabled = this.areEntryFieldsDisabled()
		let isFootageDisabled = this.areFootageFieldsDisabled()

		let selectedTeams = []

		let menuItems = partnerTeams.map( ( team ) => {
			let selectedTeam = this.state.uploadVideoInfo.teams.find( t => t === team.value )

			if ( selectedTeam ) {
				selectedTeams.push( team.value )
			}

			return (
				<MenuItem key={ `team-${ team.value }` } value={ team.name }>
					<Checkbox checked={ this.state.uploadVideoInfo.teams.findIndex( t => t === team.name ) > -1 } />
					{ team.name }
				</MenuItem>
			)
		} )

		return (
			<div>
				{ this.renderMenu(
					'Teams',
					this.state.uploadVideoInfo.teams,
					'Select one or more teams that are primary to the content',
					this.handleTeamSelect.bind( this ),
					isDisabled || isFootageDisabled,
					menuItems,
					false,
					'',
					true,
					true,
				) }
			</div>
		)

	}


	renderTextFormField( inputLabelText, inputValue, error=false, isDisabled, inputOnChangeHandler, popperNode, helperText, button ) {
		return (
			<FormControl id={ inputLabelText.split( ' ' ).join( '' ) } fullWidth error={ Boolean( error ) } disabled={ isDisabled }>
				<InputLabel shrink classes={ { root: this.props.classes.inputLabelText, shrink: this.props.classes.inputLabelShrink } }>
					{ inputLabelText }
					<Tooltip title={ popperNode } placement="right-start" enterDelay={ 500 } leaveDelay={ 200 }>
						<IconButton classes={ { root: this.props.classes.elevatedIconButton } }>
							<HelpIcon />
						</IconButton>
					</Tooltip>
				</InputLabel>

				<Input
					value={ inputValue }
					onChange={ inputOnChangeHandler }
					endAdornment={ button }
				>
				</Input>

				<FormHelperText>{ helperText }</FormHelperText>
			</FormControl>
		)
	}


	renderCardContent() {

		let isDisabled = this.areEntryFieldsDisabled()
		let isFootageDisabled = this.areFootageFieldsDisabled()

		let videoURLPopover = (
			<span>
				A video is required by selecting a local video file or by providing a URL to a valid video file<br /><br />
				If using dropbox, provide the link to the raw file by changing &quot;?dl=0&quot; to &quot;?raw=1&quot; in the URL.<br /><br />
				<i>Example correct link format:</i><br /><br />
				http://dropbox.com/.../vid.mp4?raw=1
			</span>

		)

		let thumbnailURLPopover = (
			<span>
				A URL to a valid JPG file is required.<br /><br />
				If using dropbox, provide the link to the raw file by changing &quot;?dl=0&quot; to &quot;?raw=1&quot; in the URL.<br /><br />
				<i>Example correct link format:</i><br /><br />
				http://dropbox.com/.../img.jpg?raw=1
			</span>
		)

		let gameIdPopover = (
			<span>
				If this video is directly tied to a specific game on ESPN, add the numerical gameID here.<br />
				Game IDs can be copied from URLs on ESPN.com game pages. <br />
				Sample: http://www.espn.com/college-football/game?gameId=400871304
			</span>
		)

		let partnerFootageToggle = ( <div /> )

		if ( this.props.authorizationInfo.user.isInternal ||
			( this.props.authorizationInfo.user.group.type === 'partner' && this.props.authorizationInfo.group.doesGroupContainAcl( 'api.publish.footage' ) ) ) {

			partnerFootageToggle = (
				<Grid container spacing={ 3 }>
					<Grid item md={ 3 } sm={ 12 }>
						<div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -15 } }>
							<Typography variant="subtitle2" color="textPrimary" noWrap>
								Partner Footage
							</Typography>
							<div style={ { marginLeft: 'auto' } }>
								<Switch
									checked={ this.state.partner_footage }
									onChange={ this.handlePartnerFootage }
									disabled={ isDisabled }
									color="primary"
								/>
							</div>
						</div>
					</Grid>
				</Grid>
			)

		}

		let uploadVideoButton = (
			<div>
				<input
					disabled={ isDisabled }
					className="get-file upload-file"
					style={ { display: 'none' } }
					id="raised-button-video-upload"
					multiple
					type="file"
					onChange={ ( event ) => this.handleFieldChange( 'video_file', event ) }
				/>
				<label htmlFor="raised-button-video-upload">
					<IconButton disabled={ isDisabled } component="span" classes={ { root: this.props.classes.uploadIconButtonRoot } }>
						<UploadIcon />
					</IconButton>
				</label>
			</div>
		)

		let uploadThumbnailButton = (
			<div>
				<input
					disabled={ isDisabled }
					style={ { display: 'none' } }
					id="raised-button-thumbnail-upload"
					multiple
					className="get-file thumbnail-file"
					type="file"
					onChange={ ( event ) => this.handleFieldChange( 'thumbnail_file', event ) }
					onClick={ ( event ) => event.target.value = null }
				/>
				<label htmlFor="raised-button-thumbnail-upload">
					<IconButton disabled={ isDisabled } component="span" classes={ { root: this.props.classes.uploadIconButtonRoot } }>
						<UploadIcon />
					</IconButton>
				</label>
			</div>
		)

		return (
			<Card>
				<CardHeader title="Video Information" titleTypographyProps={ { variant: 'h6' } }></CardHeader>
				<CardContent classes={ { root: this.props.classes.cardContentPadding } }>
					<Grid container spacing={ 3 }>
						<Grid item md={ 6 } sm={ 12 } className="video_url">
							{ this.renderTextFormField(
								'Video',
								this.state.uploadVideoInfo.video_url || this.state.uploadVideoInfo.local_video_file || '',
								this.state.validationState.video_url.state || this.state.validationState.local_video_content_type.state,
								isDisabled,
								( event ) => this.handleFieldChange( 'video_url', event ),
								videoURLPopover,
								( this.state.validationState.video_url.help_text ) ? this.state.validationState.video_url.help_text : this.state.validationState.local_video_content_type.help_text,
								uploadVideoButton
							) }
						</Grid>

						<Grid item md={ 6 } sm={ 12 } className="thumbnail_url">
							{ this.renderTextFormField(
								'Thumbnail URL',
								this.state.uploadVideoInfo.thumbnail_url || '',
								this.state.validationState.thumbnail_url.state,
								isDisabled,
								( event ) => this.handleFieldChange( 'thumbnail_url', event ),
								thumbnailURLPopover,
								this.state.validationState.thumbnail_url.help_text,
								uploadThumbnailButton
							) }
						</Grid>
					</Grid>

					<br/>

					{ partnerFootageToggle }

					<Grid container spacing={ 3 }>
						<Grid item md={ 6 } sm={ 12 }>
							{ this.renderTextFormField(
								'Headline',
								this.state.uploadVideoInfo.headline || '',
								this.state.validationState.headline.state,
								isDisabled,
								( event ) => this.handleFieldChange( 'headline', event ),
								'Headline should not exceed 40 characters.',
								this.state.validationState.headline.help_text
							) }
						</Grid>

						<Grid item md={ 3 } sm={ 3 }>
							{ this.renderMediaTypeSelect() }
						</Grid>

						<Grid item md={ 3 } sm={ 3 }>
							{ this.renderLanguageSelect() }
						</Grid>
					</Grid>

					<br/>

					<Grid container spacing={ 3 }>
						<Grid item md={ 12 } sm={ 12 }>
							{ this.renderTextFormField(
								'Caption',
								this.state.uploadVideoInfo.caption || '',
								this.state.validationState.caption.state,
								isDisabled,
								( event ) => this.handleFieldChange( 'caption', event ),
								'Provide the user-facing caption or description for the content of the video.  Captions should not exceed 255 characters.',
								this.state.validationState.caption.help_text
							) }
						</Grid>
					</Grid>

					<br/>

					<Grid container spacing={ 3 }>
						<Grid item md={ 6 } sm={ 12 }>
							{ this.renderTextFormField(
								'Additional Keywords',
								this.state.uploadVideoInfo.keywords || '',
								false,
								isDisabled || isFootageDisabled,
								( event ) => this.handleFieldChange( 'keywords', event ),
								'Provide a list of relevant proper names separated by commas.',
								''
							) }
						</Grid>

						<Grid item md={ 2 } sm={ 3 }>
							<FormControl error={ Boolean( this.state.validationState.publish_date.state ) }>
								<InputLabel shrink classes={ { root: this.props.classes.inputLabelText, shrink: this.props.classes.inputLabelShrink, formControl:  this.props.classes.wideFormLabel } }>
									Publish Date
									<Tooltip title="If not set, will be published immediately. To embargo content, set the date and time in the future." placement="right-start" enterDelay={ 500 } leaveDelay={ 200 }>
										<IconButton classes={ { root: this.props.classes.elevatedIconButton } }>
											<HelpIcon />
										</IconButton>
									</Tooltip>
								</InputLabel>
								<MuiPickersUtilsProvider utils={ DateFnsUtils }>
									<KeyboardDatePicker
										disabled={ isDisabled || isFootageDisabled }
										margin="normal"
										id="mui-pickers-date"
										format="MM/DD/YYYY"
										views={ [ 'year', 'month', 'date' ] }
										value={ this.state.uploadVideoInfo.publish_date }
										onChange={ this.setPublishDate.bind( this ) }
										InputAdornmentProps={ { classes: { positionEnd: this.props.classes.dateFormLabel } } }
										KeyboardButtonProps={ {
											'aria-label': 'change date',
										} }
									/>
									<FormHelperText>{ this.state.validationState.publish_date.help_text }</FormHelperText>
								</MuiPickersUtilsProvider>
							</FormControl>
						</Grid>

						<Grid item md={ 2 } sm={ 6 }>
							<FormControl id="expireDate" error={ Boolean( this.state.validationState.expiration_date.state ) }>
								<InputLabel shrink classes={ { root: this.props.classes.inputLabelText, shrink: this.props.classes.inputLabelShrink, formControl:  this.props.classes.wideFormLabel } }>
									Expire Date
									<Tooltip title="If required, set a date in which the content should expire and be unpublished" placement="right-start" enterDelay={ 500 } leaveDelay={ 200 }>
										<IconButton classes={ { root: this.props.classes.elevatedIconButton } }>
											<HelpIcon />
										</IconButton>
									</Tooltip>
								</InputLabel>
								<MuiPickersUtilsProvider utils={ DateFnsUtils }>
									<KeyboardDatePicker
										disabled={ isDisabled || isFootageDisabled }
										margin="normal"
										id="mui-pickers-date"
										format="MM/DD/YYYY"
										views={ [ 'year', 'month', 'date' ] }
										value={ this.state.uploadVideoInfo.expiration_date }
										onChange={ this.setExpirationDate.bind( this ) }
										InputAdornmentProps={ { classes: { positionEnd: this.props.classes.dateFormLabel } } }
										KeyboardButtonProps={ {
											'aria-label': 'change date',
										} }
									/>
									<FormHelperText >{ this.state.validationState.expiration_date.help_text }</FormHelperText>
								</MuiPickersUtilsProvider>
							</FormControl>
						</Grid>

						<Grid item md={ 2 } sm={ 6 }>
							{ this.renderTextFormField(
								'Game ID',
								this.state.uploadVideoInfo.game_id || '',
								false,
								isDisabled || isFootageDisabled,
								( event ) => this.handleFieldChange( 'game_id', event ),
								gameIdPopover,
								''
							) }
						</Grid>
					</Grid>

					<Grid container spacing={ 3 }>
						<Grid item md={ 3 } sm={ 12 }>
							{ this.renderSportSelect() }
						</Grid>
						<Grid item md={ 3 } sm={ 4 }>
							{ this.renderTeamsSelect() }
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		)
	}


	render() {
		return (
			<div className={ this.props.classes.root } style={ { padding: 8 } }>
				<Grid container spacing={ 2 }>
					<Grid item lg={ 8 }>
						{ this.renderGroupSelect() }
						{ this.renderCardContent() }
					</Grid>

					<Grid item lg={ 4 }>
						<VideoDetails
							videoDetails={ this.state.uploadVideoInfo }
							config={ this.state.partnerConfig }
							lookup={ this.state.partnerLookup }
							partnerFootage={ this.state.partner_footage }
							impersonatedUser={ this.state.impersonatedUser }
							impersonatedGroupId={ this.state.impersonatedGroupId }
							isInternal={ this.props.authorizationInfo.user.isInternal }
							authorizationInfo={ this.props.authorizationInfo }
							handleSubmit={ this.handleSubmitVideo.bind( this ) }
							validationState = { this.state.validationState }
						/>
					</Grid>
				</Grid>
			</div>

		)
	}
}


UploadPage.propTypes = {
	authorizationInfo	: PropTypes.object.isRequired,
	classes				: PropTypes.object.isRequired
}


function mapStateToProps( state, ownProps ) {
	return {
		authorizationInfo: state.authorizationInfo
	}
}


export default Redux.connect( mapStateToProps )( withStyles( styles )( UploadPage ) )
