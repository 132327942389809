const types = require( '../actions/actionTypes' )
const initialState = require( './initialState' )


export default function handleUploadReducer( state = initialState.uploadFileList, action ) {
	switch ( action.type ) {
	case types.ADD_NEW_UPLOAD_SUCCESS:
		return [
			...state,
			{
				mediaId 	: action.mediaId,
				name		: action.name,
				mediaType	: action.mediaType
			}
		]
	case types.REMOVE_UPLOAD_SUCCESS:
		return state.filter( item => item.mediaId !== action.mediaId )
	case types.REMOVE_UPLOADS_SUCCESS:
		return initialState.uploadFileList
	default:
		return state
	}
}
