import { beginAjaxCall, endAjaxCall, ajaxCallError } from './ajaxStatusActions'


export function callApi( method, callback = () => {}, options = {} ) {
	return function( dispatch ) {
		if ( options.hideSpinner !== true ) {
			dispatch( beginAjaxCall() )
		}

		return method()
			.then( ( res ) => {
				if ( options.hideSpinner !== true ) {
					dispatch( endAjaxCall() )
				}
				callback( null, res )
			} )
			.catch( ( err ) => {
				if ( options.hideSpinner !== true ) {
					dispatch( endAjaxCall() )
				}
				callback( err )
			} )
	}
}


export function reportApiError( error ) {
	return function( dispatch ) {
		return dispatch( ajaxCallError( error ) )
	}
}
