import helpers from '../lib/helpers'

import _get from 'lodash/get'


const CATEGORY_TYPES = {
	'team'		: 'Team',
	'league'	: 'League',
	'player'	: 'Player',
	'topic'		: 'Topic'
}


export default class Category {
	static get friendlyName() { return 'Category' }
	static get modelName() { return Category.friendlyName.replace( ' ', '' ) }
	static get workItemType() { return Category.friendlyName.replace( ' ', '_' ).toLowerCase() }


	static getCategoryContextText( category ) {
		let contextText = ''

		let leagueName = _get( category, 'context.league_name', null )
		if ( leagueName !== null ) {
			contextText = leagueName
		}
		else {
			contextText = CATEGORY_TYPES[ category.type ] ? CATEGORY_TYPES[ category.type ] : category.type
		}

		return contextText
	}


	constructor( json = {} ) {
		this.uuid = json.uuid
		this.category_id = json.category_id
		this.source = 'SearchGuru'
		this.name = json.name
		this.type = json.type
		this.now_id = json.now_id
		this.uid = json.uid

		this.context = { type: json.type }

		if ( helpers.doesExist( json.league ) ) this.context.sport = json.league
		if ( helpers.doesExist( json.league_name ) ) this.context.league_name = json.league_name
		if ( helpers.doesExist( json.image ) ) this.context.image = json.image
	}


	get modelName() { return Category.modelName }
	get friendlyName() { return Category.friendlyName }
	get workItemType() { return Category.workItemType }
}
