
const types = require( './actionTypes' )
const ajaxStatus = require( './ajaxStatusActions' )
import Api from '../dataSource/api'


export function loadCategories( categories ) {
	return { type: types.GET_PCC_CATEGORIES_SUCCESS, categories }
}


export function getPccNetworksSuccess( response ) {
	return { type: types.GET_PCC_NETWORKS_SUCCESS, response }
}


export function getPccNetworks( currentUser, cb = () => {} ) {
	return function( dispatch ) {
		dispatch( ajaxStatus.beginAjaxCall( 'Retrieving PCC Networks' ) )

		return Api.pccGetNetworkList( currentUser )
			.then( ( response ) => {
				dispatch( getPccNetworksSuccess( response ) )
				cb()
			} )
			.catch( ( error ) => {
				dispatch( ajaxStatus.ajaxCallError( error, 'Retrieving PCC Networks' ) )
				cb( error )
			} )
	}
}

