const redux = require( 'redux' )

import ajaxCallsInProgress from './ajaxStatusReducer'
import ajaxErrorInfo from './ajaxErrorReducer'
import loginReducer from './loginReducer'
import selectableMetadataReducer from './selectableMetadataReducer'
import pccNetworksReducer from './pccNetworksReducer'
import pccCategoriesReducer from './pccCategoriesReducer'
import uploadReducer from './uploadReducer'
import appNotificationReducer from './appNotificationReducer'


const rootReducer = redux.combineReducers( {
	ajaxCallsInProgress	: ajaxCallsInProgress,
	ajaxErrorInfo		: ajaxErrorInfo,
	authorizationInfo	: loginReducer,
	selectableMetadata	: selectableMetadataReducer,
	pccNetworks			: pccNetworksReducer,
	pccCategories		: pccCategoriesReducer,
	uploadFileList		: uploadReducer,
	appNotification		: appNotificationReducer
} )


export default rootReducer
