import _isString from 'lodash/isString'
import _cloneDeep from 'lodash/cloneDeep'

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import EditionPublishingComponent from './editions_publishing_rules'
import InitialConfiguration from './initial_configuration'
import DataMethods from './data_methods'
import MetadataMappings from './metadata_mappings'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import SaveIcon from '@material-ui/icons/Save'
import ReceiptIcon from '@material-ui/icons/Receipt'
import IconButton from '@material-ui/core/IconButton'
import BackIcon from '@material-ui/icons/ChevronLeft'

import JsonDetailDialog from '../common/json_detail_dialog'

import Api from '../../dataSource/api'
import { callApi, reportApiError } from '../../actions/apiCallActions'
import { showNotification } from '../../actions/appNotificationActions'

import PartnerLookup from '../../models/partner_lookup'
import GroupConfiguration from '../../models/group_configuration'

import Analytics from '../../lib/analytics'
import helpers from '../../lib/helpers'


const TAB_INITIAL_CONFIGURATION = 'INITIAL_CONFIGURATION'
const TAB_EDITION_PUBLISHING_RULES = 'EDITION_PUBLISHING_RULES'
const TAB_METADATA_MAPPING = 'METADATA_MAPPING'
const TAB_DATA_METHODS = 'DATA_METHODS'


export class GroupConfigurationPage extends React.Component {
	constructor( props, context ) {
		super( props, context )

		this.state = {
			group							: null,
			groupConfig						: null,
			groupConfigSaveError			: null,
			draftErrors						: {},
			partnerMetadata					: [],
			partnerMetadataSaveError		: null,
			selectedTab						: TAB_INITIAL_CONFIGURATION,
			showDetails						: false
		}

		this.saveDocuments = this.saveDocuments.bind( this )
	}


	async UNSAFE_componentWillMount() {

		if ( helpers.doesNotExist( this.props.match.params.group_id ) ) return

		let getGroupAndDispatch = () => {
			return new Promise( ( resolve, reject ) => {
				this.props.dispatch(
					callApi(
						() => {
							return ( ( currentUser, groupId ) => {
								return Api.getGroup( currentUser, groupId )
							} )( this.props.authorizationInfo.user, this.props.match.params.group_id )
						},

						( err, group ) => {
							if ( helpers.doesExist( err ) ) {
								this.props.dispatch( reportApiError( err ) )
								return reject( err )
							}

							resolve( group )
						}
					)
				)
			} )
		}


		let getPartnerMetadataAndDispatch = () => {
			return new Promise( ( resolve, reject ) => {
				this.props.dispatch(
					callApi(
						() => {
							return ( ( currentUser, groupId ) => {
								return Api.getPartnerMetadata( currentUser, groupId )
							} )( this.props.authorizationInfo.user, this.props.match.params.group_id )
						},

						( err, metadata ) => {
							if ( helpers.doesExist( err ) ) {
								this.props.dispatch( reportApiError( err ) )
								return reject( err )
							}

							resolve( metadata )
						}
					)
				)
			} )
		}


		let getGroupConfigAndDispatch = ( group, metadata ) => {
			return new Promise( ( resolve, reject ) => {
				this.props.dispatch(
					callApi(
						() => {
							return ( ( currentUser, groupId ) => {
								return Api.getGroupConfig( currentUser, groupId )
							} )( this.props.authorizationInfo.user, this.props.match.params.group_id )
						},

						( err, groupConfig ) => {
							if ( helpers.doesExist( err ) ) {
								if ( err.statusCode !== 404 ) {
									this.props.dispatch( reportApiError( err ) )
									return reject( err )
								}

								groupConfig = GroupConfiguration.emptyGroupConfiguration( group.toSnapshot() )
							}

							this.setState( { group, groupConfig, partnerMetadata: metadata }, () => resolve() )
						}
					)
				)
			} )
		}

		getGroupAndDispatch = getGroupAndDispatch.bind( this )
		getPartnerMetadataAndDispatch = getPartnerMetadataAndDispatch.bind( this )
		getGroupConfigAndDispatch = getGroupConfigAndDispatch.bind( this )

		try {
			let group = await getGroupAndDispatch()
			let metadata = await getPartnerMetadataAndDispatch()
			await getGroupConfigAndDispatch( group, metadata )

		}
		catch ( e ) {
			// do nothing here
		}
	}


	handleBackToGroups() {
		Analytics.recordUserActivity()
		this.props.history.goBack()
	}


	getHeaderText() {
		let headerText = ''
		let group = this.state.group
		if ( helpers.doesExist( group ) ) {
			headerText = group.name
		}

		return headerText
	}


	areDocumentsValid( partnerMetadata, groupConfig ) {
		let lookupsAreValid = true
		for ( let lookup of partnerMetadata ) {
			if ( lookup ) {
				lookupsAreValid = lookup.isDocumentValid()
				if ( !lookupsAreValid ) break
			}
		}

		let configIsValid = groupConfig ? this.state.groupConfig.isDocumentValid() : false

		return lookupsAreValid && configIsValid
	}


	handleSelectedTab( event, value ) {
		Analytics.recordUserActivity()

		if ( _isString( value ) ) {
			this.setState( { selectedTab: value } )
		}
	}


	updatePartnerMetadataState( newMetadata ) {
		Analytics.recordUserActivity()
		this.setState( { partnerMetadata: _cloneDeep( newMetadata ) } )
	}


	updateGroupConfigState( newGroupConfig ) {
		Analytics.recordUserActivity()
		this.setState( { groupConfig: _cloneDeep( newGroupConfig ) } )
	}


	async saveDocuments() {
		try {
			await this.savePartnerMetadata( this.state.partnerMetadata )
			await this.saveGroupConfig( this.state.groupConfig )
		}
		catch ( e ) {
			// do nothing here
		}
	}


	savePartnerMetadata( newMetadata ) {
		return new Promise( async ( resolve, reject ) => {
		// this is passed in an array of metadata documents, each one needs to be saved individually
		// also, we need to remove any metadata documents that (potentially) no longer exist
			if ( newMetadata.length === 0 ) resolve()

			let savedMetadata = []

			let saveMetadataAndDispatch = ( cmsMetadata ) => {
				return new Promise( ( resolve, reject ) => {
					this.props.dispatch(
						callApi(
							() => {
								return ( ( currentUser, groupId, cmsMetadata ) => {
									return Api.savePartnerMetadata( currentUser, groupId, cmsMetadata )
								} )( this.props.authorizationInfo.user, this.state.group._id, cmsMetadata )
							},

							( err, res ) => {
								if ( helpers.doesNotExist( err ) ) {
									if ( helpers.doesExist( res.body ) ) {
										savedMetadata.push( new PartnerLookup( res.body ) )
									}
									else {
										savedMetadata.push( res )
									}

									return resolve()
								}

								reject( err )
							}
						)
					)
				} )
			}


			let deleteUnsupportedAndDispatch = ( unsupportedCMS ) => {
				return new Promise( async ( resolve, reject ) => {
					this.props.dispatch(
						callApi(
							() => {
								return ( ( currentUser, groupId, cmsName ) => {
									return Api.deletePartnerMetadata( currentUser, groupId, cmsName )
								} )( this.props.authorizationInfo.user, this.state.group._id, unsupportedCMS )
							},

							( err, res ) => {
								if ( helpers.doesExist( err ) ) {
									return reject( err )
								}

								resolve()
							}
						)
					)
				} )
			}


			saveMetadataAndDispatch = saveMetadataAndDispatch.bind( this )
			deleteUnsupportedAndDispatch = deleteUnsupportedAndDispatch.bind( this )

			try {
				for ( const cmsMetadata of newMetadata ) {
					await saveMetadataAndDispatch( cmsMetadata )
				}

				let unsupportedCMSs = [ 'domestic', 'deportes' ]
				for ( let cmsMetadata of newMetadata ) {
					unsupportedCMSs.splice( unsupportedCMSs.findIndex( ( cms ) => { return cms.cms_name === cmsMetadata.cms_name } ), 1 )
				}

				for ( const unsupportedCMS of unsupportedCMSs ) {
					await deleteUnsupportedAndDispatch( unsupportedCMS )
				}

				this.props.dispatch( showNotification( 'Saved', 'Metadata Mappings', 'success' ) )

				this.setState( { partnerMetadata: savedMetadata }, () => resolve() )
			}
			catch ( err ) {
				if ( !err._isServerException && err.statusCode === 400 ) {
					// this is a data validation exception, set state to display user feedback
					this.setState( { partnerMetadataSaveError: err } )
				}

				this.props.dispatch( reportApiError( err ) )
				reject( err )

			}
		} )

	}


	saveGroupConfig( groupConfig ) {
		return new Promise( ( resolve, reject ) => {
			this.props.dispatch(
				callApi(
					() => {
						return ( ( currentUser, groupId, groupConfig ) => {
							return Api.saveGroupConfig( currentUser, groupId, groupConfig )
						} )( this.props.authorizationInfo.user, this.state.group._id, groupConfig )
					},
					( err, res ) => {
						if ( helpers.doesExist( err ) ) {
							if ( !err._isServerException && err.statusCode === 400 ) {
								// this is a data validation exception, set state to display user feedback
								this.setState( { groupConfigSaveError: err } )
							}

							this.props.dispatch( reportApiError( err ) )
							return reject( err )
						}

						this.props.dispatch( showNotification( 'Saved', 'Group Configuration', 'success' ) )

						if ( helpers.doesExist( res.body ) ) {
							return this.setState( { groupConfig: new GroupConfiguration( res.body ) }, () => {
								resolve()
							} )
						}

						this.setState( { groupConfig: res }, () => {
							resolve()
						} )
					}
				)
			)
		} )

	}


	displayContentTab() {
		let output = <span />

		switch ( this.state.selectedTab	) {
		case TAB_INITIAL_CONFIGURATION:
			output = <InitialConfiguration
				groupConfig={ this.state.groupConfig }
				selectableMetadata={ this.props.selectableMetadata }
				updateGroupConfigState={ this.updateGroupConfigState.bind( this ) }
			/>
			break

		case TAB_EDITION_PUBLISHING_RULES:
			output = <EditionPublishingComponent
				groupConfig={ this.state.groupConfig }
				selectableMetadata={ this.props.selectableMetadata }
				updateGroupConfigState={ this.updateGroupConfigState.bind( this ) }
			/>
			break

		case TAB_METADATA_MAPPING:
			output = <MetadataMappings
				groupConfig={ this.state.groupConfig }
				partnerMetadata={ this.state.partnerMetadata }
				selectableMetadata={ this.props.selectableMetadata }
				updateMetadataState={ this.updatePartnerMetadataState.bind( this ) }
			/>
			break

		case TAB_DATA_METHODS:
			output = <DataMethods
				groupConfig={ this.state.groupConfig }
				selectableMetadata={ this.props.selectableMetadata }
				updateGroupConfigState={ this.updateGroupConfigState.bind( this ) }
			/>
		}

		return output
	}


	render() {
		let dialogData = [
			{ label: 'Publishing Config', data: this.state.groupConfig },
			{ label: 'Metadata Mappings', data: this.state.partnerMetadata }
		]

		if ( helpers.doesNotExist( this.state.group ) ) {
			return (
				<AppBar position="static" color="default" style={ { boxShadow: 'none' } }>
					<Toolbar>
						<Tooltip title={ 'Go Back' } style={ { marginRight: 20 } } enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton id="goBackBtn" edge="start" onClick={ () => this.props.history.goBack() }>
								<BackIcon />
							</IconButton>
						</Tooltip>
					</Toolbar>
				</AppBar>
			)
		}

		return (
			<div>
				<AppBar position="static" color="default" style={ { boxShadow: 'none' } }>
					<Toolbar>
						<Tooltip title={ 'Go Back' } style={ { marginRight: 20 } } enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton id="goBackBtn" edge="start" onClick={ () => this.props.history.goBack() } style={ { marginLeft: '-24px' } }>
								<BackIcon />
							</IconButton>
						</Tooltip>

						<Typography variant="h6" style={ { flexGrow: 1 } }>{ this.getHeaderText() } Configuration</Typography>

						<Tooltip title="Show Config and Mapping JSON" enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton id="showConfigBtn" onClick={ () => this.setState( { showDetails: true } ) }>
								<ReceiptIcon />
							</IconButton>
						</Tooltip>

						<Tooltip title="Save Changes" enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton id="saveBtn" onClick={ this.saveDocuments } disabled={ !this.areDocumentsValid( this.state.partnerMetadata, this.state.groupConfig ) }>
								<SaveIcon />
							</IconButton>
						</Tooltip>
					</Toolbar>
				</AppBar>

				<AppBar position="static" color="default" style={ { boxShadow: 'none' } }>
					<Tabs className="partner-config-nav" indicatorColor="primary" value={ this.state.selectedTab } onChange={ this.handleSelectedTab.bind( this ) }>
						<Tab value={ TAB_INITIAL_CONFIGURATION } title="initial configuration" label="Initial Configuration" />
						<Tab value={ TAB_EDITION_PUBLISHING_RULES } title="publishing rules" label="Edition and Publishing Rules" />
						<Tab value={ TAB_METADATA_MAPPING } title="meta data mappings" label="Metadata Mappings" />
						<Tab value={ TAB_DATA_METHODS } title="data methods" label="Data Methods" />
					</Tabs>
				</AppBar>

				{ this.displayContentTab() }

				<JsonDetailDialog
					open={ this.state.showDetails }
					dialogData={ dialogData }
					dialogTitle="Configuration Detail"
					closeDialog={ () => this.setState( { showDetails: false } ) }
				/>
			</div>
		)
	}
}


GroupConfigurationPage.propTypes = {
	authorizationInfo	: PropTypes.object.isRequired,
	selectableMetadata	: PropTypes.array.isRequired,

	// injected automatically by react-router
	history				: PropTypes.object.isRequired,
	match				: PropTypes.object.isRequired
}


function mapStateToProps( state, ownProps ) {
	return {
		authorizationInfo	: state.authorizationInfo,
		selectableMetadata	: state.selectableMetadata
	}
}

export default withRouter( connect( mapStateToProps ) ( GroupConfigurationPage ) )
