import _get from 'lodash/get'

import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import { Player } from 'video-react'

import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import SendIcon from '@material-ui/icons/Send'
import ReceiptIcon from '@material-ui/icons/Receipt'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

import grey from '@material-ui/core/colors/grey'

import ShortstopAvatar from '../common/shortstop_avatar'
import JsonDetailDialog from '../common/json_detail_dialog'

import Analytics from '../../lib/analytics'
import helpers from '../../lib/helpers'

import Media from '../../models/media'

const IMAGE_LOADING = 'loading'
const IMAGE_LOADED = 'loaded'
const IMAGE_UNAVAILABLE = 'unavailable'
const NO_IMAGE = 'no-image'


class ShortstopDetail extends React.Component {
	constructor( props ) {
		super( props )

		let thumbnailState = NO_IMAGE

		let mediaType = _get( this.props.activity, 'sort_fields.shortstop_type', 'text' )
		if ( mediaType !== 'text' ) thumbnailState = IMAGE_LOADING

		this.thumbnailImage = new window.Image()

		this.thumbnailImage.onload = () => { this.setState( { thumbnail: IMAGE_LOADED } ) }
		this.thumbnailImage.onerror = () => { this.setState( { thumbnail: IMAGE_UNAVAILABLE } ) }

		this.state = { showDetails: false, showDeleteConfirmation: false, thumbnail: thumbnailState }

		this.handleEditPost = this.handleEditPost.bind( this )
		this.handleDeletePost = this.handleDeletePost.bind( this )
		this.handlePublishTest = this.handlePublishTest.bind( this )
		this.handleOpenDetails = this.handleOpenDetails.bind( this )
		this.handleCloseDetails = this.handleCloseDetails.bind( this )

		this.cancelDelete = this.cancelDelete.bind( this )
		this.deletePost = this.deletePost.bind( this )
	}


	componentDidMount() {
		let mediaType = _get( this.props.activity, 'sort_fields.shortstop_type', 'text' )
		if ( mediaType === 'text' || !this.props.workItem ) return

		this.thumbnailImage.src = this.props.workItem.getThumbnailUrl()
	}


	componentDidUpdate( prevProps, prevState, snapshot ) {
		/* eslint-disable react/no-did-update-set-state */

		let previousThumbnailUrl = null
		let currentThumbnailUrl = null

		if ( prevProps.workItem ) previousThumbnailUrl = prevProps.workItem.getThumbnailUrl()
		if ( this.props.workItem ) currentThumbnailUrl = this.props.workItem.getThumbnailUrl()

		if ( helpers.doesNotExist( currentThumbnailUrl ) && helpers.doesExist( previousThumbnailUrl ) ) {
			// user has removed the attachment
			this.thumbnailImage.src = null
			return this.setState( { thumbnail: NO_IMAGE } )
		}

		if ( helpers.doesNotExist( currentThumbnailUrl ) ) return	// still no attachment
		if ( currentThumbnailUrl === previousThumbnailUrl ) return	// the attachment did not change

		// retrieve the new attachment
		this.setState( { thumbnail: IMAGE_LOADING }, () => {
			this.thumbnailImage.src = currentThumbnailUrl
		} )

		/* eslint-enable react/no-did-update-set-state */
	}


	cancelDelete() {
		Analytics.recordUserActivity()
		this.setState( { showDeleteConfirmation: false } )
	}


	deletePost() {
		Analytics.recordUserActivity()
		this.setState( { showDeleteConfirmation: false }, () => {
			this.props.deletePost( this.props.workItem )
		} )
	}


	handleEditPost() {
		this.props.editPost( this.props.workItem )
	}


	handleDeletePost() {
		Analytics.recordUserActivity()
		this.setState( { showDeleteConfirmation: true } )
	}


	handlePublishTest() {
		Analytics.recordUserActivity()
		this.props.publishTest( this.props.workItem )
	}


	handleOpenDetails() {
		Analytics.recordUserActivity()
		this.setState( { showDetails: true } )
	}


	handleCloseDetails() {
		Analytics.recordUserActivity()
		this.setState( { showDetails: false } )
	}


	getCategoriesName( categories ) {
		if ( helpers.doesExist( categories ) ) {
			return categories.map( cat => cat.name ).join( ', ' )
		}

		return ''
	}


	get cmsNamesToMappings() {
		return {
			domestic	: 'English',
			deportes	: 'Spanish'
		}
	}


	renderDeletePostConfirmation( selectedPost ) {
		let deleteConfirmation = ( <div /> )
		if ( this.state.showDeleteConfirmation ) {
			let postText = selectedPost.shortstop.body_text
			if ( postText.length > 50 ) postText = `${ postText.substring( 0, 49 ) }...`

			deleteConfirmation = (
				<Dialog open={ this.state.showDeleteConfirmation }>
					<DialogTitle>Delete Post</DialogTitle>

					<DialogContent>
						<DialogContentText>
							Please confirm you wish to delete the post &quot;{ postText }&quot;.  This will result in the following actions:
						</DialogContentText>
						<List style={ { marginTop: 10 } }>
							<ListItem dense>
								<ListItemIcon><RemoveCircleIcon /></ListItemIcon>
								<ListItemText primary="ESPN Now Feed" secondary="Post will be removed" />
							</ListItem>

							<ListItem dense>
								<ListItemIcon><RemoveCircleIcon /></ListItemIcon>
								<ListItemText primary="Social Accounts" secondary="Any social networks that this post was cross published to will be removed" />
							</ListItem>
						</List>
					</DialogContent>

					<DialogActions>
						<Button onClick={ this.cancelDelete } color="secondary">
							Cancel
						</Button>
						<Button onClick={ this.deletePost } color="primary">
							Ok
						</Button>
					</DialogActions>
				</Dialog>
			)
		}

		return deleteConfirmation
	}


	renderThumbnail( activity, workItem ) {
		let thumbnail = ( <div /> )
		if ( !activity || !workItem ) return thumbnail

		let mediaType = _get( activity, 'sort_fields.shortstop_type', 'text' )
		if ( mediaType === 'text' ) return thumbnail

		if ( mediaType === 'video' ) {
			return (
				<Player
					playsInline
					poster={ workItem.getThumbnailUrl() }
					src={ workItem.getVideoUrl() }
				/>
			)
		}

		if ( this.state.thumbnail === IMAGE_LOADED ) {
			let imageStyle = { width: '100%', minWidth: '100%', maxWidth: '100%', display: 'block', margin: '0 auto' }
			let cardMediaStyle = {}

			let photoAttachment = workItem.getAttachment( 'photo' )
			let orientation = _get( photoAttachment.formats[ 0 ], 'metadata.orientation_flag', 'landscape' )
			if ( orientation === 'portrait' ) {
				imageStyle = { width: '31%', minWidth: '31%', maxWidth: '31%', display: 'block', margin: '0 auto' }
				cardMediaStyle = { backgroundColor: grey[ 200 ] }
			}

			thumbnail = (
				<div style={ cardMediaStyle }>
					<img src={ this.thumbnailImage.src } style={ imageStyle } />
				</div>
			)
		}
		else if ( this.state.thumbnail === IMAGE_LOADING || this.state.thumbnail === IMAGE_UNAVAILABLE ) {
			thumbnail = (
				<svg width="100%" style={ { background: grey[ 200 ] } } />
			)
		}

		return thumbnail
	}


	renderKeyValueLineItem( key, value, first=false ) {
		let style = { display: 'flex', flexDirection: 'row', marginTop: 2 }

		if ( first === true ) {
			style = { display: 'flex', flexDirection: 'row' }
		}

		return (
			<div style={ style }>
				<div style={ { flex: 1 } }><Typography variant="subtitle2">{ key }</Typography></div>
				<div style={ { flex: 2 } }><Typography variant="caption">{ value }</Typography></div>
			</div>
		)
	}


	renderShortstopStandardAttributes( activity, workItem ) {
		if ( !workItem ) return ( <div /> )

		let categories = this.getCategoriesName( workItem.shortstop.categories )
		let editions = workItem.shortstop.editions

		let nowUrl = workItem.shortstop.now_url
		let nowUrlParts = nowUrl.split( '=' )
		if ( nowUrlParts.length === 2 ) nowUrl = nowUrlParts[ 1 ]

		let event = 'None'
		let eventUrl = ''
		if ( helpers.doesExist( workItem.shortstop.event ) ) {
			eventUrl = workItem.getGamePageUrl( workItem.shortstop.event )
			event = `${ workItem.shortstop.event.short_name } - ${ moment( workItem.shortstop.event.start_time ).format( 'M/DD h:mm A' ) }`
		}

		return (
			<div style={ { marginTop: 15 } }>
				{ this.renderKeyValueLineItem( 'Shortstop ID', workItem._id, true ) }
				{ this.renderKeyValueLineItem( 'Activity ID', activity._id ) }
				{ this.renderKeyValueLineItem( 'Post Type', workItem.shortstop.media_type ) }
				{ this.renderKeyValueLineItem( 'Categories', categories ) }
				{ this.renderKeyValueLineItem( 'Event', <a href={ eventUrl } target="_blank" rel="noopener noreferrer">{ event }</a> ) }
				{ this.renderKeyValueLineItem( 'Editions', Media.getEditionsDisplayString( editions ).join( ', ' ) ) }
				{ this.renderKeyValueLineItem( 'Now URL', <a href={ workItem.shortstop.now_url } target="_blank" rel="noopener noreferrer">{ nowUrl }</a> ) }
				{ this.renderKeyValueLineItem( 'Twitter', this.renderTwitterSettings( workItem ) ) }
			</div>
		)
	}


	renderErrorDetail( activity ) {
		let message = activity.error.message

		try {
			// the following will throw an exception if the message is not valid JSON
			let messageJSON = JSON.parse( message )
			message = JSON.stringify( messageJSON, null, 4 )
		}
		catch ( e ) {
			// do nothing there
		}

		return (
			<div style={ { marginTop: 15 } }>
				{ this.renderKeyValueLineItem( 'Activity ID', activity._id, true ) }
				{ this.renderKeyValueLineItem( 'Error Code', activity.error.code ) }
				{ this.renderKeyValueLineItem( 'Message', message ) }
			</div>
		)
	}


	renderTwitterSettings( workItem ) {
		if ( !workItem.shortstop.twitter_sharing.cross_publish ) return ( <div style={ { flex: 2 } }>Not Shared</div> )

		if ( !workItem.shortstop.twitter_sharing.post_id ) {
			return ( <div style={ { flex: 2 } }>Publishing is in Progress</div> )
		}

		let linkParts = workItem.shortstop.twitter_sharing.post_id.split( '_' )
		if ( linkParts.length < 2 ) return ( <div style={ { flex: 1 } }>{ workItem.shortstop.twitter_sharing.post_id }</div> )

		let postId = linkParts[ linkParts.length - 1 ]
		let twitterHandle = workItem.shortstop.twitter_sharing.post_id.replace( `_${ postId }`, '' )

		return (
			<div style={ { flex: 2 } }>
				<a href={ `https://twitter.com/${ twitterHandle }/status/${ postId }` } target="_blank" rel="noopener noreferrer">
					{ postId }
				</a>
			</div>
		)
	}


	renderShortstopVideoAttributes( workItem ) {
		let postType = _get( workItem, 'shortstop.media_type', 'text' )

		if ( postType !== 'video' ) {
			return ( <div /> )
		}

		let video = workItem.getAttachment( 'video' )
		if ( helpers.doesNotExist( video ) || helpers.doesNotExist( video.publishing ) ) return ( <div /> )

		let firstRowStyle = { display: 'flex', flexDirection: 'row' }

		return (
			<div>
				<div style={ { marginTop: 15 } }>
					<div style={ firstRowStyle }>
						<div style={ { flex: 1 } }><strong>ESPN CMS Destinations</strong></div>
					</div>
					{ this.renderKeyValueLineItem( 'Language', Media.getLanguageDisplayString( video.publishing.edition_language ) ) }
				</div>

				{ this.renderEditionCMSDestinationInfo( 'domestic', video.publishing.domestic ) }
				{ this.renderEditionCMSDestinationInfo( 'deportes', video.publishing.deportes ) }
			</div>
		)
	}


	renderEditionCMSDestinationInfo( cmsName, destination ) {
		if ( helpers.doesNotExist( destination ) ) return ( <div /> )

		let transcodeIdDisplay = destination.transcode_id || 'Transcode request not sent'

		let transcodeIdcolor = '#000000'
		if ( !destination.transcode_id ) transcodeIdcolor = '#FF0000'

		// let rowStyle = { display: 'flex', flexDirection: 'row', marginTop: 5 }
		let firstRowStyle = { display: 'flex', flexDirection: 'row' }

		let errorMessage = ( <div /> )
		if ( helpers.doesExist( destination.error_msg ) ) {
			errorMessage = this.renderKeyValueLineItem( 'Error', destination.error_msg )
		}

		let ingested = 'No'
		if ( destination.ingest_for_edit ) ingested = 'Pending'

		if ( helpers.doesExist( destination.cms_id ) ) {
			ingested = helpers.formatBoolean( destination.ingest_for_edit )
		}

		return (
			<div style={ { marginTop: 15 } }>
				<div style={ firstRowStyle }>
					<div style={ { flex: 1, borderBottom: '1pt solid black' } }><strong>{ this.cmsNamesToMappings[ cmsName ] }</strong></div>
				</div>

				<div style = { { display: 'flex', flexDirection: 'row' } }>
					<div style={ { flex: 1 } }><Typography variant="subtitle2">Transcode ID</Typography></div>
					<div style={ { flex: 2, color: transcodeIdcolor } }><Typography variant="caption">{ transcodeIdDisplay }</Typography></div>
				</div>
				{ this.renderKeyValueLineItem( 'CMS ID', this.renderVideoLink( cmsName, destination.transcode_id, destination.cms_id ) ) }
				{ this.renderKeyValueLineItem( 'Ingested', ingested ) }
				{ errorMessage }
			</div>
		)
	}


	renderVideoLink( cmsName, transcodeId, cmsId ) {
		if ( helpers.doesNotExist( transcodeId ) ) return <div />
		if ( helpers.doesNotExist( cmsId ) ) return <font color="#FF0000">Transcode not completed</font>

		let link = `http://espn.com/video/clip?id=${ cmsId }`
		if ( cmsName.indexOf( 'deportes' ) > -1 ) {
			link = `http://espndeportes.espn.com/video/clip/_/id/${ cmsId }`
		}

		return <a href={ link } target="_blank" rel="noopener noreferrer">{ cmsId }</a>
	}


	renderWarnings( activity ) {
		if ( activity.warnings.length === 0 ) return ( <div /> )

		let rowStyle = { display: 'flex', flexDirection: 'row', marginTop: 5 }
		let firstRowStyle = { display: 'flex', flexDirection: 'row' }

		return (
			<div style={ { marginTop: 15 } }>
				<div style={ firstRowStyle }><strong>Warnings</strong></div>
				{
					activity.warnings.map( ( w ) => {
						return (
							<div key={ w.details } style={ rowStyle }>{ w.details }</div>
						)
					} )
				}
			</div>
		)
	}


	renderActions( activity ) {
		let disabled = true
		let color = grey[ 300 ]

		if ( activity.current_state === 'Published' || activity.is_test ) {
			disabled = false
			color = grey[ 700 ]
		}

		let editButton = (
			<Tooltip title="Edit" enterDelay={ 500 } leaveDelay={ 200 }>
				<IconButton id="btn-edit-post" style={ { color: color } } disabled={ disabled } onClick={ this.handleEditPost }>
					<EditIcon />
				</IconButton>
			</Tooltip>
		)

		let deleteButton = (
			<Tooltip title="Delete" enterDelay={ 500 } leaveDelay={ 200 }>
				<IconButton style={ { color: color } } disabled={ disabled } onClick={ this.handleDeletePost }>
					<DeleteIcon />
				</IconButton>
			</Tooltip>
		)

		let publishTestButton = ( <div /> )
		if ( activity.is_test ) {
			publishTestButton = (
				<Tooltip title="Publish Test" enterDelay={ 500 } leaveDelay={ 200 }>
					<IconButton id="btn-publish-test" style={ { color: grey[ 700 ] } } onClick={ this.handlePublishTest }>
						<SendIcon />
					</IconButton>
				</Tooltip>
			)
		}

		if ( disabled ) {
			editButton = (
				<IconButton id="btn-edit-post" style={ { color: color } } disabled={ disabled } onClick={ this.handleEditPost }>
					<EditIcon />
				</IconButton>
			)

			deleteButton = (
				<IconButton style={ { color: color } } disabled={ disabled } onClick={ this.handleDeletePost }>
					<DeleteIcon />
				</IconButton>
			)
		}

		return (
			<CardActions>
				{ editButton }
				{ deleteButton }
				{ publishTestButton }
				<Tooltip title="Post Details" enterDelay={ 500 } leaveDelay={ 200 }>
					<IconButton style={ { marginLeft: 'auto', color: grey[ 700 ] } } onClick={ this.handleOpenDetails }>
						<ReceiptIcon />
					</IconButton>
				</Tooltip>
			</CardActions>
		)
	}


	render() {
		if ( !this.props.activity ) {
			return (
				<Card>
					<CardHeader title="Select a post to preview" titleTypographyProps={ { variant: 'subtitle1' } }></CardHeader>
				</Card>
			)
		}

		let contributorName = _get( this.props.activity, 'owner.name', '' )
		let jobTitle = _get( this.props.activity, 'sort_fields.job_title', '' )
		let bodyText = _get( this.props.activity, 'sort_fields.body_text', '' )

		let details = ( <div /> )
		let warnings = ( <div /> )
		let videoDetails = ( <div /> )

		if ( !this.props.workItem && this.props.activity.error ) {
			details = this.renderErrorDetail( this.props.activity )
		}
		else {
			details = this.renderShortstopStandardAttributes( this.props.activity, this.props.workItem )
			warnings = this.renderWarnings( this.props.activity )
			videoDetails = this.renderShortstopVideoAttributes( this.props.workItem )
		}

		let dialogData = [
			{ label: 'Workflow', data: this.props.activity },
			{ label: 'Post', data: this.props.workItem }
		]

		return (
			<div>
				<Card>
					<CardHeader
						avatar={
							<ShortstopAvatar
								authorizationInfo={ this.props.authorizationInfo }
								activity={ this.props.activity }
								cardAvatar={ true }
								getScribeColumnist={ this.props.getScribeColumnist }
							/>
						}
						title={ contributorName }
						subheader={ jobTitle }
					/>

					<Divider />

					{ this.renderThumbnail( this.props.activity, this.props.workItem ) }

					<CardContent>
						<Typography variant="body1" style={ { whiteSpace: 'pre-wrap', wordBreak: 'break-all' } } component="p">
							{ bodyText }
						</Typography>

						{ details }
						{ warnings }
						{ videoDetails }
					</CardContent>

					<Divider />

					{ this.renderActions( this.props.activity ) }
				</Card>

				<JsonDetailDialog
					open={ this.state.showDetails }
					dialogData={ dialogData }
					closeDialog={ this.handleCloseDetails }
				/>

				{ this.renderDeletePostConfirmation( this.props.workItem ) }
			</div>
		)
	}
}


ShortstopDetail.propTypes = {
	authorizationInfo	: PropTypes.object.isRequired,
	workItem			: PropTypes.object,
	activity			: PropTypes.object,
	deletePost          : PropTypes.func.isRequired,
	editPost			: PropTypes.func.isRequired,
	publishTest			: PropTypes.func.isRequired,

	// test hook
	getScribeColumnist	: PropTypes.func,
}


export default ShortstopDetail
