import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'


class JsonDetailDialog extends React.Component {
	constructor( props ) {
		super( props )

		this.state = { current_tab: 0 }

		this.handleTabChange = this.handleTabChange.bind( this )
		this.handleCloseDialog = this.handleCloseDialog.bind( this )
	}

	handleTabChange( event, value ) {
		this.setState( { current_tab: value } )
	}

	handleCloseDialog() {
		this.setState( { current_tab: 0 }, () => this.props.closeDialog() )
	}

	renderTabs( data ) {
		let tabs = data.map( ( tab ) => {
			let tabDisabled = false
			let tabJSON = ''

			try {
				if ( tab.data === null || tab.disabled === true ) {
					tabDisabled = true
				}
				else if ( typeof data === 'object' ) {
					tabJSON = JSON.stringify( tab.data, null, 4 )
				}
			}
			catch ( e ) {
				tabDisabled = true
			}

			return { label: tab.label, disabled: tabDisabled, JSON: tabJSON }
		} )

		let tabToDisplay = tabs.find( ( tab, index ) => {
			return this.state.current_tab === index
		} )

		return (
			<div>
				<Tabs indicatorColor="primary" textColor="primary" value={ this.state.current_tab } onChange={ this.handleTabChange }>

					{ tabs.map( tab => {
						return <Tab key={ tab.label } label={ tab.label } disabled={ tab.disabled } />
					} ) }
					
				</Tabs>

				{ tabToDisplay ? <pre>{ tabToDisplay.JSON }</pre> : <div/> }
			</div>
		)
	}


	render() {
		return (
			<Dialog open={ this.props.open } maxWidth={ 'lg' } fullWidth={ true }>
				<DialogTitle disableTypography><Typography variant="subtitle2">{ this.props.dialogTitle ? this.props.dialogTitle : 'Post Details' }</Typography></DialogTitle>
				<DialogContent>
					{ this.renderTabs( this.props.dialogData ) }
				</DialogContent>
				<DialogActions>
					<Button onClick={ this.handleCloseDialog } color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}


JsonDetailDialog.propTypes = {
	open				: PropTypes.bool.isRequired,
	dialogData			: PropTypes.array.isRequired,
	dialogTitle			: PropTypes.string,
	closeDialog			: PropTypes.func.isRequired,
}


export default JsonDetailDialog
