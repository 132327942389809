import _map from 'lodash/map'
import _find from 'lodash/find'
import _cloneDeep from 'lodash/cloneDeep'
import _isArray from 'lodash/isArray'
import _remove from 'lodash/remove'

import React from 'react'
import PropTypes from 'prop-types'

import 'rc-time-picker/assets/index.css'

import moment from 'moment'

import helpers from '../../lib/helpers'
import PCC from '../../models/pcc_program'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import AddIcon from '@material-ui/icons/Add'
import HelpIcon from '@material-ui/icons/Help'
import IconButton from '@material-ui/core/IconButton'

import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'

import CategoriesSearch from './categories_search'

import DateFnsUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers'

import { withStyles } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

const styles = ( theme ) => ( {
	root				: {
		flexGrow		: 1,
	},
	uploadIconButtonRoot: {
		padding			: '3px'
	},
	input				: {
		marginLeft		: 8,
		flex			: 1,
	},
	inputLabelShrink	: {
		transform		: 'translate(0, -11px) scale(0.75);'
	},
	elevatedIconButton	: {
		paddingBottom	: '15px'
	},
	inputLabelText		: {
		fontSize		: '17px'
	},
	typography			: {
		padding			: theme.spacing( 1 ),
	},
	wideFormLabel		: {
		minWidth		: '125%'
	},
	formControl: {
		margin: theme.spacing( 1 ),
		minWidth: 120,
		maxWidth: 300,
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing( 3 ),
	},
} )


export class ProgramMetadataEntry extends React.Component {
	constructor( props, context ) {
		super( props, context )
		this.state = this.createStateFromMetadata( props.metadata )
		this.state.selectedCategories = []
	}


	UNSAFE_componentWillReceiveProps( nextProps ) {
		this.setState( this.createStateFromMetadata( nextProps.metadata ) )
	}


	createStateFromMetadata( metadata ) {
		return { metadata: _cloneDeep( metadata ), validationState: this.getCurrentValidationState( metadata ) }
	}


	createMetadataFromState( componentState ) {
		// do the reverse of above
		return _cloneDeep( componentState.metadata )
	}


	getCurrentValidationState( newMetadata ) {
		let newValidationState = _cloneDeep( this.initialValidationState )	// clone the empty state since we entirely revalidate each time

		if ( helpers.doesNotExist( newMetadata.programData.name ) ) {
			newValidationState.name = { state: 'error', help_text: 'Title is required' }
			newValidationState.is_valid = false
		}

		if ( helpers.doesNotExist( newMetadata.programData.description ) ) {
			newValidationState.description = { state: 'error', help_text: 'Description is required' }
			newValidationState.is_valid = false
		}

		if ( helpers.doesNotExist( newMetadata.programData.language ) ) {
			newValidationState.language = { state: 'error', help_text: 'Language is required' }
			newValidationState.is_valid = false
		}

		if ( helpers.doesNotExist( newMetadata.media ) ) {
			newValidationState.media = { state: 'error', help_text: 'Media is required' }
			newValidationState.is_valid = false
		}

		let createDate = null

		if ( helpers.doesExist( newMetadata ) && helpers.doesExist( newMetadata.programData.createDate ) ) {
			createDate = newMetadata.programData.createDate
		}

		if ( helpers.doesNotExist( createDate ) ) {
			newValidationState.programData.createDate = { state: 'error', help_text: 'Create date is required' }
			newValidationState.is_valid = false
		}

		return newValidationState
	}


	get initialValidationState() {
		return {
			name		: {
				state		: null,
				help_text	: null
			},
			description	: {
				state		: null,
				help_text	: null
			},
			language	: {
				state		: null,
				help_text	: null
			},
			media		: {
				state		: null,
				help_text	: null
			},
			start_date	: {
				state		: null,
				help_text	: null
			},
			end_date	: {
				state		: null,
				help_text	: null
			},
			create_date	: {
				state		: null,
				help_text	: null
			},
			is_valid	: true
		}
	}


	handleSubmitMetadata() {
		this.props.submitMetadataCallback( this.state.metadata )
	}


	handleFieldChange( propertyName, category, event ) {
		let newMetadata = _cloneDeep( this.state.metadata )

		if ( propertyName === 'categories' ) {

			let selectedcategory = {
				id 			: category.id,
				name		: category.name,
				type		: category.type,
				href		: category.href,
				artWorkUrl	: category.artworkUrl
			}

			let newValue = selectedcategory

			if ( helpers.doesExist( newValue ) ) {
				if ( !_isArray( this.state.metadata.programData.matchup.categories ) ) {
					newMetadata.programData.matchup.categories = []
				}

				let idx = this.state.metadata.programData.matchup.categories.findIndex( ( category ) => { return category.id === newValue.id && category.name === newValue.name } )

				if ( idx > -1 ) {
					newMetadata.programData.matchup.categories.splice( idx, 1 )
				}
				else {
					newMetadata.programData.matchup.categories.push( newValue )
				}
			}
		}
		else {
			let newValue = event.target.value
			if ( helpers.doesExist( newValue ) && newValue !== '' ) {
				newMetadata.programData[ propertyName ] = newValue
			}
			else {
				delete newMetadata.programData[ propertyName ]
			}
		}

		this.props.updateMetadataAndValidation( this.createMetadataFromState( { metadata: newMetadata } ), this.getCurrentValidationState( newMetadata ) )
	}


	handleNetworkChange( idx, event ) {
		let newMetadata = _cloneDeep( this.state.metadata )

		let newValue = event.target.value

		if ( helpers.doesExist( newValue ) && helpers.doesExist( newMetadata.media ) ) {
			newMetadata.media[idx].network.name = newValue
		}
		else {
			delete newMetadata.media[idx].network
		}

		this.props.updateMetadataAndValidation( this.createMetadataFromState( { metadata: newMetadata } ), this.getCurrentValidationState( newMetadata ) )
	}


	handleMediaNetworkDateChange( propertyName, idx, value ) {
		let newMetadata = _cloneDeep( this.state.metadata )
		newMetadata[ 'media' ][ idx ][ propertyName ] = moment( value ).utc().format( 'YYYY-MM-DDTHH:mm:ss.SSS+0000' )

		this.props.updateMetadataAndValidation( this.createMetadataFromState( { metadata: newMetadata } ), this.getCurrentValidationState( newMetadata ) )
	}


	handleMediaNetworkTimeChange( propertyName, idx, value ) {
		let newMetadata = _cloneDeep( this.state.metadata )
		newMetadata[ 'media' ][ idx ] [ propertyName ] = value.utc().format( 'YYYY-MM-DDTHH:mm:ss.SSS+0000' )

		this.props.updateMetadataAndValidation( this.createMetadataFromState( { metadata: newMetadata } ), this.getCurrentValidationState( newMetadata ) )
	}


	handleCreateDateChange( propertyName, value ) {
		let newMetadata = _cloneDeep( this.state.metadata )
		newMetadata.programData[ propertyName ] = moment( value ).utc().format( 'YYYY-MM-DDTHH:mm:ss.SSS+0000' )

		this.props.updateMetadataAndValidation( this.createMetadataFromState( { metadata: newMetadata } ), this.getCurrentValidationState( newMetadata ) )
	}


	handleCreateDateTimeChange( propertyName, value ) {
		let newMetadata = _cloneDeep( this.state.metadata )
		newMetadata.programData[ propertyName ] = value.utc().format( 'YYYY-MM-DDTHH:mm:ss.SSS+0000' )

		this.props.updateMetadataAndValidation( this.createMetadataFromState( { metadata: newMetadata } ), this.getCurrentValidationState( newMetadata ) )
	}


	handlePublishChange( idx, event ) {
		let newMetadata = _cloneDeep( this.state.metadata )
		let published = 'false'
		if ( event.target.checked === true ) {
			published = 'true'
		}

		if ( helpers.doesExist( newMetadata.media ) && helpers.doesExist( newMetadata.media[idx].properties ) ) {
			newMetadata.media[idx].properties.published = published
		}
		else {
			delete newMetadata.media[idx].properties.published
		}

		this.props.updateMetadataAndValidation( this.createMetadataFromState( { metadata: newMetadata } ), this.getCurrentValidationState( newMetadata ) )
	}


	getNextAssignedNetwork() {

		let availableNetwork = _find( this.state.metadata.media, [ 'network.name', 'pacrim_live' ] )
		if ( helpers.doesNotExist( availableNetwork ) ) {
			return 'pacrim_live'
		}
		availableNetwork = _find( this.state.metadata.media, [ 'network.name', 'brazil_live' ] )
		if ( helpers.doesNotExist( availableNetwork ) ) {
			return 'brazil_live'
		}
		availableNetwork = _find( this.state.metadata.media, [ 'network.name', 'carribean_live' ] )
		if ( helpers.doesNotExist( availableNetwork ) ) {
			return 'carribean_live'
		}
		availableNetwork = _find( this.state.metadata.media, [ 'network.name', 'latin_north' ] )
		if ( helpers.doesNotExist( availableNetwork ) ) {
			return 'latin_north'
		}
		availableNetwork = _find( this.state.metadata.media, [ 'network.name', 'latin_south' ] )
		if ( helpers.doesNotExist( availableNetwork ) ) {
			return 'latin_south'
		}

		return null
	}


	handleCategoryRemoved( category ) {
		let updatedCategories = _cloneDeep( this.state.metadata.programData.matchup.categories )

		_remove( updatedCategories, category )

		let newMetadata = _cloneDeep( this.state.metadata )
		newMetadata.programData.matchup.categories = updatedCategories

		this.props.updateMetadataAndValidation( this.createMetadataFromState( { metadata: newMetadata } ), this.getCurrentValidationState( newMetadata ) )
	}


	handleAddMedia() {
		let newMetadata = _cloneDeep( this.state.metadata )

		let newMedia = {
			network: {
				name: this.getNextAssignedNetwork()
			},
			properties: {
				published: 'false'
			},
		}

		newMetadata.media.push( newMedia )
		this.props.updateMetadataAndValidation( this.createMetadataFromState( { metadata: newMetadata } ), this.getCurrentValidationState( newMetadata ) )
	}


	renderLanguageSelect() {
		return (
			<FormControl fullWidth className="language" error={ Boolean( this.state.validationState.language.state ) }>
				<InputLabel shrink htmlFor="impersonated-group-native-helper" classes={ { root: this.props.classes.inputLabelText, shrink: this.props.classes.inputLabelShrink } }>
					Language
					<Tooltip title={ 'Select a language.' } placement="right-start" enterDelay={ 500 } leaveDelay={ 200 }>
						<IconButton classes={ { root: this.props.classes.elevatedIconButton } }>
							<HelpIcon />
						</IconButton>
					</Tooltip>
				</InputLabel>
				<Select value={ helpers.formatString( this.state.metadata.programData.language || '' ) } onChange={ this.handleFieldChange.bind( this, 'language', null ) }>
					<MenuItem key={ 'language-null' } value="">( Select Language )</MenuItem>
					{ PCC.LANGUAGE_TYPES.map( ( type ) => ( <MenuItem key={ `language-${ type.value }` } value={ type.value }>{ type.name }</MenuItem> ) ) }
				</Select>
				<FormHelperText>{ this.state.validationState.language.help_text }</FormHelperText>
			</FormControl>
		)
	}


	renderMediaTable( addMediaDisabled ) {
		return (
			<Paper elevation={ 1 }>
				<AppBar position="static" color="default" style={ { boxShadow: 'none' } }>
					<Toolbar disableGutters variant="dense">
						<Tooltip title="Add Sport/League" enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton id="addSportLeague" disabled={ addMediaDisabled } onClick={ this.handleAddMedia.bind( this ) } style={ { marginLeft: 'auto' } }>
								<AddIcon />
							</IconButton>
						</Tooltip>
					</Toolbar>
				</AppBar>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell style={ { width:'25%' } }>Network</TableCell>
							<TableCell style={ { width:'25%' } }>Available date</TableCell>
							<TableCell style={ { width:'25%' } }>End date</TableCell>
							<TableCell style={ { width:'25%' } }>Published</TableCell>
						</TableRow>
					</TableHead>
					<TableBody id="media">
						{ _map( this.props.metadata.media, ( media, idx ) => {
							let network = ''
							let published = false
							if ( helpers.doesExist( media ) && helpers.doesExist( media.properties ) && helpers.doesExist( media.properties.published ) ) {
								published = helpers.isTrue( media.properties.published )
							}

							if ( helpers.doesExist( media ) && helpers.doesExist( media.network ) ) {
								network = media.network.name
							}
							else {
								return null
							}

							return (
								<TableRow hover className="mediaRow" key={ `media-${ idx }` }>
									<TableCell className="network-value">
										<FormControl fullWidth>
											<Select
												value={ network }
												onChange={ this.handleNetworkChange.bind( this, idx ) }>
												{ PCC.NETWORK_TYPES.map( ( network, idx ) => ( <MenuItem key={ `network-${ idx }` } value={ network.value }>{ network.name }</MenuItem> ) ) }
											</Select>
										</FormControl>

									</TableCell>
									<TableCell className="available">
										<MuiPickersUtilsProvider utils={ DateFnsUtils }>
											<KeyboardDateTimePicker
												ampm={ false }
												value={ media.available }
												onChange={ this.handleMediaNetworkDateChange.bind( this, 'available', idx ) }
												format="MM/DD/YYYY HH:mm"
											/>
										</MuiPickersUtilsProvider>
									</TableCell>
									<TableCell className="enddate">
										<MuiPickersUtilsProvider utils={ DateFnsUtils }>
											<KeyboardDateTimePicker
												ampm={ false }
												value={ media.expires }
												onChange={ this.handleMediaNetworkDateChange.bind( this, 'expires', idx ) }
												format="MM/DD/YYYY HH:mm"
											/>
										</MuiPickersUtilsProvider>
									</TableCell>
									<TableCell className="published">
										<Checkbox checked={ published } value={ published } onChange={ this.handlePublishChange.bind( this, idx ) } />
									</TableCell>
								</TableRow>
							)
						} ) }
					</TableBody>
				</Table>
			</Paper>

		)
	}


	render() {
		const styles = {
			chip		: {
				margin		: 4
			},
			textField	: {
				marginLeft	: 15
			},
			tags		: {
				marginLeft	: 15,
				display		: 'flex',
				flexWrap	: 'wrap'
			}
		}

		let createDate = null
		let addMediaDisabled = false

		let categories = null
		if ( helpers.doesExist( this.state.metadata.programData.matchup.categories ) ) {
			if ( this.state.metadata.programData.matchup.categories.length > 0 ) {
				categories = this.state.metadata.programData.matchup.categories.map( ( category, i ) => {
					let displayName = category.name

					return (
						<Chip
							id="cs-selected-category"
							key={ i }
							style={ styles.chip }
							label={ displayName }
							onDelete={ this.handleCategoryRemoved.bind( this, category ) }
						/>
					)
				} )
			}
		}

		if ( helpers.doesExist( this.state.metadata ) && helpers.doesExist( this.state.metadata.programData.createDate ) ) {
			createDate = this.state.metadata.programData.createDate
		}

		if ( helpers.doesExist( this.state.metadata.media ) ) {
			if ( this.props.metadata.media.length === 5 ) {
				addMediaDisabled = true
			}
		}

		return (
			<div style={ { padding: 8 } }>
				<Card style={ { overflow: 'visible' } }>
					<CardHeader title="Metadata Information" titleTypographyProps={ { variant: 'subtitle1' } } />
					<Divider />
					<CardContent>
						<div style={ { display: 'flex', flexDirection: 'column' } }>
							<div>
								<FormControl fullWidth className="title" error={ Boolean( this.state.validationState.name.state ) }>
									<InputLabel shrink htmlFor="impersonated-group-native-helper" classes={ { root: this.props.classes.inputLabelText, shrink: this.props.classes.inputLabelShrink } }>
										Title
										<Tooltip title={ 'Enter a valid title.' } placement="right-start" enterDelay={ 500 } leaveDelay={ 200 }>
											<IconButton classes={ { root: this.props.classes.elevatedIconButton } }>
												<HelpIcon />
											</IconButton>
										</Tooltip>
									</InputLabel>

									<Input placeholder="title" value={ this.state.metadata.programData.name || '' } onChange={ this.handleFieldChange.bind( this, 'name', null ) } />
									<FormHelperText>{ this.state.validationState.name.help_text }</FormHelperText>
								</FormControl>
							</div>

							<div style={ { display: 'flex', marginTop: '8px' } }>
								<div style={ { flexBasis: '45%' } }>

									<FormControl fullWidth className="description" error={ Boolean( this.state.validationState.description.state ) }>
										<InputLabel shrink htmlFor="impersonated-group-native-helper" classes={ { root: this.props.classes.inputLabelText, shrink: this.props.classes.inputLabelShrink } }>
											Description
											<Tooltip title={ 'Enter a valid description.' } placement="right-start" enterDelay={ 500 } leaveDelay={ 200 }>
												<IconButton classes={ { root: this.props.classes.elevatedIconButton } }>
													<HelpIcon />
												</IconButton>
											</Tooltip>
										</InputLabel>
										<Input placeholder="description" value={ this.state.metadata.programData.description || '' } onChange={ this.handleFieldChange.bind( this, 'description', null ) } />
										<FormHelperText>{ this.state.validationState.description.help_text }</FormHelperText>
									</FormControl>
								</div>

								<div style={ { flexBasis: '25%', marginLeft: '15px' } }>
									{ this.renderLanguageSelect() }
								</div>

								<div style={ { flexBasis: '25%', marginLeft: '15px' } }>
									<FormControl className="createDate" error={ Boolean( this.state.validationState.create_date.state ) }>
										<MuiPickersUtilsProvider utils={ DateFnsUtils }>
											<KeyboardDateTimePicker
												ampm={ false }
												label={ (
													<div>
														Created Date
														<Tooltip title={ 'Create date.' } placement="right-start" enterDelay={ 500 } leaveDelay={ 200 }>
															<IconButton classes={ { root: this.props.classes.elevatedIconButton } }>
																<HelpIcon />
															</IconButton>
														</Tooltip>
													</div>
												) }
												InputLabelProps={ { classes: { root: this.props.classes.inputLabelText, shrink: this.props.classes.inputLabelShrink } } }
												value={ createDate }
												onChange={ this.handleCreateDateChange.bind( this, 'createDate' ) }
												format="MM/DD/YYYY HH:mm"
											/>
										</MuiPickersUtilsProvider>
										<FormHelperText>{ this.state.validationState.create_date.help_text }</FormHelperText>
									</FormControl>
								</div>
								<br/>
							</div>

							<div style={ { display: 'flex', marginTop: '8px' } }>
								<div style={ { flexBasis: '35%' } }>
									<CategoriesSearch
										userSelected={ this.handleFieldChange.bind( this, 'categories' ) }
										categories={ this.props.categories }
										currentSelected = { this.state.metadata.programData.matchup.categories }
										handleFieldChange={ this.handleFieldChange.bind( this ) }
									/>
								</div>
								<div style={ { flexBasis: '65%' } }>
									{ categories }
								</div>
							</div>
						</div>

					</CardContent>
				</Card>

				<Card style={ { marginTop: '8px' } }>
					<CardHeader title="Media" titleTypographyProps={ { variant: 'subtitle1' } } />
					<Divider />
					<CardContent>
						{ this.renderMediaTable( addMediaDisabled ) }
					</CardContent>
				</Card>
			</div>
		)
	}
}


ProgramMetadataEntry.propTypes = {
	metadata				: PropTypes.object.isRequired,
	categories				: PropTypes.array.isRequired,
	updateMetadataAndValidation	: PropTypes.func.isRequired,
	submitMetadataCallback	: PropTypes.func.isRequired,
	classes					: PropTypes.object.isRequired,
}


export default withStyles( styles )( ProgramMetadataEntry )
