const types = require( './actionTypes' )

export function showNotification( message, title, variant, additionalActionCallback ) {
	return function( dispatch ) {
		dispatch( {
			type: types.SHOW_NOTIFICATION,
			payload: { message: message, title: title, variant: variant, additionalActionCallback: additionalActionCallback }
		} )
	}
}

export function hideNotification( message, title, variant, additionalActionCallback ) {
	return function( dispatch ) {
		if ( additionalActionCallback ) additionalActionCallback()

		dispatch( { type: types.HIDE_NOTIFICATION, payload: { message: message, title: title, variant: variant } } )
	}
}

