import _isError from 'lodash/isError'
import _cloneDeep from 'lodash/cloneDeep'

import * as types from '../actions/actionTypes'
import * as initialState from './initialState'


export default function errorReducer( state = initialState.ajaxErrorInfo, action ) {
	switch ( action.type ) {
	case types.AJAX_CALL_ERROR: {
		let errorDataIn = action.response.body || action.response
		let errorDataOut = _cloneDeep( errorDataIn )

		if ( _isError( errorDataIn ) ) {
			// Errors are not cloneDeep-able... RPW 10-31-2016. (Happy Halloween!)
			errorDataOut = { message: errorDataIn.message, _stack: errorDataIn.stack }

			if ( errorDataIn._apiMethod !== undefined ) errorDataOut._apiMethod = errorDataIn._apiMethod
			if ( errorDataIn.statusCode !== undefined ) errorDataOut.statusCode = errorDataIn.statusCode
		}

		return errorDataOut
	}

	case types.AUTHORIZATION_LOGIN:
	case types.AUTHORIZATION_LOGOUT:
	case types.CLEAR_AJAX_CALL_ERROR:
		return initialState.ajaxErrorInfo

	default:
		return state
	}
}
