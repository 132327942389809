import _cloneDeep from 'lodash/cloneDeep'

const types = require( '../actions/actionTypes' )
const initialState = require( './initialState' )


export default function metadataReducer( state = initialState.pccNetworks, action ) {
	if ( action.type === types.GET_PCC_NETWORKS_SUCCESS ) {
		return _cloneDeep( action.response.data )
	}

	return state
}
