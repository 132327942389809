import helpers from '../lib/helpers'


export default class Media {
	static get friendlyName() { return 'Media' }
	static get modelName() { return Media.friendlyName.replace( ' ', '' ) }
	static get workItemType() { return Media.friendlyName.replace( ' ', '_' ).toLowerCase() }

	static get MEDIA_TYPES() {
		return [
			{
				name	: 'Final Game Highlight',
				value	: 'final-game-highlight'
			},
			{
				name	: 'In-Game Highlight',
				value	: 'in-game-highlight'
			},
			{
				name	: 'One Play Highlight',
				value	: 'one-play'
			},
			{
				name	: 'Analysis',
				value	: 'analysis'
			},
			{
				name	: 'Other Game Related',
				value	: 'game-related'
			},
			{
				name	: 'News',
				value	: 'news'
			},
			{
				name	: 'Interview',
				value	: 'interview'
			},
			{
				name	: 'Feature',
				value	: 'feature'
			},
			{
				name	: 'Press Conference',
				value	: 'press-conference'
			},
			{
				name	: 'Other / Miscellaneous',
				value	: 'miscellaneous'
			}
		]
	}


	static get CC_DUE_TIMES() {
		return [
			{
				name	: 'Immediate',
				value	: 0
			},
			{
				name	: '1 Hour',
				value	: 1
			},
			{
				name	: '2 Hours',
				value	: 2
			},
			{
				name	: '3 Hours',
				value	: 3
			},
			{
				name	: '4 Hours',
				value	: 4
			},
			{
				name	: '5 Hours',
				value	: 5
			},
			{
				name	: '6 Hours',
				value	: 6
			},
			{
				name	: '7 Hours',
				value	: 7
			},
			{
				name	: '8 Hours',
				value	: 8
			},
			{
				name	: '9 Hours',
				value	: 9
			},
			{
				name	: '10 Hours',
				value	: 10
			},
			{
				name	: '11 Hours',
				value	: 11
			},
			{
				name	: '12 Hours',
				value	: 12
			},
			{
				name	: 'N/A',
				value	: ''
			}
		]
	}


	static getEditionsDisplayString( editions ) {
		let editionNames = []

		for ( let edition of editions ) {
			if ( edition === 17 ) {
				editionNames.push( 'Argentina' )
			}
			else if ( edition === 18 ) {
				editionNames.push( 'Chile' )
			}
			else if ( edition === 19 ) {
				editionNames.push( 'Columbia' )
			}
			else if ( edition === 20 ) {
				editionNames.push( 'Mexico' )
			}
			else if ( edition === 21 ) {
				editionNames.push( 'Venezuela' )
			}
			else if ( edition === 22 ) {
				editionNames.push( 'US (Spanish)' )
			}
			else if ( edition === 26 ) {
				editionNames.push( 'Brasil' )
			}
			else if ( edition === 16 ) {
				editionNames.push( 'Australia' )
			}
			else if ( edition === 25 ) {
				editionNames.push( 'Global' )
			}
			else if ( edition === 30 ) {
				editionNames.push( 'Philippines' )
			}
		}

		if ( editionNames.length === 0 ) editionNames = [ 'None' ]

		return editionNames
	}


	static getLanguageDisplayString( lang ) {
		if ( lang === 'es' ) {
			return 'Spanish'
		}
		else if ( lang === 'pt' ) {
			return 'Portuguese'
		}
		else {
			return 'English'
		}
	}


	constructor( json = {} ) {
		this._id = json._id
		this.type = json.type
		this.is_test = json.is_test
		this.attachments = json.attachments
		this.user = json.user
		this.group_organization = json.group_organization
		this.workflow = json.workflow
		this.created = json.created

		if ( helpers.doesExist( json.partner_request ) ) {
			this.partner_request = json.partner_request
		}
		else if ( helpers.doesExist( json.partner_footage ) ) {
			this.partner_footage = json.partner_footage
		}
		else if ( helpers.doesExist( json.shortstop ) ) {
			this.shortstop = json.shortstop
		}
		else if ( helpers.doesExist( json.shortstop_live ) ) {
			this.shortstop_live = json.shortstop_live
		}

		if ( helpers.doesExist( json.media_center ) ) {
			this.media_center = json.media_center
		}
	}


	get modelName() { return Media.modelName }
	get friendlyName() { return Media.friendlyName }
	get workItemType() { return Media.workItemType }


	// the following methods are used to provide a common interface between partner_request and media
	getAttachment( kind ) {
		return this.attachments.find( ( a ) => { return a.kind === kind } )
	}


	getHeadline() {
		if ( helpers.doesNotExist( this.partner_request ) ) return null

		return this.partner_request.headline
	}


	getCaption() {
		if ( helpers.doesNotExist( this.partner_request ) ) return null

		return this.partner_request.caption
	}


	getOrganization() { return this.group_organization[ 0 ] }
	getUserName() { return this.user.name }


	getVideoUrl() {
		const video = this.getAttachment( 'video' )

		let url = null

		if ( helpers.doesExist( video ) ) {
			for ( let format of video.formats ) {
				if ( format.display_type === 'transferred' ) {
					// if there is a transferred version, use that
					url = format.url
					break
				}

				// default to whatever the last url found is
				url = format.url
			}
		}

		return url
	}


	getGamePageUrl( event ) {
		let league = event.league
		if ( league === 'usa.1' ) {
			league = 'soccer'
		}

		return `http://espn.com/${ league }/game?gameId=${ event.event_id }`
	}


	getThumbnailUrl() {
		const photo = this.getAttachment( 'photo' )

		let url = null

		if ( helpers.doesExist( photo ) ) {
			for ( let format of photo.formats ) {
				if ( format.display_type === 'resized' ) {
					// if there is a resized version, use that
					url = format.url
					break
				}

				// default to whatever the last url found is
				url = format.url
			}
		}

		return url
	}


	getThumbnailWidth() {
		const photo = this.getAttachment( 'photo' )
		if ( helpers.doesExist( photo ) && helpers.doesExist( photo.formats[ 0 ].metadata ) ) {
			return photo.formats[ 0 ].metadata.width
		}
	}


	getThumbnailHeight() {
		const photo = this.getAttachment( 'photo' )
		if ( helpers.doesExist( photo ) && helpers.doesExist( photo.formats[ 0 ].metadata ) ) {
			return photo.formats[ 0 ].metadata.height
		}
	}


	getLanguage() {
		if ( helpers.doesNotExist( this.partner_request ) ) return null

		return this.partner_request.language
	}


	getVideoDuration() {
		const video = this.getAttachment( 'video' )
		if ( helpers.doesExist( video ) ) {
			return video.formats[ 0 ].metadata.duration
		}
	}


	getVideoAspectRatio() {
		const video = this.getAttachment( 'video' )
		if ( helpers.doesExist( video ) ) {
			return video.formats[ 0 ].metadata.aspect_ratio
		}
	}


	getPublishDate() {
		if ( helpers.doesNotExist( this.partner_request ) ) return null

		return this.partner_request.publish_date
	}


	getExpirationDate() {
		if ( helpers.doesNotExist( this.partner_request ) ) return null

		return this.partner_request.expiration_date
	}


	getMediaType() {
		const video = this.getAttachment( 'video' )
		if ( helpers.doesExist( video ) ) {
			return video.formats[ 0 ].metadata.media_type
		}
	}


	getSport() {
		if ( helpers.doesNotExist( this.partner_request ) ) return null

		return this.partner_request.sport
	}


	getLeague() {
		if ( helpers.doesNotExist( this.partner_request ) ) return null

		return this.partner_request.league
	}


	getCategories() {
		if ( helpers.doesNotExist( this.partner_request ) ) return null

		return this.partner_request.categories
	}


	getTeams() {
		if ( helpers.doesNotExist( this.partner_request ) ) return null

		return this.partner_request.teams
	}


	getPeople() {
		if ( helpers.doesNotExist( this.partner_request ) ) return null

		return this.partner_request.people
	}


	getCompetition() {
		if ( helpers.doesNotExist( this.partner_request ) ) return null

		return this.partner_request.competition
	}


	getGameId() {
		if ( helpers.doesNotExist( this.partner_request ) ) return null

		return this.partner_request.game_id
	}


	getKeywords() {
		if ( helpers.doesNotExist( this.partner_request ) ) return null

		return this.partner_request.keywords
	}


	getPartnerProvidedId() {
		if ( helpers.doesNotExist( this.partner_request ) ) return null

		return this.partner_request.video_id
	}
}
