import _cloneDeep from 'lodash/cloneDeep'
import _defaultTo from 'lodash/defaultTo'

import React from 'react'
import PropTypes from 'prop-types'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'

import ContentClear from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'

import Analytics from '../../lib/analytics'
import helpers from '../../lib/helpers'
import SelectableMetadata from '../../models/selectable_metadata'


const styles = {
	rightFloatingButton: {
		marginLeft: 'auto'
	},
	displayHelpText: ( validation ) => validation ? { display: 'block' } : { display: 'none' }
}


class InitialConfiguration extends React.Component {
	static get SUPPORTED_LANGUAGES() { return [ 'en', 'es', 'pt' ] }


	constructor( props, context ) {
		super( props, context )
		this.state = this.createStateFromConfig( props.groupConfig )

		this.handleAddLanguage = this.handleAddLanguage.bind( this )
		this.handleAddSportLeague = this.handleAddSportLeague.bind( this )
	}


	UNSAFE_componentWillReceiveProps( nextProps ) {
		this.setState( this.createStateFromConfig( nextProps.groupConfig ) )
	}


	createStateFromConfig( groupConfig ) {
		// create the local state object from the group configuration model

		let validationState = { sport_whitelist: {}, language_whitelist: {} }

		let sportWhitelist = _cloneDeep( groupConfig.workflow.publish_to_cerebro.sport_whitelist )
		for ( let sport of sportWhitelist ) {
			let sportValidationState = {
				prevent_modification	: false,
				espn_sport				: {},
				partner_sport			: {},
				espn_league				: {},
				partner_league			: {}
			}

			if ( !groupConfig.isWhitelistSafeToDelete( sport.sport_espn_value, sport.league_espn_value ) ) {
				let sportMetadata = SelectableMetadata.getParentByValue( this.props.selectableMetadata, 'sport', sport.sport_espn_value )
				let leagueMetadata = SelectableMetadata.getChildByValue( sportMetadata, 'league', sport.league_espn_value )

				sportValidationState.prevent_modification = false
				sportValidationState.espn_league.help_text = `${ leagueMetadata.option_name } has associated editions / mappings`
			}

			// check for multiple entries with the same partner values or no partner values but the same espn values
			let partnerSports = sportWhitelist.filter( ( s ) => {
				if ( helpers.doesExist( s.sport_partner_value ) &&
					helpers.doesExist( sport.sport_partner_value ) &&
					s.sport_partner_value === sport.sport_partner_value && s.league_partner_value === sport.league_partner_value ) return true

				if ( helpers.doesNotExist( s.sport_partner_value ) &&
					helpers.doesNotExist( sport.sport_partner_value ) &&
					helpers.doesExist( s.league_partner_value ) &&
					helpers.doesExist( sport.league_partner_value ) &&
					s.league_partner_value === sport.league_partner_value ) return true

				if ( helpers.doesExist( s.sport_partner_value ) &&
					helpers.doesExist( sport.sport_partner_value ) &&
					helpers.doesNotExist( s.league_partner_value ) &&
					helpers.doesNotExist( sport.league_partner_value ) &&
					s.sport_partner_value === sport.sport_partner_value ) return true

				if ( helpers.doesNotExist( s.sport_partner_value ) &&
					helpers.doesNotExist( sport.sport_partner_value ) &&
					helpers.doesNotExist( s.league_partner_value ) &&
					helpers.doesNotExist( sport.league_partner_value ) &&
					s.sport_espn_value === sport.sport_espn_value && s.league_espn_value === sport.league_espn_value ) return true

				return false
			} )

			if ( partnerSports.length > 1 ) {
				sportValidationState.partner_sport.state = 'error'
				sportValidationState.partner_sport.help_text = 'non-unique entry'

				sportValidationState.partner_league.state = 'error'
				sportValidationState.partner_league.help_text = 'non-unique entry'

				sportValidationState.espn_sport.state = 'error'
				sportValidationState.espn_sport.help_text = 'non-unique entry'

				sportValidationState.espn_league.state = 'error'
				sportValidationState.espn_league.help_text = 'non-unique entry'
			}

			if ( helpers.doesNotExist( validationState.sport_whitelist[ sport.sport_espn_value ] ) ) validationState.sport_whitelist[ sport.sport_espn_value ] = {}

			validationState.sport_whitelist[ sport.sport_espn_value ][ sport.league_espn_value ] = sportValidationState
		}

		// check for duplicate or non-modifiable languages (validation error)
		let languageWhitelist = _cloneDeep( groupConfig.workflow.publish_to_cerebro.language_whitelist )
		for ( let language of languageWhitelist ) {
			let languageValidationState = {
				prevent_modification	: false
			}

			let entries = languageWhitelist.filter( ( l ) => { return l.espn_value === language.espn_value } )
			if ( entries.length > 1 ) {
				languageValidationState.state = 'error'
				languageValidationState.help_text = `${ language.espn_value } is duplicated`
			}
			else if ( !groupConfig.isLanguageSafeToDelete( language.espn_value ) ) {
				languageValidationState.prevent_modification = true
				languageValidationState.help_text = `${ language.espn_value } has associated editions`
			}

			validationState.language_whitelist[ language.espn_value ] = languageValidationState
		}

		return {
			sport_whitelist		: sportWhitelist,
			language_whitelist	: languageWhitelist,
			validation_state	: validationState
		}
	}


	createConfigFromState( componentState ) {
		// do the reverse of above

		let config = _cloneDeep( this.props.groupConfig )

		config.workflow.publish_to_cerebro.sport_whitelist = componentState.sport_whitelist
		config.workflow.publish_to_cerebro.language_whitelist = componentState.language_whitelist

		return config
	}


	removeLanguageAtIndex( currentIdx ) {
		// this is separated out from the method that actually changes the component state to avoid throwing warnings in our tests
		let newPartnerLanguageConfig = _cloneDeep( this.state.language_whitelist )
		newPartnerLanguageConfig.splice( currentIdx, 1 )
		return newPartnerLanguageConfig
	}


	handleRemoveLanguage( currentIdx ) {
		Analytics.recordUserActivity()
		this.props.updateGroupConfigState( this.createConfigFromState( { sport_whitelist: this.state.sport_whitelist, language_whitelist: this.removeLanguageAtIndex( currentIdx ) } ) )
	}


	handleLanguageValueChange( propertyName, idx, event ) {
		Analytics.recordUserActivity()

		let newLanguageWhitelist = _cloneDeep( this.state.language_whitelist )

		let newValue = event.target.value
		if ( newValue === '' ) {
			delete newLanguageWhitelist[ idx ][ propertyName ]
		}
		else {
			newLanguageWhitelist[ idx ][ propertyName ] = newValue
		}

		this.props.updateGroupConfigState( this.createConfigFromState( { sport_whitelist: this.state.sport_whitelist, language_whitelist: newLanguageWhitelist } ) )
	}


	handleAddLanguage() {
		Analytics.recordUserActivity()

		let newLanguageWhitelist = _cloneDeep( this.state.language_whitelist )
		newLanguageWhitelist.push( { partner_value: null, espn_value: this.getNextUnusedLanguage() } )

		this.props.updateGroupConfigState( this.createConfigFromState( { sport_whitelist: this.state.sport_whitelist, language_whitelist: newLanguageWhitelist } ) )
	}


	getNextUnusedLanguage() {
		let language = this.state.language_whitelist.find( ( l ) => { return l.espn_value === 'en' } )
		if ( helpers.doesNotExist( language ) ) return 'en'

		language = this.state.language_whitelist.find( ( l ) => { return l.espn_value === 'es' } )
		if ( helpers.doesNotExist( language ) ) return 'es'

		return 'pt'
	}


	handleAddSportLeague() {
		Analytics.recordUserActivity()

		// default this to something, null values are not allowed here
		let allSportMetadata = SelectableMetadata.getMetadataByKind( this.props.selectableMetadata, 'sport' )
		let newWhitelistEntry = { sport_espn_value: allSportMetadata[ 0 ].option_value, league_espn_value: allSportMetadata[ 0 ].children[ 0 ].option_value }

		let newSportWhitelist = _cloneDeep( this.state.sport_whitelist )
		newSportWhitelist.push( newWhitelistEntry )

		this.props.updateGroupConfigState( this.createConfigFromState( { sport_whitelist: newSportWhitelist, language_whitelist: this.state.language_whitelist } ) )
	}


	removeSportLeagueAtIndex( idx ) {
		// this is separated out from the method that actually changes the component state to avoid throwing warnings in our tests
		let newSportLeagueConfig = _cloneDeep( this.state.sport_whitelist )
		newSportLeagueConfig.splice( idx, 1 )
		return newSportLeagueConfig
	}


	handleRemoveSportLeague( idx ) {
		Analytics.recordUserActivity()

		this.props.updateGroupConfigState( this.createConfigFromState( {
			sport_whitelist		: this.removeSportLeagueAtIndex( idx ),
			language_whitelist	: this.state.language_whitelist
		} ) )
	}


	handleEspnValueChange( propertyName, idx, event ) {
		Analytics.recordUserActivity()

		let newSportWhitelist = _cloneDeep( this.state.sport_whitelist )

		newSportWhitelist[ idx ][ propertyName ] = event.target.value

		if ( propertyName === 'sport_espn_value' ) {
			// league is now invalid (applied to previous sport; initialize it to a valid one
			newSportWhitelist[ idx ].league_espn_value = 'any'
		}

		this.props.updateGroupConfigState( this.createConfigFromState( { sport_whitelist: newSportWhitelist, language_whitelist: this.state.language_whitelist } ) )
	}


	handlePartnerValueChange( propertyName, idx, event ) {
		Analytics.recordUserActivity()

		let newSportWhitelist = _cloneDeep( this.state.sport_whitelist )

		let newValue = event.target.value
		if ( newValue === '' ) {
			delete newSportWhitelist[ idx ][ propertyName ]
		}
		else {
			newSportWhitelist[ idx ][ propertyName ] = newValue
		}

		this.props.updateGroupConfigState( this.createConfigFromState( { sport_whitelist: newSportWhitelist, language_whitelist: this.state.language_whitelist } ) )
	}


	renderRemoveLanguage( language, idx ) {
		let validation = this.state.validation_state.language_whitelist[ language ]
		if ( helpers.doesNotExist( validation ) ) validation = {}

		if ( this.state.language_whitelist.length === 1 ) return <div />
		if ( validation.prevent_modification ) return <div />

		return (
			<div className="remove-language">
				<IconButton onClick={ this.handleRemoveLanguage.bind( this, idx ) }>
					<ContentClear/>
				</IconButton>
			</div>
		)
	}


	renderLanguageTable() {
		let addLanguageDisabled = false
		if ( this.state.language_whitelist.length === InitialConfiguration.SUPPORTED_LANGUAGES.length ) addLanguageDisabled = true

		return (
			<Paper elevation={ 1 }>
				<AppBar position="static" color="default" style={ { boxShadow: 'none' } }>
					<Toolbar disableGutters variant="dense">
						<Typography variant="body2" style={	{ flexGrow: 1, marginLeft: '10px' }	}>
							Configured Languages
						</Typography>

						<Tooltip title="Add Language" enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton id="addLanguage" onClick={ this.handleAddLanguage } disabled={ addLanguageDisabled } style={ styles.rightFloatingButton }>
								<AddIcon />
							</IconButton>
						</Tooltip>
					</Toolbar>
				</AppBar>

				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell style={ { width:'48%' } }>Partner Language Value</TableCell>
							<TableCell style={ { width:'48%' } }>ESPN Language</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>

					<TableBody id="configured_languages">
						{
							this.state.language_whitelist.map( ( language, idx ) => {
								let validation = this.state.validation_state.language_whitelist[ language.espn_value ]
								if ( helpers.doesNotExist( validation ) ) validation = {}

								let disabled = false
								if ( validation.prevent_modification ) disabled = true

								return (
									<TableRow className={ 'partnerLanguageRow' } key={ `partner_language-${ idx }` }>
										<TableCell className="language-partner-value">
											<FormControl>
												<Input
													value={ language.partner_value || '' }
													onChange={ this.handleLanguageValueChange.bind( this, 'partner_value', idx ) }
												/>
											</FormControl>
										</TableCell>

										<TableCell className="language-espn-value">
											<FormControl error={ Boolean( validation.state ) }>
												<Select
													disabled={ disabled }
													value={ language.espn_value }
													onChange={ this.handleLanguageValueChange.bind( this, 'espn_value', idx ) }
												>
													{ InitialConfiguration.SUPPORTED_LANGUAGES.map( ( espnLanguage, idx ) => ( <MenuItem key={ `espn-languages-${ idx }` } value={ espnLanguage }>{ espnLanguage }</MenuItem> ) ) }
												</Select>
												<FormHelperText style={ styles.displayHelpText( validation.help_text ) }>{ validation.help_text }</FormHelperText>
											</FormControl>
										</TableCell>

										<TableCell className="language-actions">{ this.renderRemoveLanguage( language, idx ) }</TableCell>
									</TableRow>
								)
							} )
						}
					</TableBody>

				</Table>
			</Paper>
		)
	}


	renderRemoveSportLeague( sportLeague, idx ) {
		let validation = this.state.validation_state.sport_whitelist[ sportLeague.sport_espn_value ][ sportLeague.league_espn_value ]
		if ( helpers.doesNotExist( validation ) ) validation = {}

		if ( validation.prevent_modification ) return <div />

		return (
			<div className="remove-sport-league">
				<IconButton onClick={ this.handleRemoveSportLeague.bind( this, idx ) }>
					<ContentClear/>
				</IconButton>
			</div>
		)
	}


	renderSportsTable() {
		let allSportMetadata = SelectableMetadata.getMetadataByKind( this.props.selectableMetadata, 'sport' )

		return (
			<Paper elevation={ 1 }>
				<AppBar position="static" color="default" style={ { boxShadow: 'none' } }>
					<Toolbar disableGutters variant="dense">
						<Typography variant="body2" style={ { flexGrow: 1, marginLeft: '10px' } }>
							Configured Sports/Leagues
						</Typography>

						<Tooltip title="Add Sport/League" enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton id="addSportLeague" onClick={ this.handleAddSportLeague } style={ styles.rightFloatingButton }>
								<AddIcon />
							</IconButton>
						</Tooltip>
					</Toolbar>
				</AppBar>
				<Table size="small" id="permittedSportsTable">
					<TableHead>
						<TableRow>
							<TableCell style={ { width: '24%' } }>ESPN Sport</TableCell>
							<TableCell style={ { width: '24%' } }>ESPN League</TableCell>
							<TableCell style={ { width: '24%' } }>Partner Sport Value</TableCell>
							<TableCell style={ { width: '24%' } }>Partner League Value</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>

					<TableBody>
						{ this.state.sport_whitelist.map( ( whitelistEntry, idx ) => {
							let validation = this.state.validation_state.sport_whitelist[ whitelistEntry.sport_espn_value ][ whitelistEntry.league_espn_value ]
							if ( helpers.doesNotExist( validation ) ) validation = {}

							let selectedSportMetadata = SelectableMetadata.getParentByValue( this.props.selectableMetadata, 'sport', whitelistEntry.sport_espn_value )

							let sportLeagues = []
							if ( helpers.doesExist( selectedSportMetadata.children ) ) sportLeagues = selectedSportMetadata.children

							let disabled = false
							if ( validation.prevent_modification ) disabled = true

							return (
								<TableRow className={ 'partnerConfigRow' } key={ `sport-league-${ idx }` }>
									<TableCell className="sport-league-espn-sport">
										<FormControl error={ Boolean( validation.espn_sport.state ) }>
											<Select
												disabled={ disabled }
												value={ _defaultTo( whitelistEntry.sport_espn_value, '' ) }
												onChange={ this.handleEspnValueChange.bind( this, 'sport_espn_value', idx ) }
											>
												{ allSportMetadata.map( ( sport ) => ( <MenuItem key={ `sport-${ sport.option_value }` } value={ sport.option_value }>{ sport.option_name }</MenuItem> ) ) }
											</Select>
											<FormHelperText style={ styles.displayHelpText( validation.espn_sport.help_text ) }>{ validation.espn_sport.help_text }</FormHelperText>
										</FormControl>
									</TableCell>

									<TableCell className="sport-league-espn-league">
										<FormControl error={ Boolean( validation.espn_league.state ) }>
											<Select
												disabled={ disabled }
												value={ _defaultTo( whitelistEntry.league_espn_value, '' ) }
												onChange={ this.handleEspnValueChange.bind( this, 'league_espn_value', idx ) }>
												{ sportLeagues.map( ( league ) => ( <MenuItem key={ `league-${ league.option_value }` } value={ league.option_value }>{ league.option_name }</MenuItem> ) ) }
											</Select>
											<FormHelperText style={ styles.displayHelpText( validation.espn_league.help_text ) }>{ validation.espn_league.help_text }</FormHelperText>
										</FormControl>
									</TableCell>

									<TableCell className="sport-league-partner-sport">
										<FormControl error={ Boolean( validation.partner_sport.state ) }>
											<Input
												value={ _defaultTo( whitelistEntry.sport_partner_value, '' ) }
												onChange={ this.handlePartnerValueChange.bind( this, 'sport_partner_value', idx ) } />
											<FormHelperText style={ styles.displayHelpText( validation.partner_sport.help_text ) }>{ validation.partner_sport.help_text }</FormHelperText>
										</FormControl>
									</TableCell>

									<TableCell className="sport-league-partner-league">
										<FormControl error={ Boolean( validation.partner_league.state ) }>
											<Input
												value={ _defaultTo( whitelistEntry.league_partner_value, '' ) }
												onChange={ this.handlePartnerValueChange.bind( this, 'league_partner_value', idx ) } />
											<FormHelperText style={ styles.displayHelpText( validation.partner_league.help_text ) }>{ validation.partner_league.help_text }</FormHelperText>
										</FormControl>
									</TableCell>

									<TableCell className="sport-league-actions">{ this.renderRemoveSportLeague( whitelistEntry, idx ) }</TableCell>
								</TableRow>
							)
						} ) }
					</TableBody>
				</Table>
			</Paper>
		
		)
	}


	render() {
		return (
			<div style = { { display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between', padding: 8 } }>
				<div style={ { flexBasis: '65%' } }>
					{ this.renderSportsTable() }
				</div>
				<div style={ { flexBasis: '32%' } }>
					{ this.renderLanguageTable() }
				</div>
			</div>
		)
	}
}


InitialConfiguration.propTypes = {
	groupConfig				: PropTypes.object.isRequired,
	selectableMetadata		: PropTypes.array.isRequired,
	updateGroupConfigState	: PropTypes.func.isRequired
}


export default InitialConfiguration
