import _remove from 'lodash/remove'
import _cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

import React from 'react'
import PropTypes from 'prop-types'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

import PostContent from './post_content'
import CategorySearch from '../common/category_search'
import SelectedCategories from './selected_categories'
import EventSearch from '../common/event_search'

import RemoveCircleIcon from '@material-ui/icons/RemoveCircleOutline'

import API from '../../dataSource/api'
import helpers from '../../lib/helpers'
import { withStyles } from '@material-ui/core/styles'


const styles = ( theme ) => ( {
	event_logo			: {
		width				: 30,
		height				: 30,
	},
	event_label			: {
		marginLeft			: 5,
	}
} )


const LANGUAGE = {
	'en' : 'English',
	'es' : 'Spanish',
	'pt' : 'Portuguese'
}


const EDITIONS = {
	17 : 'Argentina',
	16 : 'Australia',
	26 : 'Brasil',
	18 : 'Chile',
	19 : 'Colombia',
	25 : 'Global',
	20 : 'Mexico',
	30 : 'Philippines',
	22 : 'US (Spanish)',
	21 : 'Venezuela',
	0 :  'Domestic'
}


class ShortstopPostEntry extends React.Component {
	constructor( props ) {
		super( props )

		this.state = {
			categories_search: { current_search_term: '', type: 'any', searching: false, results: { categories: [], error: null } },
			event_search_dialog		: { open: false }
		}

		this.handleBodyTextChange = this.handleBodyTextChange.bind( this )

		this.handleCategoryFilterChange = this.handleCategoryFilterChange.bind( this )
		this.handleCategoryFilterSelection = this.handleCategoryFilterSelection.bind( this )

		this.handleCategoryRemoved = this.handleCategoryRemoved.bind( this )

		this.handleCancelChanges = this.handleCancelChanges.bind( this )
		this.handleSaveChanges = this.handleSaveChanges.bind( this )

		this.openAssociateEvent = this.openAssociateEvent.bind( this )
		this.closeAssociateEvent = this.closeAssociateEvent.bind( this )
		this.removeEventAssociation = this.removeEventAssociation.bind( this )
	}


	handleBodyTextChange( newText ) {
		let updatedPost = _cloneDeep( this.props.currentPost )
		updatedPost.shortstop.body_text = newText

		this.props.updatePostStateChangeCallback( updatedPost )
	}


	handleCategoryFilterSelection( selectedCategory ) {
		let updatedPost = _cloneDeep( this.props.currentPost )
		updatedPost.shortstop.categories.push( selectedCategory )

		this.props.updatePostStateChangeCallback( updatedPost )
	}

	handleCategoryRemoved( category ) {
		let updatedPost = _cloneDeep( this.props.currentPost )
		_remove( updatedPost.shortstop.categories, ( c ) => { return c.category_id === category.category_id } )

		this.props.updatePostStateChangeCallback( updatedPost )
	}


	handleCategoryFilterChange( newSearchTerm ) {
		if ( newSearchTerm === '' ) return this.resetSearch()

		let newState = _cloneDeep( this.state )
		newState.categories_search.current_search_term = newSearchTerm

		let newCategoryState = newState.categories_search.results.categories
		if ( newSearchTerm.length < 3 ) newCategoryState = []
		newState.categories_search.results = { error: null, categories: newCategoryState }

		this.setState( newState, () => {
			if ( newState.categories_search.current_search_term.length >= 3 ) {
				this.searchCategories( newState.categories_search.current_search_term, newState.categories_search.type )
			}
			else {
				// if ( this.props.searchComplete !== undefined ) this.props.searchComplete()
			}
		} )
	}


	handleCancelChanges() {
		this.props.cancelChangesCallback()
	}


	handleSaveChanges() {
		this.props.savePostCallback()
	}


	openAssociateEvent() {
		let newState = _cloneDeep( this.state )
		newState.event_search_dialog.open = true

		this.setState( newState )
	}


	closeAssociateEvent( event, leagueCategory ) {
		let newState = _cloneDeep( this.state )
		newState.event_search_dialog.open = false

		this.setState( newState, () => {
			if ( event === null || leagueCategory === null ) return

			let newPost = _cloneDeep( this.props.currentPost )

			newPost.shortstop.event = event

			let existingLeagueCategory = newPost.shortstop.categories.find( ( c ) => { return c.now_id === leagueCategory.now_id } )
			if ( !existingLeagueCategory ) newPost.shortstop.categories.push( leagueCategory )

			this.props.updatePostStateChangeCallback( newPost )
		} )
	}


	removeEventAssociation( event ) {
		let newPost = _cloneDeep( this.props.currentPost )
		newPost.shortstop.event = undefined

		this.props.updatePostStateChangeCallback( newPost )
	}


	searchCategories( searchTerm, categoryType ) {
		if ( searchTerm === '' ) return

		let newState = _cloneDeep( this.state )
		newState.categories_search.searching = true

		let searchGuruCategoryType
		if ( categoryType !== 'any' ) searchGuruCategoryType = categoryType

		this.setState( newState, () => {
			API.searchCategories( this.props.authorizationInfo.user, searchTerm, searchGuruCategoryType )
				.then( ( searchResults ) => {
					if ( searchResults.searchTerm !== this.state.categories_search.current_search_term ) return

					let newState = _cloneDeep( this.state )

					newState.categories_search.searching = false
					newState.categories_search.results = { categories: searchResults.models, error: null }

					this.setState( newState, () => {
						// if ( this.props.searchComplete !== undefined ) this.props.searchComplete()
					} )
				} )
				.catch( ( err ) => {
					let newState = _cloneDeep( this.state )

					newState.categories_search.searching = false
					newState.categories_search.results = { categories: [], error: err }

					this.setState( newState, () => {
						// if ( this.props.searchComplete !== undefined ) this.props.searchComplete()
					} )
				} )
		} )
	}

	resetSearch() {
		let newState = _cloneDeep( this.state )
		newState.categories_search = { current_search_term: '', type: this.state.categories_search.type, searching: false, results: { categories: [], error: null } }

		this.setState( newState )
	}


	renderAssociatedLanguageEditions( post ) {
		let editionsText = EDITIONS[ 0 ]
		if ( post.shortstop.editions.length > 0 ) {
			editionsText = post.shortstop.editions.map( ( edition, index ) => {
				if ( index < post.shortstop.editions.length - 1 ) {
					return `${ EDITIONS[ edition ] }, `
				}

				return EDITIONS[ edition ]
			} )
		}

		return (
			<div>
				<Typography variant="body1" color="textPrimary" style={ { marginTop: 10, marginBottom: 10 } }>
					Language: { LANGUAGE[ post.shortstop.language ] }
				</Typography>
				<Typography variant="body1" color="textPrimary" style={ { marginTop: 10, marginBottom: 10 } }>
					Editions: { editionsText }
				</Typography>
			</div>
		)
	}


	renderAssociatedEvent( post ) {
		if ( post.shortstop.event === undefined ) {
			return (
				<Button fullWidth variant="outlined" component="span" onClick={ this.openAssociateEvent }>
					Associate Event
				</Button>
			)
		}

		return (

			<div style={ { display: 'flex', alignItems: 'center' } }>
				<div style={ { display: 'flex', flexDirection: 'column' } }>
					<div style={ { display: 'flex', alignItems: 'center' } }>
						<img className={ this.props.classes.event_logo } alt={ post.shortstop.event.vis_team.name } src={ helpers.getSecureCDNLink( post.shortstop.event.vis_team.logo_url ) } />
						<Typography className={ this.props.classes.event_label } variant="subtitle1" color="textPrimary" noWrap>
							{ post.shortstop.event.vis_team.name }
						</Typography>
					</div>

					<div style={ { display: 'flex', alignItems: 'center', marginTop: 10 } }>
						<img className={ this.props.classes.event_logo } alt={ post.shortstop.event.home_team.name } src={ helpers.getSecureCDNLink( post.shortstop.event.home_team.logo_url ) } />
						<Typography className={ this.props.classes.event_label } variant="subtitle1" color="textPrimary" noWrap>
							{ post.shortstop.event.home_team.name }
						</Typography>
					</div>
				</div>

				<div style={ { display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 20, flex: 'auto' } }>
					<Typography variant="subtitle1" color="textSecondary" style={ { marginLeft: 'auto' } }>
						{ moment( post.shortstop.event.start_time ).format( 'ddd MMM Do' ) }
					</Typography>

					<Typography variant="subtitle1" color="textSecondary" style={ { marginLeft: 'auto' } }>
						{ moment( post.shortstop.event.start_time ).format( 'h:mm A' ) }
					</Typography>
				</div>

				<div style={ { marginLeft: 'auto', flex: 'none' } }>
					<IconButton onClick={ this.removeEventAssociation }>
						<RemoveCircleIcon />
					</IconButton>
				</div>
			</div>
		)
	}


	render() {
		if ( helpers.doesNotExist( this.props.currentPost ) ) return ( <div /> )

		let post = this.props.currentPost

		let saveDisabled = false
		if ( post.shortstop.categories.length === 0 || post.shortstop.body_text.trim() === '' ) {
			saveDisabled = true
		}

		let eventsSearch = ( <div /> )
		if ( this.state.event_search_dialog.open ) {
			eventsSearch = (
				<EventSearch
					currentUser={ this.props.authorizationInfo.user }
					isOpen={ this.state.event_search_dialog.open }
					closeDialog={ this.closeAssociateEvent }
				/>
			)
		}

		return (
			<Paper elevation={ 1 } style={ { marginTop: 30, padding: 10 } }>
				<Typography variant="h5" component="h3" style={ { marginTop: 10, marginBottom: 10 } }>
					Edit Post
				</Typography>

				<PostContent post={ post } updatePostText={ this.handleBodyTextChange } />

				<Typography variant="body1" color="inherit" style={ { marginTop: 30, marginBottom: 10 } }>
					CATEGORIES
				</Typography>

				<CategorySearch
					clearOnSelection={ true }
					placeholderText={ 'Search Categories' }
					searchTerm={ this.state.categories_search.current_search_term }
					categoryType={ this.state.categories_search.type }
					searching={ this.state.categories_search.searching }
					searchResults={ this.state.categories_search.results }
					updateSearchTerm={ this.handleCategoryFilterChange }
					updateCategoryType={ () => {} }
					categorySelected={ this.handleCategoryFilterSelection }
					displayTypeSelector={ true }
				/>

				<Typography variant="body1" color="inherit" noWrap style={ { marginTop: 30, marginBottom: 10 } }>
					SELECTED CATEGORIES
				</Typography>

				<SelectedCategories
					selectedCategories={ post.shortstop.categories }
					categoryRemoved={ this.handleCategoryRemoved }
				/>

				<Typography variant="body1" color="inherit" style={ { marginTop: 30, marginBottom: 10 } }>
					LANGUAGE & EDITIONS
				</Typography>

				{ this.renderAssociatedLanguageEditions( post ) }

				<Typography color="inherit" style={ { marginTop: 30, marginBottom: 10 } }>
					EVENT
				</Typography>

				<div>
					{ eventsSearch }
				</div>

				{ this.renderAssociatedEvent( post ) }

				<Button variant="contained" color="primary" disabled={ saveDisabled } onClick={ this.handleSaveChanges } style={ { marginTop: 30 } }>
					Save Changes
				</Button>
				<Button variant="contained" color="secondary" onClick={ this.handleCancelChanges } style={ { marginLeft: 10, marginTop: 30 } }>
					Cancel
				</Button>
			</Paper>
		)
	}
}


ShortstopPostEntry.propTypes = {
	authorizationInfo				: PropTypes.object.isRequired,
	currentPost  					: PropTypes.object.isRequired,
	updatePostStateChangeCallback	: PropTypes.func.isRequired,
	cancelChangesCallback           : PropTypes.func.isRequired,
	savePostCallback                : PropTypes.func.isRequired,

	// injected by material-ui
	classes							: PropTypes.object.isRequired
}


export default withStyles( styles )( ShortstopPostEntry )
