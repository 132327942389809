import _clone from 'lodash/clone'
import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'

import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import { showNotification } from '../../actions/appNotificationActions'
import * as QS from 'query-string'

import Analytics from '../../lib/analytics'
import helpers from '../../lib/helpers'

import API from '../../dataSource/api'
import { callApi, reportApiError } from '../../actions/apiCallActions'

import IngestActivityGrid from './ingest_activity_grid'
import IngestActivityFilter from './ingest_activity_filter'
import IngestActivityDetails from './ingest_activity_details'

// The following constants are used to track the loading state of the previously selected user & category filter values.
// In order to properly initialize the typeaheads, we can't render them until after the initial value has been retrieved
// from the server.  We have to keep track of where we are in this process so that we render the typeaheads either:
//
//	1. immediately if no filter value is set
//	2. after either (or both) documents have been retrieved (if either or both are set)
//	3. after either (or both) retrievals errors out
//
// This process applies to the situation when the user has selected a user / category to filter against and then refreshes
// the screen.

const DOCUMENT_LOADING = 'loading'
const DOCUMENT_LOADED = 'loaded'
const DOCUMENT_NOT_APPLICABLE = 'not-applicable'


export class IngestActivityPage extends React.Component {
	constructor( props ) {
		super( props )

		this.pageSize = 10

		this.state = {
			activity				: { data: [], total: 0 },
			groups					: [],
			selectedActivity		: null,
			selectedWorkItem		: null,
			filtered_contributor	: { status: DOCUMENT_NOT_APPLICABLE, document: null },
			filtered_group			: { status: DOCUMENT_NOT_APPLICABLE, document: null },
			query					: this.setStateFromLocation( this.props.location.search ),
			show_filter_dialog		: false,
		}

		this.handleRefresh = this.handleRefresh.bind( this )
		this.handleShowFilter = this.handleShowFilter.bind( this )

		this.filterSettingsChanged = this.filterSettingsChanged.bind( this )
		this.userFilterChanged = this.userFilterChanged.bind( this )
		this.groupFilterChanged = this.groupFilterChanged.bind( this )
		this.closeFilter = this.closeFilter.bind( this )

		this.handlePostSelected = this.handlePostSelected.bind( this )
		this.handleSort = this.handleSort.bind( this )
		this.handlePageSelected = this.handlePageSelected.bind( this )

		this.handleRepublishArchive = this.handleRepublishArchive.bind( this )
	}


	async componentDidMount() {
		try {
			// load the user to get the name so we can set the contributor search text
			if ( helpers.doesExist( this.state.query.contributor ) ) await this.loadContributor( this.state.query.contributor )

			await this.loadGroupList()
			await this.loadActivity( this.state.query.currentPage )
		}
		catch ( e ) {
			this.props.dispatch( reportApiError( e ) )
		}
	}


	setStateFromLocation( queryString ) {
		let newState = {
			currentPage		: 0,
			sort			: '-created',
			postDate		: null,
			showTests		: false,
			contributor		: null,
			group			: null
		}

		let parsedQuery = QS.parse( queryString )

		if ( helpers.isInteger( parsedQuery.currentPage ) ) {
			newState.currentPage = ( parseInt( parsedQuery.currentPage ) - 1 )
		}

		if ( helpers.doesExist( parsedQuery.sort ) ) {
			newState.sort = parsedQuery.sort
		}

		if ( helpers.doesExist( parsedQuery.postDate ) ) {
			newState.postDate = moment( parsedQuery.postDate )
		}

		if ( helpers.doesExist( parsedQuery.showTests ) ) {
			newState.showTests = helpers.isTrue( parsedQuery.showTests )
		}

		if ( helpers.doesExist( parsedQuery.contributor ) ) {
			newState.contributor = parsedQuery.contributor
		}

		if ( helpers.doesExist( parsedQuery.group ) ) {
			newState.group = parsedQuery.group
		}

		return newState
	}


	setLocationFromState( state ) {
		let queryState = state.query

		let newQuery = {}
		let newLocationHasQuery = false

		if ( helpers.isInteger( queryState.currentPage ) && parseInt( queryState.currentPage ) > 0 ) {
			newQuery.currentPage = queryState.currentPage + 1
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( queryState.sort ) && queryState.sort !== '-created' ) {
			newQuery.sort = queryState.sort
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( queryState.postDate ) ) {
			newQuery.postDate = moment( queryState.postDate ).format( 'YYYY-MM-DD' )
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( queryState.showTests ) && helpers.isTrue( queryState.showTests ) ) {
			newQuery.showTests = queryState.showTests
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( state.filtered_contributor.document ) ) {
			newQuery.contributor = state.filtered_contributor.document._id
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( state.filtered_group.document ) ) {
			newQuery.group = state.filtered_group.document.group_id
			newLocationHasQuery = true
		}

		let newLocation = '/clip-transfer'
		if ( this.props.location.pathname.endsWith( 'footage' ) ) newLocation = '/footage'

		if ( newLocationHasQuery ) newLocation = `${ newLocation }?${ QS.stringify( newQuery ) }`

		this.props.history.replace( newLocation )
	}


	loadContributor( contributorId ) {
		return new Promise( ( resolve, reject ) => {
			let newState = _cloneDeep( this.state )
			newState.filtered_contributor = { status: DOCUMENT_LOADING, document: null }

			this.setState( newState, () => {
				this.props.dispatch(
					callApi(
						() => {
							return ( ( currentUser, contributorId ) => {
								return API.getUser( currentUser, contributorId )
							} )( this.props.authorizationInfo.user, contributorId )
						},
						( err, user ) => {
							if ( helpers.doesExist( err ) ) {
								let newState = _cloneDeep( this.state )

								// just say it was loaded here (for simplicity's sake)
								newState.filtered_contributor = { status: DOCUMENT_LOADED, document: null }

								return this.setState( newState, () => {
									reject( err )
								} )
							}

							let newState = _cloneDeep( this.state )
							newState.filtered_contributor = { status: DOCUMENT_LOADED, document: user }

							this.setState( newState, () => {
								resolve()
							} )
						}
					)
				)
			} )
		} )
	}


	loadGroupList() {
		return new Promise( ( resolve, reject ) => {
			if ( !this.props.authorizationInfo.user.isInternal ) return resolve()
			if ( !this.props.location.pathname.endsWith( 'footage' ) ) return resolve()

			return this.props.dispatch(
				callApi(
					() => {
						return ( ( currentUser, params ) => {
							return API.listGroups( currentUser, params )
						} )( this.props.authorizationInfo.user, {} )
					},
					( err, groups ) => {
						if ( helpers.doesExist( err ) ) return reject( err )

						let newState = _cloneDeep( this.state )
						newState.groups = groups

						if ( this.state.query.group !== null ) {
							newState.filtered_group = { status: DOCUMENT_LOADING, document: null }

							for ( let i = 0; i < groups.length; i++ ) {
								if ( groups[ i ].group_id === this.state.query.group ) {
									newState.filtered_group = { status: DOCUMENT_LOADED, document: groups[ i ] }
								}
							}
						}

						this.setState( newState, () => {
							resolve()
						} )
					}
				)
			)
		} )
	}


	handlePostSelected( selectedActivity ) {
		let workItemType = _get( selectedActivity, 'work_item.type', 'partner_footage' )

		if ( workItemType === 'partner_footage' ) {
			// no associated media document which implies a failed request
			return this.setState( { selectedActivity: selectedActivity } )
		}

		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, mediaId ) => {
						return API.getMedia( currentUser, mediaId )
					} )( this.props.authorizationInfo.user, selectedActivity.work_item.work_item_id )
				},
				( err, workItem ) => {
					this.handleWorkItemRetrieval( err, workItem, selectedActivity )
				}
			)
		)
	}


	handleWorkItemRetrieval( err, workItem, selectedActivity ) {
		if ( helpers.doesExist( err ) ) {
			return this.props.dispatch( reportApiError( err ) )
		}

		this.setState( { selectedActivity: selectedActivity, selectedWorkItem: workItem } )
	}


	buildSearchFilter( broadcastDate, hideTests, contributor, selectedGroupId ) {
		let filter = {}

		filter.predicate = '$and'

		if ( hideTests ) filter.hideTestActivity = hideTests
		if ( helpers.doesExist( broadcastDate ) ) filter.created_on = broadcastDate.toISOString()
		if ( helpers.doesExist( contributor ) ) filter.contributor = contributor._id

		// if the user is a partner, default to only looking at their content
		if ( !this.props.authorizationInfo.user.isInternal ) {
			filter.group_id = this.props.authorizationInfo.user.group.group_id
		}
		else if ( helpers.doesExist( selectedGroupId ) ) {
			filter.group_id = selectedGroupId
		}

		filter.current_state = 'Failed,Rejected,Delivered,Publishing,Uploading,Published'
		filter.has_warnings = true
		filter.predicate = '$or'

		filter.post_type = 'shortstop-live'
		if ( this.props.location.pathname.endsWith( 'footage' ) ) {
			filter.post_type = 'partner-footage'
		}

		return filter
	}


	buildSearchPageInfo( page, pageSize ) {
		return { limit: pageSize, offset: page * pageSize }
	}


	loadActivity( page ) {
		this.setState( { activity: { data: [], total: 0 }, selectedActivity: null, selectedWorkItem: null } )

		let filter = this.buildSearchFilter( this.state.query.postDate, this.state.query.showTests, this.state.filtered_contributor.document, this.state.query.group )
		let pageInfo = this.buildSearchPageInfo( page, this.pageSize )

		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, activityName, filter, sortValue, pageInfo ) => {
						return API.searchActivity( currentUser, activityName, filter, sortValue, pageInfo )
					} )( this.props.authorizationInfo.user, 'AddMediaToSystem', filter, this.state.query.sort, pageInfo )
				},
				( err, activity ) => {
					if ( helpers.doesExist( err ) ) return this.props.dispatch( reportApiError( err ) )

					this.setState( { activity: activity } )
				}
			)
		)
	}


	handleSort( sortColumn ) {
		Analytics.recordUserActivity()

		let newQuery = _clone( this.state.query )

		newQuery.currentPage = 0
		newQuery.sort = sortColumn

		this.setState( { query: newQuery }, () => {
			this.setLocationFromState( this.state )
			this.loadActivity( this.state.query.currentPage )
		} )
	}


	handleRefresh( event ) {
		Analytics.recordUserActivity()

		this.loadActivity( this.state.query.currentPage )
	}


	filterSettingsChanged( newFilter ) {
		Analytics.recordUserActivity()

		this.setState( { query: newFilter }, () => { this.setLocationFromState( this.state ) } )
	}


	closeFilter() {
		Analytics.recordUserActivity()

		let newState = _cloneDeep( this.state )
		newState.show_filter_dialog = false

		this.setState( newState, () => { this.loadActivity( this.state.query.currentPage ) } )
	}


	userFilterChanged( selectedUser ) {
		Analytics.recordUserActivity()

		if ( helpers.doesNotExist( selectedUser ) ) {
			let newState = _cloneDeep( this.state )

			newState.query.contributor = null
			newState.filtered_contributor = { status: DOCUMENT_NOT_APPLICABLE, document: null }
			newState.currentPage = 0

			// update the query string before changing state
			this.setLocationFromState( newState )

			return this.setState( newState )
		}

		let newState = _clone( this.state )

		newState.query.contributor = selectedUser._id
		newState.filtered_contributor = { status: DOCUMENT_LOADED, document: selectedUser }
		newState.currentPage = 0

		this.setState( newState, () => {
			this.setLocationFromState( this.state )
			this.loadActivity( this.state.query.currentPage )
		} )
	}


	groupFilterChanged( selectedGroup ) {
		Analytics.recordUserActivity()

		if ( helpers.doesNotExist( selectedGroup ) ) {
			let newState = _cloneDeep( this.state )

			newState.query.group = null
			newState.filtered_group = { status: DOCUMENT_NOT_APPLICABLE, document: null }
			newState.currentPage = 0

			// update the query string before changing state
			this.setLocationFromState( newState )

			return this.setState( newState )
		}

		let newState = _clone( this.state )

		newState.query.group = selectedGroup.group_id
		newState.filtered_group = { status: DOCUMENT_LOADED, document: selectedGroup }
		newState.currentPage = 0

		this.setState( newState, () => {
			this.setLocationFromState( this.state )
			this.loadActivity( this.state.query.currentPage )
		} )
	}


	handlePageSelected( newPage ) {
		Analytics.recordUserActivity()

		let newQuery = _clone( this.state.query )
		newQuery.currentPage = parseInt( newPage )

		this.setState( { query: newQuery }, () => {
			this.setLocationFromState( this.state )
			this.loadActivity( this.state.query.currentPage )
		} )
	}


	handleShowFilter( event ) {
		Analytics.recordUserActivity()

		let newState = _cloneDeep( this.state )
		newState.show_filter_dialog = true

		this.setState( newState )
	}


	handleRepublishArchive( request ) {
		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, mediaId ) => {
						return API.publishMedia( currentUser, mediaId )
					} )( this.props.authorizationInfo.user, request._id )
				},
				( err, res ) => {
					if ( helpers.doesExist( err ) ) {
						this.props.dispatch( reportApiError( err ) )
					}
					else {
						this.props.dispatch( showNotification( 'Success', 'Publish Request', 'success' ) )

						this.loadActivity( this.state.query.currentPage )
					}
				}
			)
		)
	}


	render() {
		return (
			<div style={ { marginTop: 15 } }>
				<IngestActivityFilter
					authorizationInfo={ this.props.authorizationInfo }
					open={ this.state.show_filter_dialog }
					filterSettings={ this.state.query }
					filteredContributor={ this.state.filtered_contributor }
					filteredGroup={ this.state.filtered_group }
					groups={ this.state.groups }
					filterSettingsChanged={ this.filterSettingsChanged }
					userFilterChanged={ this.userFilterChanged }
					groupFilterChanged={ this.groupFilterChanged }
					closeFilter={ this.closeFilter }
					location={ this.props.location }
				/>

				<div style={ { display: 'flex', flexDirection: 'row', marginLeft: 10 } }>
					<div style={ { flex: 2 } }>
						<IngestActivityGrid
							authorizationInfo={ this.props.authorizationInfo }
							activity={ this.state.activity }
							filterSettings={ this.state.query }
							filteredContributor={ this.state.filtered_contributor }
							filteredGroup={ this.state.filtered_group }
							currentSort={ this.state.query.sort }
							currentPage={ this.state.query.currentPage }
							onPostSelected={ this.handlePostSelected }
							onSortChanged={ this.handleSort }
							onPageChanged={ this.handlePageSelected }
							onRefreshList={ this.handleRefresh }
							onShowFilter={ this.handleShowFilter }
						/>
					</div>

					<div style={ { flex: 1, marginLeft: 15, marginRight: 10 } }>
						<IngestActivityDetails
							authorizationInfo={ this.props.authorizationInfo }
							activity={ this.state.selectedActivity }
							workItem={ this.state.selectedWorkItem }
							republishArchive={ this.handleRepublishArchive }
							location={ this.props.location }
						/>
					</div>
				</div>
			</div>
		)
	}
}


function mapStateToProps( state, ownProps ) {
	return {
		authorizationInfo	: state.authorizationInfo
	}
}


IngestActivityPage.propTypes = {
	authorizationInfo	: PropTypes.object.isRequired,

	// injected automatically by react-router
	history				: PropTypes.object.isRequired,
	location			: PropTypes.object,
}


export default withRouter( connect( mapStateToProps )( IngestActivityPage ) )
