import _isArray from 'lodash/isArray'

import helpers from '../lib/helpers'


export default class SelectableMetadata {
	static get friendlyName() { return 'Selectable Metadata' }
	static get modelName() { return SelectableMetadata.friendlyName.replace( ' ', '' ) }
	static get workItemType() { return SelectableMetadata.friendlyName.replace( ' ', '_' ).toLowerCase() }


	static getParentByValue( metadata, type, value ) {
		return SelectableMetadata.findMetadataByValue( metadata, type, value )
	}


	static getChildByValue( parent, type, value ) {
		return SelectableMetadata.findMetadataByValue( parent.children, type, value )
	}


	static findMetadataByValue( list, type, value ) {
		if ( helpers.doesNotExist( list ) ) return { option_kind: type, option_name: 'not found', option_value: value }

		const index = list.findIndex( ( metadata ) => { return ( metadata.option_kind === type && metadata.option_value === value ) } )
		if ( index < 0 ) return { option_kind: type, option_name: 'not found', option_value: value }

		return list[ index ]
	}


	static getMetadataByKind( metadata, kind ) {
		return metadata.filter( ( m ) => { return m.option_kind === kind } )
	}


	constructor( json = {} ) {
		if ( helpers.doesExist( json._id ) ) this._id = json._id

		this.option_kind = json.option_kind
		this.option_name = json.option_name
		this.option_value = json.option_value

		this.children = []
		if ( _isArray( json.children ) && json.children.length > 0 ) {
			json.children.map( ( child ) => {
				this.children.push( new SelectableMetadata( child ) )
			} )
		}
	}


	get modelName() { return SelectableMetadata.modelName }
	get friendlyName() { return SelectableMetadata.friendlyName }
	get workItemType() { return SelectableMetadata.workItemType }
}
