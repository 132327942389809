import _clone from 'lodash/clone'
import _isString from 'lodash/isString'

import moment from 'moment'

import React from 'react'
const PropTypes = require( 'prop-types' )
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { showNotification } from '../../actions/appNotificationActions'

const QS = require( 'query-string' )

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import Analytics from '../../lib/analytics'
import helpers from '../../lib/helpers'

import Api from '../../dataSource/api'
import { callApi, reportApiError } from '../../actions/apiCallActions'

import PartnerRequest from '../../models/partner_request'

import ActivityGrid from './activity_grid'
import ActivityDetail from './activity_detail'
import ActivityFilter from './activity_filter'


const TAB_ALL_ACTIVITY = 'TAB_ALL_ACTIVITY'
const TAB_ERRORS_AND_WARNINGS = 'TAB_ERRORS_AND_WARNINGS'
const TAB_PENDING_REVIEW = 'TAB_PENDING_REVIEW'
const TAB_NOT_PUBLISHED = 'TAB_NOT_PUBLISHED'

const DOCUMENT_LOADED = 'loaded'
const DOCUMENT_NOT_APPLICABLE = 'not-applicable'

export class ActivityPage extends React.Component {
	constructor( props, context ) {
		super( props, context )

		this.pageSize = 10

		this.state = {
			groups				: [],
			activity			: { data: [], total: 0 },
			selectedActivity	: null,
			selectedWorkItem	: null,
			show_filter_dialog	: false,
			filtered_group		: { status: DOCUMENT_NOT_APPLICABLE, document: null },
			query				: this.setStateFromLocation( this.props.location.search )
		}

		this.closeFilter = this.closeFilter.bind( this )
		this.openFilter = this.openFilter.bind( this )
	}


	componentDidMount() {
		if ( this.props.authorizationInfo.user.isInternal ) {
			this.props.dispatch(
				callApi(
					() => {
						return ( ( currentUser, params ) => {
							return Api.listGroups( currentUser, params )
						} )( this.props.authorizationInfo.user, {} )
					},
					this.handleListGroups.bind( this )
				)
			)
		}

		this.loadActivity( this.state.query.currentPage )
	}


	setStateFromLocation( queryString ) {
		let newState = {
			language	: null,
			currentPage	: 0,
			startDate	: null,
			endDate		: null,
			showTests	: true,
			groupId		: null,
			sort		: '-created',
			tab			: TAB_ALL_ACTIVITY
		}

		let parsedQuery = QS.parse( queryString )

		if ( helpers.doesExist( parsedQuery.language ) ) {
			newState.language = parsedQuery.language
		}

		if ( helpers.isInteger( parsedQuery.currentPage ) ) {
			newState.currentPage = ( parseInt( parsedQuery.currentPage ) - 1 )
		}

		if ( helpers.doesExist( parsedQuery.startDate ) ) {
			newState.startDate = new Date( `${ parsedQuery.startDate }T00:00:00` )
		}

		if ( helpers.doesExist( parsedQuery.endDate ) ) {
			newState.endDate = new Date( `${ parsedQuery.endDate }T00:00:00` )
		}

		if ( helpers.doesExist( parsedQuery.showTests ) ) {
			newState.showTests = helpers.isTrue( parsedQuery.showTests )
		}

		if ( helpers.doesExist( parsedQuery.groupId ) ) {
			newState.groupId = parsedQuery.groupId
		}

		if ( helpers.doesExist( parsedQuery.sort ) ) {
			newState.sort = parsedQuery.sort
		}

		if ( helpers.doesExist( parsedQuery.tab ) ) {
			newState.tab = parsedQuery.tab
		}

		return newState
	}


	setLocationFromState( queryState ) {
		let newQuery = {}
		let newLocationHasQuery = false

		if ( helpers.doesExist( queryState.language ) ) {
			newQuery.language = queryState.language
			newLocationHasQuery = true
		}

		if ( helpers.isInteger( queryState.currentPage ) && parseInt( queryState.currentPage ) > 0 ) {
			newQuery.currentPage = queryState.currentPage + 1
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( queryState.startDate ) ) {
			newQuery.startDate = moment( queryState.startDate ).format( 'YYYY-MM-DD' )
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( queryState.endDate ) ) {
			newQuery.endDate = moment( queryState.endDate ).format( 'YYYY-MM-DD' )
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( queryState.showTests ) && helpers.isTrue( queryState.showTests ) ) {
			newQuery.showTests = queryState.showTests
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( queryState.groupId ) ) {
			newQuery.groupId = queryState.groupId
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( queryState.sort ) && queryState.sort !== '-created' ) {
			newQuery.sort = queryState.sort
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( queryState.tab ) && queryState.tab !== TAB_ALL_ACTIVITY ) {
			newQuery.tab = queryState.tab
			newLocationHasQuery = true
		}

		let newLocation = '/activity'
		if ( newLocationHasQuery ) newLocation = `${ newLocation }?${ QS.stringify( newQuery ) }`

		this.props.history.replace( newLocation )
		this.loadActivity( this.state.query.currentPage )
	}


	handleQueryChange( newQuery ) {
		Analytics.recordUserActivity()

		this.setState( { query: newQuery }, () => {	this.setLocationFromState( this.state.query ) } )
	}


	handleGroupSelect( newQuery, selectedGroup ) {
		Analytics.recordUserActivity()

		let filteredGroup

		if ( helpers.doesNotExist( selectedGroup ) ) {
			filteredGroup = { status: DOCUMENT_NOT_APPLICABLE, document: null }

			return this.setState( { query: newQuery, filtered_group: filteredGroup }, () => {	this.setLocationFromState( this.state.query ) } )
		}

		filteredGroup = { status: DOCUMENT_LOADED, document: selectedGroup }

		this.setState( { query: newQuery, filtered_group: filteredGroup }, () => {
			this.setLocationFromState( this.state.query )
			this.loadActivity( this.state.query.currentPage )
		} )
	}


	handleListGroups( err, groups ) {
		if ( helpers.doesExist( err ) ) return this.props.dispatch( reportApiError( err ) )

		let filteredGroup = { status: DOCUMENT_NOT_APPLICABLE, document: null }

		if ( this.state.query.groupId !== null ) {
			let fullGroup = groups.find( group => group.group_id === this.state.query.groupId )
			if ( fullGroup ) {
				filteredGroup = { status: DOCUMENT_LOADED, document: fullGroup }
			}
		}

		this.setState( { groups: groups, filtered_group: filteredGroup } )
	}


	activitySelectedFromList( selectedActivity ) {
		this.setState( { selectedActivity: selectedActivity } )

		if ( selectedActivity.work_item.type === PartnerRequest.workItemType ) {
			this.props.dispatch(
				callApi(
					() => {
						return ( ( currentUser, partnerRequestId ) => {
							return Api.getPartnerRequest( currentUser, partnerRequestId )
						} )( this.props.authorizationInfo.user, selectedActivity.work_item.work_item_id )
					},
					this.handleWorkItemRetrieval.bind( this )
				)
			)
		}
		else {
			this.props.dispatch(
				callApi(
					() => {
						return ( ( currentUser, mediaId ) => {
							return Api.getMedia( currentUser, mediaId )
						} )( this.props.authorizationInfo.user, selectedActivity.work_item.work_item_id )
					},
					this.handleWorkItemRetrieval.bind( this )
				)
			)
		}
	}


	ignoreRequest( request ) {
		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, mediaId ) => {
						return Api.ignoreMedia( currentUser, mediaId )
					} )( this.props.authorizationInfo.user, request._id )
				},
				( err, res ) => {
					if ( helpers.doesExist( err ) ) {
						this.props.dispatch( reportApiError( err ) )
					}
					else {
						this.props.dispatch( showNotification( 'Success', 'Ignore Request', 'success' ) )

						this.loadActivity( this.state.query.currentPage )
					}
				}
			)
		)
	}


	publishRequest( request ) {
		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, mediaId ) => {
						return Api.publishMedia( currentUser, mediaId )
					} )( this.props.authorizationInfo.user, request._id )
				},
				( err, res ) => {
					if ( helpers.doesExist( err ) ) {
						this.props.dispatch( reportApiError( err ) )
					}
					else {
						this.props.dispatch( showNotification( 'Success', 'Publish Request', 'success' ) )

						this.loadActivity( this.state.query.currentPage )
					}
				}
			)
		)
	}


	handleWorkItemRetrieval( err, workItem ) {
		if ( helpers.doesExist( err ) ) {
			this.props.dispatch( reportApiError( err ) )
			return this.setState( { selectedActivity: null } )
		}

		this.setState( { selectedWorkItem: workItem } )
	}


	buildSearchFilter( selectedTab, selectedGroupId, selectedLanguage, selectedStartDate, selectedEndDate, hideTestActivity ) {
		let filter = {}

		filter.predicate = '$and'

		if ( helpers.doesExist( selectedGroupId ) && ( selectedGroupId !== '' ) ) filter.group_id = selectedGroupId
		if ( helpers.doesExist( selectedLanguage ) && ( selectedLanguage !== '' ) ) filter.language = selectedLanguage
		if ( helpers.doesExist( hideTestActivity ) ) filter.hideTestActivity = hideTestActivity

		if ( helpers.doesExist( selectedTab ) ) {
			switch ( selectedTab ) {
			case TAB_ERRORS_AND_WARNINGS:
				filter.current_state = 'Failed,Rejected'
				filter.has_warnings = true
				filter.predicate = '$or'
				filter.warnings_for_all_states = true

				break
			case TAB_PENDING_REVIEW:
				filter.current_state = 'RequiresReview'
				break
			case TAB_NOT_PUBLISHED:
				filter.current_state = 'Ignored'
				break
			}
		}

		if ( helpers.doesExist( selectedStartDate ) ) {
			let startDate = moment( selectedStartDate ).utc().hour( 0 )
			filter.created_after = startDate.toISOString()
		}

		if ( helpers.doesExist( selectedEndDate ) ) {
			let endDate = moment( selectedEndDate ).utc().hour( 23 ).minute( 59 )
			filter.created_before = endDate.toISOString()
		}

		filter.post_type = 'partner'

		return filter
	}


	buildSearchPageInfo( page, pageSize ) {
		return { limit: pageSize, offset: page * pageSize }
	}


	loadActivity( page ) {
		this.setState( { activity: { data: [], total: 0 }, selectedActivity: null, selectedWorkItem: null } )

		let filter = this.buildSearchFilter( this.state.query.tab, this.state.query.groupId, this.state.query.language, this.state.query.startDate, this.state.query.endDate, !this.state.query.showTests )
		let pageInfo = this.buildSearchPageInfo( page, this.pageSize )

		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, activityName, filter, sortValue, pageInfo ) => {
						return Api.searchActivity( currentUser, activityName, filter, sortValue, pageInfo )
					} )( this.props.authorizationInfo.user, 'AddMediaToSystem', filter, this.state.query.sort, pageInfo )
				},
				( err, activity ) => {
					if ( helpers.doesExist( err ) ) return this.props.dispatch( reportApiError( err ) )

					this.setState( { activity: activity } )
				}
			)
		)
	}


	handleSort( sortColumn ) {
		Analytics.recordUserActivity()

		let newQuery = _clone( this.state.query )

		newQuery.sort = sortColumn
		newQuery.currentPage = 0

		this.setState( { query: newQuery }, () => { this.setLocationFromState( this.state.query ) } )
	}


	handleRefresh( evt ) {
		Analytics.recordUserActivity()

		this.loadActivity( this.state.query.currentPage )
	}


	handlePageSelected( newPage ) {
		Analytics.recordUserActivity()

		let newQuery = _clone( this.state.query )
		newQuery.currentPage = parseInt( newPage )

		this.setState( { query: newQuery }, () => { this.setLocationFromState( this.state.query ) } )
	}


	handleSwitchTab( event, value ) {
		if ( !_isString( value ) ) return

		Analytics.recordUserActivity()

		let newQuery = _clone( this.state.query )

		newQuery.tab = value
		newQuery.currentPage = 0

		this.setState( { query: newQuery }, () => { this.setLocationFromState( this.state.query ) } )
	}


	openFilter() {
		this.setState( { show_filter_dialog: true } )
	}


	closeFilter() {
		this.setState( { show_filter_dialog: false } )
	}


	render() {
		return (
			<div style={ { marginTop: 10 } }>
				<ActivityFilter
					authorizationInfo={ this.props.authorizationInfo }
					open={ this.state.show_filter_dialog }
					handleQueryChange={ this.handleQueryChange.bind( this ) }
					handleGroupSelect={ this.handleGroupSelect.bind( this ) }
					closeFilter={ this.closeFilter }
					query={ this.state.query }
					groups={ this.state.groups }
				/>

				<div style={ { display: 'flex', flexDirection: 'row', marginLeft: 10 } }>
					<div style={ { flex: 2 } }>
						<div style={ { backgroundColor: '#e8e8e8' } }>
							<Tabs indicatorColor="primary" value={ this.state.query.tab } onChange={ this.handleSwitchTab.bind( this ) }>
								<Tab value={ TAB_ALL_ACTIVITY } title="activity" label="All" />
								<Tab value={ TAB_PENDING_REVIEW } title="review" label="Pending Review" />
								<Tab value={ TAB_NOT_PUBLISHED } title="ignored" label="Not Published" />
								<Tab value={ TAB_ERRORS_AND_WARNINGS } title="warnings" label="Errors&nbsp;&amp;&nbsp;Warnings" />
							</Tabs>
						</div>

						<ActivityGrid
							activity={ this.state.activity }
							onActivitySelected={ this.activitySelectedFromList.bind( this ) }
							filterSettings={ this.state.query }
							currentSort={ this.state.query.sort }
							currentPage={ this.state.query.currentPage }
							onSortChanged={ this.handleSort.bind( this ) }
							onPageChanged={ this.handlePageSelected.bind( this ) }
							onShowFilter={ this.openFilter }
							onRefreshList={ this.handleRefresh.bind( this ) }
							filteredGroup={ this.state.filtered_group }
						/>
					</div>

					<div style={ { flex: 1, marginLeft: 15, marginRight: 10 } }>
						<ActivityDetail
							authorizationInfo={ this.props.authorizationInfo }
							selectableMetadata={ this.props.selectableMetadata }
							ignoreRequest={ this.ignoreRequest.bind( this ) }
							publishRequest={ this.publishRequest.bind( this ) }
							dispatch={ this.props.dispatch }
							activity={ this.state.selectedActivity }
							workItem={ this.state.selectedWorkItem }
						/>
					</div>
				</div>
			</div>
		)
	}
}


ActivityPage.propTypes = {
	authorizationInfo	: PropTypes.object.isRequired,
	selectableMetadata	: PropTypes.array.isRequired,

	// injected automatically by react-router
	history				: PropTypes.object,
	location			: PropTypes.object
}


function mapStateToProps( state, ownProps ) {
	return {
		authorizationInfo	: state.authorizationInfo,
		selectableMetadata	: state.selectableMetadata
	}
}


export default withRouter( connect( mapStateToProps )( ActivityPage ) )
