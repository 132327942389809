import _get from 'lodash/get'

import React from 'react'
import PropTypes from 'prop-types'

import Avatar from '@material-ui/core/Avatar'

import API from '../../dataSource/api'

import grey from '@material-ui/core/colors/grey'

const AVATAR_LOADING = 'loading'
const AVATAR_LOADED = 'loaded'
const AVATAR_UNAVAILABLE = 'unavailable'


class ShortstopAvatar extends React.Component {
	constructor( props ) {
		super( props )

		let getScribeColumnist = API.getScribeColumnist
		if ( props.getScribeColumnist ) getScribeColumnist = props.getScribeColumnist

		this.state = { open: false, avatar: AVATAR_LOADING, get_scribe_columnist: getScribeColumnist, current_columnist_id: null }

		this.avatarImage = new window.Image()

		this.avatarImage.onload = () => { if ( this.isMounted ) this.setState( { avatar: AVATAR_LOADED } ) }
		this.avatarImage.onerror = ( err ) => { if ( this.isMounted ) this.setState( { avatar: AVATAR_UNAVAILABLE, current_columnist_id: null } ) }
	}

	get isMounted() { return this._isMounted }
	set isMounted( mounted ) { this._isMounted = mounted }


	componentDidMount() {
		this.isMounted = true

		let thisColumnistId = _get( this.props.currentUser, 'shortstop.columnist_id', null )
		if ( thisColumnistId === null ) thisColumnistId = _get( this.props.activity, 'sort_fields.columnist_id', null )

		this.loadAvatarImage( thisColumnistId )
	}


	componentWillUnmount() {
		this.isMounted = false
	}


	shouldComponentUpdate( nextProps, nextState, nextContext ) {
		let thisColumnistId = _get( this.props.currentUser, 'shortstop.columnist_id', null )
		if ( thisColumnistId === null ) thisColumnistId = _get( this.props.activity, 'sort_fields.columnist_id', null )

		let nextColumnistId = _get( nextProps.currentUser, 'shortstop.columnist_id', null )
		if ( nextColumnistId === null ) nextColumnistId = _get( nextProps.activity, 'sort_fields.columnist_id', null )

		if ( thisColumnistId !== nextColumnistId ) {
			this.loadAvatarImage( nextColumnistId )
		}

		return true
	}


	loadAvatarImage( columnistId ) {
		if ( columnistId === null && this.state.current_columnist_id !== null ) return this.setState( { avatar: AVATAR_UNAVAILABLE, current_columnist_id: null } )

		if ( columnistId === this.state.current_columnist_id ) {
			if ( this.state.avatar === AVATAR_LOADED ) return
			if ( this.state.avatar === AVATAR_UNAVAILABLE ) return
		}

		this.setState( { avatar: AVATAR_LOADING, current_columnist_id: columnistId }, async () => {
			try {
				try {
					let scribeColumnistInfo = await this.state.get_scribe_columnist( this.props.authorizationInfo.user, columnistId )

					let imageUrl = scribeColumnistInfo.image_url

					imageUrl = imageUrl.replace( 'http://a.espncdn.com', 'https://secure.espncdn.com' )
					imageUrl = imageUrl.replace( 'http://www.espn.com', 'https://www.espn.com' )

					this.avatarImage.src = imageUrl
				}
				catch ( e ) {
					// DO NOTHING
				}
			}
			catch ( e ) {
				this.setState( { avatar: AVATAR_UNAVAILABLE, current_columnist_id: null } )
			}
		} )
	}


	render() {
		let avatarStyle = {
			border			: '1px solid #BDBDBD',
			objectFit		: 'cover',
			width			: 40,
			height			: 40,
			backgroundColor	: grey[ 50 ]
		}

		if ( this.props.cardAvatar ) {
			avatarStyle.marginRight = '16px'
		}

		if ( this.state.avatar === AVATAR_LOADED ) {
			return ( <Avatar style={ avatarStyle } src={ this.avatarImage.src } /> )
		}

		return ( <Avatar style={ avatarStyle } /> )
	}
}


ShortstopAvatar.propTypes = {
	authorizationInfo	: PropTypes.object.isRequired,
	activity			: PropTypes.object,
	cardAvatar			: PropTypes.bool,
	currentUser			: PropTypes.object,

	// test hooks
	getScribeColumnist	: PropTypes.func,
}


export default ShortstopAvatar
