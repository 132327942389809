import React from 'react'
import PropTypes from 'prop-types'

import _get from 'lodash/get'

import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'

import grey from '@material-ui/core/colors/grey'

import Category from '../../models/category'


class SelectedCategories extends React.Component {
	constructor( props ) {
		super( props )

		this.removeCategory = this.removeCategory.bind( this )
	}


	removeCategory( category ) {
		this.props.categoryRemoved( category )
	}


	render() {
		return (
			<div style={ { paddingTop: '5px' } }>
				{
					this.props.selectedCategories.map( ( c ) => {
						let imageUrl = _get( c, 'context.image', null )

						let avatarStyle = {}
						if ( imageUrl === null ) {
							avatarStyle = { backgroundColor: grey[ 400 ] }
						}
						else if ( c.type === 'player' ) {
							avatarStyle = { border: `1px solid ${ grey[ 400 ] }` }
						}

						let avatar = ( <Avatar alt={ c.name } src={ imageUrl } style={ avatarStyle } /> )

						return (
							<Chip
								id="selected-category"
								key={ c.now_id }
								style={ { marginRight: '5px', marginBottom: '5px', padding: '1px' } }
								label={
									<div>
										<Typography variant="caption" color="textPrimary" style={ { marginBottom: '-2px' } }>{ c.name }</Typography>
										<Typography variant="body2" color="textSecondary" style={ { marginBottom: '2px' } }>{ Category.getCategoryContextText( c ) }</Typography>
									</div>
								}
								avatar={ avatar }
								onDelete={ () => { this.removeCategory( c ) } }
							/>
						)
					} )
				}
			</div>
		)
	}
}


SelectedCategories.propTypes = {
	// component specific
	selectedCategories	: PropTypes.array.isRequired,
	categoryRemoved		: PropTypes.func.isRequired,
}


export default SelectedCategories
