import React from 'react'								// eslint-disable-line no-unused-vars
import { render } from 'react-dom'
import configureStore from './store/configureStore'
import { Provider } from 'react-redux'					// eslint-disable-line no-unused-vars
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import '../node_modules/video-react/dist/video-react.css'

import './styles/styles.css'

import App from './components/app'

import ActivityPage from './components/activity/activity_page'
import ShortstopActivityPage from './components/shortstop/shortstop_page'
import IngestActivityPage from './components/ingest/ingest_activity_page'
import UploadPage from './components/upload/upload_page'
import GroupsPage from './components/groups/groups_page'
import UsersPage from './components/users/users_page'
import GroupConfiguration from './components/configuration/group_config_page'
import International from './components/international/ProgramPage'
import PCCProgramMetadata from './components/international/ProgramMetadataPage'
import ShortstopPostEdit from './components/shortstop/edit_shortstop_post_page'
import AnalyticsHome from './components/analytics/AnalyticsHome'


function renderPartnerActivity( routeProps ) {
	return ( <App><ActivityPage /></App> )
}


function renderShortstopActivity( routeProps ) {
	return ( <App><ShortstopActivityPage /></App> )
}


function renderIngestActivity( routeProps ) {
	return ( <App><IngestActivityPage /></App> )
}


function renderUpload( routeProps ) {
	return ( <App><UploadPage /></App> )
}


function renderGroups( routeProps ) {
	return ( <App><GroupsPage /></App> )
}


function renderGroupUsers( routeProps ) {
	return ( <App><UsersPage /></App> )
}


function renderGroupConfiguration( routeProps ) {
	return ( <App><GroupConfiguration /></App> )
}


function renderInternational( routeProps ) {
	return ( <App><International /></App> )
}


function renderPCCProgramMetadata( routeProps ) {
	return ( <App><PCCProgramMetadata /></App> )
}


function renderShortstopPostEdit( routeProps ) {
	return ( <App><ShortstopPostEdit /></App> )
}


function renderAnalyticsHome( routeProps ) {
	return ( <App><AnalyticsHome /></App> )
}

const theme = createMuiTheme( {
	typography: {
		fontFamily: '\'Lato\', sans-serif'
	},
} )

render (
	<Provider store={ configureStore() }>
		<MuiThemeProvider theme={ theme }>
			<MuiPickersUtilsProvider utils={ MomentUtils }>
				<BrowserRouter>
					<Switch>
						<Route path="/analytics" render={ renderAnalyticsHome } />

						<Route path="/activity" render={ renderPartnerActivity } />
						<Route key="footage" path="/footage" render={ renderIngestActivity } />

						<Route path="/shortstop-activity/:id" render={ renderShortstopPostEdit } />
						<Route path="/shortstop-activity" render={ renderShortstopActivity } />

						<Route path="/clip-transfer" render={ renderIngestActivity } />
						<Route path="/upload" render={ renderUpload } />

						<Route path="/groups/:group_id/users" render={ renderGroupUsers } />
						<Route path="/groups/:group_id/configuration" render={ renderGroupConfiguration } />
						<Route path="/groups" render={ renderGroups } />

						<Route path="/international" render={ renderInternational } />
						<Route path="/programs/:id/metadata" render={ renderPCCProgramMetadata } />

						<Route path="/" render={ renderPartnerActivity } />
					</Switch>
				</BrowserRouter>
			</MuiPickersUtilsProvider>
		</MuiThemeProvider>
	</Provider>,
	document.getElementById( 'app' )
)
