import _cloneDeep from 'lodash/cloneDeep'
import _defaultTo from 'lodash/defaultTo'

import React from 'react'
import PropTypes from 'prop-types'

import helpers from '../../lib/helpers'
import TextField from '@material-ui/core/TextField'

import ShortstopAvatar from '../common/shortstop_avatar'


class ShortstopDetail extends React.Component {
	handleUpdate( propertyName, event ) {
		let newUser = _cloneDeep( this.props.user )

		let newValue = event.target.value
		if ( propertyName === 'shortstop_live_access' ) {
			newValue = event.target.checked
		}
		else if ( propertyName === 'columnist_id' ) {
			newValue = newValue.trim()
		}

		if ( helpers.doesNotExist( newValue ) || newValue === '' ) {
			delete newUser.shortstop[ propertyName ]
		}
		else {
			newUser.shortstop[ propertyName ] = newValue
		}

		this.props.updateUser( newUser )
	}


	render() {
		if ( helpers.doesNotExist( this.props.user.shortstop ) ) return ( <div /> )

		return (
			<div style={ { marginTop: 20, display: 'flex', flexDirection: 'column' } }>
				<div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center' } }>
					<TextField
						value={ _defaultTo( this.props.user.shortstop.columnist_id, '' ) }
						margin="dense"
						onChange={ ( e ) => { this.handleUpdate( 'columnist_id', e ) } }
						label="Columnist ID"
					/>

					<ShortstopAvatar
						authorizationInfo={ this.props.authorizationInfo }
						currentUser={ this.props.user }
						cardAvatar={ false }
						getScribeColumnist={ this.props.getScribeColumnist }
					/>
				</div>
			</div>
		)
	}
}


ShortstopDetail.propTypes = {
	authorizationInfo		: PropTypes.object.isRequired,
	user					: PropTypes.object.isRequired,
	updateUser				: PropTypes.func.isRequired,

	// test hook
	getScribeColumnist	: PropTypes.func,
}


export default ShortstopDetail
