import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import React from 'react'
import PropTypes from 'prop-types'

import { Player } from 'video-react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CardActions from '@material-ui/core/CardActions'
import Divider from '@material-ui/core/Divider'
import grey from '@material-ui/core/colors/grey'

import IconButton from '@material-ui/core/IconButton'
import IgnoreIcon from '@material-ui/icons/Archive'
import PublishIcon from '@material-ui/icons/Send'

import Tooltip from '@material-ui/core/Tooltip'
import ReceiptIcon from '@material-ui/icons/Receipt'

import ShortstopAvatar from '../common/shortstop_avatar'
import JsonDetailDialog from '../common/json_detail_dialog'

import { showNotification } from '../../actions/appNotificationActions'

import '../../styles/one_line_definition_list.css'

import Analytics from '../../lib/analytics'
import helpers from '../../lib/helpers'

import SelectableMetadata from '../../models/selectable_metadata'

import Api from '../../dataSource/api'
import { callApi, reportApiError } from '../../actions/apiCallActions'

class ActivityDetail extends React.Component {
	constructor( props ) {
		super( props )

		this.state = {
			partnerRequest: null,
			partnerRestrictions: null,
			showDetails: false
		}

		this.handleOpenDetails = this.handleOpenDetails.bind( this )
		this.handleCloseDetails = this.handleCloseDetails.bind( this )
	}


	getRequestDetails() {
		return new Promise( ( resolve, reject ) => {
			if ( helpers.doesExist( this.state.partnerRequest ) ) {
				return resolve()
			}

			if ( helpers.doesExist( this.props.workItem.partner_request ) ) {
				// the work item is a media document that was created from a request; load the request

				this.props.dispatch(
					callApi(
						() => {
							return ( ( currentUser, mediaId ) => {
								return Api.getPartnerRequest( currentUser, mediaId )
							} )( this.props.authorizationInfo.user, this.props.workItem.partner_request.partner_request_id )
						},
						( err, partnerRequest ) => {
							if ( helpers.doesExist( err ) ) {
								this.props.dispatch( reportApiError( err ) )
								return reject()
							}

							this.setState( { partnerRequest: partnerRequest }, () => resolve() )
						}
					)
				)
			}
			else {
				// the work item is a partner_request that was not delivered
				this.setState( { partnerRequest: this.props.workItem }, () => resolve() )
			}
		} )

	}


	getGroupConfig() {
		return new Promise( ( resolve, reject ) => {
			if ( helpers.doesExist( this.state.partnerRestrictions ) ) {
				return resolve()
			}

			if ( helpers.doesExist( this.props.workItem ) ) {

				this.props.dispatch(
					callApi(
						() => {
							return ( ( currentUser, groupId ) => {
								return Api.getGroupConfig( currentUser, groupId )
							} )( this.props.authorizationInfo.user, this.props.workItem.group_organization[0].group_id )
						},
						( err, groupConfig ) => {
							if ( helpers.doesExist( err ) ) {
								this.props.dispatch( reportApiError( err ) )
								return reject()
							}

							this.setState( { partnerRestrictions: groupConfig.workflow.publish_to_cerebro.editions }, () => resolve() )
						}
					)
				)
			}
			else {
				this.setState( { partnerRestrictions: this.props.workItem.group_organization[0] }, () => resolve() )
			}
		} )


	}


	ignoreRequest() {
		this.props.ignoreRequest( this.props.workItem )
	}


	publishRequest() {
		this.props.publishRequest( this.props.workItem )
	}


	async handleOpenDetails() {
		Analytics.recordUserActivity()

		try {
			await this.getRequestDetails()
			await this.getGroupConfig()

			this.setState( { showDetails: true } )
		}
		catch ( e ) {
			this.props.dispatch( showNotification( 'Failure', 'Data Failed to Load', 'error' ) )
		}
	}


	handleCloseDetails() {
		Analytics.recordUserActivity()

		this.setState( {
			partnerRequest: null,
			partnerRestrictions: null,
			showDetails: false,
		} )
	}


	get cmsNamesToMappings() {
		return {
			domestic	: 'English CMS',
			deportes	: 'Spanish CMS'
		}
	}


	getVideoSource() {
		if ( this.props.workItem ) {
			if ( this.props.activity.sort_fields.local_video_file ) {
				if ( this.props.workItem.attachments && this.props.workItem.attachments.length > 0 && this.props.workItem.attachments[0].formats.length > 0 && this.props.workItem.attachments[0].formats[0].url.includes( 'https://' ) ) {
					return ( <a href={ this.props.workItem.attachments[0].formats[0].url } target="_blank" rel="noopener noreferrer">Video</a> )
				}
				else {
					return ( <span>{ this.props.activity.sort_fields.local_video_file }</span> )
				}
			}
			else {
				return ( <a href={ this.props.workItem.getVideoUrl() } target="_blank" rel="noopener noreferrer">Video</a> )
			}
		}
		else {
			return
		}
	}


	renderEmptyDetail() {
		return (
			<Card className="video-preview-panel">
				<CardHeader title="Select a video to preview" titleTypographyProps={ { variant: 'subtitle1' } }></CardHeader>
			</Card>
		)
	}


	renderVideoLink( cmsName, transcodeId, cmsId ) {
		if ( helpers.doesNotExist( transcodeId ) ) return null
		if ( helpers.doesNotExist( cmsId ) ) return <font color="#FF0000">Transcode not completed</font>

		let link = `http://espn.com/video/clip?id=${ cmsId }`
		if ( cmsName.indexOf( 'deportes' ) > -1 ) {
			link = `http://espndeportes.espn.com/video/clip/_/id/${ cmsId }`
		}

		return <a href={ link } target="_blank" rel="noopener noreferrer">{ cmsId }</a>
	}


	renderKeyValueLineItem( key, value, first=false ) {
		let style = { display: 'flex', flexDirection: 'row', marginTop: 2 }

		if ( first === true ) {
			style = { display: 'flex', flexDirection: 'row' }
		}

		return (
			<div style={ style }>
				<div style={ { flex: 1 } }><Typography variant="subtitle2">{ key }</Typography></div>
				<div style={ { flex: 2 } }><Typography variant="caption">{ value }</Typography></div>
			</div>
		)
	}


	renderEditionCMSDestinationInfo( cmsName, destination ) {
		if ( helpers.doesNotExist( destination ) ) return ( <div /> )

		let transcodeIdDisplay = destination.transcode_id || 'Transcode request not sent'

		let transcodeIdcolor = '#000000'
		if ( !destination.transcode_id ) transcodeIdcolor = '#FF0000'

		let errorMessage = ( <div /> )
		if ( helpers.doesExist( destination.error_msg ) ) {
			errorMessage = (
				<div>
					{ this.renderKeyValueLineItem( 'Error', destination.error_msg, true ) }
				</div>
			)
		}

		return (
			<div key={ `cms-destination-${ cmsName }` }>
				<Typography variant="h6" style={ { marginTop: 10 } }>
					{ this.cmsNamesToMappings[ cmsName ] }
				</Typography>
				{ this.renderKeyValueLineItem( 'Transcoding ID', <font color={ transcodeIdcolor }>{ transcodeIdDisplay }</font> ) }
				{ this.renderKeyValueLineItem( 'CMS ID', this.renderVideoLink( cmsName, destination.transcode_id, destination.cms_id ) ) }
				{ this.renderKeyValueLineItem( 'Ingested', helpers.formatBoolean( destination.ingest_for_edit ) ) }
				{ errorMessage }
			</div>
		)
	}


	renderEditionDestinationInfo( workItem ) {
		let video = workItem.getAttachment( 'video' )
		if ( helpers.doesNotExist( video ) || helpers.doesNotExist( video.publishing ) ) return ( <div /> )

		let sport = '<ANY>'
		let league = '<ANY>'

		if ( helpers.doesExist( video.publishing.edition_sport ) ) {
			let sportMetadata = SelectableMetadata.getParentByValue( this.props.selectableMetadata, 'sport', video.publishing.edition_sport )
			sport = sportMetadata.option_name

			if ( helpers.doesNotExist( video.publishing.edition_league ) ) {
				let leagueMetadata = SelectableMetadata.getChildByValue( sportMetadata, 'league', video.publishing.edition_league )
				league = leagueMetadata.option_name
			}
		}

		return (
			<div style={ { marginTop: 10 } }>
				<Typography variant="h6" gutterBottom>
					Destination ESPN CMSes
				</Typography>
				<div>
					<Typography variant="h6">
						Matched Configuration
					</Typography>

					{ this.renderKeyValueLineItem( 'Language', video.publishing.edition_language, true ) }
					{ this.renderKeyValueLineItem( 'Sport', sport ) }
					{ this.renderKeyValueLineItem( 'League', league ) }
					{ this.renderKeyValueLineItem( 'Encrypted', helpers.formatBoolean( video.publishing.encrypt ) ) }

					{ this.renderEditionCMSDestinationInfo( 'domestic', video.publishing.domestic ) }
					{ this.renderEditionCMSDestinationInfo( 'deportes', video.publishing.deportes ) }
				</div>
			</div>
		)
	}


	renderThumbnail( activity, workItem ) {
		if ( !activity || !workItem ) return ( <div /> )

		return (
			<Player
				playsInline
				poster={ workItem.getThumbnailUrl() }
				src={ workItem.getVideoUrl() }
			/>
		)
	}


	renderErrorDetail( activity ) {
		let message = activity.error.message

		try {
			// the following will throw an exception if the message is not valid JSON
			let messageJSON = JSON.parse( message )
			message = JSON.stringify( messageJSON, null, 4 )
		}
		catch ( e ) {
			// do nothing there
		}

		return (
			<div style={ { marginTop: 10 } }>
				{ this.renderKeyValueLineItem( 'Activity ID', activity._id ) }
				{ this.renderKeyValueLineItem( 'Error Code', activity.error.code ) }
				{ this.renderKeyValueLineItem( 'Message', message ) }
			</div>
		)
	}


	renderHeaderInformation( workItem ) {
		return (
			<div>
				<Typography style={ { whiteSpace: 'pre-wrap', wordBreak: 'break-all', marginBottom: 5 } } variant="subtitle2">
					{ workItem.getHeadline() }
				</Typography>

				<Typography variant="caption" style={ { whiteSpace: 'pre-wrap', wordBreak: 'break-all' } }>
					{ workItem.getCaption() }
				</Typography>

				<div style={ { marginTop: 10 } }>
					{ this.renderKeyValueLineItem( 'Partner Request ID', workItem._id ) }
				</div>
			</div>
		)
	}


	renderInformation( workItem ) {
		return (
			<div>
				<Typography variant="subtitle1" style={ { marginTop: 10 } }>
					Information
				</Typography>

				<div>
					{ this.renderKeyValueLineItem( 'Partner', workItem.getOrganization().name, true ) }
					{ this.renderKeyValueLineItem( 'User', workItem.getUserName() ) }
					{ this.renderKeyValueLineItem( 'Sources', <span>{ this.getVideoSource() }&nbsp;|&nbsp;<a href={ workItem.getThumbnailUrl() } target="_blank" rel="noopener noreferrer">Image</a></span> ) }
					{ this.renderKeyValueLineItem( 'Language', workItem.getLanguage() ) }
					{ this.renderKeyValueLineItem( 'Duration', workItem.getVideoDuration() ) }
					{ this.renderKeyValueLineItem( 'Aspect Ratio', workItem.getVideoAspectRatio() ) }
					{ this.renderKeyValueLineItem( 'Published', workItem.getPublishDate() ) }
					{ this.renderKeyValueLineItem( 'Expires', workItem.getExpirationDate() ) }
				</div>
			</div>
		)

	}


	renderMetadata( workItem, sportMetadata, leagueMetadata ) {
		return (
			<div>
				<Typography variant="subtitle1" style={ { marginTop: 10 } }>
					Metadata
				</Typography>

				<div>
					{ this.renderKeyValueLineItem( 'Type', workItem.getMediaType(), true ) }
					{ this.renderKeyValueLineItem( 'Sport', sportMetadata.option_name ) }
					{ this.renderKeyValueLineItem( 'League', leagueMetadata.option_name ) }
					{ this.renderKeyValueLineItem( 'Categories', workItem.getCategories().join( ', ' ) ) }
					{ this.renderKeyValueLineItem( 'Teams', workItem.getTeams().join( ', ' ) ) }
					{ this.renderKeyValueLineItem( 'People', workItem.getPeople().join( ', ' ) ) }
					{ this.renderKeyValueLineItem( 'Competition', workItem.getCompetition() ) }
					{ this.renderKeyValueLineItem( 'Game ID', workItem.getGameId() ) }
					{ this.renderKeyValueLineItem( 'Keywords', workItem.getKeywords() ) }
					{ this.renderKeyValueLineItem( 'Partner Video ID', workItem.getPartnerProvidedId() ) }
				</div>
			</div>
		)

	}


	renderContributor( selectedActivity ) {
		let groupName = _get( selectedActivity, 'sort_fields.group_name', null )
		let groupLogo = _get( selectedActivity, 'sort_fields.group_logo', null )

		if ( groupName === null || groupLogo === null ) {
			return (
				<ShortstopAvatar
					authorizationInfo={ this.props.authorizationInfo }
					activity={ selectedActivity }
					cardAvatar={ true }
					getScribeColumnist={ this.props.getScribeColumnist }
				/>
			)
		}

		return (
			<img alt={ groupName } src={ groupLogo } style={ { height: 40, width: 40 } } />
		)
	}


	renderIgnoreButton() {
		if ( this.props.activity.current_state !== 'RequiresReview' ) return ( <div /> )

		return (
			<div>
				<Tooltip title="Ignore Request" enterDelay={ 500 } leaveDelay={ 200 }>
					<IconButton className="ignore-request" onClick={ this.ignoreRequest.bind( this ) }>
						<IgnoreIcon />
					</IconButton>
				</Tooltip>
			</div>
		)
	}


	renderPublishButton() {
		if ( this.props.activity.current_state !== 'RequiresReview' && this.props.activity.current_state !== 'Ignored' && this.props.activity.current_state !== 'Failed' ) return ( <div /> )

		return (
			<div>
				<Tooltip title="Publish Request" enterDelay={ 500 } leaveDelay={ 200 }>
					<IconButton className="publish-request" onClick={ this.publishRequest.bind( this ) }>
						<PublishIcon />
					</IconButton>
				</Tooltip>
			</div>
		)
	}


	render() {
		if ( _isEmpty( this.props.workItem ) ) return this.renderEmptyDetail()

		const workItem = this.props.workItem
		const activity = this.props.activity

		let sportMetadata = SelectableMetadata.getParentByValue( this.props.selectableMetadata, 'sport', workItem.getSport() )
		let leagueMetadata = SelectableMetadata.getChildByValue( sportMetadata, 'league', workItem.getLeague() )

		let contributorName = _get( activity, 'owner.name', '' )

		let jobTitle = _get( activity, 'sort_fields.job_title', null )
		if ( jobTitle === null ) jobTitle = _get( activity, 'sort_fields.group_name', '' )

		let headline = _get( this.props.workItem, 'shortstop_live.headline', null )
		if ( headline === null ) headline = _get( this.props.workItem, 'partner_footage.headline', null )

		let caption = _get( this.props.workItem, 'shortstop_live.caption', null )
		if ( caption === null ) caption = _get( this.props.workItem, 'partner_footage.caption', null )

		let error = ( <div /> )

		let header = ( <div /> )
		let thumbnail = ( <div /> )
		let information = ( <div /> )
		let metadata = ( <div /> )
		let editionDestinationInfo = ( <div /> )

		if ( !this.props.workItem.partner_request && activity.error ) {
			error = this.renderErrorDetail( activity )
		}
		else {
			header = this.renderHeaderInformation( workItem )
			thumbnail = this.renderThumbnail( activity, workItem )
			information = this.renderInformation( workItem )
			metadata = this.renderMetadata( workItem, sportMetadata, leagueMetadata )
			editionDestinationInfo = this.renderEditionDestinationInfo( workItem )
		}

		let dialogData = [
			{ label: 'Workflow', data: activity },
			{ label: 'Request', data: this.state.partnerRequest },
			{ label: 'Rights', data: this.state.partnerRestrictions },
		]

		if ( helpers.doesExist( this.props.workItem.partner_request ) ) {
			dialogData.splice( 2, 0, { label: 'Post', data: this.props.workItem } )
		}
		else {
			dialogData.splice( 2, 0, { label: 'Post', disabled: true } )
		}

		return (
			<div>
				<Card className="video-preview-panel">
					<CardHeader
						avatar={
							this.renderContributor( activity )
						}
						title={ contributorName }
						subheader={ jobTitle }
					/>

					<Divider />

					{ thumbnail }

					<CardContent>
						{ error }

						{ header }
						{ information }
						{ metadata }
						{ editionDestinationInfo }
					</CardContent>

					<Divider />

					<CardActions>
						{ this.renderIgnoreButton() }
						{ this.renderPublishButton() }
						<Tooltip title="Post Details" enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton style={ { marginLeft: 'auto', color: grey[ 700 ] } } onClick={ this.handleOpenDetails }>
								<ReceiptIcon />
							</IconButton>
						</Tooltip>
					</CardActions>

					<JsonDetailDialog
						open={ this.state.showDetails }
						dialogData={ dialogData }
						closeDialog={ this.handleCloseDetails }
					/>

				</Card>
			</div>
		)
	}
}


ActivityDetail.propTypes = {
	authorizationInfo	: PropTypes.object.isRequired,
	selectableMetadata	: PropTypes.array.isRequired,
	dispatch			: PropTypes.func.isRequired,
	ignoreRequest		: PropTypes.func.isRequired,
	publishRequest		: PropTypes.func.isRequired,
	workItem			: PropTypes.object,
	activity			: PropTypes.object,

	// test hook
	getScribeColumnist	: PropTypes.func,
}

export default ActivityDetail
