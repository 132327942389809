import _cloneDeep from 'lodash/cloneDeep'
import _defaultTo from 'lodash/defaultTo'

import Group from './group'
import helpers from '../lib/helpers'

const DEFAULT_GROUP_CONFIG_WORKFLOW = {
	publish_to_cerebro: {
		headline_method		: 'plain',
		caption_method		: 'plain',
		scribe_name_method	: 'headlineOnly',
		alert_settings		: { alert_when_published: false },
		language_whitelist	: [ { espn_value: 'en' } ],
		sport_whitelist		: [],
		editions			: [],
		asset_download		: { use_partner_asset_location: true }
	}
}


export default class GroupConfiguration {
	static get friendlyName() { return 'Group Configuration' }
	static get modelName() { return GroupConfiguration.friendlyName.replace( ' ', '' ) }
	static get workItemType() { return GroupConfiguration.friendlyName.replace( ' ', '_' ).toLowerCase() }

	static emptyGroupConfiguration( group ) {
		return new GroupConfiguration( { group: Group.buildSnapshot( group ) } )
	}


	constructor( json = {} ) {
		// default the workflow object
		this.workflow = _cloneDeep( DEFAULT_GROUP_CONFIG_WORKFLOW )

		if ( helpers.doesExist( json._id ) ) this._id = json._id

		if ( helpers.doesExist( json.group ) ) {
			// default the contributing partner name
			this.workflow.publish_to_cerebro.contributing_partner = json.group.name
		}

		// if a workflow object was passed in, overwrite the defaults
		if ( helpers.doesExist( json.workflow ) ) {
			this.workflow = json.workflow
		}

		this.group = Group.buildSnapshot( json.group )

		if ( helpers.doesExist( json.created ) ) {
			this.created = json.created
		}
	}


	get modelName() { return GroupConfiguration.modelName }
	get friendlyName() { return GroupConfiguration.friendlyName }
	get workItemType() { return GroupConfiguration.workItemType }


	get group_id() {
		if ( helpers.doesExist( this.group ) ) {
			return _defaultTo( this.group.group_id, this.group[ '$id' ] )
		}

		return ''
	}


	lookupEspnValueForSport( partnerSport ) {
		return this.lookupEspnValue( partnerSport, this.workflow.publish_to_cerebro.sport_whitelist, 'sport_partner_value', 'sport_espn_value' )
	}


	lookupEspnValueForLeague( partnerLeague ) {
		return this.lookupEspnValue( partnerLeague, this.workflow.publish_to_cerebro.sport_whitelist, 'league_partner_value', 'league_espn_value' )
	}


	/**
	 * @function lookupEspnValue
	 *
	 * @description Used to lookup partner values that correspond to a given ESPN value.
	 *
	 * @param partnerValue ESPN value (this will be the ESPN value if no partner value is specified in the config)
	 * @param configValues list of allowed values as defined in the user's configuration
	 * @param partnerValuePropName name of the partner value property
	 * @param espnValuePropName name of the ESPN value property
	 *
	 * @returns espnValue the partner value maps to (if found) or <null> (if not found)
	 */
	lookupEspnValue( partnerValue, configValues, partnerValuePropName, espnValuePropName ) {
		let configValueIndex = configValues.findIndex( ( l ) => {
			return ( helpers.doesExist( l[ partnerValuePropName ] ) && l[ partnerValuePropName ] === partnerValue ) ||
				( helpers.doesNotExist( l[ partnerValuePropName ] ) && l[ espnValuePropName ] === partnerValue )
		} )

		let espnValue = null
		if ( configValueIndex > -1 ) {
			espnValue = configValues[ configValueIndex ][ espnValuePropName ]
		}

		return espnValue
	}


	isDocumentValid() {
		// check if there are any duplicate whitelist entries
		let hasInvalidSportWhitelistEntries = false

		for ( let sport of this.workflow.publish_to_cerebro.sport_whitelist ) {
			// check for multiple entries with the same partner values or no partner values but the same espn values
			let entries = this.workflow.publish_to_cerebro.sport_whitelist.filter( ( s ) => {
				if ( helpers.doesExist( s.sport_partner_value ) &&
					helpers.doesExist( sport.sport_partner_value ) &&
					s.sport_partner_value === sport.sport_partner_value && s.league_partner_value === sport.league_partner_value ) return true

				if ( helpers.doesNotExist( s.sport_partner_value ) &&
					helpers.doesNotExist( sport.sport_partner_value ) &&
					helpers.doesExist( s.league_partner_value ) &&
					helpers.doesExist( sport.league_partner_value ) &&
					s.league_partner_value === sport.league_partner_value ) return true

				if ( helpers.doesExist( s.sport_partner_value ) &&
					helpers.doesExist( sport.sport_partner_value ) &&
					helpers.doesNotExist( s.league_partner_value ) &&
					helpers.doesNotExist( sport.league_partner_value ) &&
					s.sport_partner_value === sport.sport_partner_value ) return true

				if ( helpers.doesNotExist( s.sport_partner_value ) &&
					helpers.doesNotExist( sport.sport_partner_value ) &&
					helpers.doesNotExist( s.league_partner_value ) &&
					helpers.doesNotExist( sport.league_partner_value ) &&
					s.sport_espn_value === sport.sport_espn_value && s.league_espn_value === sport.league_espn_value ) return true

				return false
			} )

			if ( entries.length > 1 ) {
				hasInvalidSportWhitelistEntries = true
			}
		}

		// check if there are any duplicate language entries
		let hasDuplicateLanguageEntries = false

		for ( let language of this.workflow.publish_to_cerebro.language_whitelist ) {
			let entries = this.workflow.publish_to_cerebro.language_whitelist.filter( ( l ) => { return l.espn_value === language.espn_value } )

			if ( entries.length > 1 ) {
				hasDuplicateLanguageEntries = true
				break
			}
		}

		// check if there are any duplicate editions
		let hasDuplicateEditions = false

		for ( let edition of this.workflow.publish_to_cerebro.editions ) {
			// initialize the validation state here while checking
			edition.validationState = { state: null, helpText: null }

			let entries = this.workflow.publish_to_cerebro.editions.filter( ( e ) => {
				if ( helpers.doesExist( e.sport ) ) {
					if ( helpers.doesExist( e.league ) ) {
						return e.language === edition.language && e.sport === edition.sport && e.league === edition.league
					}

					return e.language === edition.language && e.sport === edition.sport && ( helpers.doesNotExist( e.league ) && helpers.doesNotExist( edition.league ) )
				}
				else {
					return e.language === edition.language && ( helpers.doesNotExist( e.sport ) && helpers.doesNotExist( edition.sport ) ) && ( helpers.doesNotExist( e.league ) && helpers.doesNotExist( edition.league ) )
				}
			} )

			if ( entries.length > 1 ) {
				hasDuplicateEditions = true
				break
			}
		}

		// check if the expiration date value is empty
		let hasNoExpirationDateValue = false
		for ( let edition of this.workflow.publish_to_cerebro.editions ) {
			if ( helpers.doesExist( edition.cms_targets ) ) {
				for ( let target of edition.cms_targets ) {
					if ( helpers.doesNotExist( target.default_exp_date_value ) ) {
						hasNoExpirationDateValue = true
						break
					}
				}
			}
		}

		// if the alert settings are set to send an alert on publish, ensure that the slack channel and emoji properties are not null
		let hasRequiredAlertSettings = false

		if ( !this.workflow.publish_to_cerebro.alert_settings.alert_when_published ) {
			hasRequiredAlertSettings = true
		}
		else if ( helpers.doesExist( this.workflow.publish_to_cerebro.alert_settings.slack_channel ) && helpers.doesExist( this.workflow.publish_to_cerebro.alert_settings.emoji ) ) {
			hasRequiredAlertSettings = true
		}

		return !( hasInvalidSportWhitelistEntries || hasDuplicateLanguageEntries || hasDuplicateEditions || hasNoExpirationDateValue ) && hasRequiredAlertSettings
	}


	lookupEdition( language, sport, league ) {
		let definedEditions = this.workflow.publish_to_cerebro.editions

		// look for any editions that specifically match the parameters (edition matching), or that at least match both the sport & league (whitelist entry matching)
		let matches = definedEditions.filter( ( e ) => {
			if ( helpers.doesExist( language ) ) {
				return e.language === language && e.sport === sport && e.league === league
			}
			else {
				// this may match more than one
				return e.sport === sport && e.league === league
			}
		} )

		if ( matches.length === 0 ) {
			matches = definedEditions.filter( ( e ) => {
				// look for any matches that apply to the specific language and sport, but any league (edition matching),
				// or that match the specific sport & any league regardless of language (whitelist entry matching)

				if ( helpers.doesExist( language ) ) {
					return e.language === language && e.sport === sport && e.league === 'any'
				}
				else {
					// this may match more than one
					return e.sport === sport && e.league === 'any'
				}
			} )

			if ( matches.length === 0 ) {
				// look for any matches that apply to the specific language and any sport / league (edition matching),
				// or that match any sport & league regardless of language (whitelist entry matching)

				matches = definedEditions.filter( ( e ) => {
					if ( helpers.doesExist( language ) ) {
						return e.language === language && e.sport === 'any' && e.league === 'any'
					}
					else {
						// this may match more than one
						return e.sport === 'any' && e.league === 'any'
					}
				} )
			}
		}

		if ( matches.length === 0 || matches.length > 1 ) {
			return null
		}

		return matches[ 0 ]
	}


	isWhitelistSafeToDelete( espnSport, espnLeague ) {
		let editions = this.workflow.publish_to_cerebro.editions
		// check to see if any editions exists at all
		if ( editions.length === 0 ) {
			return true
		}

		let canDeleteWhitelist = false

		let assoicatedEdition = this.lookupEdition( null, espnSport, espnLeague )
		if ( helpers.doesExist( assoicatedEdition ) ) {
			// check if there are any other whitelist entries that map to this same edition
			for ( let sport of this.workflow.publish_to_cerebro.sport_whitelist ) {
				if ( sport.sport_espn_value === espnSport && sport.league_espn_value === espnLeague ) continue // skip the one we passed in

				let edition = this.lookupEdition( assoicatedEdition.language, sport.sport_espn_value, sport.league_espn_value )
				if ( helpers.doesExist( edition ) ) {
					if ( helpers.doesExist( assoicatedEdition.sport ) ) {
						if ( edition.sport === assoicatedEdition.sport ) {
							if ( helpers.doesExist( assoicatedEdition.league ) ) {
								if ( edition.league === assoicatedEdition.league ) {
									canDeleteWhitelist = true
								}
							}
							else {
								canDeleteWhitelist = true
							}
						}
					}
					else {
						if ( helpers.doesNotExist( edition.sport ) ) {
							canDeleteWhitelist = true
						}
					}
				}
			}
		}
		else {
			canDeleteWhitelist = true
		}

		return canDeleteWhitelist
	}


	isLanguageSafeToDelete( editionLanguage ) {
		let editions = this.workflow.publish_to_cerebro.editions
		let languageHasEdition = false

		let editionsMatched = editions.filter( ( searchParams ) => {
			if ( helpers.doesExist( searchParams.language ) ) {
				//language is passed
				return searchParams.language === editionLanguage
			}
			else {
				// language is not passed
				return helpers.doesNotExist( searchParams.language )
			}
		} )

		if ( editionsMatched.length > 0 ) {
			languageHasEdition = true
		}

		return !languageHasEdition
	}


	getListOfAvailableSports() {
		// the list of sports available in the drop down selection should contain:

		//	* each whitelisted sport
		//	* each sport not on the whitelist but currently configured as an edition (orphan)
		//	* "- ANY - " needs to be an option in any case; it's used in edition setup as a wildcard that matches anything
		//		whereas it's used on the whitelist to allow anything to come through
		//		it's possible to have an edition that matches anything on the whitelist without whitelisting any content

		let sports = []

		for ( let whitelistedSport of this.workflow.publish_to_cerebro.sport_whitelist ) {
			if ( whitelistedSport.sport_espn_value === 'any' ) continue	// we'll add this to the top of the list at the end
			if ( sports.findIndex( ( s ) => { return s.espn_value === whitelistedSport.sport_espn_value } ) > -1 ) continue

			sports.push( { espn_value: whitelistedSport.sport_espn_value } )
		}

		for ( let edition of this.workflow.publish_to_cerebro.editions ) {
			if ( edition.sport === 'any' ) continue	// we'll add this to the top of the list at the end
			if ( sports.findIndex( ( s ) => { return s.espn_value === edition.sport } ) > -1 ) continue

			sports.push( { espn_value: edition.sport } )
		}

		if ( sports.findIndex( ( s ) => { return s.espn_value === 'any' } ) === -1 ) {
			sports.unshift( { espn_value: 'any' } )	// add this to the top of the list
		}

		return sports
	}


	getListOfAvailableLeagues( sport ) {
		// the list of leagues available in the drop down selection should contain:

		//	* each whitelisted league for the given sport
		//	* each league not on the whitelist but currently configured as an edition (orphan)
		//	* "- ANY - " needs to be an option in any case; it's used in edition setup as a wildcard that matches anything
		//		whereas it's used on the whitelist to allow anything to come through
		//		it's possible to have an edition that matches anything on the whitelist without whitelisting any content

		let leagues = []

		for ( let whitelistedSport of this.workflow.publish_to_cerebro.sport_whitelist ) {
			if ( whitelistedSport.sport_espn_value !== sport ) continue
			if ( whitelistedSport.league_espn_value === 'any' ) continue	// we'll add this to the top of the list at the end
			if ( leagues.findIndex( ( l ) => { return l.espn_value === whitelistedSport.league_espn_value } ) > -1 ) continue

			leagues.push( { espn_value: whitelistedSport.league_espn_value } )
		}

		for ( let edition of this.workflow.publish_to_cerebro.editions ) {
			if ( edition.sport !== sport ) continue
			if ( edition.league === 'any' ) continue	// we'll add this to the top of the list at the end
			if ( leagues.findIndex( ( l ) => { return l.espn_value === edition.league } ) > -1 ) continue

			leagues.push( { espn_value: edition.league } )
		}

		if ( leagues.findIndex( ( l ) => { return l.espn_value === 'any' } ) === -1 ) {
			leagues.unshift( { espn_value: 'any' } )	// add this to the top of the list
		}

		return leagues
	}
}
