import Group from './group'
import helpers from '../lib/helpers'


export default class PartnerLookup {
	static get friendlyName() { return 'Partner Lookup' }
	static get modelName() { return PartnerLookup.friendlyName.replace( ' ', '' ) }
	static get workItemType() { return PartnerLookup.friendlyName.replace( ' ', '_' ).toLowerCase() }


	constructor( json = {} ) {
		this._id = json._id
		this.cms_name = json.cms_name
		this.entry_groups = json.entry_groups
		this.group = Group.buildSnapshot( json.group )
		this.created = json.created
	}


	get modelName() { return PartnerLookup.modelName }
	get friendlyName() { return PartnerLookup.friendlyName }
	get workItemType() { return PartnerLookup.workItemType }


	getEntryGroupForEdition( sport, league ) {
		// find the entry group that corresponds to the edition sport & league

		// this will match on:
		//	1) entry group with that specific sport / league
		//	2) entry group with <ANY> sport and that specific league
		//	4) entry group with <ANY> sport and <ANY> league

		// 1) is the highest priority, followed by 2), 3) only applies if 1), and 2) miss
		let entryGroups = this.entry_groups.filter( ( eg ) => { return eg.sport === sport && eg.league === league } )

		if ( entryGroups.length === 1 ) return entryGroups[ 0 ]
		if ( entryGroups.length > 1 ) return null

		entryGroups = this.entry_groups.filter( ( eg ) => { return eg.sport === sport } )
		if ( entryGroups.length === 1 ) return entryGroups[ 0 ]
		if ( entryGroups.length > 1 ) return null

		entryGroups = []
		for ( let entryGroup of this.entry_groups ) {
			if ( helpers.doesNotExist( entryGroup.sport ) && helpers.doesNotExist( entryGroup.league ) ) {
				entryGroups.push( entryGroup )
			}
		}

		if ( entryGroups.length === 1 ) return entryGroups[ 0 ]

		return null
	}


	isDocumentValid() {
		// check for null keywords and duplicate sport / leagues in the entry groups
		let hasDuplicateEntryGroups = false
		let hasDuplicateKeywordOrCmsId = false
		let hasEmptyKeyword = false

		for ( let entryGroup of this.entry_groups ) {
			let entries = this.entry_groups.filter( ( eg ) => { return eg.sport === entryGroup.sport && eg.league === entryGroup.league } )

			if ( entries.length > 1 ) {
				hasDuplicateEntryGroups = true
				break
			}

			// check for duplicate keywords or CMS IDs within the entry group
			for ( let entry of entryGroup.entries ) {
				if ( helpers.doesNotExist( entry.keyword ) ) {
					hasEmptyKeyword = true
					break
				}

				let keywords = entryGroup.entries.filter( ( e ) => { return e.keyword === entry.keyword } )
				let cmsIDs = entryGroup.entries.filter( ( e ) => { return e.cms_id === entry.cms_id && e.cms_id !== undefined } )

				if ( keywords.length > 1 || cmsIDs.length > 1 ) {
					hasDuplicateEntryGroups = true
					break
				}
			}

			if ( hasDuplicateKeywordOrCmsId ) break
		}

		return !( hasDuplicateEntryGroups || hasEmptyKeyword || hasDuplicateKeywordOrCmsId )
	}


	isWhitelistSafeToDelete( espnSport, espnLeague ) {
		// check to see if there are any entries exists for the requested sport & league
		let entryGroups = this.entry_groups.filter( ( eg ) => { return eg.sport === espnSport && eg.league === espnLeague } )

		if ( entryGroups.length === 0 ) {
			return true
		}

		// check to see if there are any entries matches for the requested sport & league
		for ( let entryGroup of this.entry_groups ) {
			if ( ( entryGroup.sport === espnSport ) && ( entryGroup.league === espnLeague ) ) {
				return false
			}
		}
	}
}
