export default class ScribeColumnist {
	static get friendlyName() { return 'Scribe Columnist' }
	static get modelName() { return ScribeColumnist.friendlyName.replace( ' ', '' ) }
	static get workItemType() { return ScribeColumnist.friendlyName.toString().replace( ' ', '_' ).toLowerCase() }


	constructor( json = {} ) {
		this.id = json.id
		this.name = json.name
		this.columnist_id = json.columnist_id
		this.image_url = json.image_url
	}


	get modelName() { return ScribeColumnist.modelName }
	get friendlyName() { return ScribeColumnist.friendlyName }
	get workItemType() { return ScribeColumnist.workItemType }
}
