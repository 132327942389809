module.exports = {
	ajaxCallsInProgress	: [],
	ajaxErrorInfo		: null,
	authorizationInfo	: { user: null, group: null },
	selectableMetadata	: [],
	pccNetworks			: [],
	pccCategories		: [],
	uploadFileList		: [],
	appNotification		: { message: '', title: '', variant: 'success', open: false, additionalActionCallback: null }
}
