import _map from 'lodash/map'
import _isNil from 'lodash/isNil'
import _omitBy from 'lodash/omitBy'
import _isEmpty from 'lodash/isEmpty'
import _mapValues from 'lodash/mapValues'

import React from 'react'
import PropTypes from 'prop-types'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'

import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import EditIcon from '@material-ui/icons/Edit'

import Typography from '@material-ui/core/Typography'

import helpers from '../../lib/helpers'

import '../../styles/one_line_definition_list.css'


class ProgramDetail extends React.Component {
	constructor( props ) {
		super( props )
	}


	handleLink( path ) {
		this.props.history.push( path )
	}


	renderEmptyDetail() {
		return (
			<Card className="program-preview-panel">
				<CardHeader title="Select a program to preview" titleTypographyProps={ { variant: 'subtitle1' } }/>
			</Card>
		)
	}


	renderKeyValueLineItem( key, value, first=false ) {
		let style = { display: 'flex', flexDirection: 'row', marginTop: 2 }

		if ( first === true ) {
			style = { display: 'flex', flexDirection: 'row' }
		}

		return (
			<div style={ style }>
				<div style={ { flex: 1 } }><Typography variant="subtitle2">{ key }</Typography></div>
				<div style={ { flex: 2 } }><Typography variant="caption">{ value }</Typography></div>
			</div>
		)
	}


	render() {

		let program = this.props.program
		let mediaNetworks = []

		if ( _isEmpty( program ) ) return this.renderEmptyDetail()

		//we might have empty network info in the media when program asset is created in PCC
		if ( helpers.doesExist( program.media ) ) {
			mediaNetworks = _mapValues( this.props.program.media, 'network.name' )
			let networkNames = _omitBy( mediaNetworks, _isNil )
			mediaNetworks = networkNames
		}

		return (
			<div>
				<Card className="program-preview-panel">
					<CardHeader title={ program.title } titleTypographyProps={ { variant: 'caption' } }/>

					<Divider />

					<CardContent>
						{ this.renderKeyValueLineItem( 'Program ID', program.id ) }
						{ this.renderKeyValueLineItem( 'Description', program.description ) }
						{ this.renderKeyValueLineItem( 'Language', program.language ) }
						{ this.renderKeyValueLineItem( 'Duration', program.getDuration( program ) ) }
						{ this.renderKeyValueLineItem( 'Sport', program.getCategoryValue( 'sport' ) ) }
						{ this.renderKeyValueLineItem( 'League', program.getCategoryValue( 'league' ) ) }
						{ this.renderKeyValueLineItem( 'Series', program.getCategoryValue( 'franchise' ) ) }
						{ this.renderKeyValueLineItem( 'Network', _map( mediaNetworks ).join( '  ,  ' ) ) }
					</CardContent>

					<Divider />

					<CardActions>
						<Tooltip title="Edit Metadata" enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton
								style={ { marginLeft: 'auto' } }
								className="edit-program"
								onClick={ this.handleLink.bind( this, `/programs/${ program.id }/metadata` ) }
							>
								<EditIcon />
							</IconButton>
						</Tooltip>
					</CardActions>
				</Card>
			</div>
		)
	}
}


ProgramDetail.propTypes = {
	program				: PropTypes.object,
	history				: PropTypes.object.isRequired
}


export default ProgramDetail
