import _cloneDeep from 'lodash/cloneDeep'
import React from 'react'
import PropTypes from 'prop-types'
import * as Redux from 'react-redux'
const QS = require( 'query-string' )

import { withRouter } from 'react-router'

import helpers from '../../lib/helpers'
import Analytics from '../../lib/analytics'

import Api from '../../dataSource/api'
import { callApi, reportApiError } from '../../actions/apiCallActions'
import { showNotification } from '../../actions/appNotificationActions'

import Group from '../../models/group'

import DeleteConfirmation from '../common/delete_confirmation'

import GroupsGrid from './groups_grid'
import GroupDetail from './group_detail'
import _clone from 'lodash/clone'


export class GroupsPage extends React.Component {
	constructor( props, context ) {
		super( props, context )

		this.state = {
			groups					: { data: [], total: 0 },
			allGroups				: [],
			parentGroups			: [],
			selectedGroup			: null,
			query					: this.setStateFromLocation( this.props.location.search )
		}

		this.handleSort = this.handleSort.bind( this )
		this.handleNewGroup = this.handleNewGroup.bind( this )
		this.handlePageSelected = this.handlePageSelected.bind( this )
		this.handleParentGroupSelect = this.handleParentGroupSelect.bind( this )
		this.handleEditGroup = this.handleEditGroup.bind( this )
		this.handleDeleteGroup = this.handleDeleteGroup.bind( this )

		this.handleCancelEdit = this.handleCancelEdit.bind( this )
		this.handleSaveGroup = this.handleSaveGroup.bind( this )
		this.handleUpdateSelectedGroup = this.handleUpdateSelectedGroup.bind( this )
		this.updateNameFilter = this.updateNameFilter.bind( this )
	}


	componentDidMount() {
		this.reloadData( this.state.query.currentPage )
	}


	setStateFromLocation( queryString ) {
		let newState = {
			currentPage		: 0,
			sort			: 'name',
			parentGroup		: null,
			nameSearchText	: '',
			pageSize		: 10
		}

		let parsedQuery = QS.parse( queryString )

		if ( helpers.isInteger( parsedQuery.currentPage ) ) {
			newState.currentPage = ( parseInt( parsedQuery.currentPage ) - 1 )
		}

		if ( helpers.doesExist( parsedQuery.sort ) ) {
			newState.sort = parsedQuery.sort
		}

		if ( helpers.doesExist( parsedQuery.parentGroup ) ) {
			newState.parentGroup = parsedQuery.parentGroup
		}

		if ( helpers.isInteger( parsedQuery.pageSize ) ) {
			newState.pageSize = parseInt( parsedQuery.pageSize )
		}

		if ( helpers.doesExist( parsedQuery.nameSearchText ) ) {
			newState.nameSearchText = parsedQuery.nameSearchText
		}

		return newState
	}


	setLocationFromState( queryState, displayWaitSpinner = true ) {
		let newQuery = {}
		let newLocationHasQuery = false

		if ( helpers.isInteger( queryState.currentPage ) && parseInt( queryState.currentPage ) > 0 ) {
			newQuery.currentPage = queryState.currentPage + 1
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( queryState.sort ) && queryState.sort !== 'name' ) {
			newQuery.sort = queryState.sort
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( queryState.parentGroup ) ) {
			newQuery.parentGroup = queryState.parentGroup
			newLocationHasQuery = true
		}

		if ( helpers.isInteger( queryState.pageSize ) && parseInt( queryState.pageSize ) !== 10 ) {
			newQuery.pageSize = queryState.pageSize
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( queryState.nameSearchText ) && queryState.nameSearchText !== '' ) {
			newQuery.nameSearchText = queryState.nameSearchText
			newLocationHasQuery = true
		}

		let newLocation = '/groups'
		if ( newLocationHasQuery ) newLocation = `${ newLocation }?${ QS.stringify( newQuery ) }`

		this.props.history.replace( newLocation )
		this.reloadData( this.state.query.currentPage, displayWaitSpinner )
	}


	reloadData( page, displayWaitSpinner = true ) {
		let params = { limit: this.state.query.pageSize, offset: page * this.state.query.pageSize, sort: this.state.query.sort }

		if ( helpers.doesExist( this.state.query.parentGroup ) ) {
			params.parent = this.state.query.parentGroup
		}

		if ( this.state.query.nameSearchText !== '' ) {
			params.name = this.state.query.nameSearchText
		}

		if ( displayWaitSpinner ) {
			this.props.dispatch(
				callApi(
					() => {
						return ( ( currentUser, params ) => {
							return Api.searchGroups( currentUser, params )
						} )( this.props.authorizationInfo.user, params )
					},

					( err, groups ) => {
						if ( helpers.doesExist( err ) ) return this.props.dispatch( reportApiError( err ) )

						this.setState( { groups: groups } )

						this.loadGroupsList()
					}
				)
			)
		}
		else {
			Api.searchGroups( this.props.authorizationInfo.user, params ).then( ( groups ) => {
				this.setState( { groups: groups } )
			} ).catch( ( err ) => {
				this.props.dispatch( reportApiError( err ) )
			} )
		}
	}


	loadGroupsList() {
		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser ) => {
						return Api.listGroups( currentUser )
					} )( this.props.authorizationInfo.user )
				},
				( err, groups ) => {
					if ( helpers.doesExist( err ) ) return this.props.dispatch( reportApiError( err ) )

					this.setState( { allGroups: groups } )

					this.loadParentGroupsList()
				}
			)
		)
	}


	loadParentGroupsList() {
		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser ) => {
						return Api.listGroups( currentUser, { parentGroups: true } )
					} )( this.props.authorizationInfo.user )
				},
				( err, groups ) => {
					if ( helpers.doesExist( err ) ) return this.props.dispatch( reportApiError( err ) )

					this.setState( { parentGroups: groups } )
				}
			)
		)
	}


	handleEditGroup( group ) {
		Analytics.recordUserActivity()

		this.setState( { selectedGroup: group } )
	}


	handleDeleteGroup( group, groupIndex ) {
		Analytics.recordUserActivity()

		this.handleCancelEdit()
		this.deleteDialog.show( group, groupIndex, group.name, 'users, configuration and lookups, as well as all media and partner requests submitted by that group\'s users.' )
	}


	handleCancelEdit() {
		Analytics.recordUserActivity()
		this.setState( { selectedGroup: null } )
	}


	deleteGroup( group, groupIndex ) {
		Analytics.recordUserActivity()
		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, groupId ) => {
						return Api.deleteGroup( currentUser, groupId )
					} )( this.props.authorizationInfo.user, group._id )
				},
				this.groupActionCallback.bind( this )
			)
		)
	}


	handleUpdateSelectedGroup( group ) {
		let newState = _cloneDeep( this.state )
		newState.selectedGroup = group
		this.setState( newState )
	}


	handleSaveGroup( group ) {
		Analytics.recordUserActivity()
		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, group ) => {
						return Api.saveGroup( currentUser, group )
					} )( this.props.authorizationInfo.user, group )
				},
				this.groupActionCallback.bind( this )
			)
		)
	}


	groupActionCallback( err, res ) {
		if ( helpers.doesExist( err ) ) return this.props.dispatch( reportApiError( err ) )

		this.reloadData( this.state.query.currentPage )
		this.setState( { selectedGroup: null }, () => this.props.dispatch( showNotification( 'Successfully saved', 'Saved', 'success' ) )
		)
	}


	handleNewGroup() {
		this.handleEditGroup( new Group( { type: 'partner', acls: Group.defaultACLsForGroupType( 'partner' ) } ) )
	}


	handleSort( sortColumn ) {
		Analytics.recordUserActivity()

		let newQuery = _cloneDeep( this.state.query )

		newQuery.currentPage = 0
		newQuery.sort = sortColumn

		this.setState( { query: newQuery }, () => { this.setLocationFromState( this.state.query ) } )
	}


	handlePageSelected( newPage ) {
		let newQuery = _cloneDeep( this.state.query )
		newQuery.currentPage = parseInt( newPage )

		this.setState( { query: newQuery }, () => { this.setLocationFromState( this.state.query ) } )
	}


	handleParentGroupSelect( event ) {
		Analytics.recordUserActivity()

		let newParentGroup = event.target.value
		if ( newParentGroup === '-' ) newParentGroup = null

		let newQuery = _cloneDeep( this.state.query )

		newQuery.parentGroup = newParentGroup
		newQuery.currentPage = 0

		this.setState( { query: newQuery }, () => { this.setLocationFromState( this.state.query ) } )
	}


	updateNameFilter( event ) {
		let newQuery = _clone( this.state.query )

		newQuery.currentPage = 0
		newQuery.nameSearchText = event.target.value

		this.setState( { query: newQuery }, () => { this.setLocationFromState( this.state.query, false ) } )
	}


	render() {
		return (
			<div>
				<DeleteConfirmation ref={ ( deleteDialog ) => { this.deleteDialog = deleteDialog } } deleteCallback={ this.deleteGroup.bind( this ) } />

				<div style={ { marginTop: 15 } }>
					<div style={ { display: 'flex', flexDirection: 'row', marginLeft: 10 } }>
						<div style={ { flex: 2 } }>
							<GroupsGrid
								groups={ this.state.groups }
								parentGroups={ this.state.parentGroups }
								currentSort={ this.state.query.sort }
								currentPage={ this.state.query.currentPage }
								currentUserGroup={ this.props.authorizationInfo.user.group }
								parentGroup={ this.state.query.parentGroup }
								onSortChanged={ this.handleSort }
								onPageChanged={ this.handlePageSelected }
								handleEditGroup={ this.handleEditGroup }
								handleDeleteGroup={ this.handleDeleteGroup }
								handleParentGroupSelect={ this.handleParentGroupSelect }
								handleNewGroup={ this.handleNewGroup }
								filterSearchText={ this.state.query.nameSearchText }
								filterNameChanged={ this.updateNameFilter }
								history={ this.props.history }
							/>
						</div>

						<div style={ { flex: 1, marginLeft: 15, marginRight: 10 } }>
							<GroupDetail
								group={ this.state.selectedGroup }
								groups={ this.state.allGroups }
								handleCancelEdit={ this.handleCancelEdit }
								handleSaveGroup={ this.handleSaveGroup }
								handleUpdateSelectedGroup={ this.handleUpdateSelectedGroup }
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}


GroupsPage.propTypes = {
	authorizationInfo	: PropTypes.object.isRequired,

	// injected automatically by react-router
	history				: PropTypes.object.isRequired,
	location			: PropTypes.object.isRequired
}


function mapStateToProps( state, ownProps ) {
	return {
		authorizationInfo: state.authorizationInfo
	}
}


export default withRouter( Redux.connect( mapStateToProps )( GroupsPage ) )
