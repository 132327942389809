import React from 'react'
import PropTypes from 'prop-types'
const moment = require( 'moment' )

import GraphCleaner from './GraphCleaner'
import DashboardHelper from '../../lib/dashboard_helper'


class ShortstopSection1 extends React.Component {
	render() {
		if ( this.props.lineGraphError !== null ) {
			return (
				DashboardHelper.showError( this.props.lineGraphError )
			)
		}

		return (
			<div style={ { display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-around' } }>
				<div style={ { flexBasis: '45%' } }>
					<GraphCleaner
						currentTimeFrame={ this.props.currentTimeFrame }
						timeFrameMoment={ this.props.timeFrameMoment }
						overallGraphType={ 'lineGraph' }
						data={ this.props.lineGraphData.shortstop.data }

						documentType={ 'shortstop' }
						dateInfo={ this.props.dateInfo }
					/>
				</div>

				<div style={ { flexBasis: '45%' } }>
					<GraphCleaner
						currentTimeFrame={ this.props.currentTimeFrame }
						timeFrameMoment={ this.props.timeFrameMoment }
						overallGraphType={ 'lineGraph' }
						data={ this.props.lineGraphData.shortstop_live.data }

						documentType={ 'shortstop_live' }
						dateInfo={ this.props.dateInfo }
					/>
				</div>
			</div>
		)
	}
}


ShortstopSection1.propTypes = {
	currentTimeFrame	: PropTypes.string.isRequired,
	timeFrameMoment		: PropTypes.instanceOf( moment ).isRequired,

	lineGraphData		: PropTypes.object.isRequired,
	dateInfo			: PropTypes.object.isRequired,
	lineGraphError		: PropTypes.object,
}


export default ShortstopSection1
