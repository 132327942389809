import _isArray from 'lodash/isArray'

import helpers from '../lib/helpers'


export default class PccProgram {
	static get friendlyName() { return 'PCC Program' }
	static get modelName() { return PccProgram.friendlyName.replace( ' ', '' ) }
	static get workItemType() { return PccProgram.friendlyName.toString().replace( ' ', '_' ).toLowerCase() }


	static get STUDIO_TYPES() {
		return [
			{
				name	: 'Yes',
				value	: 'true'
			},
			{
				name	: 'no',
				value	: 'false'
			}
		]
	}


	static get LANGUAGE_TYPES() {
		return [
			{
				name	: 'English',
				value	: 'English'
			},
			{
				name	: 'Spanish',
				value	: 'Spanish'
			},
			{
				name	: 'Portuguese',
				value	: 'Portuguese'
			}
		]
	}


	static get NETWORK_TYPES() {
		return [
			{
				name	: 'latin_north',
				value	: 'latin_north'
			},
			{
				name	: 'latin_south',
				value	: 'latin_south'
			},
			{
				name	: 'pacrim_live',
				value	: 'pacrim_live'
			},
			{

				name	: 'brazil_live',
				value	: 'brazil_live'
			},
			{
				name	: 'carribean_live',
				value	: 'carribean_live'
			}
		]
	}


	static get DATE_FORMAT_STRING() {
		return 'YYYY-MM-DDTHH:mm:ss:SSS+0000'
	}


	constructor( json = {} ) {
		this.id = json.id
		this.artworkUrl = json.artworkUrl
		this.title = json.title
		this.href = json.href
		this.media = json.media
		this.categories = json.categories
		this.airings = json.airings
		this.sport = json.sport
		this.league = json.league
		this.properties = json.properties
		this.franchise = json.franchise
		this.createdOn = json.createdOn
	}


	get modelName() { return PccProgram.modelName }
	get friendlyName() { return PccProgram.friendlyName }
	get workItemType() { return PccProgram.workItemType }


	get isPublished() {
		let published = false
		if ( helpers.doesExist( this.media ) && helpers.doesExist( this.media.properties ) ) {
			published = helpers.isTrue( this.media.properties.published )
		}

		return published
	}


	get language() {
		let language = null
		if ( helpers.doesExist( this.properties ) ) language = this.properties.language

		return language
	}


	get description() {
		let description = null
		if ( helpers.doesExist( this.properties ) ) description = this.properties.synopsis

		return description
	}


	get network() {
		let network = null
		if ( helpers.doesExist( this.media ) && helpers.doesExist( this.media.network ) ) {
			network = this.media.network.name
		}

		return network
	}


	getCategoryValue( categoryType ) {
		if ( helpers.doesNotExist( categoryType ) ) return null
		if ( !_isArray( this.categories ) ) return null

		let category = this.categories.find( ( c ) => { return c.type === categoryType } )
		if ( helpers.doesNotExist( category ) ) return null

		return category.name
	}


	getDuration( program ) {
		let duration = null

		if ( helpers.doesExist( program.media ) ) {
			if ( helpers.doesExist( program.media[ 0 ] ) ) {
				duration = helpers.formatTime( program.media[ 0 ].duration )
			}
		}

		return duration
	}
}
