const types = require( './actionTypes' )


function beginAjaxCall( actionShortDescription ) {
	return { type: types.BEGIN_AJAX_CALL, info: actionShortDescription }
}


function endAjaxCall( actionShortDescription ) {
	return { type: types.END_AJAX_CALL, info: actionShortDescription }
}


function clearAjaxCallError() {
	return function( dispatch ) {
		dispatch( { type: types.CLEAR_AJAX_CALL_ERROR } )
	}
}


function ajaxCallError( ajaxError ) {
	return { type: types.AJAX_CALL_ERROR, response: ajaxError }
}


module.exports = {
	ajaxCallError,
	beginAjaxCall,
	endAjaxCall,
	clearAjaxCallError
}
