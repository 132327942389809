import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListSubheader from '@material-ui/core/ListSubheader'
import BulletPointIcon from '@material-ui/icons/Remove'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import config from '../../lib/config'

const styles = {
	dialog: {
		width: '80%%',
		maxWidth: 'none',
		paddingLeft: '250px',
		paddingRight: '250px'
	}
}

const DATE_RELEASED = 'May 22, 2023'
const NEW_FEATURES = [
	'Upgrade Node version up to 12'
]


class WhatsNewDialog extends React.Component {
	constructor( props, context ) {
		super( props, context )
	}

	render() {
		if ( !this.props.isOpen ) return ( <div /> )

		return (
			<Dialog
				open = { this.props.isOpen }
				onClose = { this.props.closeDialog }
			>
				<DialogTitle id="dialog-title">{ 'What\'s New' }</DialogTitle>
				<DialogContent>
					<div className={ styles.dialog }>
						<div>
							<List dense>
								<ListItem>
									<ListItemText primary="Version" secondary={ config.appVersion } />
									<ListItemText primary="Date Released" secondary={ DATE_RELEASED } />
								</ListItem>
							</List>
						</div>
						<List subheader={ <ListSubheader component="div">Features</ListSubheader> } dense>
							{
								NEW_FEATURES.map( ( bullet ) => {
									return (
										<ListItem key={ bullet }>
											<ListItemIcon><BulletPointIcon /></ListItemIcon>
											<ListItemText primary={ bullet } />
										</ListItem>
									)
								} )
							}
						</List>
					</div>
				</DialogContent>
				<DialogActions>
					<Button id="close-dialog" onClick={ this.props.closeDialog } color="primary">
						OK
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

WhatsNewDialog.propTypes = {
	isOpen				: PropTypes.bool.isRequired,
	closeDialog			: PropTypes.func.isRequired
}

export default WhatsNewDialog
