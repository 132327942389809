import _clone from 'lodash/clone'

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
const QS = require( 'query-string' )

import helpers from '../../lib/helpers'

import Api from '../../dataSource/api'
import { callApi, reportApiError } from '../../actions/apiCallActions'

import Analytics from '../../lib/analytics'

import ProgramsGrid from './ProgramsGrid'
import ProgramDetail from './ProgramDetail'


export class InternationalPage extends React.Component {
	constructor( props, context ) {
		super( props, context )

		this.pageSize = 10

		this.state = {
			programs		: { data: [], total: 0 },
			selectedProgram	: null,
			query			: this.setStateFromLocation( this.props.location.search )
		}
	}


	componentDidMount() {
		this.loadPrograms( this.state.query.currentPage )
	}


	setStateFromLocation( queryString ) {
		let newState = {
			currentPage		: 0
		}

		let parsedQuery = QS.parse( queryString )

		if ( helpers.isInteger( parsedQuery.currentPage ) ) {
			newState.currentPage = ( parseInt( parsedQuery.currentPage ) - 1 )
		}

		return newState
	}


	setLocationFromState( queryState ) {
		let newQuery = {}
		let newLocationHasQuery = false

		if ( helpers.isInteger( queryState.currentPage ) && parseInt( queryState.currentPage ) > 0 ) {
			newQuery.currentPage = queryState.currentPage + 1
			newLocationHasQuery = true
		}

		let newLocation = '/international'
		if ( newLocationHasQuery ) newLocation = `${ newLocation }?${ QS.stringify( newQuery ) }`

		this.props.history.replace( newLocation )
		this.loadPrograms( this.state.query.currentPage )
	}


	buildSearchPageInfo( page, pageSize ) {
		return { rows: pageSize, start: page * pageSize }
	}


	loadPrograms( page ) {
		if ( helpers.doesExist( this.props.loadPrograms ) ) return this.props.loadPrograms()

		let pageInfo = this.buildSearchPageInfo( page, this.pageSize )

		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, pageInfo ) => {
						return Api.pccListPrograms( currentUser, pageInfo )
					} )( this.props.authorizationInfo.user, pageInfo )
				},
				( err, res ) => {
					if ( helpers.doesExist( err ) ) return this.props.dispatch( reportApiError( err ) )

					this.setState( { programs: { data: res.data, total: res.total } } )
				}
			)
		)
	}


	programSelected( program ) {
		Analytics.recordUserActivity()

		this.setState( { selectedProgram: program } )
	}


	handleRefreshButton( evt ) {
		Analytics.recordUserActivity()

		this.loadPrograms( this.state.query.currentPage )
	}


	handlePageSelected( newPage ) {
		Analytics.recordUserActivity()

		let newQuery = _clone( this.state.query )
		newQuery.currentPage = parseInt( newPage )

		this.setState( { query: newQuery }, () => { this.setLocationFromState( this.state.query ) } )
	}


	render() {
		return (
			<div style={ { display: 'flex', padding: '8px' } }>
				<div style={ { flex: 2 } }>
					<ProgramsGrid
						programs={ this.state.programs.data }
						selectedCallback={ this.programSelected.bind( this ) }
						onRefreshList={ this.handleRefreshButton.bind( this ) }
						totalRecords={ this.state.programs.total }
						currentPage={ this.state.query.currentPage }
						onPageSelected={ this.handlePageSelected.bind( this ) }
					/>
				</div>

				<div style={ { flex: 1, marginLeft: 15 } }>
					<ProgramDetail
						authorizationInfo={ this.props.authorizationInfo }
						dispatch={ this.props.dispatch }
						program={ this.state.selectedProgram }
						history={ this.props.history }
					/>
				</div>
			</div>
		)
	}
}


function mapStateToProps( state, ownProps ) {
	return {
		authorizationInfo	: state.authorizationInfo
	}
}


InternationalPage.propTypes = {
	authorizationInfo	: PropTypes.object.isRequired,
	loadPrograms		: PropTypes.func,	// hook to replace the loadPrograms method for testing

	// injected automatically by react-router
	location			: PropTypes.object.isRequired,
	history				: PropTypes.object.isRequired
}


export default withRouter( connect( mapStateToProps )( InternationalPage ) )
