import React from 'react'
import PropTypes from 'prop-types'
const moment = require( 'moment' )

import GraphCleaner from './GraphCleaner'
import DashboardHelper from '../../lib/dashboard_helper'


class ShortstopSection2 extends React.Component {
	render() {
		if ( this.props.shortstopError !== null ) {
			return (
				DashboardHelper.showError( this.props.shortstopError )
			)
		}

		return (
			<div style={ { display: 'flex', flexDirection: 'column' } }>
				<div style={ { display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-around' } }>
					<div style={ { flexBasis: '30%' } }>
						<GraphCleaner
							pieGraphType={ 'post_type' }
							timeFrameMoment={ this.props.timeFrameMoment }
							currentTimeFrame={ this.props.currentTimeFrame }
							data={ this.props.data.data }
							overallGraphType={ 'pieGraph' }
						/>
					</div>

					<div style={ { flexBasis: '30%' } }>
						<GraphCleaner
							pieGraphType={ 'language' }
							timeFrameMoment={ this.props.timeFrameMoment }
							currentTimeFrame={ this.props.currentTimeFrame }
							data={ this.props.data.data }
							overallGraphType={ 'pieGraph' }
						/>
					</div>

					<div style={ { flexBasis: '30%' } }>
						<GraphCleaner
							pieGraphType={ 'platform' }
							timeFrameMoment={ this.props.timeFrameMoment }
							currentTimeFrame={ this.props.currentTimeFrame }
							data={ this.props.data.data }
							overallGraphType={ 'pieGraph' }
						/>
					</div>
				</div>

				<div style={ { display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-around' } }>
					<div style={ { flexBasis: '48%' } }>
						<GraphCleaner
							currentTimeFrame={ this.props.currentTimeFrame }
							timeFrameMoment={ this.props.timeFrameMoment }
							overallGraphType={ 'table' }
							data={ this.props.data.data }

							documentType={ 'shortstop' }
							tableGraphType={ 'contributor' }
							groupInfo={ this.props.groupInfo }
						/>
					</div>

					<div style={ { flexBasis: '48%' } }>
						<GraphCleaner
							currentTimeFrame={ this.props.currentTimeFrame }
							timeFrameMoment={ this.props.timeFrameMoment }
							overallGraphType={ 'table' }
							data={ this.props.data.data }

							documentType={ 'shortstop' }
							tableGraphType={ 'category' }
							groupInfo={ this.props.groupInfo }
						/>
					</div>
				</div>
			</div>
		)
	}
}


ShortstopSection2.propTypes = {
	currentTimeFrame	: PropTypes.string.isRequired,
	timeFrameMoment		: PropTypes.instanceOf( moment ).isRequired,
	data				: PropTypes.object.isRequired,
	shortstopError		: PropTypes.object,
	groupInfo			: PropTypes.object.isRequired
}


export default ShortstopSection2
