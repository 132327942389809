import Group from './group'
import helpers from '../lib/helpers'


export default class User {
	static get friendlyName() { return 'User' }
	static get modelName() { return User.friendlyName.replace( ' ', '' ) }
	static get workItemType() { return User.friendlyName.replace( ' ', '_' ).toLowerCase() }


	static profilePictureUrl( columnist_id ) {
		return `https://secure.espncdn.com/i/columnists/${ columnist_id }_55.jpg`
	}


	static combinerProfilePictureUrl( columnist_id ) {
		return `https://secure.espncdn.com/combiner/i?img=%2Fi%2Fcolumnists%2Ffull%2F${ columnist_id }.png&w=80&h=80&scale=crop`
	}


	constructor( json = {} ) {
		this._id = json._id
		this.username = json.username
		this.state = json.state
		this.contact = json.contact
		this.created = json.created
		this.access_tokens = json.access_tokens
		this.group = Group.buildSnapshot( json.group )

		if ( helpers.doesExist( json.shortstop ) ) {
			this.shortstop = json.shortstop
		}
		else if ( helpers.doesExist( this.group ) && this.group.type === 'shortstop' ) {
			this.shortstop = {
				shortstop_live_access	: false,
				social_tokens			: {},
				default_categories		: []
			}
		}

		if ( helpers.doesNotExist( this.contact ) ) this.contact = {}
		if ( helpers.doesNotExist( this.access_tokens ) ) this.access_tokens = {}
		if ( helpers.doesNotExist( this.group ) ) this.group = {}
		if ( helpers.doesNotExist( this.state ) ) this.state = 'active'
	}


	get modelName() { return User.modelName }
	get friendlyName() { return User.friendlyName }
	get workItemType() { return User.workItemType }


	get isInternal() {
		if ( helpers.doesNotExist( this.group ) ) return false

		return ( this.group.type === 'administrator' ) || ( this.group.type === 'mx-editorial' )
	}


	get isShortstop() {
		if ( helpers.doesNotExist( this.group ) ) return false

		return this.group.type === 'shortstop'
	}


	get profilePictureUrl() {
		if ( helpers.doesNotExist( this.shortstop ) ) return null

		if ( this.shortstop.columnist_id === 'espn_generic' ) {
			return User.profilePictureUrl( this.shortstop.columnist_id )
		}

		return User.combinerProfilePictureUrl( this.shortstop.columnist_id )
	}


	get combinerProfilePictureUrl() {
		if ( helpers.doesNotExist( this.shortstop ) ) return null

		return User.combinerProfilePictureUrl( this.shortstop.columnist_id )
	}
}
