import React from 'react'
import PropTypes from 'prop-types'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'

import IconButton from '@material-ui/core/IconButton'
import ClosedIcon from '@material-ui/icons/ChevronRight'
import OpenIcon from '@material-ui/icons/KeyboardArrowDown'

import { withStyles } from '@material-ui/core/styles'

import FileUploadDetails from './file_upload_details'

const styles = ( theme ) => ( {
	removedTopPadding	: {
		paddingTop 		: '0px'
	},
	removedBottomPadding: {
		paddingBottom	: '0px'
	}
} )

class FileUploadList extends React.Component {
	constructor( props ) {
		super( props )

		this.state = {
			open: true
		}
	}

	get currentUser() {
		return this.props.authorizationInfo.user
	}

	render() {
		return (
			<Card style={ { margin: '8px' } }>
				<CardHeader
					title={
						<div>
							<IconButton onClick={ () => this.setState( { open: !this.state.open } ) }>
								{ this.state.open ? <OpenIcon /> : <ClosedIcon /> }
							</IconButton>
							<Typography variant="h6" display="inline">Uploading File List</Typography>
						</div>
					}
					classes={ { root: this.props.classes.removedBottomPadding } }
				/>

				<CardContent classes={ { root: this.props.classes.removedTopPadding } }>
					<div className={ this.state.open ? 'visible' : '' } id="upload-list-collapse">
						<List className="upload-list-group">
							{
								this.props.uploadFileList? this.props.uploadFileList.map( ( file ) => {
									return <FileUploadDetails key={ file.mediaId } authorizationInfo={ this.props.authorizationInfo } dispatch={ this.props.dispatch } file={ file }/>
								} ) : ''
							}
						</List>
					</div>
				</CardContent>
			</Card>
		)
	}
}


FileUploadList.propTypes = {
	authorizationInfo	: PropTypes.object.isRequired,
	dispatch			: PropTypes.func.isRequired,
	uploadFileList 		: PropTypes.array.isRequired,
	classes				: PropTypes.object.isRequired
}


export default withStyles( styles )( FileUploadList )
