import React from 'react'
const moment = require( 'moment' )

import Typography from '@material-ui/core/Typography'

import helpers from './helpers'
import Api from '../dataSource/api'


export default class DashboardHelper {
	static buildFilter( params, sort ) {
		let filter = {}

		if ( helpers.doesExist( params.type ) ) {
			filter[ 'type' ] = params.type
		}

		if ( helpers.doesExist( params.num_units ) ) {
			filter[ 'num_units' ] = params.num_units
		}

		if ( helpers.doesExist( params.time_frame ) ) {
			filter[ 'time_frame' ] = params.time_frame
		}

		if ( helpers.doesExist( params.start_metrics_date ) ) {
			filter[ 'start_metrics_date' ] = params.start_metrics_date
		}

		if ( helpers.doesExist( sort ) ) {
			filter[ 'sort' ] = sort
		}

		return filter
	}

	static colorArray() {
		return [ '#0074D9', '#FF4136', '#2ECC40' ]
	}


	static getGraphOptions( params ) {
		let fontSize = params.fontsize || 18
		let titleFontSize = params.titleFontSize || 30

		let title = params.title || 'Graph title'
		let label = params.label || 'Graph label'
		let animation = params.animation || 'linear'

		return {
			responsive: true,
			scales: {
				yAxes: [
					{
						gridLines: {
							color: 'black',
							borderDash: [ 2, 5 ],
							fontSize: fontSize
						},
						scaleLabel: {
							display: true,
							labelString: 'Number Posts',
							fontSize: fontSize,
						},
						ticks: {
							beginAtZero: true
						}
					}
				],
				xAxes: [
					{
						gridLines: {
							display: false,
							color: 'black'
						},
						scaleLabel: {
							display: false,
							labelString: DashboardHelper.prettyDateString( label ),
							fontSize: fontSize
						},
					}
				]
			},
			title: {
				display: false,
				text: title,
				fontSize: titleFontSize
			},
			legend: {
				labels: {
					fontSize: fontSize
				}
			},
			animation: {
				easing: animation
			}
		}
	}


	static getChildrenDateInfo( timeFrameMoment, currentTimeFrameState ) {
		const beginTimeFrame = timeFrameMoment.clone().startOf( currentTimeFrameState )
		let endTimeFrame = moment()

		// If looking at past data, set end to end of that time frame instead of current day
		if ( moment().isAfter( timeFrameMoment.clone().endOf( currentTimeFrameState ) ) ) {
			endTimeFrame = timeFrameMoment.endOf( currentTimeFrameState )
		}

		// Views Sunday as zero, need to add 1 to get full week
		let currentTimeFrameAsNumber = endTimeFrame.day() + 1
		let timeToAdd = 'day'
		let formatToUse = 'YYYY-MM-DD'

		if ( currentTimeFrameState === 'month' ) {
			currentTimeFrameAsNumber = endTimeFrame.date()
		}
		else if ( currentTimeFrameState === 'year' ) {
			timeToAdd = 'month'
			formatToUse = 'YYYY-MM'
			currentTimeFrameAsNumber = endTimeFrame.format( 'MM' )
		}

		return {
			currentTimeFrameAsNumber: currentTimeFrameAsNumber,
			timeToAdd: timeToAdd,
			formatToUse: formatToUse,
			beginTimeFrame: beginTimeFrame
		}
	}


	static getMomentFormat( timeFrame ) {
		switch ( timeFrame ) {
		case ( 'day' ):
			return 'YYYY-MM-DD'
		case ( 'month' ):
			return 'YYYY-MM'
		case ( 'week' ):
			return 'GGGG-WW'
		case ( 'year' ):
			return 'YYYY'
		}
	}


	static makeArrayOfDates( params ) {
		let end = params.endDate.diff( params.startDate, params.timeFrame ) + 1

		for ( let i = 0; i < end; i++ ) {
			params.array.push( params.startDate.format( params.format ) )
			params.startDate.add( 1, params.timeFrame )
		}
	}


	static getAnalyticsData( params, user, cb ) {
		let sort = null
		if ( helpers.doesExist( params.sort ) ) {
			sort = params.sort
		}

		const filter = DashboardHelper.buildFilter( params, sort )
		Api.searchAnalytics( user, filter, sort )
			.then( ( data ) => {
				cb( null, data )
			} )
			.catch( ( err ) => {
				cb( err )
			} )
	}


	static getDateLabels( currentTimeFrame, timeFrameMoment ) {
		const allMonths = moment.months()
		const daysOfWeek = moment.weekdays()

		let arrayOfDays = []
		for ( let i = 1; i <= timeFrameMoment.clone().daysInMonth(); i++ ) {
			arrayOfDays.push( i )
		}

		switch ( currentTimeFrame ) {
		case ( 'year' ):
			return allMonths
		case ( 'month' ):
			return arrayOfDays
		case ( 'week' ):
			return daysOfWeek
		}
	}


	static prettyDates( timeFrameMoment, currentTimeFrame ) {
		switch ( currentTimeFrame ) {
		case ( 'year' ):
			return timeFrameMoment.format( 'YYYY' )
		case ( 'month' ):
			return timeFrameMoment.format( 'MMMM' )
		case ( 'week' ):
			return `${ timeFrameMoment.clone().startOf( 'week' ).format( 'MM/DD/YY' ) }-${ timeFrameMoment.clone().endOf( 'week' ).format( 'MM/DD/YY' ) }`
		}
	}


	static prettyDateString( currentTimeFrame ) {
		return currentTimeFrame.charAt( 0 ).toUpperCase() + currentTimeFrame.slice( 1 )
	}


	static getLoadingScreen() {
		return (
			<div className="loader loader--active">
				<div className="loader-circle"></div>
				<div className="loader-line-mask">
					<div className="loader-line"></div>
				</div>
			</div>
		)
	}


	static showError( error ) {
		return <div>
			<h2>
				<Typography color="error">Error!</Typography>
				<br/>
				<hr/>
				<div>{ error.message }</div>
				<div>{ error.stack }</div>
				<hr/>
			</h2>
		</div>
	}
}
