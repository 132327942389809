import _clone from 'lodash/clone'
import _cloneDeep from 'lodash/cloneDeep'

import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { DatePicker } from '@material-ui/pickers'

import Analytics from '../../lib/analytics'
import helpers from '../../lib/helpers'

import API from '../../dataSource/api'

import CategorySearch from '../common/category_search'
import UserSearch from '../common/user_search'

const DOCUMENT_LOADED = 'loaded'


export class ShortstopActivityFilter extends React.Component {
	constructor( props, context ) {
		super( props, context )

		this.state = {
			categories_search: { current_search_term: '', type: 'any', searching: false, results: { categories: [], error: null } },
		}

		this.handleToggleChanged = this.handleToggleChanged.bind( this )

		this.handlePostDateChange = this.handlePostDateChange.bind( this )
		this.handlePostDateReset = this.handlePostDateReset.bind( this )

		this.handleUserFilterSelection = this.handleUserFilterSelection.bind( this )

		this.handleCategoryFilterChange = this.handleCategoryFilterChange.bind( this )
		this.handleCategoryFilterSelection = this.handleCategoryFilterSelection.bind( this )
	}


	handleToggleChanged( event, propName ) {
		let newFilter = _clone( this.props.filterSettings )
		newFilter[ propName ] = event.target.checked

		this.props.filterSettingsChanged( newFilter )
	}


	handlePostDateChange( newDate ) {
		let postDate = new Date( newDate.format( 'YYYY-MM-DDT00:00:00' ) )

		let newFilter = _clone( this.props.filterSettings )
		newFilter.postDate = postDate

		this.props.filterSettingsChanged( newFilter )
	}


	handlePostDateReset( event ) {
		if ( helpers.doesNotExist( this.props.filterSettings.postDate ) ) return

		let newFilter = _clone( this.props.filterSettings )
		newFilter.postDate = null

		this.props.filterSettingsChanged( newFilter )
	}


	handleUserFilterSelection( selectedUser ) {
		this.props.userFilterChanged( selectedUser )
	}


	handleCategoryFilterSelection( selectedCategory ) {
		this.props.categoryFilterChanged( selectedCategory )
	}


	handleCategoryFilterChange( newSearchTerm ) {
		Analytics.recordUserActivity()

		if ( newSearchTerm === '' ) return this.resetSearch()

		let newState = _cloneDeep( this.state )
		newState.categories_search.current_search_term = newSearchTerm

		let newCategoryState = newState.categories_search.results.categories
		if ( newSearchTerm.length < 3 ) newCategoryState = []
		newState.categories_search.results = { error: null, categories: newCategoryState }

		this.setState( newState, () => {
			if ( newState.categories_search.current_search_term.length >= 3 ) {
				this.searchCategories( newState.categories_search.current_search_term, newState.categories_search.type )
			}
			else {
				// if ( this.props.searchComplete !== undefined ) this.props.searchComplete()
			}
		} )
	}


	searchCategories( searchTerm, categoryType ) {
		if ( searchTerm === '' ) return

		let newState = _cloneDeep( this.state )
		newState.categories_search.searching = true

		let searchGuruCategoryType
		if ( categoryType !== 'any' ) searchGuruCategoryType = categoryType

		this.setState( newState, () => {
			API.searchCategories( this.props.authorizationInfo.user, searchTerm, searchGuruCategoryType )
				.then( ( searchResults ) => {
					if ( searchResults.searchTerm !== this.state.categories_search.current_search_term ) return

					let newState = _cloneDeep( this.state )

					newState.categories_search.searching = false
					newState.categories_search.results = { categories: searchResults.models, error: null }

					this.setState( newState, () => {
						// if ( this.props.searchComplete !== undefined ) this.props.searchComplete()
					} )
				} )
				.catch( ( err ) => {
					let newState = _cloneDeep( this.state )

					newState.categories_search.searching = false
					newState.categories_search.results = { categories: [], error: err }

					this.setState( newState, () => {
						// if ( this.props.searchComplete !== undefined ) this.props.searchComplete()
					} )
				} )
		} )
	}

	resetSearch() {
		let newState = _cloneDeep( this.state )
		newState.categories_search = { current_search_term: '', type: this.state.categories_search.type, searching: false, results: { categories: [], error: null } }

		this.setState( newState )
	}


	renderToggleControls() {
		return (
			<div>
				<div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center' } }>
					<Typography variant="subtitle2" color="textPrimary" noWrap>
						In Progress
					</Typography>
					<div style={ { marginLeft: 'auto' } }>
						<Switch
							checked={ this.props.filterSettings.showInProgress }
							onChange={ ( e ) => { this.handleToggleChanged( e, 'showInProgress' ) } }
							color="primary"
						/>
					</div>
				</div>

				<div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -15 } }>
					<Typography variant="subtitle2" color="textPrimary" noWrap>
						Published
					</Typography>
					<div style={ { marginLeft: 'auto' } }>
						<Switch
							checked={ this.props.filterSettings.showPublished }
							onChange={ ( e ) => { this.handleToggleChanged( e, 'showPublished' ) } }
							color="primary"
						/>
					</div>
				</div>

				<div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -15 } }>
					<Typography variant="subtitle2" color="textPrimary" noWrap>
						Unpublished
					</Typography>
					<div style={ { marginLeft: 'auto' } }>
						<Switch
							checked={ this.props.filterSettings.showUnpublished }
							onChange={ ( e ) => { this.handleToggleChanged( e, 'showUnpublished' ) } }
							color="primary"
						/>
					</div>
				</div>

				<div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -15 } }>
					<Typography variant="subtitle2" color="textPrimary" noWrap>
						Events Only
					</Typography>
					<div style={ { marginLeft: 'auto' } }>
						<Switch
							checked={ this.props.filterSettings.eventsOnly }
							onChange={ ( e ) => { this.handleToggleChanged( e, 'eventsOnly' ) } }
							color="primary"
						/>
					</div>
				</div>

				<div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -15 } }>
					<Typography variant="subtitle2" color="textPrimary" noWrap>
						Tests
					</Typography>
					<div style={ { marginLeft: 'auto' } }>
						<Switch
							checked={ this.props.filterSettings.showTests }
							onChange={ ( e ) => { this.handleToggleChanged( e, 'showTests' ) } }
							color="primary"
						/>
					</div>
				</div>
			</div>
		)
	}


	renderDatePicker() {
		return (
			<div style={ { display: 'flex', flexDirection: 'column' } }>
				<DatePicker
					label="Post Date"
					value={ this.props.filterSettings.postDate || null }
					format="MMM Do, YYYY"
					minDate={ new Date( 2017, 0, 1 ) } maxDate={ new Date() }
					onChange={ this.handlePostDateChange }
				/>

				<Button variant="contained" onClick={ this.handlePostDateReset } style={ { marginTop: 10 } }>
					Reset Date
				</Button>
			</div>
		)
	}


	renderTypeaheads() {
		if ( ( helpers.doesExist( this.props.filterSettings.category_uuid ) && this.props.filteredCategory.status !== DOCUMENT_LOADED ) ||
			( helpers.doesExist( this.props.filterSettings.contributor_id ) && this.props.filteredContributor.status !== DOCUMENT_LOADED ) ) {

			// Don't render anything if we're still loading the previously selected filter values.  We want to initialize the typeaheads
			// appropriately.  This is the case if the user has selected a contributor or category to filter on and has refreshed the page
			return ( <div /> )
		}

		return (
			<div>
				<div>
					<UserSearch
						authorizationInfo={ this.props.authorizationInfo }
						initialSelection={ this.props.filteredContributor.document }
						userSelected={ this.handleUserFilterSelection } />
				</div>

				<div style={ { marginTop: 30 } }>
					<CategorySearch
						clearOnSelection={ false }
						placeholderText={ 'Search Categories' }
						searchTerm={ this.state.categories_search.current_search_term }
						categoryType={ this.state.categories_search.type }
						searching={ this.state.categories_search.searching }
						initialSelection={ this.props.filteredCategory.document }
						searchResults={ this.state.categories_search.results }
						updateSearchTerm={ this.handleCategoryFilterChange }
						updateCategoryType={ () => {} }
						categorySelected={ this.handleCategoryFilterSelection }
						displayTypeSelector={ false }
					/>
				</div>
			</div>
		)
	}


	render() {
		if ( !this.props.open ) return ( <div /> )

		return (
			<Dialog open={ this.props.open } onClose={ this.handleClose } maxWidth={ 'md' } fullWidth={ true }>
				<DialogTitle>Shortstop Activity Filter</DialogTitle>
				<DialogContent>
					<div style={ { display: 'flex', flexDirection: 'row', height: 300 } }>
						<div style={ { flex: 1, padding: 10 } }>
							{ this.renderToggleControls() }
						</div>
						<div style={ { flex: 1, padding: 10 } }>
							{ this.renderDatePicker() }
						</div>
						<div style={ { flex: 1, padding: 10, paddingTop: '25px' } }>
							{ this.renderTypeaheads() }
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={ this.props.closeFilter } color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}


ShortstopActivityFilter.propTypes = {
	authorizationInfo		: PropTypes.object.isRequired,
	open					: PropTypes.bool.isRequired,
	filterSettings			: PropTypes.object.isRequired,
	filteredContributor		: PropTypes.object.isRequired,
	filteredCategory		: PropTypes.object.isRequired,
	filterSettingsChanged	: PropTypes.func.isRequired,

	// the following two filter changed callbacks are special-cases that need to be handled differently
	userFilterChanged		: PropTypes.func.isRequired,
	categoryFilterChanged	: PropTypes.func.isRequired,

	closeFilter				: PropTypes.func.isRequired,
}


export default ShortstopActivityFilter
