import helpers from '../lib/helpers'

export default class Analytics {

	static get friendlyName() { return 'Analytics' }
	static get modelName() { return Analytics.friendlyName.replace( ' ', '' ) }
	static get workItemType() { return Analytics.friendlyName.replace( ' ', '_' ).toLowerCase() }

	constructor( json = {} ) {
		this.id = json.id
		this.type = json.type
		this.time_frame = json.time_frame
		this.metrics_date = json.metrics_date
		this.total_posts = json.total_posts

		if ( helpers.doesExist( json.contributors ) ) {
			this.contributors = json.contributors
		}

		if ( helpers.doesExist( json.post_type ) ) {
			this.post_type = json.post_type
		}

		if ( helpers.doesExist( json.categories ) ) {
			this.categories = json.categories
		}

		if ( helpers.doesExist( json.language ) ) {
			this.language = json.language
		}

		if ( helpers.doesExist( json.platforms ) ) {
			this.platforms = json.platforms
		}

		if ( helpers.doesExist( json.partners ) ) {
			this.partners = json.partners
		}

	}

	get modelName() { return Analytics.modelName }
	get friendlyName() { return Analytics.friendlyName }
	get workItemType() { return Analytics.workItemType }
}
