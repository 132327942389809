import _get from 'lodash/get'
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Player } from 'video-react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import grey from '@material-ui/core/colors/grey'

import ShortstopAvatar from '../common/shortstop_avatar'


const IMAGE_LOADING = 'loading'
const IMAGE_LOADED = 'loaded'
const IMAGE_UNAVAILABLE = 'unavailable'
const NO_IMAGE = 'no-image'


class PostPreview extends React.Component {
	constructor( props ) {
		super( props )

		this.state = { thumbnail: NO_IMAGE }
		if ( this.props.currentPost.shortstop.media_type !== 'text' ) {
			this.state = { thumbnail: IMAGE_LOADING }
		}

		this.thumbnailImage = new window.Image()

		this.thumbnailImage.onload = () => { this.setState( { thumbnail: IMAGE_LOADED } ) }
		this.thumbnailImage.onerror = () => { this.setState( { thumbnail: IMAGE_UNAVAILABLE } ) }
	}


	componentDidMount() {
		if ( this.props.currentPost.shortstop.media_type === 'text' ) return
		this.thumbnailImage.src = this.props.currentPost.getThumbnailUrl()
	}


	getPostMetadata() {
		let categories = _get( this.props.currentPost, 'shortstop.categories', [] )
		let created = _get( this.props.currentPost, 'created' )
		let displayCategory
		let displayCategoryName = ''
		let metadata = null

		if ( categories && categories.length ) {
			displayCategory = categories.find( category => {
				return category.type === 'topic'
			} )

			if ( !displayCategory ) {
				displayCategory = categories.find( category => {
					return category.type === 'league'
				} )

				if ( !displayCategory ) {
					displayCategory = categories.find( category => {
						return category.type === 'team'
					} )
				}
			}

			if ( displayCategory && displayCategory.name ) {
				displayCategoryName = displayCategory.name
			}
		}

		if ( displayCategoryName ) {
			metadata = displayCategoryName

			if ( created ) {
				metadata += ` - ${ moment( created ).fromNow() }`
			}
		}
		else if ( created ) {
			metadata = moment( created ).fromNow()
		}

		return metadata
	}


	renderMetadata() {
		let metadata = this.getPostMetadata()

		if ( metadata === null ) {
			return (
				<div style={ { marginLeft: 15, marginRight: 20, display: 'flex', flexDirection: 'row', marginBottom: 20 } }>
					<img src="https://secure.espncdn.com/redesign/assets/img/icons/icon-espn-e-@2x.png" style={ { width: 20, height: 20 } } />
				</div>
			)
		}

		return (
			<div style={ { marginLeft: 15, marginRight: 20, display: 'flex', flexDirection: 'row' } }>
				<img src="https://secure.espncdn.com/redesign/assets/img/icons/icon-espn-e-@2x.png" style={ { width: 20, height: 20 } } />
				<Typography variant="body1" paragraph style={ { marginLeft: 10, color: grey[ 600 ] } }>
					{ metadata }
				</Typography>
			</div>
		)
	}


	renderThumbnail() {
		let thumbnail = ( <div /> )

		if ( this.props.currentPost.shortstop.media_type === 'text' ) return thumbnail

		if ( this.props.currentPost.shortstop.media_type === 'video' ) {
			return (
				<Player
					playsInline
					poster={ this.props.currentPost.getThumbnailUrl() }
					src={ this.props.currentPost.getVideoUrl() }
				/>
			)
		}

		if ( this.state.thumbnail === IMAGE_LOADED ) {
			let imageStyle = { width: '100%', minWidth: '100%', maxWidth: '100%', display: 'block', margin: '0 auto' }
			let cardMediaStyle = {}

			let photoAttachment = this.props.currentPost.getAttachment( 'photo' )
			let orientation = _get( photoAttachment.formats[ 0 ], 'metadata.orientation_flag', 'landscape' )
			if ( orientation === 'portrait' ) {
				imageStyle = { width: '31%', minWidth: '31%', maxWidth: '31%', display: 'block', margin: '0 auto' }
				cardMediaStyle = { backgroundColor: grey[ 200 ] }
			}

			thumbnail = (
				<div style={ cardMediaStyle }>
					<img src={ this.thumbnailImage.src } style={ imageStyle } />
				</div>
			)
		}
		else if ( this.state.thumbnail === IMAGE_LOADING || this.state.thumbnail === IMAGE_UNAVAILABLE ) {
			thumbnail = (
				<svg width="100%" style={ { background: grey[ 200 ] } } />
			)
		}

		return thumbnail
	}


	render() {
		return (
			<Card style={ { marginTop: 30, maxWidth: 420, minWidth: 420 } }>
				<CardHeader
					avatar={
						<ShortstopAvatar
							authorizationInfo={ this.props.authorizationInfo }
							currentUser={ this.props.postAuthor }
							cardAvatar={ true }
							getScribeColumnist={ this.props.getScribeColumnist }
						/>
					}
					title={ this.props.postAuthor.contact.name }
					subheader={ this.props.postAuthor.contact.job_title }
				/>

				{ this.renderThumbnail() }

				<CardContent>
					<Typography variant="body1" style={ { whiteSpace: 'pre-wrap', wordBreak: 'break-all' } } component="p">
						{ this.props.currentPost.shortstop.body_text }
					</Typography>
				</CardContent>

				{ this.renderMetadata() }
			</Card>
		)
	}
}


PostPreview.propTypes = {
	authorizationInfo	: PropTypes.object.isRequired,
	postAuthor  		: PropTypes.object.isRequired,
	currentPost			: PropTypes.object.isRequired,

	// test hook
	getScribeColumnist	: PropTypes.func,
}


export default PostPreview
