import _cloneDeep from 'lodash/cloneDeep'
import React from 'react'
const PropTypes = require( 'prop-types' )
import * as Redux from 'react-redux'

import { withRouter } from 'react-router'

import Analytics from '../../lib/analytics'
import helpers from '../../lib/helpers'

import Api from '../../dataSource/api'
import { callApi, reportApiError } from '../../actions/apiCallActions'
import { showNotification } from '../../actions/appNotificationActions'

import ShortstopPostEntry from './shortstop_post_entry'
import PostPreview from './post_preview'


export class EditShortstopPostPage extends React.Component {
	constructor( props, context ) {
		super( props, context )
		this.state = Object.assign( this.initialState )
	}


	get initialState() {
		return {
			currentPost	: null,
			postAuthor	: null
		}
	}


	componentDidMount() {
		this.getPostDetails( this.props.match.params.id )
	}


	getPostDetails( postId ) {
		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, mediaId ) => {
						return Api.getMedia( currentUser, mediaId )
					} )( this.props.authorizationInfo.user, postId )
				},
				( err, res ) => {
					if ( helpers.doesExist( err ) ) return this.props.dispatch( reportApiError( err ) )
					if ( helpers.doesExist( res ) ) {
						this.setState( { currentPost: ( res ) }, () => {
							this.getPostAuthor( res.user.user_id )
						} )
					}
				}
			)
		)
	}


	getPostAuthor( postAuthorId ) {
		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, postAuthorId ) => {
						return Api.getUser( currentUser, postAuthorId )
					} )( this.props.authorizationInfo.user, postAuthorId )
				},
				( err, res ) => {
					if ( helpers.doesExist( err ) ) return this.props.dispatch( reportApiError( err ) )
					if ( helpers.doesExist( res ) ) {
						this.setState( { postAuthor: ( res ) } )
					}
				}
			)
		)
	}


	savePostChanges() {
		this.props.dispatch(
			callApi(
				() => {
					return ( ( user, post ) => {
						return Api.saveMedia( user, post )
					} )( this.props.authorizationInfo.user, this.state.currentPost )
				},
				( err, newPost ) => {
					if ( helpers.doesExist( err ) ) {
						return this.props.dispatch( reportApiError( err ) )
					}

					this.props.dispatch( showNotification( 'Success', 'Update Post', 'success' ) )

					this.props.history.goBack()
				}
			)
		)
	}


	handleUpdatePostStateChange( post ) {
		this.setState( { currentPost: _cloneDeep( post ) } )
	}


	cancelEdit() {
		Analytics.recordUserActivity()
		this.props.history.goBack()
	}


	render() {
		if ( helpers.doesNotExist( this.props.match.params.id ) ) return ( <div /> )
		if ( helpers.doesNotExist( this.state.currentPost ) ) return ( <div /> )
		if ( helpers.doesNotExist( this.state.postAuthor ) ) return ( <div /> )

		return (
			<div style={ { display: 'flex', flexDirection: 'row', justifyContent: 'center' } }>
				<div style={ { minWidth: 500 } }>
					<ShortstopPostEntry
						authorizationInfo={ this.props.authorizationInfo }
						currentPost={ this.state.currentPost }
						updatePostStateChangeCallback={ this.handleUpdatePostStateChange.bind( this ) }
						savePostCallback={ this.savePostChanges.bind( this ) }
						cancelChangesCallback={ this.cancelEdit.bind( this ) }
					/>
				</div>

				<div style={ { marginLeft: 30, minWidth: 420 } }>
					<PostPreview
						authorizationInfo={ this.props.authorizationInfo }
						postAuthor={ this.state.postAuthor }
						currentPost={ this.state.currentPost }
						getScribeColumnist={ this.props.getScribeColumnist }
					/>
				</div>
			</div>
		)
	}
}


function mapStateToProps( state, ownProps ) {
	return {
		authorizationInfo : state.authorizationInfo
	}
}


EditShortstopPostPage.propTypes = {
	authorizationInfo	: PropTypes.object.isRequired,
	params				: PropTypes.object,

	// injected automatically by react-router
	match				: PropTypes.object.isRequired,
	history				: PropTypes.object.isRequired,

	// test hook
	getScribeColumnist	: PropTypes.func,
}


export default withRouter( Redux.connect( mapStateToProps )( EditShortstopPostPage ) )
