import React from 'react'
import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'


class PostContent extends React.Component {
	constructor( props ) {
		super( props )

		this.updateBodyText = this.updateBodyText.bind( this )
	}


	updateBodyText( event ) {
		if ( event.target.value.endsWith( '  ' ) ) return

		let updatedText = event.target.value

		try {
			updatedText = event.target.value.trimLeft()
		}
		catch ( e ) {
			// for some reason, trimStart() is failing on the build server
		}

		this.props.updatePostText( updatedText )
	}


	render() {
		let error = true
		let helpText = 'Post must contain some content'

		if ( this.props.post.shortstop.body_text.length > 0 ) {
			error = false
			helpText = `Character count: ${ this.props.post.shortstop.body_text.length }`
		}

		return (
			<FormControl error={ error } fullWidth>
				<TextField
					error={ error }
					placeholder="Happening now..."
					multiline
					rowsMax={ 50 }
					value={ this.props.post.shortstop.body_text }
					onChange={ this.updateBodyText }
				/>
				<FormHelperText>{ helpText }</FormHelperText>
			</FormControl>
		)
	}
}


PostContent.propTypes = {
	// component specific
	post			: PropTypes.object.isRequired,
	updatePostText	: PropTypes.func.isRequired,
}


export default PostContent
