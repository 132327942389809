import React from 'react'
import PropTypes from 'prop-types'
const moment = require( 'moment' )

import GraphCleaner from './GraphCleaner'
import DashboardHelper from '../../lib/dashboard_helper'


class PartnerSection extends React.Component {
	getAdminOnlyCol() {
		if ( this.props.groupInfo.type !== 'partner' ) {
			return (
				<GraphCleaner
					currentTimeFrame={ this.props.currentTimeFrame }
					timeFrameMoment={ this.props.timeFrameMoment }
					overallGraphType={ 'table' }

					data={ this.props.tableData.data }

					documentType={ 'partner' }
					tableGraphType={ 'partner' }
					groupInfo={ this.props.groupInfo }
				/>
			)
		}

		return <div />
	}


	render() {
		if ( this.props.error !== null ) {
			return (
				DashboardHelper.showError( this.props.error )
			)
		}
		const adminOnlyCol = this.getAdminOnlyCol()
		return (
			<div style={ { display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-around' } }>
				<div style={ { flexBasis: '48%' } }>
					<GraphCleaner
						groupInfo={ this.props.groupInfo }

						currentTimeFrame={ this.props.currentTimeFrame }
						timeFrameMoment={ this.props.timeFrameMoment }

						data={ this.props.lineData.data }
						documentType={ 'partner' }
						dateInfo={ this.props.dateInfo }
						overallGraphType={ 'lineGraph' }
					/>
				</div>

				<div style={ { flexBasis: '48%' } }>
					{ adminOnlyCol }
				</div>
			</div>
		)
	}
}


PartnerSection.propTypes = {
	currentTimeFrame			: PropTypes.string.isRequired,
	timeFrameMoment				: PropTypes.instanceOf( moment ).isRequired,
	error						: PropTypes.object,

	// Line
	lineData					: PropTypes.object.isRequired,
	dateInfo					: PropTypes.object.isRequired,
	groupInfo					: PropTypes.object,

	// table
	tableData					: PropTypes.object,
}


export default PartnerSection
