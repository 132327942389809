import _clone from 'lodash/clone'
import _get from 'lodash/get'

import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

import { DatePicker } from '@material-ui/pickers'

import config from '../../lib/config'
import helpers from '../../lib/helpers'

import UserSearch from '../common/user_search'

const DOCUMENT_LOADED = 'loaded'


export class IngestActivityFilter extends React.Component {
	constructor( props ) {
		super( props )

		this.handleToggleChanged = this.handleToggleChanged.bind( this )

		this.handlePostDateChange = this.handlePostDateChange.bind( this )
		this.handlePostDateReset = this.handlePostDateReset.bind( this )

		this.handleGroupSelection = this.handleGroupSelection.bind( this )
		this.handleUserFilterSelection = this.handleUserFilterSelection.bind( this )
	}


	handleToggleChanged( event, propName ) {
		let newFilter = _clone( this.props.filterSettings )
		newFilter[ propName ] = event.target.checked

		this.props.filterSettingsChanged( newFilter )
	}


	handlePostDateChange( newDate ) {
		let postDate = new Date( newDate.format( 'YYYY-MM-DDT00:00:00' ) )

		let newFilter = _clone( this.props.filterSettings )
		newFilter.postDate = postDate

		this.props.filterSettingsChanged( newFilter )
	}


	handlePostDateReset( event ) {
		if ( helpers.doesNotExist( this.props.filterSettings.postDate ) ) return

		let newFilter = _clone( this.props.filterSettings )
		newFilter.postDate = null

		this.props.filterSettingsChanged( newFilter )
	}


	handleGroupSelection( event ) {
		let newGroup = null
		if ( event.target.value !== '' ) {
			for ( let i = 0; i < this.props.groups.length; i++ ) {
				if ( this.props.groups[ i ].group_id === event.target.value ) {
					newGroup = this.props.groups[ i ]
					break
				}
			}
		}

		this.props.groupFilterChanged( newGroup )
	}


	handleUserFilterSelection( selectedUser ) {
		this.props.userFilterChanged( selectedUser )
	}


	renderToggleControls() {
		let testToggle = ( <div /> )

		if ( config.environment !== 'production' ) {
			testToggle = (
				<div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -15 } }>
					<Typography variant="subtitle2" color="textPrimary" noWrap>
						Tests
					</Typography>
					<div style={ { marginLeft: 'auto' } }>
						<Switch
							checked={ this.props.filterSettings.showTests }
							onChange={ ( e ) => { this.handleToggleChanged( e, 'showTests' ) } }
							color="primary"
						/>
					</div>
				</div>
			)
		}

		return testToggle
	}


	renderDatePicker() {
		return (
			<div style={ { display: 'flex', flexDirection: 'column' } }>
				<DatePicker
					label="Post Date"
					value={ this.props.filterSettings.postDate || null }
					format="MMM Do, YYYY"
					minDate={ new Date( 2017, 0, 1 ) } maxDate={ new Date() }
					onChange={ this.handlePostDateChange }
				/>

				<Button variant="contained" onClick={ this.handlePostDateReset } style={ { marginTop: 10 } }>
					Reset Date
				</Button>
			</div>
		)
	}


	renderGroupSelect() {
		if ( !this.props.location.pathname.endsWith( 'footage' ) ) return ( <div /> )

		let currentValue = _get( this.props.filteredGroup, 'document.group_id', '' )

		return (
			<FormControl style={ { minWidth: 450 } }>
				<InputLabel>Group</InputLabel>
				<Select
					value={ currentValue }
					onChange={ this.handleGroupSelection }
					autoWidth={ true }
				>
					<MenuItem value="">None</MenuItem>
					{
						this.props.groups.map( ( g ) => {
							return ( <MenuItem key={ g.group_id } value={ g.group_id }>{ g.name }</MenuItem> )
						} )
					}
				</Select>
			</FormControl>
		)
	}


	renderUserSearch() {
		if ( this.props.location.pathname.endsWith( 'footage' ) ) return ( <div /> )


		if ( helpers.doesExist( this.props.filterSettings.contributor ) && this.props.filteredContributor.status !== DOCUMENT_LOADED ) {

			// Don't render anything if we're still loading the previously selected filter values.  We want to initialize the typeaheads
			// appropriately.  This is the case if the user has selected a contributor or category to filter on and has refreshed the page
			return ( <div /> )
		}

		return (
			<UserSearch
				authorizationInfo={ this.props.authorizationInfo }
				initialSelection={ this.props.filteredContributor.document }
				userSelected={ this.handleUserFilterSelection } />
		)
	}


	render() {
		if ( !this.props.open ) return ( <div /> )

		let title = 'Clip Transfer Activity Filter'

		let location = _get( this.props.location, 'pathname', 'clip-transfer' )
		if ( location.endsWith( 'footage' ) ) {
			title = 'Partner Footage Activity Filter'
		}

		return (
			<Dialog open={ this.props.open } onClose={ this.handleClose } maxWidth={ 'md' } fullWidth={ true }>
				<DialogTitle>{ title }</DialogTitle>
				<DialogContent>
					<div style={ { display: 'flex', flexDirection: 'row', height: 300 } }>
						<div style={ { flex: 1, padding: 10, paddingTop: '40px' } }>
							{ this.renderToggleControls() }
						</div>
						<div style={ { flex: 1, padding: 10 } }>
							{ this.renderDatePicker() }
						</div>
						<div style={ { flex: 1, padding: 10 } }>
							{ this.renderGroupSelect() }
							
							<div style={ { paddingTop: '15px' } }>
								{ this.renderUserSearch() }
							</div>
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={ this.props.closeFilter } color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}


IngestActivityFilter.propTypes = {
	authorizationInfo		: PropTypes.object.isRequired,
	open					: PropTypes.bool.isRequired,
	filterSettings			: PropTypes.object.isRequired,
	filteredContributor		: PropTypes.object.isRequired,
	filteredGroup			: PropTypes.object.isRequired,
	groups					: PropTypes.array.isRequired,
	filterSettingsChanged	: PropTypes.func.isRequired,

	// the following filter changed callback is a special-cases that needs to be handled differently
	userFilterChanged		: PropTypes.func.isRequired,
	groupFilterChanged		: PropTypes.func.isRequired,

	closeFilter				: PropTypes.func.isRequired,

	// typically injected automatically by react-router, this is passed by the page component
	location 				: PropTypes.object.isRequired,
}


export default IngestActivityFilter
