import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'

import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'

import lightBlue from '@material-ui/core/colors/lightBlue'

import config from '../../lib/config'
import Analytics from '../../lib/analytics'


const styles = ( theme ) => ( {
	button: {
		color			: lightBlue[ 50 ],
		textTransform	: 'none',
		margin			: theme.spacing( 1 ),
		fontSize		: 12,
		maxHeight		: 48,
		minHeight		: 48,
		marginTop		: 0,
		marginBottom	: 0,
		borderRadius	: 0,
	},
	button_selected		: {
		backgroundColor		: '#4b80a0',
	},
} )


export class Navigation extends React.Component {
	constructor( props, context ) {
		super( props, context )

		this.state = { showAbout: false }
	}


	handleLink( path ) {
		Analytics.recordUserActivity()
		this.props.history.push( path )
	}


	handleToggleAbout( open ) {
		this.setState( { showAbout: open } )
	}


	renderButton( label, link ) {
		let className = this.props.classes.button
		if ( this.props.location.pathname === link ) className = classNames( this.props.classes.button, this.props.classes.button_selected )

		return (
			<Button className={ className } onClick={ ( e ) => { this.handleLink( link ) } }>
				{ label }
			</Button>
		)
	}


	renderPartnerActivity() {
		let route = ( <div /> )

		if ( !config.isShortstop && this.props.authorizationInfo.user.group.type !== 'international' ) {
			return this.renderButton( 'Partner Activity', '/' )
		}

		return route
	}


	renderPartnerFootage() {
		let route = ( <div /> )

		if ( this.props.authorizationInfo.user.group.type === 'partner' && !this.props.authorizationInfo.group.doesGroupContainAcl( 'api.publish.footage' ) ) return null

		if ( !config.isShortstop && this.props.authorizationInfo.user.group.type !== 'international' ) {
			return this.renderButton( 'Partner Footage', '/footage' )
		}

		return route
	}


	renderShortstop() {
		let route = ( <div /> )

		if ( config.isShortstop || ( config.environment !== 'production' && this.props.authorizationInfo.user.group.type !== 'partner' && this.props.authorizationInfo.user.group.type !== 'international' ) ) {
			return this.renderButton( 'Shortstop Activity', '/shortstop-activity' )
		}

		return route
	}


	renderClipTransfer() {
		let route = ( <div /> )

		if ( config.isShortstop || ( config.environment !== 'production' && this.props.authorizationInfo.user.group.type !== 'partner' && this.props.authorizationInfo.user.group.type !== 'international' ) ) {
			return this.renderButton( 'Clip Transfer', '/clip-transfer' )
		}

		return route
	}


	renderGroupMaintenance() {
		let route = ( <div /> )

		if ( this.props.authorizationInfo.user.group.type === 'administrator' || this.props.authorizationInfo.user.group.type === 'mx-editorial' ) {
			return this.renderButton( 'Partner Configuration', '/groups' )
		}

		return route
	}


	renderInternational() {
		let route = ( <div /> )

		if ( !config.isShortstop && ( this.props.authorizationInfo.user.group.type === 'administrator' || this.props.authorizationInfo.user.group.type === 'international' ) ) {
			return this.renderButton( 'ESPNi VOD', '/international' )
		}

		return route
	}


	renderAnalytics() {
		let route = ( <div /> )

		if ( this.props.authorizationInfo.user.group.type === 'administrator' || this.props.authorizationInfo.user.group.type === 'mx-editorial' || this.props.authorizationInfo.user.group.type === 'partner' ) {
			return this.renderButton( 'Analytics', '/analytics' )
		}

		return route
	}


	renderUploadVideo() {
		let route = ( <div /> )

		if ( !config.isShortstop && this.props.authorizationInfo.user.group.type !== 'international' ) {
			return this.renderButton( 'Upload Video', '/upload' )
		}

		return route
	}


	renderAbout() {
		return (
			<div style={ { flexGrow: 5, minHeight: 48, maxHeight: 48 } }>
				<Button className={ this.props.classes.button } style={ { float: 'right', marginRight: -1 } } color="inherit" onClick={ ( e ) => { this.handleToggleAbout( true ) } }>
					About
				</Button>
			</div>
		)
	}


	renderMenu() {
		if ( ( this.props.authorizationInfo ) && ( this.props.authorizationInfo.user ) && ( this.props.authorizationInfo.group ) ) {
			return (
				<div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' } }>
					{ this.renderUploadVideo() }
					{ this.renderPartnerActivity() }
					{ this.renderPartnerFootage() }
					{ this.renderShortstop() }
					{ this.renderClipTransfer() }
					{ this.renderGroupMaintenance() }
					{ this.renderInternational() }
					{ this.renderAnalytics() }

					{ this.renderAbout() }
				</div>
			)
		}
		else {
			return ( <div /> )
		}
	}


	renderAboutBox() {
		let title = 'ESPN Media Exchange'
		if ( config.isShortstop ) title = 'ESPN Shortstop'

		return (
			<Dialog
				fullWidth
				maxWidth="md"
				open={ this.state.showAbout }
				onClose={ ( e ) => { this.handleToggleAbout( false ) } }
			>
				<DialogTitle id="dialog-title">{ title }</DialogTitle>
				<DialogContent>
					<div>
						<div>
							<List dense>
								<ListItem><ListItemText primary="Environment" secondary={ config.environment } /></ListItem>
							</List>
							<List dense>
								<ListItem><ListItemText primary="Version" secondary={ config.appVersion } /></ListItem>
							</List>
						</div>
						<List dense>
							<ListItem><ListItemText primary="Last Commit" secondary={ `${ config.lastCommitDesc } (${ config.lastCommitSha })` } /></ListItem>
						</List>
					</div>
				</DialogContent>
				<DialogActions>
					<Button id="close-dialog" onClick={ ( e ) => { this.handleToggleAbout( false ) } } color="primary">
						OK
					</Button>
				</DialogActions>
			</Dialog>
		)
	}


	render() {
		return (
			<div>
				<Toolbar variant="dense" style={ { backgroundColor: '#6194bc', padding: 0 } }>
					{ this.renderMenu() }
				</Toolbar>

				{ this.renderAboutBox() }
			</div>
		)
	}
}


Navigation.propTypes = {
	authorizationInfo		: PropTypes.object.isRequired,

	// injected automatically by material-ui
	classes					: PropTypes.object.isRequired,

	// injected automatically by react-router
	history					: PropTypes.object.isRequired,
	location				: PropTypes.object.isRequired,
}


export default withStyles( styles, { withTheme: true } )( Navigation )
