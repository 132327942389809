require( '../styles/arrows.css' )

/**

	@function toggleColumnHeaderName

	When a user clicks on a grid column we need to *either* toggle the
	increment / decrement of the current sort (if the user clicked on the
	same column) OR change it to a new sort (incrementing sort)
*/
export function toggleColumnHeaderName( newSort, oldSort ) {
	let normalizedOldSort = oldSort
	if ( normalizedOldSort.startsWith( '-' ) ) normalizedOldSort = normalizedOldSort.substring( 1, normalizedOldSort.length )

	let normalizedNewSort = newSort
	if ( normalizedNewSort.startsWith( '-' ) ) normalizedNewSort = normalizedNewSort.substring( 1, normalizedNewSort.length )

	if ( normalizedOldSort === normalizedNewSort ) {
		if ( oldSort.startsWith( '-' ) ) {
			newSort = oldSort.substr( 1 )	// toggle it off
		}
		else {
			newSort = `-${ newSort }`
		}
	}

	return newSort
}


/**
	@function sortableClassNames

	return the proper CSS if the column is the currently sorted column, and which
	way the sort is going if it's selected
*/
export function sortableClassNames( columnName, currentSort ) {
	let output = `${ columnName } sortable`

	let normalizedSort = currentSort
	if ( normalizedSort.startsWith( '-' ) ) normalizedSort = normalizedSort.substring( 1, normalizedSort.length )

	if ( normalizedSort === columnName ) {
		output = `${ output } current`

		if ( currentSort.startsWith( '-' ) ) {
			output = `${ output } down-arrow`
		}
		else {
			output = `${ output } up-arrow`
		}
	}

	return output
}
