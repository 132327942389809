import XLSX from 'xlsx'
import _get from 'lodash/get'


export default class ExcelProcessing {

	/**
	 * @function saveWorkbook
	 * @description save shortstop specific rawData into the workbook
	 */
	static saveWorkbook( rawData, user, isShortstop ) {
		let wb = XLSX.utils.book_new()
		const MIN_COLUMN_WIDTH = 12
        
		if ( !isShortstop ) {
			// if in the future workbooks for partners is desired, process here.
			return null
		}
        
		wb.Props = {
			Title: 'Shortstop Data Workbook',
			Subject: 'Shorstop Data',
			Author: user,
			CreatedDate: new Date()
		}
        
		let arrayOfSheetData = []

		let shortstopPostMetrics = _get( rawData.lineGraphData.shortstop, 'data', null )
		this.appendSheetData( { arrayOfSheetData: arrayOfSheetData, name: 'Shortstop Post Metrics', cols: [ 'Date', 'Num. Posts' ], data: shortstopPostMetrics, desiredProps: [ 'metrics_date', 'total_posts' ] } )

		let clipTransferMetrics = _get( rawData.lineGraphData.shortstop_live, 'data', null )
		this.appendSheetData( { arrayOfSheetData: arrayOfSheetData, name: 'Clip Transfer Metrics', cols: [ 'Date', 'Num. Transfer' ], data: clipTransferMetrics, desiredProps: [ 'metrics_date', 'total_posts' ] } )

		let postMetricsData = _get( rawData.shortstop, 'data[0].post_type' )
		this.appendSheetData( { arrayOfSheetData: arrayOfSheetData, name: 'Post Metrics', cols: [ 'Post Type', 'Num. Posts' ], data: postMetricsData, header: 'Post Type', toJson: true } )

		let postMetricsLanguage = _get( rawData.shortstop, 'data[0].language' )
		this.appendSheetData( { arrayOfSheetData: arrayOfSheetData, name: 'Post Metrics', cols: [ 'Language', 'Num. Posts' ], data: postMetricsLanguage, header: 'Language', toJson: true } )

		let postMetricsPlatforms = _get( rawData.shortstop, 'data[0].platforms' )
		this.appendSheetData( { arrayOfSheetData: arrayOfSheetData, name: 'Post Metrics', cols: [ 'Platform', 'Num. Posts' ], data: postMetricsPlatforms, header: 'Platform', toJson: true } )

		let shortstopContributorMetrics = _get( rawData.shortstop, 'data[0].contributors', null )
		this.appendSheetData( { arrayOfSheetData: arrayOfSheetData, name: 'Shorstop Contributor Metrics', cols: [ 'Contributor', 'Num. Posts' ], data: shortstopContributorMetrics, desiredProps: [ 'name', 'number_posts' ] } )

		let shortstopCategoryMetrics = _get( rawData.shortstop, 'data[0].categories', null )
		this.appendSheetData( { arrayOfSheetData: arrayOfSheetData, name: 'Shortstop Category Metrics', cols: [ 'Category', 'Num. Posts' ], data: shortstopCategoryMetrics, desiredProps: [ 'name', 'number_posts' ] } )

		let clipContributorMetrics = _get( rawData.shortstop_live, 'data[0].contributors' )
		this.appendSheetData( { arrayOfSheetData: arrayOfSheetData, name: 'Clip Contributor Metrics', cols: [ 'Contributor', 'Num. Posts' ], data: clipContributorMetrics, desiredProps: [ 'name', 'number_posts' ] } )

		// for each of the parsed data above, add it to the workbook
		arrayOfSheetData.forEach( ( sheetProps ) => {
			wb = this.makeSheet( wb, sheetProps.name, sheetProps.cols, sheetProps.data, MIN_COLUMN_WIDTH, sheetProps.header )
		} )

		this.writeWorkbookToFile( wb )
	}


	/**
	 * @function writeWorkbookToFile
	 * @description download workbook as xlsx file
	 */
	static writeWorkbookToFile( wb ) {
		XLSX.writeFile( wb, `${ wb.Props.Title }.xlsx` )
	}


	/**
	 * @function appendSheetData
	 * @description save shortstop specific rawData as a binary workbook
	 *
	 * @param arrayOfSheetData array to append parsed sheet data to
	 * @param name name of sheet in workbook
	 * @param data data to add to the sheet
	 * @param cols name of column labels for sheet
	 * @param desiredProps properties to extract from each element of data array
	 * @param toJson is the data a singular json object
	 * @param header array of row header titles if desired
	 */
	static appendSheetData( { arrayOfSheetData, name, data, cols, desiredProps, toJson = false, header = false } = { } ) {
		if ( data && data.length !== 0 ) {

			if ( toJson ) {
				// if the data is a JSON object and not an array, then process the key value pairs into an array of arrays

				let jsonToArray = ( json ) => Object.keys( json ).map( key => [ key, json[key] ] )
				data = jsonToArray( data )
			}

			let parsedData = []

			if ( desiredProps ) {
				// if a set of desired props is provided, extract each from the data array

				data.forEach( ( datum ) => {
					let row = []
					desiredProps.forEach( ( prop ) => {
						row.push( datum[prop] )
					} )
					
					parsedData.push( row )
				} )
			}
			else {
				parsedData = data
			}

			arrayOfSheetData.push( { name: name, cols: cols, data: parsedData, header: header } )
		}
		else {
			arrayOfSheetData.push( { name: name, cols: [ 'no data' ], data: [ [ ] ], header: header } )
		}
	}
	
	
	/**
	 * @function makeSheet
	 * @description add a sheet to an existing workbook
	 *
	 * @param wb the workbook to append the sheet data to
	 * @param name name of sheet
	 * @param colNames name of columns for sheet
	 * @param data data to add to the sheet
	 * @param minColumnWidth minimum width of columns in the sheet
	 * @param header array of row header titles if desired
	 *
	 * @returns updated input workbook with new sheet or existing sheet appended to
	 */
	static makeSheet( wb, name, colNames, data, minColumnWidth, header ) {
		let rows = [ colNames ]
    
		data.forEach( datum => {
			rows.push( datum )
		} )
	
		// get max width of columns required for given data and update the length of columns in the sheet if needed
		let wscols = this.getMaxWidthOfColumns( data, minColumnWidth )
		wscols = wscols.map( length => { return length > minColumnWidth ? { wch: length } : { wch: minColumnWidth } } )
    
		if ( wb.Sheets && !wb.Sheets[name] ) {
			// if the sheet name does not exist, then make a new one.

			wb.SheetNames.push( name )
    
			if ( header ) {
				rows.unshift( [ header ] )
			}
    
			let ws = XLSX.utils.aoa_to_sheet( rows )
			ws['!cols'] = wscols

			wb.Sheets[name] = ws
		}
		else if ( wb.Sheets && wb.Sheets[name] ) {
			// if the sheet name already exists, append the data to the existing sheet

			let ws = wb.Sheets[name]
    
			if ( header ) {
				XLSX.utils.sheet_add_aoa( ws, [ [ '' ], [ header ] ], { origin: -1 } )
			}
    
			XLSX.utils.sheet_add_aoa( ws, rows, { origin: -1 } )

			wb.Sheets[name] = ws
		}
    
		return wb
	}


	/**
	 * @function getMaxWidthOfColumns
	 *
	 * @description get the lengths of longest string representation of data per column in a matrix
	 *
	 * @param data matrix of data
	 *
	 * @returns 1D array with max width needed per column for the input data
	 */
	static getMaxWidthOfColumns( data ) {
		let transposeAndMakeString = ( matrix ) => matrix[0].map( ( _, i ) => matrix.map( row => row[i] ).map( el => String( el ) ) )
		let maxCharsPerColumn = transposeAndMakeString( data ).map( arr => arr.map( el => el.length ).reduce( ( a, b ) => Math.max( a, b ) ) )
		
		return maxCharsPerColumn
	}
}
