import React from 'react'
const PropTypes = require( 'prop-types' )

import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import PartnerInfo from './common/partner_info'
import Navigation from './common/navigation'
import AjaxInfo from './common/AjaxInfo'

import Typography from '@material-ui/core/Typography'

import helpers from '../lib/helpers'
import FileUploadList from './file-upload/file_upload_list'
import { removeUploads } from '../actions/uploadActions'

import AppNotification from './common/app_notification'
import { hideNotification } from '../actions/appNotificationActions'


export class App extends React.Component {
	constructor( props, context ) {
		super( props, context )

		this.timeoutID = null

		let formData = new FormData()
		window.formData = formData
	}


	isAdminRoute() {
		if ( this.props.location.pathname === '/' ) return false
		if ( this.props.location.pathname.indexOf( '/upload' ) > -1 ) return false
		if ( this.props.location.pathname.indexOf( '/activity' ) > -1 ) return false
		if ( this.props.location.pathname.indexOf( '/footage' ) > -1 ) return false
		if ( this.props.location.pathname.indexOf( '/international' ) > -1 ) return false
		if ( this.props.location.pathname.indexOf( 'metadata' ) > -1 ) return false
		if ( this.props.location.pathname.indexOf( '/analytics' ) > -1 ) return false

		return true
	}


	updateUploadFileList() {
		this.props.dispatch( removeUploads() )
	}


	renderProgress() {
		if ( this.props.ajaxCallsInProgress ) {
			if ( this.props.ajaxCallsInProgress.length > 0 ) {
				return (
					<div className="loader loader--active">
						<div className="loader-circle"></div>
						<div className="loader-line-mask">
							<div className="loader-line"></div>
						</div>
					</div>
				)
			}
			else {
				return (
					<div className="loader">
						<div className="loader-circle"></div>
						<div className="loader-line-mask">
							<div className="loader-line"></div>
						</div>
					</div>
				)
			}
		}
	}


	renderChildren() {
		if ( helpers.doesNotExist( this.props.authorizationInfo.user ) ) return this.renderWelcomeText()

		if ( !this.props.authorizationInfo.user.isInternal ) {
			if ( this.props.authorizationInfo.user.group.type === 'international' ) {
				if ( this.props.location.pathname === '/upload' || this.props.location.pathname === '/activity' ) {
					return this.props.history.push( '/international' )
				}
			}
			if ( this.isAdminRoute() ) {
				if ( this.props.authorizationInfo.user.group.type === 'international' ) {
					return this.props.history.push( '/international' )
				}

				return this.props.history.push( '/' )
			}
		}

		let authorizationInfo = this.props.authorizationInfo
		let dispatch = this.props.dispatch
		let fileList = this.props.uploadFileList

		return (
			<div>
				{
					this.props.uploadFileList.length > 0 ? <FileUploadList authorizationInfo={ authorizationInfo } dispatch={ dispatch } uploadFileList={ fileList }/> : ''
				}
				{ this.props.children }
			</div>
		)
	}


	renderWelcomeText() {
		return (
			<div style={ { padding: 20 } }>
				<Typography variant="h5">Welcome to the ESPN Partner Publishing Tool</Typography>
				<ul style={ { listStyleType: 'none', paddingLeft: '0px' } }>
					<li style={ { marginBottom: '10px' } }>Registered users: login using the link in the top right of the screen.</li>
					<li>Unregistered users: If you are in the ESPN partner publishing program but have not yet been granted access, contact your ESPN business partner to request access.</li>
				</ul>
			</div>
		)
	}


	render() {
		return (
			<div>
				<PartnerInfo
					authorizationInfo={ this.props.authorizationInfo }
					ajaxCallsInProgress={ this.props.ajaxCallsInProgress }
					history={ this.props.history }
					loggedOutCallback={ this.updateUploadFileList.bind( this ) }
					dispatch={ this.props.dispatch }
				/>

				<Navigation
					authorizationInfo={ this.props.authorizationInfo }
					history={ this.props.history }
					location={ this.props.location }
				/>

				{ this.renderChildren() }

				{ this.renderProgress() }
				<AjaxInfo />

				<AppNotification
					variant={ this.props.appNotification.variant }
					message={ this.props.appNotification.message }
					title={ this.props.appNotification.title }
					open={ this.props.appNotification.open }
					notificationDismissed={
						() => this.props.dispatch( hideNotification(
							this.props.appNotification.message,
							this.props.appNotification.title,
							this.props.appNotification.variant,
							this.props.appNotification.additionalActionCallback
						) )
					}
				/>
			</div>
		)
	}
}


function mapStateToProps( state, ownProps ) {
	return {
		authorizationInfo	: state.authorizationInfo,
		uploadFileList		: state.uploadFileList,
		ajaxCallsInProgress	: state.ajaxCallsInProgress,
		appNotification		: state.appNotification
	}
}


App.propTypes = {
	authorizationInfo	: PropTypes.object.isRequired,
	ajaxCallsInProgress	: PropTypes.array.isRequired,
	appNotification		: PropTypes.object.isRequired,
	children			: PropTypes.object.isRequired,
	uploadFileList		: PropTypes.array,

	// injected automatically by react-router
	history				: PropTypes.object.isRequired,
	location			: PropTypes.object.isRequired,
}


export default connect( mapStateToProps )( withRouter( App, { withRef: true } ) )
