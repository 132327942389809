import React from 'react'
import PropTypes from 'prop-types'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'

import AddIcon from '@material-ui/icons/Add'
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import BackIcon from '@material-ui/icons/ChevronLeft'

import helpers from '../../lib/helpers'


const COLUMNS = [
	{ id: 'username', width: '25%', numeric: false, padding: 'dense', sortable: true, label: 'Username' },
	{ id: 'name', width: '25%', numeric: false, padding: 'dense', sortable: true, label: 'Name, Title' },
	{ id: 'state', width: '10%', numeric: false, padding: 'dense', sortable: false, label: 'State' },
	{ id: 'actions', width: '40%', numeric: false, padding: 'dense', sortable: false, label: '' },
]


class UsersGrid extends React.Component {
	columnHeaderSelected( e, column ) {
		if ( !column.sortable ) return

		// default the new sort to the clicked column DESC
		let sortColumn = `-${ column.id }`

		if ( this.props.currentSort.includes( column.id ) && this.props.currentSort.startsWith( '-' ) ) {
			// set the sort order to ASC only if the user has clicked on the same column and the current sort order is DESC
			sortColumn = column.id
		}

		this.props.sortCallback( sortColumn )
	}


	getActionSeparator() {
		return ' | '
	}


	userSelected( user ) {
		this.props.displayDetailsCallback( user )
	}


	getCurrentGroup() {
		return this.props.group
	}


	getAddUserText() {
		let addUserText = 'Add New User'

		let group = this.getCurrentGroup()
		if ( helpers.doesExist( group ) ) {
			addUserText = `Add New User to ${ this.props.group.name }`
		}

		return addUserText
	}


	renderEditAction( user, currentUserId ) {
		let disabled = false
		if ( user._id === currentUserId ) disabled = true

		return <IconButton style={ { padding: 5 } } onClick={ ( e ) => { this.userSelected( user ) } } disabled={ disabled }><Edit fontSize="small" /></IconButton>
	}


	renderDeleteAction( user, userIndex, currentUserId ) {
		let disabled = false
		if ( user._id === currentUserId ) disabled = true

		return <IconButton style={ { padding: 5 } } onClick={ ( e ) => { this.props.handleDeleteUser( user, userIndex ) } } disabled={ disabled }><Delete fontSize="small" /></IconButton>
	}


	renderTableToolbar( filterSearchText ) {
		let tooltipText = this.getAddUserText()

		if ( this.props.group === null ) return ( <Toolbar /> )

		return (
			<AppBar position="static" color="default" style={ { boxShadow: 'none' } }>
				<Toolbar>
					<Tooltip title={ 'Go Back' } style={ { marginRight: 20 } } enterDelay={ 500 } leaveDelay={ 200 }>
						<IconButton edge="start" onClick={ () => this.props.history.goBack() }>
							<BackIcon />
						</IconButton>
					</Tooltip>

					<Typography variant="h6" style={ { width: '50%' } }>{ this.props.group.name } Users</Typography>

					<TextField
						id="contact-name-filter"
						placeholder="Filter Users by Name"
						style={ { marginLeft: 20, width: '33%' } }
						value={ filterSearchText }
						onChange={ this.props.filterNameChanged }
						margin="normal"
					/>

					<div style={ { marginLeft: 'auto' } }>
						<Tooltip title={ tooltipText } style={ { marginRight: 20 } } enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton onClick={ this.props.handleNewUser }>
								<AddIcon />
							</IconButton>
						</Tooltip>
					</div>
				</Toolbar>
			</AppBar>
		)
	}


	renderTablePagination( total, currentPage, onPageChanged ) {
		if ( total === 0 ) return null
		
		return (
			<TablePagination
				rowsPerPageOptions={ [] }
				component="div"
				count={ total }
				rowsPerPage={ 10 }
				page={ currentPage }
				backIconButtonProps={ {
					'aria-label': 'Previous Page',
				} }
				nextIconButtonProps={ {
					'aria-label': 'Next Page',
				} }
				onChangePage={ ( e, pageNumber ) => { if ( !e ) return; onPageChanged( pageNumber ) } }
			/>
		)
	}


	render() {
		return (
			<Paper elevation={ 2 }>
				{ this.renderTableToolbar( this.props.filterSearchText ) }

				<Table size="small">
					<TableHead>
						<TableRow>
							{ COLUMNS.map( ( column ) => {
								let header = ( <div>{ column.label }</div> )
								if ( column.sortable ) {
									header = (
										<TableSortLabel
											active={ this.props.currentSort.includes( column.id ) }
											direction={ this.props.currentSort.includes( '-' ) ? 'desc' : 'asc' }
											onClick={ ( e ) => { this.columnHeaderSelected( e, column ) } }
										>
											{ column.label }
										</TableSortLabel>
									)
								}

								return (
									<TableCell
										key={ column.id }
										align={ column.numeric ? 'right' : 'left' }
										style={ { width: column.width } }
									>
										{ header }
									</TableCell>
								)
							} ) }
						</TableRow>
					</TableHead>

					<TableBody>
						{ this.props.users.map( ( user, idx ) => {
							return (
								<TableRow key={ `user-${ user._id }` } onClick={ ( e ) => { this.userSelected( user ) } } >
									<TableCell>{ user.username }</TableCell>
									<TableCell>
										<div>
											{ user.contact.name }
											<br />
											<small>{ user.contact.job_title }</small>
										</div>
									</TableCell>
									<TableCell>{ user.state }</TableCell>
									<TableCell align="right">
										{ this.renderEditAction( user, this.props.authorizationInfo.user._id ) }
										{ this.getActionSeparator() }
										{ this.renderDeleteAction( user, idx, this.props.authorizationInfo.user._id ) }
									</TableCell>
								</TableRow>
							)
						} ) }
					</TableBody>
				</Table>

				{ this.renderTablePagination( this.props.totalUsers, this.props.currentPage, this.props.onPageChanged ) }
			</Paper>
		)
	}
}


UsersGrid.propTypes = {
	authorizationInfo		: PropTypes.object.isRequired,
	group					: PropTypes.object,
	users					: PropTypes.array.isRequired,
	totalUsers				: PropTypes.number.isRequired,
	currentSort				: PropTypes.string.isRequired,
	currentPage				: PropTypes.number.isRequired,
	onPageChanged			: PropTypes.func.isRequired,
	filterNameChanged		: PropTypes.func.isRequired,
	sortCallback			: PropTypes.func.isRequired,
	displayDetailsCallback	: PropTypes.func.isRequired,
	filterSearchText		: PropTypes.string.isRequired,
	handleNewUser			: PropTypes.func.isRequired,
	handleDeleteUser		: PropTypes.func.isRequired,
	history					: PropTypes.object.isRequired
}


export default UsersGrid
