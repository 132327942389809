import _cloneDeep from 'lodash/cloneDeep'

const types = require( '../actions/actionTypes' )
const initialState = require( './initialState' )


export default function loginErrorReducer( state = initialState.authorizationInfo, action ) {
	if ( action.type === types.AUTHORIZATION_LOGIN ) {
		return { user: _cloneDeep( action.user ), group: _cloneDeep( action.group ) }
	}
	else if ( action.type === types.AUTHORIZATION_LOGOUT ) {
		return initialState.authorizationInfo
	}

	return state
}
