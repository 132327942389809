import _get from 'lodash/get'

import React from 'react'
import PropTypes from 'prop-types'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import AddIcon from '@material-ui/icons/Add'
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import People from '@material-ui/icons/People'
import Settings from '@material-ui/icons/Settings'

import Analytics from '../../lib/analytics'

import Group from '../../models/group'
import TextField from '@material-ui/core/TextField'


const COLUMNS = [
	{ id: 'logo', width: '15%', numeric: false, padding: 'dense', sortable: false, label: '' },
	{ id: 'name', width: '30%', numeric: false, padding: 'dense', sortable: true, label: 'Name' },
	{ id: 'type', width: '20%', numeric: false, padding: 'dense', sortable: true, label: 'Type' },
	{ id: 'parent', width: '15%', numeric: false, padding: 'dense', sortable: false, label: 'Parent' },
	{ id: 'actions', width: '20%', numeric: false, padding: 'dense', sortable: false, label: '' },
]


class GroupsGrid extends React.Component {
	handleLink( path ) {
		Analytics.recordUserActivity()
		this.props.history.push( path )
	}


	getActionSeparator() {
		return ' | '
	}


	renderEditAction( group, currentGroupId ) {
		let disabled = false
		if ( group._id === currentGroupId ) disabled = true

		return <IconButton style={ { padding: 5 } } onClick={ ( e ) => { this.props.handleEditGroup( group ) } } disabled={ disabled }><Edit fontSize="small" /></IconButton>
	}


	renderDeleteAction( group, groupIndex, currentGroupId ) {
		let disabled = false
		if ( group._id === currentGroupId ) disabled = true

		return <IconButton style={ { padding: 5 } } onClick={ ( e ) => { this.props.handleDeleteGroup( group, groupIndex ) } } disabled={ disabled }><Delete fontSize="small" /></IconButton>
	}


	renderUsersAction( group ) {
		return <IconButton style={ { padding: 5 } } onClick={ this.handleLink.bind( this, `/groups/${ group._id }/users` ) }><People fontSize="small" /></IconButton>
	}


	renderConfigAction( group ) {
		return <IconButton style={ { padding: 5 } } onClick={ this.handleLink.bind( this, `/groups/${ group._id }/configuration` ) }><Settings fontSize="small" /></IconButton>
	}


	handleColumnHeaderSelected( e, column ) {
		if ( !column.sortable ) return

		// default the new sort to the clicked column DESC
		let sortColumn = `-${ column.id }`

		if ( this.props.currentSort.includes( column.id ) && this.props.currentSort.startsWith( '-' ) ) {
			// set the sort order to ASC only if the user has clicked on the same column and the current sort order is DESC
			sortColumn = column.id
		}

		this.props.onSortChanged( sortColumn )
	}


	renderTableToolbar( filterSearchText ) {
		return (
			<AppBar position="static" color="default" style={ { boxShadow: 'none' } }>
				<Toolbar>
					<div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' } }>
						<FormControl style={ { width: '20%' } }>
							<InputLabel>Parent Filter</InputLabel>
							<Select
								value={ this.props.parentGroup || '-' }
								onChange={ this.props.handleParentGroupSelect }
							>
								<MenuItem value="-">NONE</MenuItem>
								{ this.props.parentGroups.map( ( parent ) => {
									return ( <MenuItem key={ `parent-${ parent.group_id }` } value={ parent.group_id }>{ parent.name }</MenuItem> )
								} ) }
							</Select>
						</FormControl>
						<TextField
							id="group-name-filter"
							placeholder="Filter Groups by Name"
							style={ { marginLeft: 120, width: '40%' } }
							value={ filterSearchText }
							onChange={ this.props.filterNameChanged }
							margin="normal"
						/>
						<div style={ { flex: '1 1 100%' } } />

						<Tooltip title="Add New Group" enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton onClick={ this.props.handleNewGroup }>
								<AddIcon />
							</IconButton>
						</Tooltip>
					</div>
				</Toolbar>
			</AppBar>
		)
	}


	renderTablePagination( total, currentPage, onPageChanged ) {
		if ( total === 0 ) return null
		
		return (
			<TablePagination
				rowsPerPageOptions={ [] }
				component="div"
				count={ total }
				rowsPerPage={ 10 }
				page={ currentPage }
				backIconButtonProps={ {
					'aria-label': 'Previous Page',
				} }
				nextIconButtonProps={ {
					'aria-label': 'Next Page',
				} }
				onChangePage={ ( e, pageNumber ) => { if ( !e ) return; onPageChanged( pageNumber ) } }
			/>
		)
	}


	render() {
		return (
			<Paper elevation={ 2 }>
				{ this.renderTableToolbar( this.props.filterSearchText ) }
				<Table size="small">
					<TableHead>
						<TableRow>
							{ COLUMNS.map( ( column ) => {
								let header = ( <div>{ column.label }</div> )
								if ( column.sortable ) {
									header = (
										<Tooltip
											title="Sort"
											placement={ column.numeric ? 'bottom-end' : 'bottom-start' }
											enterDelay={ 500 } leaveDelay={ 200 }
										>
											<TableSortLabel
												active={ this.props.currentSort.includes( column.id ) }
												direction={ this.props.currentSort.includes( '-' ) ? 'desc' : 'asc' }
												onClick={ ( e ) => { this.handleColumnHeaderSelected( e, column ) } }
											>
												{ column.label }
											</TableSortLabel>
										</Tooltip>
									)
								}

								return (
									<TableCell
										key={ column.id }
										align={ column.numeric ? 'right' : 'left' }
										style={ { width: column.width } }
									>
										{ header }
									</TableCell>
								)
							} ) }
						</TableRow>
					</TableHead>

					<TableBody>
						{ this.props.groups.data.map( ( group, index ) => {
							let parentName = _get( group, 'parent.name', '' )

							return (
								<TableRow key={ group._id } onClick={ ( e ) => { this.props.handleEditGroup( group ) } }>
									<TableCell><img src={ group.logo_url } style={ { height: 30 } } /></TableCell>
									<TableCell>{ group.name }</TableCell>
									<TableCell>{ Group.GROUP_TYPES.find( ( t ) => { return t.key === group.type } ).displayValue }</TableCell>
									<TableCell>{ parentName }</TableCell>
									<TableCell style={ { padding: 0 } }>
										{ this.renderEditAction( group, this.props.currentUserGroup.group_id ) }
										{ this.getActionSeparator() }
										{ this.renderConfigAction( group, this.props.currentUserGroup.group_id ) }
										{ this.getActionSeparator() }
										{ this.renderUsersAction( group ) }
										{ this.getActionSeparator() }
										{ this.renderDeleteAction( group, index, this.props.currentUserGroup.group_id ) }
									</TableCell>
								</TableRow>
							)
						} ) }
					</TableBody>
				</Table>

				{ this.renderTablePagination( this.props.groups.total, this.props.currentPage, this.props.onPageChanged ) }
			</Paper>
		)
	}
}


GroupsGrid.propTypes = {
	groups					: PropTypes.object.isRequired,
	parentGroups			: PropTypes.array.isRequired,
	currentSort				: PropTypes.string.isRequired,
	currentPage				: PropTypes.number.isRequired,
	currentUserGroup		: PropTypes.object.isRequired,
	parentGroup				: PropTypes.string,
	handleEditGroup			: PropTypes.func.isRequired,
	handleDeleteGroup		: PropTypes.func.isRequired,
	onSortChanged			: PropTypes.func.isRequired,
	onPageChanged			: PropTypes.func.isRequired,
	handleParentGroupSelect	: PropTypes.func.isRequired,
	handleNewGroup			: PropTypes.func.isRequired,
	filterSearchText		: PropTypes.string.isRequired,
	filterNameChanged		: PropTypes.func.isRequired,
	history					: PropTypes.object.isRequired
}


export default GroupsGrid
