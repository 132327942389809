import _clone from 'lodash/clone'

import React from 'react'
import PropTypes from 'prop-types'

import * as Redux from 'react-redux'
import * as QS from 'query-string'

import { withRouter } from 'react-router'

import Analytics from '../../lib/analytics'
import helpers from '../../lib/helpers'

import Api from '../../dataSource/api'
import { callApi, reportApiError } from '../../actions/apiCallActions'

import User from '../../models/user'

import UsersGrid from './users_grid'
import UserDetail from './user_detail'

import DeleteConfirmation from '../common/delete_confirmation'


export class UsersPage extends React.Component {
	constructor( props ) {
		super( props )

		this.pageSize = 10

		this.state = {
			group						: null,
			usersOnCurrentPage			: [],
			totalUsers					: 0,
			selectedUser				: null,
			hideSpinner					: false,
			query						: this.setStateFromLocation( this.props.location.search ),
		}

		this.userSelectedForEditing = this.userSelectedForEditing.bind( this )

		this.updateNameFilter = this.updateNameFilter.bind( this )

		this.handleNewUser = this.handleNewUser.bind( this )

		this.handleDeleteUser = this.handleDeleteUser.bind( this )
		this.deleteUser = this.deleteUser.bind( this )

		this.userSelectedForEditing = this.userSelectedForEditing.bind( this )
		this.userSelectedForDeleting = this.userSelectedForDeleting.bind( this )
		this.handleSort = this.handleSort.bind( this )

		this.handlePageSelected = this.handlePageSelected.bind( this )

		this.cancelEditing = this.cancelEditing.bind( this )
		this.saveUser = this.saveUser.bind( this )
	}


	componentDidMount() {
		this.reloadData( this.state.query.currentPage )
	}


	setStateFromLocation( queryString ) {
		let newState = {
			currentPage		: 0,
			sort			: 'name',
			nameSearchText	: ''
		}

		let parsedQuery = QS.parse( queryString )

		if ( helpers.isInteger( parsedQuery.currentPage ) ) {
			newState.currentPage = ( parseInt( parsedQuery.currentPage ) - 1 )
		}

		if ( helpers.doesExist( parsedQuery.sort ) ) {
			newState.sort = parsedQuery.sort
		}

		if ( helpers.doesExist( parsedQuery.nameSearchText ) ) {
			newState.nameSearchText = parsedQuery.nameSearchText
		}

		return newState
	}


	setLocationFromState( queryState ) {
		let newQuery = {}
		let newLocationHasQuery = false

		if ( helpers.isInteger( queryState.currentPage ) && parseInt( queryState.currentPage ) > 0 ) {
			newQuery.currentPage = queryState.currentPage + 1
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( queryState.sort ) && queryState.sort !== 'name' ) {
			newQuery.sort = queryState.sort
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( queryState.nameSearchText ) && queryState.nameSearchText !== '' ) {
			newQuery.nameSearchText = queryState.nameSearchText
			newLocationHasQuery = true
		}

		let newLocation = `/groups/${ this.props.match.params.group_id }/users`
		if ( newLocationHasQuery ) newLocation = `${ newLocation }?${ QS.stringify( newQuery ) }`

		this.props.history.replace( newLocation )
		this.reloadData( this.state.query.currentPage )
	}


	handlePageSelected( newPage ) {
		let newQuery = _clone( this.state.query )
		newQuery.currentPage = parseInt( newPage )

		this.setState( { query: newQuery }, () => { this.setLocationFromState( this.state.query ) } )
	}


	reloadData( page ) {
		let params = { limit: this.pageSize, offset: page * this.pageSize, sort: this.state.query.sort }

		if ( helpers.doesExist( this.props.match.params.group_id ) ) {
			params.group_id = this.props.match.params.group_id
		}

		if ( this.state.query.nameSearchText !== '' ) {
			params.name = this.state.query.nameSearchText
		}

		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, params ) => {
						return Api.searchGroupUsers( currentUser, params )
					} )( this.props.authorizationInfo.user, params )
				},
				this.handleUserSearch.bind( this ),
				{ hideSpinner: this.state.hideSpinner }
			)
		)

		if ( helpers.doesExist( this.props.match.params.group_id ) && ( helpers.doesNotExist( this.state.group ) || ( this.state.group._id !== this.props.match.params.group_id ) ) ) {
			this.props.dispatch(
				callApi(
					() => {
						return ( ( currentUser, groupId ) => {
							return Api.getGroup( currentUser, groupId )
						} )( this.props.authorizationInfo.user, this.props.match.params.group_id )
					},
					( err, group ) => {
						if ( helpers.doesExist( err ) ) return this.props.dispatch( reportApiError( err ) )

						this.setState( { group: group } )
					}
				)
			)
		}
	}


	handleUserSearch( err, res ) {
		if ( helpers.doesExist( err ) ) return this.props.dispatch( reportApiError( err ) )

		this.setState( { usersOnCurrentPage: res.data, totalUsers: res.total, hideSpinner: false } )
	}


	getCurrentGroup() {
		return this.state.group
	}


	userSelectedForEditing( user ) {
		Analytics.recordUserActivity()

		this.setState( { selectedUser: user } )
	}


	userSelectedForDeleting( user, userIndex ) {
		Analytics.recordUserActivity()

		this.cancelEditing()
		this.deleteDialog.show( user, userIndex, user.contact.name, 'media and partner requests' )
	}


	handleNewUser( user ) {
		this.userSelectedForEditing( new User( { group: this.getCurrentGroup().toSnapshot() } ) )
	}


	handleSort( sortColumn ) {
		Analytics.recordUserActivity()

		let newQuery = _clone( this.state.query )

		newQuery.currentPage = 0
		newQuery.sort = sortColumn

		this.setState( { query: newQuery }, () => { this.setLocationFromState( this.state.query ) } )
	}


	cancelEditing() {
		this.setState( { selectedUser: null } )
	}


	saveUser( user ) {
		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, user ) => {
						return Api.saveUser( currentUser, user )
					} )( this.props.authorizationInfo.user, user )
				},
				this.userActionCallback.bind( this )
			)
		)
	}


	handleDeleteUser( user, userIndex ) {
		Analytics.recordUserActivity()

		this.cancelEditing()
		this.deleteDialog.show( user, userIndex, user.contact.name, 'users, configuration and lookups, as well as all media and partner requests submitted by that group\'s users.' )
	}


	deleteUser( user, userIndex ) {
		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, userId ) => {
						return Api.deleteUser( currentUser, userId )
					} )( this.props.authorizationInfo.user, user._id )
				},
				this.userActionCallback.bind( this )
			)
		)
	}


	userActionCallback( err, res ) {
		if ( helpers.doesExist( err ) ) return this.props.dispatch( reportApiError( err ) )

		this.reloadData( this.state.query.currentPage )
		this.setState( { selectedUser: null } )
	}


	updateNameFilter( event ) {
		let newQuery = _clone( this.state.query )

		newQuery.currentPage = 0
		newQuery.nameSearchText = event.target.value

		this.setState( { query: newQuery, hideSpinner: true }, () => { this.setLocationFromState( this.state.query ) } )
	}


	render() {
		return (
			<div>
				<DeleteConfirmation ref={ ( deleteDialog ) => { this.deleteDialog = deleteDialog } } deleteCallback={ this.deleteUser } />
				<div style={ { marginTop: 10 } }>
					<div style={ { display: 'flex', flexDirection: 'row', marginLeft: 10 } }>
						<div style={ { flex: 2 } }>
							<UsersGrid
								authorizationInfo={ this.props.authorizationInfo }
								group={ this.state.group }
								groupId={ this.props.match.params.group_id }
								users={ this.state.usersOnCurrentPage }
								currentUser={ this.props.authorizationInfo.user }
								sortCallback={ this.handleSort }
								displayDetailsCallback={ this.userSelectedForEditing }
								currentSort={ this.state.query.sort }
								currentPage={ this.state.query.currentPage }
								totalUsers={ this.state.totalUsers }
								offsetResult={ this.state.query.currentPage * this.pageSize }
								onPageChanged={ this.handlePageSelected }
								filterNameChanged={ this.updateNameFilter }
								filterSearchText={ this.state.query.nameSearchText }
								handleNewUser={ this.handleNewUser }
								handleDeleteUser={ this.handleDeleteUser }
								history={ this.props.history }
							/>
						</div>

						<div style={ { flex: 1, marginLeft: 15, marginRight: 10, marginTop: 1 } }>
							<UserDetail
								authorizationInfo={ this.props.authorizationInfo }
								user={ this.state.selectedUser }
								cancelCallback={ this.cancelEditing }
								saveCallback={ this.saveUser }
							/>
						</div>
					</div>
				</div>
			</div>

		)
	}
}


function mapStateToProps( state, ownProps ) {
	return {
		authorizationInfo	: state.authorizationInfo
	}
}


UsersPage.propTypes = {
	authorizationInfo	: PropTypes.object.isRequired,

	// injected automatically by react-router
	history				: PropTypes.object.isRequired,
	location			: PropTypes.object.isRequired,
	match				: PropTypes.object.isRequired
}


export default withRouter( Redux.connect( mapStateToProps )( UsersPage ) )
