import moment from 'moment'

const URL = require( 'url-parse' )


export default class Helpers {
	static isTrue( value ) {
		if ( value === true ) {
			return true
		}

		if ( value === 'true' ) {
			return true
		}

		if ( value === 1 ) {
			return true
		}

		if ( value === '1' ) {
			return true
		}

		if ( value === 'on' ) {
			return true
		}

		if ( value === 'yes' ) {
			return true
		}

		return false
	}


	static isInteger( value ) {
		// leave the comparison operator in the second clause as "==", we're taking advantage of the type coercion to detect strings that contain integer values
		return !isNaN( value ) && parseInt( Number( value ) ) == value && !isNaN( parseInt( value, 10 ) )
	}


	static doesExist( value ) {
		return ( value !== null && value !== undefined )
	}


	static doesNotExist( value ) {
		return Helpers.doesExist( value ) === false
	}


	static formatDateTime( timestampString, formatString ) {
		if ( Helpers.doesNotExist( timestampString ) ) return null

		let format = formatString
		if ( Helpers.doesNotExist( formatString ) ) format = moment.ISO_8601

		let dateTime = moment( timestampString, format )
		if ( !dateTime.isValid() ) return null

		return dateTime.format( 'YYYY-MM-DD HH:mm:ss' )
	}


	static formatTime( timestampString ) {
		if ( Helpers.doesNotExist( timestampString ) ) return null
		let formattedString = moment.utc( timestampString ).format( 'HH:mm:ss' )

		return formattedString
	}


	static formatBoolean( bool ) {
		if ( bool ) {
			return 'Yes'
		}
		else {
			return 'No'
		}
	}


	static formatString( obj, defaultStr = '' ) {
		if ( Helpers.doesExist( obj ) ) {
			return obj
		}
		else {
			return defaultStr
		}
	}


	static getCombinerUrl( sourceImageUrl, size ) {
		let sourceUrl = new URL( sourceImageUrl )
		let sourcePath = sourceUrl.pathname

		return `https://secure.espncdn.com/combiner/i?img=${ sourcePath }&w=${ size }&height=${ size }&scale=crop`
	}

	static getSecureCDNLink( httpUrl ) {
		return httpUrl.replace( 'http://a.espncdn.com', 'https://secure.espncdn.com' )
	}
}
