import _clone from 'lodash/clone'
import _cloneDeep from 'lodash/cloneDeep'

import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { showNotification } from '../../actions/appNotificationActions'

import * as QS from 'query-string'

import Analytics from '../../lib/analytics'
import helpers from '../../lib/helpers'

import API from '../../dataSource/api'
import { callApi, reportApiError } from '../../actions/apiCallActions'

import ShortstopGrid from './shortstop_grid'
import ShortstopActivityFilter from './shortstop_activity_filter'
import ShortstopDetail from './shortstop_detail'


// The following constants are used to track the loading state of the previously selected user & category filter values.
// In order to properly initialize the typeaheads, we can't render them until after the initial value has been retrieved
// from the server.  We have to keep track of where we are in this process so that we render the typeaheads either:
//
//	1. immediately if no filter value is set
//	2. after either (or both) documents have been retrieved (if either or both are set)
//	3. after either (or both) retrievals errors out
//
// This process applies to the situation when the user has selected a user / category to filter against and then refreshes
// the screen.

const DOCUMENT_LOADING = 'loading'
const DOCUMENT_LOADED = 'loaded'
const DOCUMENT_NOT_APPLICABLE = 'not-applicable'


export class ShortstopPage extends React.Component {
	constructor( props ) {
		super( props )

		this.pageSize = 10

		this.state = {
			activity				: { data: [], total: 0 },
			selectedActivity		: null,
			selectedWorkItem		: null,
			filtered_contributor	: { status: DOCUMENT_NOT_APPLICABLE, document: null },
			filtered_category		: { status: DOCUMENT_NOT_APPLICABLE, document: null },
			query					: this.setStateFromLocation( this.props.location.search ),
			show_filter_dialog		: false,
		}

		this.filterSettingsChanged = this.filterSettingsChanged.bind( this )
		this.userFilterChanged = this.userFilterChanged.bind( this )
		this.categoryFilterChanged = this.categoryFilterChanged.bind( this )
		this.closeFilter = this.closeFilter.bind( this )

		this.handleRefresh = this.handleRefresh.bind( this )
		this.handleShowFilter = this.handleShowFilter.bind( this )

		this.handlePostSelected = this.handlePostSelected.bind( this )
		this.handleSort = this.handleSort.bind( this )
		this.handlePageSelected = this.handlePageSelected.bind( this )

		this.deletePost = this.deletePost.bind( this )
		this.editPost = this.editPost.bind( this )
		this.publishTest = this.publishTest.bind( this )
	}


	async componentDidMount() {
		try {
			// load the user to get the name so we can set the contributor search text
			if ( helpers.doesExist( this.state.query.contributor_id ) ) await this.loadContributor( this.state.query.contributor_id )

			// load the previously selected filtered category
			if ( helpers.doesExist( this.state.query.category_uuid ) ) await this.loadFilteredCategory( this.state.query.category_uuid )

			await this.loadActivity( this.state.query.currentPage )
		}
		catch ( e ) {
			this.props.dispatch( reportApiError( e ) )
		}
	}


	setStateFromLocation( queryString ) {
		let newState = {
			currentPage		: 0,
			sort			: '-created',
			postDate		: null,
			showTests		: false,
			showInProgress	: true,
			showPublished	: true,
			eventsOnly		: false,
			showUnpublished	: false,
			contributor_id	: null,
			category_uuid	: null
		}

		let parsedQuery = QS.parse( queryString )

		if ( helpers.isInteger( parsedQuery.currentPage ) ) {
			newState.currentPage = ( parseInt( parsedQuery.currentPage ) - 1 )
		}

		if ( helpers.doesExist( parsedQuery.sort ) ) {
			newState.sort = parsedQuery.sort
		}

		if ( helpers.doesExist( parsedQuery.postDate ) ) {
			newState.postDate = new Date( `${ parsedQuery.postDate }T00:00:00` )
		}

		if ( helpers.doesExist( parsedQuery.showTests ) ) {
			newState.showTests = helpers.isTrue( parsedQuery.showTests )
		}

		if ( helpers.doesExist( parsedQuery.showInProgress ) ) {
			newState.showInProgress = helpers.isTrue( parsedQuery.showInProgress )
		}

		if ( helpers.doesExist( parsedQuery.showPublished ) ) {
			newState.showPublished = helpers.isTrue( parsedQuery.showPublished )
		}

		if ( helpers.doesExist( parsedQuery.showUnpublished ) ) {
			newState.showUnpublished = helpers.isTrue( parsedQuery.showUnpublished )
		}

		if ( helpers.doesExist( parsedQuery.eventsOnly ) ) {
			newState.eventsOnly = helpers.isTrue( parsedQuery.eventsOnly )
		}

		if ( helpers.doesExist( parsedQuery.contributor_id ) ) {
			newState.contributor_id = parsedQuery.contributor_id
		}

		if ( helpers.doesExist( parsedQuery.category_uuid ) ) {
			newState.category_uuid = parsedQuery.category_uuid
		}

		return newState
	}


	setLocationFromState( state ) {
		let queryState = state.query

		let newQuery = {}
		let newLocationHasQuery = false

		if ( helpers.isInteger( queryState.currentPage ) && parseInt( queryState.currentPage ) > 0 ) {
			newQuery.currentPage = queryState.currentPage + 1
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( queryState.sort ) && queryState.sort !== '-created' ) {
			newQuery.sort = queryState.sort
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( queryState.postDate ) ) {
			newQuery.postDate = moment( queryState.postDate ).format( 'YYYY-MM-DD' )
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( queryState.showTests ) && helpers.isTrue( queryState.showTests ) ) {
			newQuery.showTests = queryState.showTests
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( queryState.showInProgress ) && !helpers.isTrue( queryState.showInProgress ) ) {
			newQuery.showInProgress = queryState.showInProgress
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( queryState.showPublished ) && !helpers.isTrue( queryState.showPublished ) ) {
			newQuery.showPublished = queryState.showPublished
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( queryState.showUnpublished ) && helpers.isTrue( queryState.showUnpublished ) ) {
			newQuery.showUnpublished = queryState.showUnpublished
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( queryState.eventsOnly ) && helpers.isTrue( queryState.eventsOnly ) ) {
			newQuery.eventsOnly = queryState.eventsOnly
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( state.filtered_contributor.document ) ) {
			newQuery.contributor_id = state.filtered_contributor.document._id
			newLocationHasQuery = true
		}

		if ( helpers.doesExist( state.filtered_category.document ) ) {
			newQuery.category_uuid = state.filtered_category.document.uuid
			newLocationHasQuery = true
		}

		let newLocation = '/shortstop-activity'
		if ( newLocationHasQuery ) newLocation = `${ newLocation }?${ QS.stringify( newQuery ) }`

		this.props.history.replace( newLocation )
	}


	loadContributor( contributorId ) {
		return new Promise( ( resolve, reject ) => {
			let newState = _cloneDeep( this.state )
			newState.filtered_contributor = { status: DOCUMENT_LOADING, document: null }

			this.setState( newState, () => {
				this.props.dispatch(
					callApi(
						() => {
							return ( ( currentUser, contributorId ) => {
								return API.getUser( currentUser, contributorId )
							} )( this.props.authorizationInfo.user, contributorId )
						},
						( err, user ) => {
							if ( helpers.doesExist( err ) ) {
								let newState = _cloneDeep( this.state )

								// just say it was loaded here (for simplicity's sake)
								newState.filtered_contributor = { status: DOCUMENT_LOADED, document: null }

								return this.setState( newState, () => {
									reject( err )
								} )
							}

							let newState = _cloneDeep( this.state )
							newState.filtered_contributor = { status: DOCUMENT_LOADED, document: user }

							this.setState( newState, () => {
								resolve()
							} )
						}
					)
				)
			} )
		} )
	}


	loadFilteredCategory( categoryUUID ) {
		return new Promise( ( resolve, reject ) => {
			let newState = _cloneDeep( this.state )
			newState.filtered_category = { status: DOCUMENT_LOADING, document: null }

			this.setState( newState, () => {
				this.props.dispatch(
					callApi(
						() => {
							return ( ( currentUser, contributorId ) => {
								return API.getCategory( currentUser, categoryUUID )
							} )( this.props.authorizationInfo.user, categoryUUID )
						},
						( err, category ) => {
							if ( helpers.doesExist( err ) ) {
								let newState = _cloneDeep( this.state )

								// just say it was loaded here (for simplicity's sake)
								newState.filtered_category = { status: DOCUMENT_LOADED, document: null }

								return this.setState( newState, () => {
									reject( err )
								} )
							}

							let newState = _cloneDeep( this.state )
							newState.filtered_category = { status: DOCUMENT_LOADED, document: category }

							this.setState( newState, () => { resolve() } )
						}
					)
				)
			} )
		} )
	}


	loadActivity( page ) {
		return new Promise( ( resolve, reject ) => {
			this.setState( { activity: { data: [], total: 0 }, selectedActivity: null, selectedWorkItem: null } )

			let filter = this.buildSearchFilter( this.state.query.postDate, this.state.query.showTests, this.state.query.showPublished, this.state.query.showInProgress, this.state.query.showUnpublished, this.state.query.eventsOnly, this.state.filtered_contributor.document, this.state.filtered_category.document )
			let pageInfo = this.buildSearchPageInfo( page, this.pageSize )

			this.props.dispatch(
				callApi(
					() => {
						return ( ( currentUser, filter, sortValue, pageInfo ) => {
							return API.searchShortstopActivity( currentUser, filter, sortValue, pageInfo )
						} )( this.props.authorizationInfo.user, filter, this.state.query.sort, pageInfo )
					},
					( err, activity ) => {
						if ( helpers.doesExist( err ) ) return reject( err )

						this.setState( { activity: activity }, () => {
							resolve()
						} )
					}
				)
			)
		} )
	}


	handlePostSelected( post ) {
		if ( helpers.doesNotExist( post.work_item.work_item_id ) ) {
			return this.setState( { selectedActivity: post } )
		}

		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, mediaId ) => {
						return API.getMedia( currentUser, mediaId )
					} )( this.props.authorizationInfo.user, post.work_item.work_item_id )
				},
				( err, workItem ) => {
					this.handleWorkItemRetrieval( err, workItem, post )
				}
			)
		)
	}


	handleWorkItemRetrieval( err, workItem, post ) {
		if ( helpers.doesExist( err ) ) {
			return this.props.dispatch( reportApiError( err ) )
		}

		this.setState( { selectedActivity: post, selectedWorkItem: workItem } )
	}


	buildSearchFilter( postDate, showTests, showPublished, showInProgress, showUnpublished, eventsOnly, contributor, category ) {
		let filter = {}

		if ( showTests ) filter.show_tests = showTests
		if ( helpers.doesExist( postDate ) ) filter.created_on = moment( postDate ).toISOString()
		if ( helpers.doesExist( contributor ) ) filter.contributor = contributor._id
		if ( helpers.doesExist( category ) ) filter.category = category.name
		if ( helpers.doesExist( eventsOnly ) && eventsOnly ) filter.has_event = eventsOnly

		filter.current_state = 'Failed,Rejected'

		if ( showPublished ) {
			filter.current_state = `${ filter.current_state },Published`
		}

		if ( showInProgress ) {
			filter.current_state = `${ filter.current_state },Delivered,Publishing,TranscodingVideo,PendingUnpublish`
		}

		if ( showUnpublished ) {
			filter.current_state = `${ filter.current_state },Unpublished`
		}

		return filter
	}


	buildSearchPageInfo( page, pageSize ) {
		return { limit: pageSize, offset: page * pageSize }
	}


	handleSort( sortColumn ) {
		Analytics.recordUserActivity()

		let newQuery = _clone( this.state.query )

		newQuery.currentPage = 0
		newQuery.sort = sortColumn

		this.setState( { query: newQuery }, () => {
			this.setLocationFromState( this.state )
			this.loadActivity( this.state.query.currentPage )
		} )
	}


	handleRefresh( event ) {
		Analytics.recordUserActivity()

		this.loadActivity( this.state.query.currentPage )
	}


	handleShowFilter( event ) {
		Analytics.recordUserActivity()

		let newState = _cloneDeep( this.state )
		newState.show_filter_dialog = true

		this.setState( newState )
	}


	handlePageSelected( newPage ) {
		Analytics.recordUserActivity()

		let newQuery = _clone( this.state.query )
		newQuery.currentPage = parseInt( newPage )

		this.setState( { query: newQuery }, () => {
			this.setLocationFromState( this.state )
			this.loadActivity( this.state.query.currentPage )
		} )
	}


	filterSettingsChanged( newFilter ) {
		Analytics.recordUserActivity()

		this.setState( { query: newFilter }, () => { this.setLocationFromState( this.state ) } )
	}


	closeFilter() {
		Analytics.recordUserActivity()

		let newState = _cloneDeep( this.state )

		newState.show_filter_dialog = false
		newState.query.currentPage = 0

		this.setState( newState, () => {
			this.setLocationFromState( this.state )
			this.loadActivity( this.state.query.currentPage )
		} )
	}


	userFilterChanged( selectedUser ) {
		Analytics.recordUserActivity()

		if ( helpers.doesNotExist( selectedUser ) ) {
			let newState = _cloneDeep( this.state )

			newState.query.contributor_id = null
			newState.filtered_contributor = { status: DOCUMENT_NOT_APPLICABLE, document: null }
			newState.currentPage = 0

			// update the query string before changing state
			this.setLocationFromState( newState )

			return this.setState( newState, () => {
				if ( helpers.doesExist( this.props.categorySelectedCallback ) ) this.props.categorySelectedCallback()	// for tests
			} )
		}

		let newState = _clone( this.state )

		newState.filtered_contributor = { status: DOCUMENT_LOADED, document: selectedUser }
		newState.currentPage = 0

		this.setState( newState, () => {
			this.setLocationFromState( this.state )

			if ( helpers.doesExist( this.props.contributorSelectedCallback ) ) this.props.contributorSelectedCallback()
		} )
	}


	categoryFilterChanged( selectedCategory ) {
		Analytics.recordUserActivity()

		if ( helpers.doesNotExist( selectedCategory ) ) {
			let newState = _cloneDeep( this.state )

			newState.query.category_uuid = null
			newState.filtered_category = { status: DOCUMENT_NOT_APPLICABLE, document: null }
			newState.currentPage = 0

			// update the query string before changing state
			this.setLocationFromState( newState )

			return this.setState( newState, () => {
				if ( helpers.doesExist( this.props.categorySelectedCallback ) ) this.props.categorySelectedCallback()	// for tests
			} )
		}

		let newState = _cloneDeep( this.state )

		newState.filtered_category = { status: DOCUMENT_LOADED, document: selectedCategory }
		newState.currentPage = 0

		this.setLocationFromState( newState )

		this.setState( newState, () => {
			if ( helpers.doesExist( this.props.categorySelectedCallback ) ) this.props.categorySelectedCallback()	// for tests
		} )
	}


	deletePost( selectedPost ) {
		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, mediaId ) => {
						return API.deleteMedia( currentUser, mediaId )
					} )( this.props.authorizationInfo.user, selectedPost._id )
				},
				( err, res ) => {
					if ( helpers.doesExist( err ) ) {
						this.props.dispatch( reportApiError( err ) )
					}
					else {
						this.props.dispatch( showNotification( 'Success', 'Delete Post', 'success' ) )

						this.loadActivity( this.state.query.currentPage )
					}
				}
			)
		)
	}


	publishTest( selectedPost ) {
		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, mediaId ) => {
						return API.publishShortstopTest( currentUser, selectedPost )
					} )( this.props.authorizationInfo.user, selectedPost._id )
				},
				( err, res ) => {
					if ( helpers.doesExist( err ) ) {
						this.props.dispatch( reportApiError( err ) )
					}
					else {
						this.props.dispatch( showNotification( 'Success', 'Publish Test Post', 'success' ) )

						this.loadActivity( this.state.query.currentPage )
					}
				}
			)
		)
	}


	editPost( selectedPost ) {
		this.props.history.push( `/shortstop-activity/${ selectedPost._id }` )
	}


	render() {
		return (
			<div style={ { marginTop: 15 } }>
				<ShortstopActivityFilter
					authorizationInfo={ this.props.authorizationInfo }
					open={ this.state.show_filter_dialog }
					filterSettings={ this.state.query }
					filteredContributor={ this.state.filtered_contributor }
					filteredCategory={ this.state.filtered_category }
					filterSettingsChanged={ this.filterSettingsChanged }
					userFilterChanged={ this.userFilterChanged }
					categoryFilterChanged={ this.categoryFilterChanged }
					closeFilter={ this.closeFilter }
				/>

				<div style={ { display: 'flex', flexDirection: 'row', marginLeft: 10 } }>
					<div style={ { flex: 2 } }>
						<ShortstopGrid
							authorizationInfo={ this.props.authorizationInfo }
							activity={ this.state.activity }
							filterSettings={ this.state.query }
							filteredContributor={ this.state.filtered_contributor }
							filteredCategory={ this.state.filtered_category }
							currentSort={ this.state.query.sort }
							currentPage={ this.state.query.currentPage }
							onPostSelected={ this.handlePostSelected }
							onSortChanged={ this.handleSort }
							onPageChanged={ this.handlePageSelected }
							onRefreshList={ this.handleRefresh }
							onShowFilter={ this.handleShowFilter }
						/>
					</div>

					<div style={ { flex: 1, marginLeft: 15, marginRight: 10 } }>
						<ShortstopDetail
							authorizationInfo={ this.props.authorizationInfo }
							activity={ this.state.selectedActivity }
							workItem={ this.state.selectedWorkItem }
							deletePost={ this.deletePost }
							editPost={ this.editPost }
							publishTest={ this.publishTest }
						/>
					</div>
				</div>
			</div>
		)
	}
}


function mapStateToProps( state, ownProps ) {
	return {
		authorizationInfo	: state.authorizationInfo
	}
}


ShortstopPage.propTypes = {
	authorizationInfo				: PropTypes.object.isRequired,

	// injected automatically by react-router
	history							: PropTypes.object.isRequired,

	// test hooks
	contributorSelectedCallback		: PropTypes.func,
	categorySelectedCallback		: PropTypes.func,
}


export default withRouter( connect( mapStateToProps )( ShortstopPage ) )
