const types = require( '../actions/actionTypes' )
const initialState = require( './initialState' )


export default function appNotificationReducer( state = initialState.appNotification, action ) {
	if ( action.type === types.SHOW_NOTIFICATION ) {
		return {
			message: action.payload.message,
			variant: action.payload.variant,
			title: action.payload.title,
			additionalActionCallback: action.payload.additionalActionCallback,
			open: true
		}
	}
	else if ( action.type === types.HIDE_NOTIFICATION ) {
		return {
			message: action.payload.message,
			variant: action.payload.variant,
			title: action.payload.title,
			additionalActionCallback: initialState.appNotification.additionalActionCallback,
			open: false
		}
	}

	return state
}
