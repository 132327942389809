import _isArray from 'lodash/isArray'

import helpers from '../lib/helpers'


export default class PccMetadata {
	static get friendlyName() { return 'PCC Metadata' }
	static get modelName() { return PccMetadata.friendlyName.replace( ' ', '' ) }
	static get workItemType() { return PccMetadata.friendlyName.toString().replace( ' ', '_' ).toLowerCase() }


	static createFromPCCProgram( pccProgram ) {
		let pccMetadata = { programData: { matchup: { categories: [], airingRights: {} } } }

		pccMetadata.programData.programUuid = pccProgram.id

		if ( helpers.doesExist( pccProgram.title ) ) pccMetadata.programData.name = pccProgram.title

		if ( helpers.doesExist( pccProgram.properties ) ) {
			if ( helpers.doesExist( pccProgram.properties.synopsis ) ) pccMetadata.programData.description = pccProgram.properties.synopsis
			if ( helpers.doesExist( pccProgram.properties.language ) ) pccMetadata.programData.language = pccProgram.properties.language
		}

		if ( helpers.doesExist( pccProgram.categories ) && _isArray( pccProgram.categories ) ) {
			pccMetadata.programData.matchup.categories = pccProgram.categories
		}


		if ( helpers.doesExist( pccProgram.createdOn ) ) pccMetadata.programData.createDate = pccProgram.createdOn


		if ( helpers.doesExist( pccProgram.media ) ) {
			pccMetadata.media = pccProgram.media
		}


		return pccMetadata
	}


	constructor( json = {} ) {
		this.id = json.id
		this.name = json.name
		this.description = json.description
		this.language = json.language
		this.categories = json.categories
		this.published = json.published
		this.airingRights = json.airingRights
		this.programData = json.programData
		this.createDate = json.createDate
		this.media = json.media
	}


	getCategoryValue( categoryType ) {
		if ( helpers.doesNotExist( categoryType ) ) return null
		if ( !_isArray( this.categories ) ) return null

		let category = this.categories.find( ( c ) => { return c.type === categoryType } )
		if ( helpers.doesNotExist( category ) ) return null

		return category.name
	}


	get modelName() { return PccMetadata.modelName }
	get friendlyName() { return PccMetadata.friendlyName }
	get workItemType() { return PccMetadata.workItemType }
}
