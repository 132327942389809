export default class PccNetwork {
	static get friendlyName() { return 'PCC Network' }
	static get modelName() { return PccNetwork.friendlyName.replace( ' ', '' ) }
	static get workItemType() { return PccNetwork.friendlyName.toString().replace( ' ', '_' ).toLowerCase() }


	constructor( json = {} ) {
		this.href = json.href
		this.artworkUrl = json.artworkUrl
		this.name = json.name
	}


	get modelName() { return PccNetwork.modelName }
	get friendlyName() { return PccNetwork.friendlyName }
	get workItemType() { return PccNetwork.workItemType }
}
