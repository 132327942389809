import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogButton from '@material-ui/core/Button'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'

import red from '@material-ui/core/colors/red'
import grey from '@material-ui/core/colors/grey'

import helpers from '../../lib/helpers'


class DeleteConfirmation extends React.Component {
	constructor( props, context ) {
		super( props, context )

		this.state = { show: false, modelToDelete: null, modelIndex: null, objectName: null, related: null, typedObjectName: null }

		this.deleteObject = this.deleteObject.bind( this )
		this.updateTypedModelName = this.updateTypedModelName.bind( this )
		this.cancelDelete = this.cancelDelete.bind( this )
	}


	show( modelToDelete, modelIndex, objectName, related ) {
		// called by external components
		this.setState( { show: true, modelToDelete: modelToDelete, modelIndex: modelIndex, objectName: objectName, related: related, typedObjectName: null } )
	}


	cancelDelete() {
		this.setState( { show: false } )
	}


	deleteObject() {
		this.setState( { show: false } )
		this.props.deleteCallback( this.state.modelToDelete, this.state.modelIndex )
	}


	getModelName() {
		if ( helpers.doesNotExist( this.state.modelToDelete ) ) return null

		return this.state.modelToDelete.modelName
	}


	updateTypedModelName( evt ) {
		this.setState( { typedObjectName: evt.target.value } )
	}


	renderDeleteButton() {
		let disabled = true
		let backgroundColor = red[ 200 ]

		if ( this.state.typedObjectName === this.state.objectName ) {
			disabled = false
			backgroundColor = red[ 700 ]
		}

		return (
			<Button
				style={ { backgroundColor: backgroundColor, color: grey[ 50 ], width: '100%', marginTop: 10 } }
				disabled={ disabled }
				onClick={ this.deleteObject }>
				{ `I understand the consequences, delete this ${ this.getModelName() }` }
			</Button>
		)
	}


	render() {
		return (
			<Dialog
				open={ this.state.show }
				onClose={ this.cancelDelete.bind( this ) }
			>
				<DialogTitle>Are you ABSOLUTELY sure?</DialogTitle>
				<DialogContent>
					<div style={ { color: '#796620', backgroundColor: '#f8eec7', borderColor: '#f2e09a', width: '109%', height: 40, paddingTop: 10, paddingLeft: 25, marginTop: 0, marginLeft: -25, marginBottom: 10, borderStyle: 'solid', borderWidth: '1px 0' } }>
						Unexpected bad things will happen if you don&apos;t read this!
					</div>
					<p className="delete-conf-first-sentence">This action <strong>CANNOT</strong> be undone.  This will permanently delete the <strong>{ this.state.objectName }</strong> { this.getModelName() } and all related { this.state.related }</p>
					<p className="delete-conf-second-sentence">Please type in the name of the { this.getModelName() } to confirm.</p>

					<TextField
						label={ `${ this.getModelName() } Name` }
						style={ { width: '100%' } }
						value={ helpers.formatString( this.state.typedObjectName ) }
						onChange={ this.updateTypedModelName }
						margin="normal"
						variant="outlined"
					/>

					{ this.renderDeleteButton() }
				</DialogContent>
				<DialogActions>
					<DialogButton id="close-dialog" onClick={ this.cancelDelete } color="primary">
						Cancel
					</DialogButton>
				</DialogActions>
			</Dialog>

		)
	}
}


DeleteConfirmation.propTypes = {
	deleteCallback	: PropTypes.func.isRequired
}


export default DeleteConfirmation
