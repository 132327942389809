import _isEmpty from 'lodash/isEmpty'
import _cloneDeep from 'lodash/cloneDeep'
import _defaultTo from 'lodash/defaultTo'

import React from 'react'
import PropTypes from 'prop-types'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'

import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'

import IconButton from '@material-ui/core/IconButton'
import TrashIcon from '@material-ui/icons/Delete'

import Analytics from '../../lib/analytics'
import helpers from '../../lib/helpers'

import DeleteConfirmation from '../common/delete_confirmation'

import PartnerLookup from '../../models/partner_lookup'
import SelectableMetadata from '../../models/selectable_metadata'


const SUPPORTED_ENTRY_TYPES = [
	{ option_name: 'Person', option_value: 'person' },
	{ option_name: 'Team', option_value: 'team' },
	{ option_name: 'League', option_value: 'league' },
	{ option_name: 'Sport', option_value: 'sport' },
	{ option_name: 'Competition', option_value:	'competition' }
]

const MODEL_NAME = 'Entry Group'


const styles = {
	rightFloatingButton: {
		marginLeft: 'auto'
	},
	displayHelpText: ( validation ) => validation ? { display: 'block' } : { display: 'none' }
}


class MetadataMappings extends React.Component {
	constructor( props, context ) {
		super( props, context )

		let stateFromMetadata = this.createStateFromMetadata( props.partnerMetadata )

		this.state = stateFromMetadata
	}


	UNSAFE_componentWillReceiveProps( nextProps ) {
		if ( nextProps.partnerMetadata ) {
			this.setState( this.createStateFromMetadata( nextProps.partnerMetadata ) )
		}
	}


	createStateFromMetadata( partnerMetadata ) {
		if ( _isEmpty( partnerMetadata ) ) {
			return {
				entryGroups	: []
			}
		}

		/*
			We're given an array of partner_lookup documents, one for each CMS with the following structure:
			{
				cms_name,
				entry_groups: [
					{
						sport,
						league,
						entries: []
					}
				]
			}

			We're grouping by entry groups in the UI, which can belong to multiple CMSs, so our state looks like:
			[
				{
					sport,
					league,
					entries: [
						{
		 					entry_type,
							keyword,
							domestic,
							deportes
						}
					]
				}
			]
		 */

		let entryGroupsState = []

		if ( partnerMetadata.length > 0 ) {
			// add all entry groups up front, this will include potential duplicates
			for ( let entryGroup of partnerMetadata[ 0 ].entry_groups ) {
				entryGroupsState.push( { sport: entryGroup.sport, league: entryGroup.league, entries: [], modelName: MODEL_NAME, validationState: { state: null, helpText: null } } )
			}
		}

		for ( let lookup of partnerMetadata ) {
			// looping by index for a reason here
			for ( let entryGroupIndex = 0; entryGroupIndex < lookup.entry_groups.length; entryGroupIndex++ ) {
				let entryGroup = lookup.entry_groups[ entryGroupIndex ]

				for ( let entryIndex = 0; entryIndex < entryGroup.entries.length; entryIndex++ ) {
					let entry = entryGroup.entries[ entryIndex ]

					if ( entryGroupsState[ entryGroupIndex ].entries.length === entryIndex ) {
						let validationState = { state: null, keywordHelpText: null, domesticHelpText: null, deportesHelpText: null }
						entryGroupsState[ entryGroupIndex ].entries.push( { entry_type: entry.entry_type, keyword: entry.keyword, validationState: validationState } )
					}

					if ( !isNaN( entry.cms_id ) ) {
						entryGroupsState[ entryGroupIndex ].entries[ entryIndex ][ lookup.cms_name ] = parseInt( entry.cms_id )
					}
				}
			}
		}

		// check for duplicate sport / leagues in the entry groups
		for ( let entryGroup of entryGroupsState ) {
			let entries = entryGroupsState.filter( ( state ) => { return state.sport === entryGroup.sport && state.league === entryGroup.league } )
			if ( entries.length > 1 ) {
				let sport = SelectableMetadata.getParentByValue( this.props.selectableMetadata, 'sport', entryGroup.sport )
				let league = SelectableMetadata.getChildByValue( sport, 'league', entryGroup.league )

				entryGroup.validationState = { state: 'error', helpText: `${ league.option_name } is duplicated` }
			}

			// check for duplicate keyword or CMS IDs within the same entry group
			for ( let entry of entryGroup.entries ) {
				if ( helpers.doesNotExist( entry.keyword ) ) {
					entry.validationState = { state: 'error', keywordHelpText: 'Keyword is required' }
				}

				if ( helpers.doesExist( entry.keyword ) ) {
					entries = entryGroup.entries.filter( ( e ) => { return e.keyword === entry.keyword } )
					if ( entries.length > 1 ) {
						entry.validationState = { state: 'error', keywordHelpText: `${ entry.keyword } is duplicated` }
					}
				}

				if ( helpers.doesNotExist( entry.domestic ) && helpers.doesNotExist( entry.deportes ) ) {
					entry.validationState.state = 'error'

					entry.validationState.domesticHelpText = 'At least one ID is required'
					entry.validationState.deportesHelpText = 'At least one ID is required'

					continue
				}

				entries = entryGroup.entries.filter( ( e ) => { return e.domestic === entry.domestic && e.domestic !== undefined } )
				if ( entries.length > 1 ) {
					entry.validationState.state = 'error'
					entry.validationState.domesticHelpText = `${ entry.domestic } is duplicated`
				}

				entries = entryGroup.entries.filter( ( e ) => { return e.deportes === entry.deportes && e.deportes !== undefined } )
				if ( entries.length > 1 ) {
					entry.validationState.state = 'error'
					entry.validationState.deportesHelpText = `${ entry.deportes } is duplicated`
				}
			}
		}

		return {
			entryGroups	: entryGroupsState
		}
	}


	createMetadataFromState( componentState ) {
		// do the reverse of above

		let partnerMetadata = _cloneDeep( this.props.partnerMetadata )

		// just create mappings for all three CMSs, logic is much easier and there's no harm
		let domesticIndex = partnerMetadata.findIndex( ( m ) => { return m.cms_name === 'domestic' } )
		if ( domesticIndex < 0 ) {
			partnerMetadata.push( new PartnerLookup( { cms_name: 'domestic', entry_groups: [], group: this.props.groupConfig.group } ) )
			domesticIndex = partnerMetadata.length - 1
		}

		let deportesIndex = partnerMetadata.findIndex( ( m ) => { return m.cms_name === 'deportes' } )
		if ( deportesIndex < 0 ) {
			partnerMetadata.push( new PartnerLookup( { cms_name: 'deportes', entry_groups: [], group: this.props.groupConfig.group } ) )
			deportesIndex = partnerMetadata.length - 1
		}

		partnerMetadata[ domesticIndex ].entry_groups = []
		partnerMetadata[ deportesIndex ].entry_groups = []

		for ( let entryGroup of componentState.entryGroups ) {
			for ( let cmsMetadata of partnerMetadata ) {
				cmsMetadata.entry_groups.push( { sport: entryGroup.sport, league: entryGroup.league, entries: [] } )
			}

			for ( let entry of entryGroup.entries ) {
				if ( helpers.doesNotExist( entry.domestic ) && helpers.doesNotExist( entry.deportes ) ) {
					// add it to the all mappings by default with no cms id
					let domesticEntry = { entry_type: entry.entry_type, keyword: entry.keyword }
					partnerMetadata[ domesticIndex ].entry_groups[ partnerMetadata[ domesticIndex ].entry_groups.length - 1 ].entries.push( domesticEntry )

					let deportesEntry = { entry_type: entry.entry_type, keyword: entry.keyword }
					partnerMetadata[ deportesIndex ].entry_groups[ partnerMetadata[ deportesIndex ].entry_groups.length - 1 ].entries.push( deportesEntry )

					continue
				}

				let domesticEntry = { entry_type: entry.entry_type, keyword: entry.keyword }
				if ( helpers.doesExist( entry.domestic ) ) domesticEntry.cms_id = entry.domestic
				partnerMetadata[ domesticIndex ].entry_groups[ partnerMetadata[ domesticIndex ].entry_groups.length - 1 ].entries.push( domesticEntry )

				let deportesEntry = { entry_type: entry.entry_type, keyword: entry.keyword }
				if ( helpers.doesExist( entry.deportes ) ) deportesEntry.cms_id = entry.deportes
				partnerMetadata[ deportesIndex ].entry_groups[ partnerMetadata[ deportesIndex ].entry_groups.length - 1 ].entries.push( deportesEntry )
			}
		}

		return partnerMetadata
	}


	handleAddEntry( entryGroupIndex ) {
		Analytics.recordUserActivity()

		let newEntryGroups = _cloneDeep( this.state.entryGroups )
		newEntryGroups[ entryGroupIndex ].entries.push( { entry_type: 'team' } )

		this.props.updateMetadataState( this.createMetadataFromState( { entryGroups: newEntryGroups } ) )
	}


	handleRemoveEntry( entryGroupIndex, entryIndex, event ) {
		Analytics.recordUserActivity()

		let newEntryGroups = _cloneDeep( this.state.entryGroups )
		newEntryGroups[ entryGroupIndex ].entries.splice( entryIndex, 1 )

		this.props.updateMetadataState( this.createMetadataFromState( { entryGroups: newEntryGroups } ) )
	}


	handleAddEntryGroup() {
		Analytics.recordUserActivity()

		let newEntryGroups = _cloneDeep( this.state.entryGroups )

		// default this to 'any' / 'any'
		newEntryGroups.push( { sport: 'any', league: 'any', entries: [], modelName: MODEL_NAME } )

		this.props.updateMetadataState( this.createMetadataFromState( { entryGroups: newEntryGroups } ) )
	}


	handleEntryGroupSelectedForRemoval( entryGroupIndex, event ) {
		Analytics.recordUserActivity()

		let entryGroup = this.state.entryGroups[ entryGroupIndex ]

		let sport = SelectableMetadata.getParentByValue( this.props.selectableMetadata, 'sport', entryGroup.sport )
		let league = SelectableMetadata.getChildByValue( sport, 'league', entryGroup.league )

		this.deleteDialog.show( entryGroup, entryGroupIndex, `${ sport.option_name } / ${ league.option_name }`, ' all metadata mappings for this entry group.' )

		event.stopPropagation()
	}


	handleRemoveEntryGroup( entryGroup, entryGroupIndex ) {
		Analytics.recordUserActivity()

		let newEntryGroups = _cloneDeep( this.state.entryGroups )

		newEntryGroups.splice( entryGroupIndex, 1 )

		this.props.updateMetadataState( this.createMetadataFromState( { entryGroups: newEntryGroups } ) )
	}


	updateEntryValue( propertyName, entryGroupIndex, entryIndex, event ) {
		Analytics.recordUserActivity()

		let newEntryGroups = _cloneDeep( this.state.entryGroups )

		let newValue = event.target.value
		if ( newValue === '' ) {
			delete newEntryGroups[ entryGroupIndex ].entries[ entryIndex ][ propertyName ]
		}
		else {
			if ( propertyName !== 'keyword' && propertyName !== 'entry_type' ) {
				newValue = parseInt( newValue )
			}

			newEntryGroups[ entryGroupIndex ].entries[ entryIndex ][ propertyName ] = newValue
		}

		this.props.updateMetadataState( this.createMetadataFromState( { entryGroups: newEntryGroups } ) )
	}


	updateEntryGroupValue( propertyName, entryGroupIndex, event ) {
		Analytics.recordUserActivity()

		let newEntryGroups = _cloneDeep( this.state.entryGroups )

		let newValue = event.target.value
		newEntryGroups[ entryGroupIndex ][ propertyName ] = newValue

		if ( propertyName === 'sport' ) {
			// this invalidates the current league selection since that belongs to the previous sport; default the league to 'any'
			newEntryGroups[ entryGroupIndex ][ 'league' ] = 'any'
		}

		this.props.updateMetadataState( this.createMetadataFromState( { entryGroups: newEntryGroups } ) )
	}


	renderEntryTypeSelector( entry, entryGroupIndex, entryIndex ) {
		return (
			<FormControl error={ Boolean( entry.validationState.state ) }>
				<Select
					autoWidth
					value={ entry.entry_type }
					onChange={ this.updateEntryValue.bind( this, 'entry_type', entryGroupIndex, entryIndex ) }
				>
					{ SUPPORTED_ENTRY_TYPES.map( ( entryType, idx ) => {
						return (
							<MenuItem key={ `entry-group-${ entryGroupIndex }-entry-${ entryIndex }-type-${ idx }` } value={ entryType.option_value }>{ entryType.option_name }</MenuItem>
						)
					} ) }
				</Select>
			</FormControl>
		)
	}


	renderEntriesForEntryGroup( entries, entryGroupIndex ) {
		return (
			<Paper elevation={ 1 }>
				<AppBar position="static" color="default" style={ { boxShadow: 'none' } }>
					<Toolbar disableGutters variant="dense">
						<Tooltip title="Add Metadata Entry" enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton id="addMetadata" className="add-entry" onClick={ this.handleAddEntry.bind( this, entryGroupIndex ) } style={ styles.rightFloatingButton }>
								<AddIcon />
							</IconButton>
						</Tooltip>
					</Toolbar>
				</AppBar>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell style={ { width:'12%' } }>Metadata Type</TableCell>
							<TableCell style={ { width:'28%' } }>Partner Value</TableCell>
							<TableCell>English Scribe ID</TableCell>
							<TableCell>Spanish Scribe ID</TableCell>
							<TableCell style={ { width:'4%' } }/>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							entries.map( ( entry, entryIndex ) => {
								return (
									<TableRow className="entry-group-entry" key={ `entry-group-${ entryGroupIndex }-entry-${ entryIndex }` }>
										<TableCell className="entry-type">{ this.renderEntryTypeSelector( entry, entryGroupIndex, entryIndex ) }</TableCell>
										<TableCell className="entry-keyword">
											<FormControl error={ Boolean( entry.validationState.state ) }>
												<Input
													placeholder="Enter Partner Value"
													value={ _defaultTo( entry.keyword, '' ) }
													onChange={ this.updateEntryValue.bind( this, 'keyword', entryGroupIndex, entryIndex ) } />
												<FormHelperText style={ styles.displayHelpText( entry.validationState.keywordHelpText ) }>{ entry.validationState.keywordHelpText }</FormHelperText>
											</FormControl>
										</TableCell>
										<TableCell className="entry-domestic">
											<FormControl error={ Boolean( entry.validationState.state ) }>
												<Input
													placeholder="English Scribe ID"
													value={ _defaultTo( entry.domestic, '' ) }
													onChange={ this.updateEntryValue.bind( this, 'domestic', entryGroupIndex, entryIndex ) } />
												<FormHelperText style={ styles.displayHelpText( entry.validationState.domesticHelpText ) }>{ entry.validationState.domesticHelpText }</FormHelperText>
											</FormControl>
										</TableCell>
										<TableCell className="entry-deportes">
											<FormControl error={ Boolean( entry.validationState.state ) }>
												<Input
													placeholder="Spanish Scribe ID"
													value={ _defaultTo( entry.deportes, '' ) }
													onChange={ this.updateEntryValue.bind( this, 'deportes', entryGroupIndex, entryIndex ) } />
												<FormHelperText style={ styles.displayHelpText( entry.validationState.deportesHelpText ) }>{ entry.validationState.deportesHelpText }</FormHelperText>
											</FormControl>
										</TableCell>
										<TableCell>
											<IconButton className="remove-entry" onClick={ this.handleRemoveEntry.bind( this, entryGroupIndex, entryIndex ) } style={ { verticalAlign: 'middle' } }>
												<TrashIcon/>
											</IconButton>
											{ /* <a className="remove-entry" onClick={ this.handleRemoveEntry.bind( this, entryGroupIndex, entryIndex ) }><i className="fa-trash fas fa-lg" style={ { verticalAlign: 'middle' } } /></a> */ }
										</TableCell>
									</TableRow>
								)
							} )
						}
					</TableBody>
				</Table>
			</Paper>
		)
	}


	renderLeagueSelector( entryGroup, entryGroupIndex ) {
		let leagues = this.props.groupConfig.getListOfAvailableLeagues( entryGroup.sport )

		return (
			<FormControl error={ Boolean( entryGroup.validationState.state ) }>
				<Select
					autoWidth
					value={ entryGroup.league || '' }
					onChange={ this.updateEntryGroupValue.bind( this, 'league', entryGroupIndex ) }>
					{
						leagues.map( ( league, idx ) => {
							let sport = SelectableMetadata.getParentByValue( this.props.selectableMetadata, 'sport', entryGroup.sport )
							let displayValue = SelectableMetadata.getChildByValue( sport, 'league', league.espn_value ).option_name

							return (
								<MenuItem key={ `edition-${ entryGroupIndex }-league-${ idx }` } value={ league.espn_value }>{ displayValue }</MenuItem>
							)
						} )
					}
				</Select>
				<FormHelperText style={ styles.displayHelpText( entryGroup.validationState.helpText ) }>{ entryGroup.validationState.helpText }</FormHelperText>
			</FormControl>
		)
	}


	render() {
		let groupConfigSports = this.props.groupConfig.getListOfAvailableSports()

		return (
			<div style={ { padding: '8px', display: 'flex', flexDirection: 'column' } }>
				<Fab className="add-entry-group" variant="extended" style={ { margin: '0px 8px 8px 10px', alignSelf: 'center' } } onClick={ this.handleAddEntryGroup.bind( this ) }>
					<AddIcon style={ { marginRight: '8px' } }/>
					Add Metadata Group
				</Fab>

				<DeleteConfirmation ref={ ( deleteDialog ) => { this.deleteDialog = deleteDialog } } deleteCallback={ this.handleRemoveEntryGroup.bind( this ) } />

				{
					this.state.entryGroups.map( ( entryGroup, entryGroupIndex ) => {
						let sport = SelectableMetadata.getParentByValue( this.props.selectableMetadata, 'sport', entryGroup.sport )
						let league = SelectableMetadata.getChildByValue( sport, 'league', entryGroup.league )

						let header = (
							<div>
								<div style={ { display: 'flex', alignItems: 'center' } }>
									<IconButton className="remove-entry-top" style={ { marginRight: '15px' } } onClick={ this.handleEntryGroupSelectedForRemoval.bind( this, entryGroupIndex ) }>
										<TrashIcon />
									</IconButton>
									<div>{ sport.option_name } / { league.option_name }</div>
								</div>
							</div>
						)

						return (
							<ExpansionPanel className="entry-group" key={ `entry-group-${ entryGroupIndex }` }>
								<ExpansionPanelSummary expandIcon={ <ExpandMoreIcon /> }>
									{ header }
								</ExpansionPanelSummary>

								<ExpansionPanelDetails>
									<Table size="small">
										<TableHead>
											<TableRow>
												<TableCell style={ { width:'15%' } }>Sport</TableCell>
												<TableCell style={ { width:'15%' } }>League</TableCell>
												<TableCell style={ { width:'70%' } }>Mappings</TableCell>
											</TableRow>
										</TableHead>

										<TableBody>
											<TableRow>
												<TableCell style={ { verticalAlign: 'top' } }>
													<FormControl error={ Boolean( entryGroup.validationState.state ) }>
														<Select
															autoWidth
															value={ entryGroup.sport }
															onChange={ this.updateEntryGroupValue.bind( this, 'sport', entryGroupIndex ) }>
															{ groupConfigSports.map( ( sport, idx ) => {
																let displayValue = SelectableMetadata.getParentByValue( this.props.selectableMetadata, 'sport', sport.espn_value ).option_name

																return (
																	<MenuItem key={ `entry-group-${ entryGroupIndex }-sport-${ idx } ` } value={ sport.espn_value }>{ displayValue }</MenuItem>
																)
															} ) }
														</Select>
													</FormControl>
												</TableCell>
												<TableCell style={ { verticalAlign: 'top' } } className="entry-group-league">{ this.renderLeagueSelector( entryGroup, entryGroupIndex ) }</TableCell>
												<TableCell>{ this.renderEntriesForEntryGroup( entryGroup.entries, entryGroupIndex ) }</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</ExpansionPanelDetails>
							</ExpansionPanel>
						)
					} )
				}

			</div>
		)
	}
}


MetadataMappings.propTypes = {
	groupConfig			: PropTypes.object.isRequired,
	partnerMetadata		: PropTypes.array.isRequired,
	selectableMetadata	: PropTypes.array.isRequired,
	updateMetadataState	: PropTypes.func.isRequired
}


export default MetadataMappings
