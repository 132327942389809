import _pull from 'lodash/pull'

import helpers from '../lib/helpers'


export default class Group {
	static get ALL_ACLS() {
		return [
			'api.publish.create',
			'api.activity.search',
			'api.media.lookup',
			'api.group-config.lookup',
			'api.partner-lookup.lookup',
			'api.partner-request.lookup',
			'api.pcc',
			'api.now-xml',
			'api.shortstop',
			'api.publish.footage'
		]
	}


	static get GROUP_TYPES() {
		return [
			{ key: 'partner', displayValue: 'Media Exchange Partner' },
			{ key: 'administrator', displayValue: 'Administrator' },
			{ key: 'mx-editorial', displayValue: 'Media Exchange Editorial' },
			{ key: 'shortstop', displayValue: 'Shortstop' },
			{ key: 'international', displayValue: 'ESPN International' },
			{ key: 'xml-feed', displayValue: 'ESPN Shortstop XML Ingest' }
		]
	}


	static defaultACLsForGroupType( groupType ) {
		switch ( groupType ) {
		case 'administrator':
			return Group.ALL_ACLS
		case 'partner':
			return _pull( Group.ALL_ACLS, 'api.pcc', 'api.shortstop', 'api.now-xml' )
		case 'mx-editorial':
			return _pull( Group.ALL_ACLS, 'api.pcc', 'api.shortstop', 'api.now-xml', 'api.publish.footage' )
		case 'shortstop':
			return _pull( Group.ALL_ACLS, 'api.pcc', 'api.publish.footage', 'api.now-xml' )
		case 'international':
			return [ 'api.pcc' ]
		case 'xml-feed':
			return [ 'api.now-xml' ]
		}
	}


	static buildSnapshot( group ) {
		if ( helpers.doesNotExist( group ) || helpers.doesNotExist( group._id ) && helpers.doesNotExist( group.group_id ) ) return undefined

		let snapshot = {
			name		: group.name,
			type		: group.type,
			logo_url	: group.logo_url
		}

		if ( helpers.doesExist( group._id ) ) {
			snapshot.group_id = group._id
		}
		else {
			snapshot.group_id = group.group_id
		}

		if ( helpers.doesExist( group.parent ) ) snapshot.parent = Group.buildSnapshot( group.parent )

		return snapshot
	}


	static get friendlyName() { return 'Group' }
	static get modelName() { return Group.friendlyName.replace( ' ', '' ) }
	static get workItemType() { return Group.friendlyName.toString().replace( ' ', '_' ).toLowerCase() }


	constructor( json = {} ) {
		this._id = json._id
		this.name = json.name
		this.type = json.type
		this.logo_url = json.logo_url
		this.acls = json.acls
		this.created = json.created

		this.parent = Group.buildSnapshot( json.parent )
	}


	get modelName() { return Group.modelName }
	get friendlyName() { return Group.friendlyName }
	get workItemType() { return Group.workItemType }


	toSnapshot() {
		return Group.buildSnapshot( this )
	}


	doesGroupContainAcl( acl ) {
		let hasAcl = false

		for ( let a of this.acls ) {
			if ( a === acl ) {
				hasAcl = true
				break
			}
		}

		return hasAcl
	}
}
