import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import _get from 'lodash/get'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import purple from '@material-ui/core/colors/purple'

import AutoRenewIcon from '@material-ui/icons/Autorenew'
import FilterListIcon from '@material-ui/icons/FilterList'

import DoneIcon from '@material-ui/icons/Done'
import BuildIcon from '@material-ui/icons/Build'
import HourglassFullIcon from '@material-ui/icons/HourglassFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import ErrorIcon from '@material-ui/icons/Error'
import WarningIcon from '@material-ui/icons/Warning'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

import ShortstopAvatar from '../common/shortstop_avatar'

import config from '../../lib/config'
import helpers from '../../lib/helpers'


const COLUMNS = [
	{ id: 'status', width: '5%', numeric: false, padding: 'dense', sortable: false, label: '' },
	{ id: 'username', width: '25%', numeric: false, padding: 'dense', sortable: true, label: 'Contributor' },
	{ id: 'created', width: '16%', numeric: false, padding: 'dense', sortable: true, label: 'Created' },
	{ id: 'headline', width: '54%', numeric: false, padding: 'dense', sortable: false, label: 'Headline' },
]

const ICON_FONT_SIZE = 'small'
const MAX_BODY_TEXT_WORDS = 12


class IngestActivityGrid extends React.Component {
	constructor( props ) {
		super( props )

		this.columnHeaderSelected = this.columnHeaderSelected.bind( this )
	}


	columnHeaderSelected( e, column ) {
		if ( !column.sortable ) return

		// default the new sort to the clicked column DESC
		let sortColumn = `-${ column.id }`

		if ( this.props.currentSort.includes( column.id ) && this.props.currentSort.startsWith( '-' ) ) {
			// set the sort order to ASC only if the user has clicked on the same column and the current sort order is DESC
			sortColumn = column.id
		}

		this.props.onSortChanged( sortColumn )
	}


	getRowColor( post ) {
		let color = '#f2dede'

		if ( helpers.doesNotExist( post.error ) && post.is_test ) {
			color = '#ffffff'
		}
		else if ( post.current_state === 'Uploading' ) {
			color = purple[ 50 ]
		}
		else if ( post.current_state === 'Delivered' || post.current_state === 'Publishing' || post.current_state === 'TranscodingVideo' || post.current_state === 'PendingUnpublish' ) {
			color = '#d9edf7'
		}
		else if ( post.current_state === 'Published' || post.current_state === 'Unpublished' ) {
			color = '#ffffff'
		}

		return color
	}


	getFilterDescription( filterSettings, filteredContributor, filteredGroup ) {
		let descriptionStatus = { description: 'Filter:', is_first: true }

		if ( ( config.environment !== 'production' && filterSettings.showTests ) ||
			filterSettings.showInProgress || filterSettings.showPublished || filterSettings.showUnpublished ) {

			descriptionStatus.description = `${ descriptionStatus.description } Showing (`

			if ( config.environment !== 'production' ) {
				descriptionStatus = this.appendToggleDescription( filterSettings.showTests, 'Tests', descriptionStatus )
			}

			descriptionStatus.description = `${ descriptionStatus.description })`
			descriptionStatus.is_first = false
		}

		if ( ( config.environment !== 'production' && !filterSettings.showTests ) ||
			!filterSettings.showInProgress || !filterSettings.showPublished || !filterSettings.showUnpublished ) {

			let sectionText = ' Hiding ('
			if ( descriptionStatus.description.indexOf( 'Showing' ) > -1 ) sectionText = `,${ sectionText }`

			descriptionStatus = { description: `${ descriptionStatus.description }${ sectionText }`, is_first: true }

			if ( config.environment !== 'production' ) {
				descriptionStatus = this.appendToggleDescription( !filterSettings.showTests, 'Tests', descriptionStatus )
			}

			descriptionStatus.description = `${ descriptionStatus.description })`
			descriptionStatus.is_first = false
		}

		if ( filterSettings.postDate ) {
			descriptionStatus.description = `${ descriptionStatus.description }, Post Date: ${ moment( filterSettings.postDate ).format( 'MMM Do, YYYY' ) }`
			descriptionStatus.is_first = false
		}

		if ( filteredGroup.document ) {
			if ( descriptionStatus.is_first ) {
				descriptionStatus.description = `Group: ${ filteredGroup.document.name }`
				descriptionStatus.is_first = false
			}
			else {
				descriptionStatus.description = `${ descriptionStatus.description }, Group: ${ filteredGroup.document.name }`
			}
		}

		if ( filteredContributor.document ) {
			if ( descriptionStatus.is_first ) {
				descriptionStatus.description = `Contributor: ${ filteredContributor.document.contact.name }`
				descriptionStatus.is_first = false
			}
			else {
				descriptionStatus.description = `${ descriptionStatus.description }, Contributor: ${ filteredContributor.document.contact.name }`
			}
		}

		return descriptionStatus.description
	}


	appendToggleDescription( toggleValue, toggleDescription, currentDescriptionStatus ) {
		// if the supplied value is true, modify the filter description as appropriate
		// the current status is used to check if this is the first time the description is being
		// modified for a particular segment, used to determine if we need a leading ", " or not

		if ( !toggleValue ) return currentDescriptionStatus

		if ( currentDescriptionStatus.is_first ) {
			return { description: `${ currentDescriptionStatus.description }${ toggleDescription }`, is_first: false }
		}

		return { description: `${ currentDescriptionStatus.description }, ${ toggleDescription }` }
	}


	renderTableToolbar( filterSettings, filteredContributor, filteredGroup ) {
		return (
			<AppBar position="static" color="default" style={ { boxShadow: 'none' } }>
				<Toolbar>
					<div style={ { minWidth: '85%' } }>
						<Typography variant="subtitle2" id="tableTitle">
							{ this.getFilterDescription( filterSettings, filteredContributor, filteredGroup ) }
						</Typography>
					</div>
					<div style={ { flex: '1 1 100%' } } />
					<div style={ { display: 'flex', flexDirection: 'row' } }>
						<Tooltip title="Filter" enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton onClick={ this.props.onShowFilter }>
								<FilterListIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Refresh" enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton onClick={ this.props.onRefreshList }>
								<AutoRenewIcon />
							</IconButton>
						</Tooltip>
					</div>
				</Toolbar>
			</AppBar>
		)
	}


	renderStatusIcon( post ) {
		let icon = ( <ErrorIcon color="error" fontSize={ ICON_FONT_SIZE } /> )

		if ( post.current_state === 'Unpublished' ) {
			icon = ( <DeleteForeverIcon color="disabled" fontSize={ ICON_FONT_SIZE } /> )
		}
		else if ( helpers.doesNotExist( post.error ) && post.is_test ) {
			icon = ( <BuildIcon color="action" fontSize={ ICON_FONT_SIZE } /> )
		}
		else if ( post.current_state === 'Uploading' ) {
			icon = ( <CloudUploadIcon color="action" fontSize={ ICON_FONT_SIZE } /> )
		}
		else if ( post.current_state === 'Delivered' || post.current_state === 'Publishing' || post.current_state === 'TranscodingVideo' ) {
			icon = ( <HourglassFullIcon color="disabled" fontSize={ ICON_FONT_SIZE } /> )
		}
		else if ( post.current_state === 'Published' ) {
			icon = ( <DoneIcon color="action" fontSize={ ICON_FONT_SIZE } /> )
		}

		return icon
	}


	renderSecondaryIcon( post ) {
		let icon = ( <div /> )

		if ( helpers.doesNotExist( post.error ) && !( post.current_state === 'Failed' || post.current_state === 'Rejected' ) && post.warnings.length > 0 ) {
			icon = ( <WarningIcon color="disabled" fontSize={ ICON_FONT_SIZE } /> )
		}

		return icon
	}


	renderContributor( post ) {
		let groupName = _get( post, 'sort_fields.group_name', null )
		let groupLogo = _get( post, 'sort_fields.group_logo', null )

		if ( groupName === null || groupLogo === null ) {
			// this is a clip transfer post

			return (
				<div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center' } }>
					<ShortstopAvatar
						authorizationInfo={ this.props.authorizationInfo }
						activity={ post }
						cardAvatar={ false }
						getScribeColumnist={ this.props.getScribeColumnist }
					/>
					<div style={ { marginLeft: 5 } }>{ post.owner.name }</div>
				</div>
			)
		}

		// this is partner footage
		return (
			<div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center' } }>
				<img src={ post.sort_fields.group_logo } style={ { height: 40, width: 40 } } />
				<div style={ { marginLeft: 5 } }>{ groupName }</div>
			</div>
		)
	}


	renderPostContent( post ) {
		let headline = _get( post, 'sort_fields.headline', '' )

		let bodyTextParts = headline.split( ' ' )
		if ( bodyTextParts.length > MAX_BODY_TEXT_WORDS ) {
			let abbreviatedText = bodyTextParts[ 0 ]
			for ( let i = 1; i < MAX_BODY_TEXT_WORDS; i++ ) {
				abbreviatedText = `${ abbreviatedText } ${ bodyTextParts[ i ] }`
			}

			headline = `${ abbreviatedText }...`
		}

		return (
			<div style={ { whiteSpace: 'pre-wrap', wordBreak: 'break-all' } }>{ headline }</div>
		)
	}


	renderTablePagination( total, currentPage, onPageChanged ) {
		if ( total === 0 ) return null

		return (
			<TablePagination
				rowsPerPageOptions={ [] }
				component="div"
				count={ total }
				rowsPerPage={ 10 }
				page={ currentPage }
				backIconButtonProps={ {
					'aria-label': 'Previous Page',
				} }
				nextIconButtonProps={ {
					'aria-label': 'Next Page',
				} }
				onChangePage={ ( e, pageNumber ) => { if ( !e ) return; onPageChanged( pageNumber ) } }
			/>
		)
	}


	render() {
		return (
			<Paper elevation={ 2 }>
				{ this.renderTableToolbar( this.props.filterSettings, this.props.filteredContributor, this.props.filteredGroup ) }
				<Table size="small">
					<TableHead>
						<TableRow>
							{ COLUMNS.map( ( column ) => {
								let header = ( <div>{ column.label }</div> )
								if ( column.sortable ) {
									header = (
										<Tooltip
											title="Sort"
											placement={ column.numeric ? 'bottom-end' : 'bottom-start' }
											enterDelay={ 500 } leaveDelay={ 200 }
										>
											<TableSortLabel
												active={ this.props.currentSort.includes( column.id ) }
												direction={ this.props.currentSort.includes( '-' ) ? 'desc' : 'asc' }
												onClick={ ( e ) => { this.columnHeaderSelected( e, column ) } }
											>
												{ column.label }
											</TableSortLabel>
										</Tooltip>
									)
								}

								return (
									<TableCell
										key={ column.id }
										align={ column.numeric ? 'right' : 'left' }
										style={ { width: column.width } }
									>
										{ header }
									</TableCell>
								)
							} ) }
						</TableRow>
					</TableHead>

					<TableBody>
						{ this.props.activity.data.map( ( post ) => {
							return (
								<TableRow key={ post._id } style={ { backgroundColor: this.getRowColor( post ) } } onClick={ ( e ) => { this.props.onPostSelected( post ) } }>
									<TableCell>
										{ this.renderStatusIcon( post ) }
										{ this.renderSecondaryIcon( post ) }
									</TableCell>
									<TableCell>{ this.renderContributor( post ) }</TableCell>
									<TableCell>{ moment( post.created ).fromNow() }</TableCell>
									<TableCell>{ this.renderPostContent( post ) }</TableCell>
								</TableRow>
							)
						} ) }
					</TableBody>
				</Table>

				{ this.renderTablePagination( this.props.activity.total, this.props.currentPage, this.props.onPageChanged ) }
			</Paper>
		)
	}
}


IngestActivityGrid.propTypes = {
	authorizationInfo	: PropTypes.object.isRequired,
	activity			: PropTypes.object.isRequired,
	filterSettings		: PropTypes.object.isRequired,
	filteredContributor	: PropTypes.object.isRequired,
	filteredGroup		: PropTypes.object.isRequired,
	currentSort			: PropTypes.string.isRequired,
	currentPage			: PropTypes.number.isRequired,
	onPostSelected		: PropTypes.func.isRequired,
	onSortChanged		: PropTypes.func.isRequired,
	onPageChanged		: PropTypes.func.isRequired,
	onRefreshList		: PropTypes.func.isRequired,
	onShowFilter		: PropTypes.func.isRequired,

	// test hook
	getScribeColumnist	: PropTypes.func,
}


export default IngestActivityGrid
