import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'

import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Divider from '@material-ui/core/Divider'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import { withStyles } from '@material-ui/core/styles'

import Analytics from '../../lib/analytics'
import helpers from '../../lib/helpers'
import SelectableMetadata from '../../models/selectable_metadata'
import Media from '../../models/media'


const styles = theme => ( {
	root: {
		'&$error': {
			color: '#8a6d3b'
		}
	},
	asterisk: {
		'&$error': {
			color: '#8a6d3b'
		}
	},
	underline: {
		'&$error:after': {
			borderBottomColor: '#8a6d3b',
		}
	},
	error: {
	}
} )


class DataMethods extends React.Component {
	constructor( props, context ) {
		super( props, context )

		this.state = this.createStateFromConfig( props.groupConfig )
	}


	UNSAFE_componentWillReceiveProps( nextProps ) {
		this.setState( this.createStateFromConfig( nextProps.groupConfig ) )
	}


	createStateFromConfig( groupConfig ) {
		// create the local state object from the group configuration model

		let clonedConfig = _cloneDeep( groupConfig )

		let dataMethods = {
			headlineMethod			: clonedConfig.workflow.publish_to_cerebro.headline_method,
			captionMethod			: clonedConfig.workflow.publish_to_cerebro.caption_method,
			scribeNameMethod		: clonedConfig.workflow.publish_to_cerebro.scribe_name_method
		}

		let closedCaptioning = {
			is_requested 	: _get( clonedConfig, 'workflow.publish_to_cerebro.closed_captioning.is_requested', false ),
			hours_until_due : _get( clonedConfig, 'workflow.publish_to_cerebro.closed_captioning.hours_until_due', '' ).toString()
		}

		let assetDownload = {
			use_partner_asset_location 	: _get( clonedConfig, 'workflow.publish_to_cerebro.asset_download.use_partner_asset_location', false )
		}

		let english = this.getEditionLanguage( clonedConfig, 'en' )
		if ( helpers.doesExist( english ) && helpers.doesExist( english.attribution_text ) ) {
			dataMethods.englishAttributionText = english.attribution_text
		}

		let spanish = this.getEditionLanguage( clonedConfig, 'es' )
		if ( helpers.doesExist( spanish ) && helpers.doesExist( spanish.attribution_text ) ) {
			dataMethods.spanishAttributionText = spanish.attribution_text
		}

		let portuguese = this.getEditionLanguage( clonedConfig, 'pt' )
		if ( helpers.doesExist( portuguese ) && helpers.doesExist( portuguese.attribution_text ) ) {
			dataMethods.portugueseAttributionText = portuguese.attribution_text
		}

		let validationState = {
			alertSettings: {
				slackChannel: { state: null, helpText: null, preventModification: false },
				emoji		: { state: null, helpText: null, preventModification: false }
			}
		}

		if ( clonedConfig.workflow.publish_to_cerebro.alert_settings.alert_when_published ) {
			if ( helpers.doesNotExist( clonedConfig.workflow.publish_to_cerebro.alert_settings.slack_channel ) ) {
				validationState.alertSettings.slackChannel = { state: 'error', helpText: 'Slack Channel is required if publishing alerts are enabled', preventModification: false }
			}

			if ( helpers.doesNotExist( clonedConfig.workflow.publish_to_cerebro.alert_settings.emoji ) ) {
				validationState.alertSettings.emoji = { state: 'error', helpText: 'Emoji is required if publishing alerts are enabled', preventModification: false }
			}
		}
		else {
			validationState.alertSettings = {
				slackChannel: { state: 'warning', helpText: 'Must enable publishing alerts to set this value', preventModification: true },
				emoji		: { state: 'warning', helpText: 'Must enable publishing alerts to set this value', preventModification: true }
			}
		}

		return {
			dataMethods			: dataMethods,
			alertSettings		: clonedConfig.workflow.publish_to_cerebro.alert_settings,
			closedCaptioning	: closedCaptioning,
			assetDownload		: assetDownload,
			validationState		: validationState
		}
	}


	createConfigFromState( componentState ) {
		// do the reverse of above

		let config = _cloneDeep( this.props.groupConfig )

		config.workflow.publish_to_cerebro.headline_method = componentState.dataMethods.headlineMethod
		config.workflow.publish_to_cerebro.caption_method = componentState.dataMethods.captionMethod
		config.workflow.publish_to_cerebro.scribe_name_method = componentState.dataMethods.scribeNameMethod
		config.workflow.publish_to_cerebro.closed_captioning = componentState.closedCaptioning
		config.workflow.publish_to_cerebro.asset_download = componentState.assetDownload

		if ( helpers.doesExist( componentState.dataMethods.englishAttributionText ) ) {
			let english = config.workflow.publish_to_cerebro.language_whitelist.findIndex( ( l ) => { return l.espn_value === 'en' } )
			config.workflow.publish_to_cerebro.language_whitelist[ english ].attribution_text = componentState.dataMethods.englishAttributionText
		}

		if ( helpers.doesExist( componentState.dataMethods.spanishAttributionText ) ) {
			let spanish = config.workflow.publish_to_cerebro.language_whitelist.findIndex( ( l ) => { return l.espn_value === 'es' } )
			config.workflow.publish_to_cerebro.language_whitelist[ spanish ].attribution_text = componentState.dataMethods.spanishAttributionText
		}

		if ( helpers.doesExist( componentState.dataMethods.portugueseAttributionText ) ) {
			let portuguese = config.workflow.publish_to_cerebro.language_whitelist.findIndex( ( l ) => { return l.espn_value === 'pt' } )
			config.workflow.publish_to_cerebro.language_whitelist[ portuguese ].attribution_text = componentState.dataMethods.portugueseAttributionText
		}

		config.workflow.publish_to_cerebro.alert_settings.alert_when_published = componentState.alertSettings.alert_when_published

		if ( helpers.doesExist( componentState.alertSettings.slack_channel ) ) {
			config.workflow.publish_to_cerebro.alert_settings.slack_channel = componentState.alertSettings.slack_channel
		}
		else {
			delete config.workflow.publish_to_cerebro.alert_settings.slack_channel
		}

		if ( helpers.doesExist( componentState.alertSettings.emoji ) ) {
			config.workflow.publish_to_cerebro.alert_settings.emoji = componentState.alertSettings.emoji
		}
		else {
			delete config.workflow.publish_to_cerebro.alert_settings.emoji
		}

		config.workflow.publish_to_cerebro.closed_captioning.is_requested = componentState.closedCaptioning.is_requested

		if ( helpers.doesExist( componentState.closedCaptioning.hours_until_due ) ) {
			config.workflow.publish_to_cerebro.closed_captioning.hours_until_due = componentState.closedCaptioning.hours_until_due
		}
		else {
			delete config.workflow.publish_to_cerebro.closed_captioning.hours_until_due
		}

		config.workflow.publish_to_cerebro.asset_download.use_partner_asset_location = componentState.assetDownload.use_partner_asset_location

		return config
	}


	getEditionLanguage( groupConfig, espnLanguage ) {
		return groupConfig.workflow.publish_to_cerebro.language_whitelist.find( ( l ) => { return l.espn_value === espnLanguage } )
	}


	getDisplayHelpText( validationState ) {
		if ( validationState ) {
			return { display: 'block' }
		}

		return { display: 'none' }
	}


	updateDataMethodValue( propertyName, event ) {
		Analytics.recordUserActivity()

		let newDataMethods = _cloneDeep( this.state.dataMethods )

		let newValue = event.target.value
		if ( helpers.doesNotExist( newValue ) ) {
			delete newDataMethods[ propertyName ]
		}
		else {
			newDataMethods[ propertyName ] = newValue
		}

		this.props.updateGroupConfigState( this.createConfigFromState( { dataMethods: newDataMethods, alertSettings: this.state.alertSettings, closedCaptioning:this.state.closedCaptioning, assetDownload: this.state.assetDownload } ) )
	}


	updateAlertSettingsValue( propertyName, event ) {
		Analytics.recordUserActivity()

		let newAlertSettings = _cloneDeep( this.state.alertSettings )

		let newValue = event.target.value
		if ( propertyName === 'alert_when_published' ) {
			newValue = event.target.checked
		}

		if ( helpers.doesNotExist( newValue ) || newValue === '' ) {
			delete newAlertSettings[ propertyName ]
		}
		else {
			newAlertSettings[ propertyName ] = newValue
		}

		this.props.updateGroupConfigState( this.createConfigFromState( { dataMethods: this.state.dataMethods, alertSettings: newAlertSettings, closedCaptioning:this.state.closedCaptioning, assetDownload: this.state.assetDownload } ) )
	}


	updateClosedCaptioningValue( propertyName, event ) {
		Analytics.recordUserActivity()

		let newClosedCaptioning = _cloneDeep( this.state.closedCaptioning )

		let newValue = event.target.value
		if ( propertyName === 'is_requested' ) {
			newValue = event.target.checked
		}

		if ( helpers.doesNotExist( newValue ) || newValue === '' ) {
			delete newClosedCaptioning[ propertyName ]
		}
		else {
			newClosedCaptioning[ propertyName ] = newValue
		}

		this.props.updateGroupConfigState( this.createConfigFromState( { dataMethods: this.state.dataMethods, alertSettings: this.state.alertSettings, closedCaptioning:newClosedCaptioning, assetDownload: this.state.assetDownload } ) )
	}


	updateAssetDownloadValue( propertyName, event ) {
		Analytics.recordUserActivity()

		let newAssetDownload = _cloneDeep( this.state.assetDownload )

		let newValue = event.target.value
		if ( propertyName === 'use_partner_asset_location' ) {
			newValue = event.target.checked
		}

		if ( helpers.doesNotExist( newValue ) || newValue === '' ) {
			delete newAssetDownload[ propertyName ]
		}
		else {
			newAssetDownload[ propertyName ] = newValue
		}

		this.props.updateGroupConfigState( this.createConfigFromState( { dataMethods: this.state.dataMethods, alertSettings: this.state.alertSettings, closedCaptioning:this.state.closedCaptioning, assetDownload:newAssetDownload } ) )
	}


	renderAttributionText( languageName, espnValue, statePropertyName, configValue ) {
		let attributionValidationState = null
		let attributionHelpText = ''
		let attributionDisabled = false

		if ( helpers.doesNotExist( this.getEditionLanguage( this.props.groupConfig, espnValue ) ) ) {
			attributionValidationState = 'warning'
			attributionHelpText = `"${ languageName }" is not a valid language in the current configuration`
			attributionDisabled = true
		}

		let warningClasses = { root:this.props.classes.root, error:this.props.classes.error }
		let warningClassesInput = { root:this.props.classes.root, error:this.props.classes.error, underline: this.props.classes.underline }

		return (
			<FormControl style={ { marginTop: '5px' } } fullWidth className={ `attr-text-${ espnValue }` } error={ Boolean( attributionValidationState ) }>
				<InputLabel
					style={ { fontSize: '16px' } }
					classes={ attributionValidationState === 'warning' ? warningClasses : {} }
					shrink
				>
					{ `${ languageName } Attribution Text` }
				</InputLabel>

				<Input
					classes={ attributionValidationState === 'warning' ? warningClassesInput : {} }
					placeholder="Enter full attribution text"
					value={ configValue || '' }
					onChange={ this.updateDataMethodValue.bind( this, statePropertyName ) }
					disabled={ attributionDisabled }
				/>

				<FormHelperText classes={ attributionValidationState === 'warning' ? warningClasses : {} }>{ attributionHelpText }</FormHelperText>
			</FormControl>
		)
	}


	renderAlertSettings() {
		let warningClasses = { root:this.props.classes.root, error:this.props.classes.error }
		let warningClassesInput = { root:this.props.classes.root, error:this.props.classes.error, underline: this.props.classes.underline }

		let slackChannelStyle = {}
		let slackChannelInputStyle = {}
		let alertEmojiStyle = {}
		let alertEmojiInputStyle = {}

		if ( this.state.validationState.alertSettings.slackChannel.state === 'warning' ) {
			slackChannelStyle = warningClasses
			slackChannelInputStyle = warningClassesInput
		}
		if ( this.state.validationState.alertSettings.emoji.state === 'warning' ) {
			alertEmojiStyle = warningClasses
			alertEmojiInputStyle = warningClassesInput
		}
		
		return (
			<Card style={ { margin: '8px' } }>
				<CardHeader title="Alert Settings" />

				<Divider />
				
				<CardContent>
					<FormControl className="alert-settings-enabled">
						<FormGroup>
							<FormControlLabel
								control= {
									<Checkbox
										color="primary"
										value={ this.state.alertSettings.alert_when_published }
										checked={ this.state.alertSettings.alert_when_published }
										onChange={ this.updateAlertSettingsValue.bind( this, 'alert_when_published' ) }
									/>
								}
								label="Alert When Video Published"
								labelPlacement="end"
							>
							</FormControlLabel>
						</FormGroup>
					</FormControl>

					<FormControl fullWidth className="alert-settings-slack-channel" error={ Boolean( this.state.validationState.alertSettings.slackChannel.state ) }>
						<InputLabel shrink style={ { fontSize: '16px' } } classes={ slackChannelStyle }>Slack Channel</InputLabel>

						<Input
							classes={ slackChannelInputStyle }
							placeholder="Channel name, without the hashtag.  Cannot be a personal account."
							value={ this.state.alertSettings.slack_channel || '' }
							onChange={ this.updateAlertSettingsValue.bind( this, 'slack_channel' ) }
							disabled={ this.state.validationState.alertSettings.slackChannel.preventModification }
						/>

						<FormHelperText
							classes={ slackChannelStyle }
						>
							{ this.state.validationState.alertSettings.slackChannel.helpText }
						</FormHelperText>
					</FormControl>

					<FormControl style={ { marginTop: '15px' } } fullWidth className="alert-settings-emoji" error={ Boolean( this.state.validationState.alertSettings.emoji.state ) }>
						<InputLabel shrink style={ { fontSize: '16px' } } classes={ alertEmojiStyle }>Alert Emoji</InputLabel>

						<Input
							classes={ alertEmojiInputStyle }
							placeholder="Name of the emoji in the Slack system, including ':' characters.  (ex: ':thumbsup:')"
							value={ this.state.alertSettings.emoji || '' }
							onChange={ this.updateAlertSettingsValue.bind( this, 'emoji' ) }
							disabled={ this.state.validationState.alertSettings.emoji.preventModification }
						/>

						<FormHelperText
							classes={ alertEmojiStyle }
						>
							{ this.state.validationState.alertSettings.emoji.helpText }
						</FormHelperText>
					</FormControl>
				</CardContent>
			</Card>

		)
	}


	renderClosedCaption() {
		return (
			<Card style={ { margin: '8px' } }>
				<CardHeader title="Closed Captioning" />

				<Divider />

				<CardContent>
					<FormControl className="closed-caption-request-enabled">
						<FormGroup>
							<FormControlLabel
								control= {
									<Checkbox
										color="primary"
										value={ this.state.closedCaptioning.is_requested }
										checked={ this.state.closedCaptioning.is_requested }
										onChange={ this.updateClosedCaptioningValue.bind( this, 'is_requested' ) }
									/>
								}
								label="Closed Captioning Requested"
								labelPlacement="end"
							>
							</FormControlLabel>
						</FormGroup>
					</FormControl>

					<FormControl fullWidth className="caption-due-time">
						<InputLabel style={ { fontSize: '16px' } } shrink>Time Until Captioned Due</InputLabel>

						<Select
							value={ this.state.closedCaptioning.hours_until_due || '' }
							onChange={ this.updateClosedCaptioningValue.bind( this, 'hours_until_due' ) }>
							{ Media.CC_DUE_TIMES.map( ( dueTime ) => ( <MenuItem key={ `dueTime-${ dueTime.value }` } value={ dueTime.value }>{ dueTime.name }</MenuItem> ) ) }
						</Select>
					</FormControl>
				</CardContent>
			</Card>
		)
	}


	renderAssetDownload() {
		return (
			<Card style={ { margin: '8px' } }>
				<CardHeader title="Asset Transfer" />

				<Divider />

				<CardContent>
					<FormControl className="asset-download">
						<FormGroup>
							<FormControlLabel
								control= {
									<Checkbox
										color="primary"
										value={ this.state.assetDownload.use_partner_asset_location }
										checked={ this.state.assetDownload.use_partner_asset_location }
										onChange={ this.updateAssetDownloadValue.bind( this, 'use_partner_asset_location' ) }
									/>
								}
								label="Use Partner Asset Location"
								labelPlacement="end"
							>
							</FormControlLabel>
						</FormGroup>
					</FormControl>
				</CardContent>
			</Card>
		)
	}


	render() {
		let headlineMethods = SelectableMetadata.getMetadataByKind( this.props.selectableMetadata, 'headlineMethod' )
		let captionMethods = SelectableMetadata.getMetadataByKind( this.props.selectableMetadata, 'captionMethod' )
		let scribeMethods = SelectableMetadata.getMetadataByKind( this.props.selectableMetadata, 'scribeNameMethod' )

		return (
			<div style={ { display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between', padding: 8 } }>
				<div style={ { flex: 1 } }>
					<Card style={ { margin: '8px' } }>
						<CardHeader title="Headlines" />

						<Divider />
						
						<CardContent>
							<FormControl className="headline" fullWidth>
								<InputLabel style={ { fontSize: '16px' } } shrink>Headline Format</InputLabel>

								<Select
									value={ this.state.dataMethods.headlineMethod }
									onChange={ this.updateDataMethodValue.bind( this, 'headlineMethod' ) }
								>
									{ headlineMethods.map( ( method ) => ( <MenuItem key={ `headline-${ method.option_value }` } value={ method.option_value }>{ method.option_name }</MenuItem> ) ) }
								</Select>
							</FormControl>

							<FormControl className="caption" fullWidth style={ { marginTop: '15px', marginBottom: '15px' } }>
								<InputLabel style={ { fontSize: '16px' } } shrink>Caption Format</InputLabel>

								<Select
									value={ this.state.dataMethods.captionMethod }
									onChange={ this.updateDataMethodValue.bind( this, 'captionMethod' ) }>
									{ captionMethods.map( ( method ) => ( <MenuItem key={ `caption-${ method.option_value }` } value={ method.option_value }>{ method.option_name }</MenuItem> ) ) }
								</Select>
							</FormControl>

							{ this.renderAttributionText( 'English', 'en', 'englishAttributionText', this.state.dataMethods.englishAttributionText ) }
							{ this.renderAttributionText( 'Spanish', 'es', 'spanishAttribxrqutionText', this.state.dataMethods.spanishAttributionText ) }
							{ this.renderAttributionText( 'Portuguese', 'pt', 'portugueseAttributionText', this.state.dataMethods.portugueseAttributionText ) }
						</CardContent>
					</Card>

					{ this.renderClosedCaption() }
				</div>

				<div style={ { flex: 1 } }>
					<Card style={ { margin: '8px' } }>
						<CardHeader title="CMS Methods" />

						<Divider />
						
						<CardContent>
							<FormControl className="scribe-name" fullWidth>
								<InputLabel style={ { fontSize: '16px' } } shrink>CMS Title Format</InputLabel>
								<Select
									value={ this.state.dataMethods.scribeNameMethod }
									onChange={ this.updateDataMethodValue.bind( this, 'scribeNameMethod' ) }>
									{ scribeMethods.map( ( method ) => ( <MenuItem key={ `scribe-${ method.option_value }` } value={ method.option_value }>{ method.option_name }</MenuItem> ) ) }
								</Select>
							</FormControl>
						</CardContent>
					</Card>
				
					{ this.renderAlertSettings() }
					{ this.renderAssetDownload() }
				</div>
			</div>
		)
	}
}


DataMethods.propTypes = {
	groupConfig				: PropTypes.object.isRequired,
	selectableMetadata		: PropTypes.array.isRequired,
	updateGroupConfigState	: PropTypes.func.isRequired,
	classes					: PropTypes.object.isRequired
}


export default withStyles( styles )( DataMethods )
