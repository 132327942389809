import React from 'react'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'

import { amber, green } from '@material-ui/core/colors'

import IconButton from '@material-ui/core/IconButton'

import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'

import Divider from '@material-ui/core/Divider'
import makeStyles from '@material-ui/styles/makeStyles'

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon
}

const useStyles1 = makeStyles( theme => ( {
	success: {
		backgroundColor: green[600]
	},
	error: {
		backgroundColor: theme.palette.error.dark
	},
	info: {
		backgroundColor: theme.palette.primary.dark
	},
	warning: {
		backgroundColor: amber[700]
	},
	icon: {
		fontSize: 20
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing( 1 )
	},
	message: {
		display: 'flex',
		alignItems: 'center'
	}
} ) )


function MySnackbarContentWrapper( props ) {
	const classes = useStyles1()
	const { className, message, onClose, variant, title, ...other } = props
	const Icon = variantIcon[variant]
    
	return (
		<SnackbarContent
			className={ clsx( classes[variant], className ) }
			aria-describedby="client-snackbar"
			message={
				<div id="client-snackbar" className={ classes.message }>
					<Icon className={ clsx( classes.icon, classes.iconVariant ) } />
					<div style={ { display: 'flex', flexDirection: 'column' } }>
						<div>{ title }</div>
						<Divider />
						<div>{ message }</div>
					</div>
				</div>
			}
			action={ [
				<IconButton
					key="close"
					aria-label="Close"
					color="inherit"
					onClick={ onClose }
				>
					<CloseIcon className={ classes.icon } />
				</IconButton>
			] }
			{ ...other }
		/>
	)
}


MySnackbarContentWrapper.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	message: PropTypes.node,
	onClose: PropTypes.func,
	variant: PropTypes.oneOf( [ 'success', 'warning', 'error', 'info' ] ).isRequired
}


class AppNotification extends React.Component {
	renderSnackbar( message, variant, title ) {
		return (
			<Snackbar
				anchorOrigin={ {
					vertical	: 'bottom',
					horizontal	: 'right',
				} }
				open={ this.props.open }
				autoHideDuration={ 3000 }
				onClose={ this.props.notificationDismissed }
			>
				<MySnackbarContentWrapper
					onClose={ this.props.notificationDismissed }
					variant={ variant }
					message={ message }
					title={ title }
				/>
			</Snackbar>
		)
	}


	render() {
		return this.renderSnackbar( this.props.message, this.props.variant, this.props.title )
	}
}


AppNotification.propTypes = {
	title					: PropTypes.string.isRequired,
	variant					: PropTypes.string.isRequired,
	message                 : PropTypes.string.isRequired,
	open					: PropTypes.bool.isRequired,
	notificationDismissed	: PropTypes.func.isRequired
}


export default AppNotification
