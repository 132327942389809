import helpers from './helpers'


let isShortstop		= ( typeof( kIS_SHORTSTOP ) !== 'undefined' ) ? kIS_SHORTSTOP : false
let appVersion		= ( typeof( kAPP_VERSION ) !== 'undefined' ) ? kAPP_VERSION : '0.0.0'
let lastCommitSha	= ( typeof( kLAST_COMMIT_SHA ) !== 'undefined' ) ? kLAST_COMMIT_SHA : 'xxxxxx'
let lastCommitDesc	= ( typeof( kLAST_COMMIT_DESC ) !== 'undefined' ) ? kLAST_COMMIT_DESC : 'UNKNOWN'


let apiServers = {
	partner_publishing	: {
		local		: 'https://mex.dev:9283',
		development	: 'https://api.partnerpub.espnsb.com',
		qa			: 'https://api.partnerpub.espnqa.com',
		production	: 'https://api.partnerpub.espn.com'
	},
	shortstop			: {
		development	: 'https://api.shortstop.espnsb.com',
		qa			: 'https://api.shortstop.espnqa.com',
		production	: 'https://api.shortstop.espn.com'
	}
}


let clientHosts = {
	partner_publishing	: {
		local		: 'https://mexui.dev:9283',
		development	: 'https://admin.partnerpub.espnsb.com',
		qa			: 'https://admin.partnerpub.espnqa.com',
		production	: 'https://admin.partnerpub.espn.com'
	},
	shortstop			: {
		development	: 'https://admin.shortstop.espnsb.com',
		qa			: 'https://admin.shortstop.espnqa.com',
		production	: 'https://admin.shortstop.espn.com'
	}
}


export default class Config {
	static get environment() {
		return process.env.NODE_ENV || 'test'
	}


	static get isShortstop() {
		return helpers.isTrue( isShortstop )
	}


	static get apiHost() {
		if ( this.isShortstop ) {
			return apiServers[ 'shortstop' ][ Config.environment ]
		}

		return apiServers[ 'partner_publishing' ][ Config.environment ]
	}


	static get clientHost() {
		if ( this.isShortstop ) {
			return clientHosts[ 'shortstop' ][ Config.environment ]
		}

		return clientHosts[ 'partner_publishing' ][ Config.environment ]
	}


	static get oidClientId() {
		return 'ESPN-SHORTSTOP.IOS-PROD'
	}


	static get idleTimeoutInMS() {
		return 20 * 60 * 1000 // 20 minutes
	}


	static get appVersion() {
		return appVersion
	}


	static get getRegionSpecificApiUrl() {
		let platform = this.isShortstop ? 'ss' : 'pp'
		let env = ( Config.environment === 'production' ) ? 'espn' : ( Config.environment === 'qa' ) ? 'espnqa' : 'espnsb'

		return `https://media-exchange-server-${ platform }-web.REGION.aws.hosted.${ env }.com`
	}


	static get lastCommitSha() {
		return lastCommitSha
	}


	static get lastCommitDesc() {
		return lastCommitDesc
	}
}
