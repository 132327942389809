import _get from 'lodash/get'
import _merge from 'lodash/merge'
import _sortBy from 'lodash/sortBy'

import * as request from 'superagent'

import Config from '../lib/config'
import helpers from '../lib/helpers'

import User from '../models/user'
import Media from '../models/media'
import Group from '../models/group'
import Activity from '../models/activity'
import PartnerLookup from '../models/partner_lookup'
import PartnerRequest from '../models/partner_request'
import GroupConfiguration from '../models/group_configuration'
import SelectableMetadata from '../models/selectable_metadata'
import PccProgram from '../models/pcc_program'
import PccCategory from '../models/pcc_category'
import PccNetwork from '../models/pcc_network'
import Category from '../models/category'
import ScribeColumnist from '../models/scribe_columnist'
import Analytics from '../models/analytics'
import UserMetadata from '../models/user_metadata'
import Event from '../models/event'


function setupRequest( currentUser, req ) {
	req.set( 'Authorization', 'Bearer ' + currentUser.access_tokens.bearer )
		.set( 'x-mex-user-id', currentUser._id )
		.type( 'application/json' )
		.accept( 'json' )

	return req
}


function requestMethodForSave( document ) {
	if ( helpers.doesExist( document._id ) ) return request.put

	return request.post
}


export function allowedRouteFor( user, resource ) {
	let prefix = `${ Config.apiHost }/api/v1`
	let path = ''

	if ( user.isInternal || resource === 'publish' ) {
		path = `${ prefix }/${ resource }`
	}
	else {
		path = `${ prefix }/my/${ resource }`
	}

	return path
}


export default class Api {
	static getGroupConfig( currentUser, groupId ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/groups/${ groupId }/config`

			setupRequest( currentUser, request.get( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Get ${ GroupConfiguration.friendlyName }` )

					resolve( new GroupConfiguration( res.body ) )
				} )
		} )
	}

	static saveGroupConfig( currentUser, groupId, config ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/groups/${ groupId }/config`
			let requestMethod = requestMethodForSave( config )

			setupRequest( currentUser, requestMethod( path ) )
				.send( config )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Save ${ GroupConfiguration.friendlyName }` )

					resolve( new GroupConfiguration( res.body ) )
				} )
		} )
	}


	static getPartnerMetadata( currentUser, groupId ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/groups/${ groupId }/lookup`

			setupRequest( currentUser, request.get( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Get ${ PartnerLookup.friendlyName }` )

					let models = []
					res.body.map( ( item ) => {
						models.push( new PartnerLookup( item ) )
					} )

					resolve( models )
				} )
		} )
	}


	static savePartnerMetadata( currentUser, groupId, metadata ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/groups/${ groupId }/lookup/${ metadata.cms_name }`
			let requestMethod = requestMethodForSave( metadata )

			setupRequest( currentUser, requestMethod( path ) )
				.send( metadata )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Save ${ PartnerLookup.friendlyName }` )

					resolve( new PartnerLookup( res.body ) )
				} )
		} )
	}


	static deletePartnerMetadata( currentUser, groupId, cmsName ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/groups/${ groupId }/lookup/${ cmsName }`

			setupRequest( currentUser, request.delete( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Delete ${ PartnerLookup.friendlyName }` )

					resolve( new PartnerLookup( res.body ) )
				} )
		} )
	}


	static getPartnerMetadataForCMS( currentUser, groupId, cmsName ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/groups/${ groupId }/lookup/${ cmsName }`

			setupRequest( currentUser, request.get( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Get ${ PartnerLookup.friendlyName }` )

					resolve( new PartnerLookup( res.body ) )
				} )
		} )
	}


	static listGroups( currentUser, params ) {
		return new Promise( ( resolve, reject ) => {
			let path = allowedRouteFor( currentUser, 'groups/list' )

			setupRequest( currentUser, request.get( path ) )
				.query( params )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `List ${ Group.friendlyName }` )

					let groupsSnapShot = []
					res.body.map( ( group ) => {
						groupsSnapShot.push( Group.buildSnapshot( group ) )
					} )
					resolve( groupsSnapShot )
				} )
		} )
	}


	static searchGroups( currentUser, params ) {
		return new Promise( ( resolve, reject ) => {
			let path = allowedRouteFor( currentUser, 'groups' )

			setupRequest( currentUser, request.get( path ) )
				.query( params )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Search ${ Group.friendlyName }` )

					let models = []
					res.body.map( ( item ) => {
						models.push( new Group( item ) )
					} )

					let totalCount = parseInt( res.headers[ 'x-total-count' ] )

					resolve( {
						data	: models,
						total	: totalCount
					} )
				} )
		} )
	}


	static searchActivity( currentUser, activityName, filters, sortValue, pageInfo ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/activity`

			let queryParams = _merge( { activity_name: activityName, sort: sortValue }, filters, pageInfo )
			if ( !currentUser.isInternal ) {
				queryParams = _merge( queryParams, { group_id: currentUser.group.group_id } )
			}

			setupRequest( currentUser, request.get( path ) )
				.query( queryParams )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Search ${ Activity.friendlyName }` )

					let models = []
					res.body.map( ( item ) => {
						models.push( new Activity( item ) )
					} )

					let totalCount = parseInt( res.headers[ 'x-total-count' ] )

					resolve( {
						data	: models,
						total	: totalCount
					} )
				} )
		} )
	}


	static searchShortstopActivity( currentUser, filters, sortValue, pageInfo ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/activity/shortstop`

			let queryParams = _merge( { sort: sortValue }, filters, pageInfo )

			setupRequest( currentUser, request.get( path ) )
				.query( queryParams )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Search Shortstop ${ Activity.friendlyName }` )

					let models = []
					res.body.map( ( item ) => {
						models.push( new Activity( item ) )
					} )

					let totalCount = parseInt( res.headers[ 'x-total-count' ] )

					resolve( {
						data	: models,
						total	: totalCount
					} )
				} )
		} )
	}


	static getMedia( currentUser, mediaId, params = {} ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/media/${ mediaId }`

			setupRequest( currentUser, request.get( path ) )
				.query( params )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Get ${ Media.friendlyName }` )

					resolve( new Media( res.body ) )
				} )
		} )
	}


	static publishMedia( currentUser, mediaId, params = {} ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/media/${ mediaId }/publish`

			setupRequest( currentUser, request.put( path ) )
				.query( params )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Publish ${ Media.friendlyName }` )

					resolve()
				} )
		} )
	}


	static ignoreMedia( currentUser, mediaId, params = {} ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/media/${ mediaId }/ignore`

			setupRequest( currentUser, request.put( path ) )
				.query( params )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Publish ${ Media.friendlyName }` )

					resolve()
				} )
		} )
	}


	static deleteMedia( currentUser, mediaId ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/media/${ mediaId }`

			setupRequest( currentUser, request.delete( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Delete ${ Media.friendlyName }` )

					resolve( new Media( res.body ) )
				} )
		} )
	}


	static publishShortstopTest( currentUser, media ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/media/${ media._id }/publish-shortstop-test`

			setupRequest( currentUser, request.put( path ) )
				.send( media )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Publish Shortstop Test ${ Media.friendlyName }` )

					resolve()
				} )
		} )
	}


	static saveMedia( currentUser, media ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/media`
			let requestMethod = requestMethodForSave( media )

			if ( helpers.doesExist( media._id ) ) {
				path = `${ path }/${ media._id }`
			}

			setupRequest( currentUser, requestMethod( path ) )
				.send( media )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Save ${ Media.friendlyName }` )

					resolve( new Media( res.body ) )
				} )
		} )
	}


	static getPartnerRequest( currentUser, partnerRequestId, params = {} ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/partner-requests/${ partnerRequestId }`

			setupRequest( currentUser, request.get( path ) )
				.query( params )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Get ${ PartnerRequest.friendlyName }` )

					resolve( new PartnerRequest( res.body ) )
				} )
		} )
	}


	static listGroupUsers( currentUser, groupId, params ) {
		return new Promise( ( resolve, reject ) => {
			let path = allowedRouteFor( currentUser, `groups/${ groupId }/users` )

			setupRequest( currentUser, request.get( path ) )
				.query( params )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `List ${ User.friendlyName } in ${ Group.friendlyName }` )

					resolve( res.body )
				} )
		} )
	}


	static searchGroupUsers( currentUser, params ) {
		return new Promise( ( resolve, reject ) => {
			let path = allowedRouteFor( currentUser, 'users' )

			setupRequest( currentUser, request.get( path ) )
				.query( params )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Search ${ User.friendlyName } in ${ Group.friendlyName }` )

					let models = []
					res.body.map( ( item ) => {
						models.push( new User( item ) )
					} )

					let totalCount = parseInt( res.headers[ 'x-total-count' ] )

					resolve( {
						data	: models,
						total	: totalCount
					} )
				} )
		} )
	}


	static searchUsers( currentUser, params ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/users`

			setupRequest( currentUser, request.get( path ) )
				.query( params )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Get ${ User.friendlyName }` )

					let models = []
					res.body.map( ( item ) => {
						models.push( new User( item ) )
					} )

					let totalCount = parseInt( res.headers[ 'x-total-count' ] )

					resolve( {
						models		: models,
						total		: totalCount,
						searchTerm	: params.name
					} )
				} )
		} )
	}


	static getUser( currentUser, userId, params ) {
		return new Promise( ( resolve, reject ) => {
			let path = allowedRouteFor( currentUser, `users/${ userId }` )

			setupRequest( currentUser, request.get( path ) )
				.query( params )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Get ${ User.friendlyName }` )

					resolve( new User( res.body ) )
				} )
		} )
	}


	static saveUser( currentUser, user ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/users`
			let requestMethod = requestMethodForSave( user )

			if ( helpers.doesExist( user._id ) ) {
				path = `${ path }/${ user._id }`
			}

			setupRequest( currentUser, requestMethod( path ) )
				.send( user )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Create ${ User.friendlyName }` )

					resolve( new User( res.body ) )
				} )
		} )
	}


	static deleteUser( currentUser, userId ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/users/${ userId }`

			setupRequest( currentUser, request.delete( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Delete ${ User.friendlyName }` )

					resolve( new User( res.body ) )
				} )
		} )
	}


	static getGroup( currentUser, groupId ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/groups/${ groupId }`

			setupRequest( currentUser, request.get( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Get ${ Group.friendlyName }` )

					resolve( new Group( res.body ) )
				} )
		} )
	}


	static saveGroup( currentUser, group ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/groups`
			let requestMethod = requestMethodForSave( group )

			if ( helpers.doesExist( group._id ) ) {
				path = `${ path }/${ group._id }`
			}

			setupRequest( currentUser, requestMethod( path ) )
				.send( group )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Create ${ Group.friendlyName }` )

					resolve( new Group( res.body ) )
				} )
		} )
	}


	static deleteGroup( currentUser, groupId ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/groups/${ groupId }`

			setupRequest( currentUser, request.delete( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Delete ${ Group.friendlyName }` )

					resolve( new Group( res.body ) )
				} )
		} )
	}


	static loginWithOneId( email, swid, accessToken ) {
		return new Promise( ( resolve, reject ) => {
			request.post( `${ Config.apiHost }/api/v1/auth/one_id` )
				.type( 'application/json' )
				.accept( 'json' )
				.send( { access_token: accessToken, swid: swid, email: email, did_client_id: Config.oidClientId } )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, 'Login', { _isLogin: true } )

					resolve( new User( res.body ) )
				} )
		} )
	}


	static uploadVideo( currentUser, uploadVideoInfo ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/publish`

			setupRequest( currentUser, request.post( path ) )
				.send( uploadVideoInfo )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, 'Upload Video' )

					return resolve( new Media( res.body ) )
				} )
		} )
	}


	static getSelectableMetadata( currentUser, params ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/metadata`

			setupRequest( currentUser, request.get( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Get ${ SelectableMetadata.friendlyName }` )

					let models = []
					res.body.map( ( item ) => {
						models.push( new SelectableMetadata( item ) )
					} )

					resolve( models )
				} )
		} )
	}


	static pccListPrograms( currentUser, pageInfo ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/pcc/programs?rows=${ pageInfo.rows }&start=${ pageInfo.start }`

			setupRequest( currentUser, request.get( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, '(PCC) List Programs' )

					let models = []
					res.body.rows.map( ( item ) => {
						models.push( new PccProgram( item ) )
					} )

					resolve( {
						data	: models,
						total	: res.body.total
					} )
				} )
		} )
	}


	static pccGetProgram( currentUser, programId ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/pcc/programs/${ programId }`

			setupRequest( currentUser, request.get( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, '(PCC) Get Program' )

					resolve( new PccProgram( res.body ) )
				} )
		} )
	}

	// we need to send program id for VOD not media id the multiple media support
	static pccUpdateProgramMetadata( currentUser, programId, metadata ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/pcc/programs/${ programId }`

			setupRequest( currentUser, request.put( path ) )
				.send( metadata )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, '(PCC) Update Program Metadata' )

					resolve( res )
				} )
		} )
	}


	static pccGetNetworkList( currentUser ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/pcc/networks`

			setupRequest( currentUser, request.get( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, '(PCC) Get Network List' )

					let networks = []
					res.body.rows.map( ( network ) => {
						networks.push( new PccNetwork( network ) )
					} )

					resolve( {
						data	: networks,
						total	: res.body.total
					} )
				} )
		} )
	}


	static pccGetCategories( currentUser ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/pcc/categories`

			setupRequest( currentUser, request.get( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, '(PCC) Get Categories' )

					let categories = []
					res.body.msg.map( ( category ) => {
						categories.push( new PccCategory( category ) )
					} )

					resolve( {
						data	: _sortBy( categories, [ 'name' ] ),
						total	: res.body.total
					} )
				} )
		} )
	}


	static searchCategories( currentUser, searchTerm, categoryType ) {
		let query = { q: searchTerm }
		if ( helpers.doesExist( categoryType ) ) query.filters = categoryType

		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v2/categories`
			setupRequest( currentUser, request.get( path ) )
				.query( query )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Search ${ Category.friendlyName }` )

					let models = []
					res.body.map( ( item ) => {
						models.push( new Category( item ) )
					} )

					resolve( { searchTerm, models } )
				} )
		} )
	}


	static searchEvents( currentUser, teamUid, startDate ) {
		return new Promise( ( resolve, reject ) => {
			let queryParams = { team_uid: teamUid, start_date: startDate }

			let path = `${ Config.apiHost }/api/v1/events`
			setupRequest( currentUser, request.get( path ) )
				.query( queryParams )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Search ${ Event.friendlyName }` )

					let events = res.body.map( ( item ) => {
						return new Event( item )
					} )

					resolve( events )
				} )
		} )
	}


	static getCategory( currentUser, categoryUUID ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v2/categories/${ categoryUUID }`
			setupRequest( currentUser, request.get( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Get ${ Category.friendlyName }` )

					resolve( res.body )
				} )
		} )
	}


	static getLeagueCategory( currentUser, leagueId ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v2/categories/league/${ leagueId }`
			setupRequest( currentUser, request.get( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Get League ${ Category.friendlyName }` )

					resolve( new Category( res.body ) )
				} )
		} )
	}


	static getUploadsForUser( currentUser ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/uploads/`
			setupRequest( currentUser, request.get( path ) )
				.query( { q: currentUser._id } )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Unable to identify uploads for user:  ${ currentUser }` )

					resolve ( res )
				} )
		} )
	}


	static createUploadForUser( currentUser, uploadInfo ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/uploads/`
			setupRequest( currentUser, request.post( path ) )
				.send( uploadInfo )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Unable to create uploads for user:  ${ currentUser._id } on aws` )

					resolve ( res )
				} )
		} )
	}


	static getPreSignedUrl( currentUser, workItem ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/media/get-upload-url`
			setupRequest( currentUser, request.put( path ) )
				.send( workItem )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Unable to get presigned url for upload for user: ${ currentUser._id } on aws` )

					resolve ( res )
				} )
		} )
	}


	static getScribeColumnist( currentUser, columnistId ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/scribe/columnist/${ columnistId }`
			setupRequest( currentUser, request.get( path ) )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Get ${ ScribeColumnist.friendlyName }` )

					resolve( new ScribeColumnist( res.body ) )
				} )
		} )
	}


	static searchAnalytics( currentUser, filters ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/analytics`
			let queryParams = _merge( filters )

			setupRequest( currentUser, request.get( path ) )
				.query( queryParams )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Search ${ Analytics.friendlyName }` )

					let models = []
					res.body.map( ( item ) => {
						models.push( new Analytics( item ) )
					} )

					let totalCount = parseInt( res.headers[ 'x-total-count' ] )
					resolve( {
						data	: models,
						total	: totalCount
					} )
				} )
		} )
	}


	static getUserMetadata( currentUser ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/users/${ currentUser._id }/metadata`

			setupRequest( currentUser, request.get( path ) )
				.end( ( err, res ) => {
					let statusCode = 500
					if ( helpers.doesExist( res ) ) statusCode = _get( res, 'statusCode', 500 )

					if ( helpers.doesExist( err ) && statusCode !== 404 ) return Api.handleError( err, res, reject, `Unable to retrieve metadata for user: ${ currentUser.username }` )

					if ( statusCode === 404 ) return resolve( null )
					resolve( new UserMetadata( res.body ) )
				} )
		} )
	}


	static createUserMetadata( currentUser, workItem ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/users/${ currentUser._id }/metadata`

			setupRequest( currentUser, request.post( path ) )
				.send( workItem )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Unable to retrieve metadata for user: ${ currentUser.username }` )
					resolve( new UserMetadata( res.body ) )
				} )
		} )
	}


	static updateUserMetadata( currentUser, workItem ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/users/${ currentUser._id }/metadata`

			setupRequest( currentUser, request.put( path ) )
				.send( workItem )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, `Unable to retrieve metadata for user: ${ currentUser.username }` )
					resolve( new UserMetadata( res.body ) )
				} )
		} )
	}


	static publishPartnerFootage( currentUser, partnerFootage ) {
		return new Promise( ( resolve, reject ) => {
			let path = `${ Config.apiHost }/api/v1/publish/footage`

			setupRequest( currentUser, request.post( path ) )
				.send( partnerFootage )
				.end( ( err, res ) => {
					if ( helpers.doesExist( err ) ) return Api.handleError( err, res, reject, 'Unable to publish footage' )

					return resolve( new Media( res.body ) )
				} )
		} )
	}


	static handleError( err, res, reject, apiMethod, extraProps = {} ) {
		let body = null
		if ( helpers.doesExist( res ) ) body = _get( res, 'body', null )

		let statusCode = 500
		if ( helpers.doesExist( res ) ) statusCode = _get( res, 'statusCode', 500 )

		if ( body !== null ) return reject( Object.assign( body, { _apiMethod: apiMethod, _isServerException: false, statusCode: statusCode }, extraProps ) )

		return reject( Object.assign( err, { _apiMethod: apiMethod, _isServerException: true, statusCode: statusCode }, extraProps ) )
	}
}
