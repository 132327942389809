import _cloneDeep from 'lodash/cloneDeep'
import _defaultTo from 'lodash/defaultTo'

import React from 'react'
import PropTypes from 'prop-types'

import Analytics from '../../lib/analytics'
import helpers from '../../lib/helpers'
import SelectableMetadata from '../../models/selectable_metadata'
import ContentClear from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Checkbox from '@material-ui/core/Checkbox'

import Slide from '@material-ui/core/Slide'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'


const styles = {
	rightFloatingButton: {
		marginLeft: 'auto'
	},
	displayHelpText: ( validation ) => validation ? { display: 'block' } : { display: 'none' }
}


class EditionsAndPublishingRules extends React.Component {
	constructor( props, context ) {
		super( props, context )

		this.state = this.createStateFromConfig( props.groupConfig )
	}


	UNSAFE_componentWillReceiveProps( nextProps ) {
		this.setState( this.createStateFromConfig( nextProps.groupConfig ) )
	}


	createStateFromConfig( groupConfig ) {
		// create the local state object from the group configuration model

		let validationState = { editions: {} }

		let editions = _cloneDeep( groupConfig.workflow.publish_to_cerebro.editions )

		// check for duplicate editions (validation error)
		for ( let edition of editions ) {
			// initialize the validation state here while checking
			let editionValidationState = {}

			let entries = groupConfig.workflow.publish_to_cerebro.editions.filter( ( e ) => {
				if ( helpers.doesExist( e.sport ) ) {
					if ( helpers.doesExist( e.league ) ) {
						return e.language === edition.language && e.sport === edition.sport && e.league === edition.league
					}

					return e.language === edition.language && e.sport === edition.sport && ( helpers.doesNotExist( e.league ) && helpers.doesNotExist( edition.league ) )
				}
				else {
					return e.language === edition.language && ( helpers.doesNotExist( e.sport ) && helpers.doesNotExist( edition.sport ) ) && ( helpers.doesNotExist( e.league ) && helpers.doesNotExist( edition.league ) )
				}
			} )

			if ( entries.length > 1 ) {
				editionValidationState.state = 'error'
				editionValidationState.help_text = 'Edition is duplicated'
			}

			if ( helpers.doesNotExist( validationState[ edition.language ] ) ) validationState[ edition.language ] = {}
			if ( helpers.doesNotExist( validationState[ edition.language ][ edition.sport ] ) ) validationState[ edition.language ][ edition.sport ] = {}

			validationState[ edition.language ][ edition.sport ][ edition.language ] = editionValidationState
		}

		// check for orphaned editions (validation warning)
		let matchedEditions = []

		for ( let sport of groupConfig.workflow.publish_to_cerebro.sport_whitelist ) {
			for ( let language of groupConfig.workflow.publish_to_cerebro.language_whitelist ) {
				let edition = groupConfig.lookupEdition( language.espn_value, sport.sport_espn_value, sport.league_espn_value )
				if ( helpers.doesExist( edition ) ) matchedEditions.push( edition )
			}
		}

		for ( let edition of editions ) {
			let entries = matchedEditions.filter( ( e ) => {
				if ( helpers.doesExist( e.sport ) ) {
					if ( helpers.doesExist( e.league ) ) {
						return e.language === edition.language && e.sport === edition.sport && e.league === edition.league
					}

					return e.language === edition.language && e.sport === edition.sport && helpers.doesNotExist( e.league )
				}
				else {
					return e.language === edition.language && helpers.doesNotExist( e.sport ) && helpers.doesNotExist( e.league )
				}
			} )

			if ( entries.length === 0 && helpers.doesNotExist( validationState[ edition.language ][ edition.sport ][ edition.language ].state ) ) {
				validationState[ edition.language ][ edition.sport ][ edition.language ].state = 'warning'
				validationState[ edition.language ][ edition.sport ][ edition.language ].help_text = 'Edition is orphaned'
			}
		}

		// check for empty expiration value
		for ( let edition of editions ) {
			for ( let target of edition.cms_targets ) {
				if ( isNaN( target.default_exp_date_value ) ) {
					target.default_exp_date_value = null
				}
				if ( helpers.doesNotExist( target.default_exp_date_value ) ) {
					validationState[ edition.language ][ edition.sport ][ edition.language ].state = 'error'
					validationState[ edition.language ][ edition.sport ][ edition.language ].date_help_text = 'Expiration value is required'
				}
			}
		}

		return {
			editions			: editions,
			validation_state	: validationState,
			showFullCms			: this.state ? this.state.showFullCms : false,
			showFullTable		: this.state ? this.state.showFullTable : true
		}
	}


	createConfigFromState( componentState ) {
		// do the reverse of above

		let config = _cloneDeep( this.props.groupConfig )
		config.workflow.publish_to_cerebro.editions = _cloneDeep( componentState.editions )

		return config
	}


	handleAddEdition() {
		Analytics.recordUserActivity()

		let newEditions = _cloneDeep( this.state.editions )
		
		newEditions.push( {
			language: this.props.groupConfig.workflow.publish_to_cerebro.language_whitelist[ 0 ].espn_value,
			sport: 'any',
			league: 'any',
			encrypt: false,
			cerebro_auto_publish: true,
			hold_for_review: false,
			cms_targets: [],
		} )

		this.props.updateGroupConfigState( this.createConfigFromState( { editions: newEditions } ) )
	}


	handleAddCMSTarget( editionIndex ) {
		Analytics.recordUserActivity()

		let newEditions = _cloneDeep( this.state.editions )
		newEditions[ editionIndex ].cms_targets.push( {
			name: 'domestic', ingest_for_edit: false, shows: [ 'no_show' ], default_exp_date_value: 2, default_exp_date_units: 'years', excluded_partners: [], geo_restrictions: { target_type: 'none', targets: [] }
		} )

		this.props.updateGroupConfigState( this.createConfigFromState( { editions: newEditions } ) )
	}


	handleDeleteEdition( editionIndex ) {
		Analytics.recordUserActivity()

		let newEditions = _cloneDeep( this.state.editions )
		newEditions.splice( editionIndex, 1 )

		this.props.updateGroupConfigState( this.createConfigFromState( { editions: newEditions } ) )
	}


	handleDeleteCMSTarget( editionIndex, targetIndex ) {
		Analytics.recordUserActivity()

		let newEditions = _cloneDeep( this.state.editions )
		newEditions[ editionIndex ].cms_targets.splice( targetIndex, 1 )

		this.props.updateGroupConfigState( this.createConfigFromState( { editions: newEditions } ) )
	}


	updateCMSTargetValue( propertyName, editionIndex, targetIndex, event ) {
		Analytics.recordUserActivity()

		let newEditions = _cloneDeep( this.state.editions )

		let newValue = event.target.value
		if ( propertyName === 'ingest_for_edit' ) {
			newValue = event.target.checked
		}


		if ( propertyName === 'shows' ) {
			newValue = event.target.value.split( ',' )
		}

		if ( propertyName === 'default_exp_date_value' ) {
			newValue = parseInt( event.target.value )
		}

		if ( propertyName === 'default_exp_date_units' ) {
			newValue = event.target.value
		}

		newEditions[ editionIndex ].cms_targets[ targetIndex ][ propertyName ] = newValue

		this.props.updateGroupConfigState( this.createConfigFromState( { editions: newEditions } ) )
	}


	updateEditionValue( propertyName, editionIndex, event ) {
		Analytics.recordUserActivity()

		let newEditions = _cloneDeep( this.state.editions )

		let newValue = event.target.value
		if ( newValue === '' ) {
			delete newEditions[ editionIndex ][ propertyName ]
		}
		else {
			if ( propertyName === 'encrypt' || propertyName === 'cerebro_auto_publish' || propertyName === 'hold_for_review' ) {
				newValue = event.target.checked
			}

			newEditions[ editionIndex ][ propertyName ] = newValue

			if ( propertyName === 'sport' ) newEditions[ editionIndex ].league = 'any'
		}

		this.props.updateGroupConfigState( this.createConfigFromState( { editions: newEditions } ) )
	}


	renderCMSTargetTable( editionIndex ) {
		let allCMSMetadata = SelectableMetadata.getMetadataByKind( this.props.selectableMetadata, 'cms' )

		let tableWidths = { cms: '15%', ingest: '15%', Shows: '40%', expiration_quantity: '10%', expiration_unit: '15%', trash: '5%' }

		if ( !this.state.showFullCms ) {
			tableWidths = { cms: '100%', ingest: '0%', Shows: '0%', expiration_quantity: '0%', expiration_unit: '0%', trash: '0%' }
		}

		return (
			<Paper elevation={ 1 }>
				<AppBar position="static" color="default" style={ { boxShadow: 'none' } }>
					<Toolbar disableGutters variant="dense">
						<Tooltip title="Add CMS Target" enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton id="addCmsTarget" onClick={ this.handleAddCMSTarget.bind( this, editionIndex ) } style={ styles.rightFloatingButton }>
								<AddIcon />
							</IconButton>
						</Tooltip>
					</Toolbar>
				</AppBar>

				<Table size="small" className="cms-table-publishing-rules" style={ { padding: '0px 0px 0px 12px' } }>
					<TableHead>
						<TableRow>
							<TableCell style={ { width: tableWidths.cms } }>CMS</TableCell>
							{ this.renderAnimatedNode( <TableCell style={ { width: tableWidths.ingest } }>Ingest</TableCell>, true ) }
							{ this.renderAnimatedNode( <TableCell style={ { width: tableWidths.shows } }>Shows</TableCell> ) }
							{ this.renderAnimatedNode( <TableCell style={ { width: tableWidths.expiration_quantity } }>Expiration</TableCell> ) }
							{ this.renderAnimatedNode( <TableCell style={ { width: tableWidths.expiration_unit } } /> ) }
							{ this.renderAnimatedNode( <TableCell style={ { width: tableWidths.trash } } /> ) }
						</TableRow>
					</TableHead>

					<TableBody>
						{
							this.state.editions[ editionIndex ].cms_targets.map( ( cmsTarget, targetIndex ) => {
								let validationState = this.state.validation_state[ this.state.editions[ editionIndex ].language ][ this.state.editions[ editionIndex ].sport ][ this.state.editions[ editionIndex ].language ]

								return (
									<TableRow className="cms-target" key={ `cms_target-${ editionIndex }-${ targetIndex }` }>
										<TableCell>
											<FormControl fullWidth>
												<Select
													value={ cmsTarget.name }
													onChange={ this.updateCMSTargetValue.bind( this, 'name', editionIndex, targetIndex ) }>
													{ allCMSMetadata.map( ( cms, idx ) => {
														return <MenuItem key={ `edition-${ editionIndex }-target-${ targetIndex }-cms-${ idx }` } value={ cms.option_value }>{ cms.option_name }</MenuItem>
													} ) }
												</Select>
											</FormControl>
										</TableCell>

										{ this.renderAnimatedNode(
											<TableCell>
												<Checkbox checked={ cmsTarget.ingest_for_edit } onChange={ this.updateCMSTargetValue.bind( this, 'ingest_for_edit', editionIndex, targetIndex ) } />
											</TableCell>
										) }

										{ this.renderAnimatedNode(
											<TableCell>
												<FormControl fullWidth>
													<Input value={ cmsTarget.shows.join() } onChange={ this.updateCMSTargetValue.bind( this, 'shows', editionIndex, targetIndex ) }/>
												</FormControl>
											</TableCell>
										) }

										{ this.renderAnimatedNode(
											<TableCell>
												<FormControl className="editionExpireNum" error={ Boolean( validationState.state ) }>
													<Input
														value={ _defaultTo( cmsTarget.default_exp_date_value, '' ) }
														onChange={ this.updateCMSTargetValue.bind( this, 'default_exp_date_value', editionIndex, targetIndex ) }
													/>
													<FormHelperText style={ styles.displayHelpText( validationState.date_help_text ) }>{ validationState.date_help_text }</FormHelperText>
												</FormControl>
											</TableCell>
										) }

										{ this.renderAnimatedNode(
											<TableCell>
												<FormControl className="editionExpireDateUnits">
													<Select
														value={ cmsTarget.default_exp_date_units }
														onChange={ this.updateCMSTargetValue.bind( this, 'default_exp_date_units', editionIndex, targetIndex ) }
													>
														<MenuItem value="years">years</MenuItem>
														<MenuItem value="months">months</MenuItem>
														<MenuItem value="days">days</MenuItem>
													</Select>
												</FormControl>
											</TableCell>
										) }

										{ this.renderAnimatedNode(
											<TableCell>
												<IconButton onClick={ this.handleDeleteCMSTarget.bind( this, editionIndex, targetIndex ) }><ContentClear/></IconButton>
											</TableCell>
										) }
									</TableRow>
								)
							} )
						}
					</TableBody>
				</Table>
			</Paper>
			
		)
	}


	renderAnimatedNode( node, first ) {
		let animationHandlingProps = first ? { onEnter: () => this.setState( { showFullTable: false } ), onExited: () => this.setState( { showFullTable: true } ) } : null

		return (
			<Slide direction="left" in={ this.state.showFullCms } mountOnEnter unmountOnExit { ...animationHandlingProps } exit={ false }>
				{ node }
			</Slide>
		)
	}


	renderLeagueSelector( edition, editionIndex ) {
		let disabled = true
		let leagues = []

		if ( helpers.doesExist( edition.sport ) ) {
			disabled = false
			leagues = this.props.groupConfig.getListOfAvailableLeagues( edition.sport )
		}

		let validationState = this.state.validation_state[ edition.language ][ edition.sport ][ edition.language ]

		return (
			<FormControl error={ Boolean( validationState.state ) } fullWidth>
				<Select
					value={ edition.league || '' }
					onChange={ this.updateEditionValue.bind( this, 'league', editionIndex ) }
					disabled={ disabled }>
					{
						leagues.map( ( league, idx ) => {
							let sport = SelectableMetadata.getParentByValue( this.props.selectableMetadata, 'sport', edition.sport )
							let displayValue = SelectableMetadata.getChildByValue( sport, 'league', league.espn_value ).option_name

							return (
								<MenuItem key={ `edition-${ editionIndex }-league-${ idx }` } value={ league.espn_value }>{ displayValue }</MenuItem>
							)
						} )
					}
				</Select>
				<FormHelperText style={ styles.displayHelpText( validationState.help_text ) }>{ validationState.help_text }</FormHelperText>
			</FormControl>
		)
	}


	render() {
		let tableWidths = { language: '10%', sport: '15%', league: '20%', encrypt: '12%', auto_publish: '13%', hold_for_review: '12%', trash: '3%', cms_table: '15%' }

		if ( !this.state.showFullTable ) {
			tableWidths = { language: '10%', sport: '15%', league: '20%', encrypt: '0%', auto_publish: '0%', hold_for_review: '0%', trash: '0%', cms_table: '55%' }
		}

		let groupConfigSports = this.props.groupConfig.getListOfAvailableSports()
		let groupConfigLanguages = this.props.groupConfig.workflow.publish_to_cerebro.language_whitelist

		return (
			<div style={ { padding: 8 } }>
				<Paper elevation={ 1 } >
					<AppBar position="static" color="default" style={ { boxShadow: 'none' } }>
						<Toolbar disableGutters variant="dense">
							<div style={ { marginLeft: 'auto' } }>
								<Tooltip title="Add Publishing Configuration" enterDelay={ 500 } leaveDelay={ 200 }>
									<IconButton id="addEdition" onClick={ this.handleAddEdition.bind( this ) } style={ styles.rightFloatingButton }>
										<AddIcon />
									</IconButton>
								</Tooltip>
								<Tooltip title="Show Full CMS" enterDelay={ 500 } leaveDelay={ 200 }>
									<IconButton id="showFullCms" onClick={ () => this.setState( prevState => { return { showFullCms: !prevState.showFullCms } } ) } style={ styles.rightFloatingButton }>
										<MenuIcon />
									</IconButton>
								</Tooltip>
							</div>

						</Toolbar>
					</AppBar>

					<Table className="table-publishing-rules">
						<TableHead>
							<TableRow>
								<TableCell style={ { width: tableWidths.language } }>Language</TableCell>
								<TableCell style={ { width: tableWidths.sport } }>Sport</TableCell>
								<TableCell style={ { width: tableWidths.league } }>League</TableCell>

								{ this.state.showFullTable ? <>
									<TableCell style={ { width: tableWidths.encrypt } }>Encrypt</TableCell>
									<TableCell style={ { width: tableWidths.auto_publish, paddingRight: '16px' } }>Auto Publish</TableCell>
									<TableCell style={ { width: tableWidths.hold_for_review, paddingRight: '10px' } }>Hold for Review</TableCell>
									<TableCell style={ { width: tableWidths.trash } } />
								</> : null }

								<TableCell style={ { width: tableWidths.cms_table } }>CMS Targets</TableCell>
							</TableRow>
						</TableHead>
						<TableBody className="table-publishing-rules-body">
							{
								this.state.editions.map( ( edition, editionIndex ) => {
									let validationState = this.state.validation_state[ edition.language ][ edition.sport ][ edition.language ]

									return (
										<TableRow className="edition" key={ `edition-${ editionIndex }` }>
											<TableCell className="edition-language">
												<FormControl error={ Boolean( validationState.state ) } fullWidth>
													<Select
														value={ edition.language }
														onChange={ this.updateEditionValue.bind( this, 'language', editionIndex ) }>
														{
															groupConfigLanguages.map( ( language, idx ) => {
																return (
																	<MenuItem key={ `edition-${ editionIndex }-languages-${ idx }` } value={ language.espn_value }>{ language.espn_value }</MenuItem>
																)
															} )
														}
													</Select>
												</FormControl>
											</TableCell>

											<TableCell className="edition-sport">
												<FormControl error={ Boolean( validationState.state ) } fullWidth>
													<Select
														value={ edition.sport || '' }
														onChange={ this.updateEditionValue.bind( this, 'sport', editionIndex ) }>
														{
															groupConfigSports.map( ( sport, idx ) => {
																let displayValue = SelectableMetadata.getParentByValue( this.props.selectableMetadata, 'sport', sport.espn_value ).option_name

																return (
																	<MenuItem key={ `edition-${ editionIndex }-sport-${ idx }` } value={ sport.espn_value }>{ displayValue }</MenuItem>
																)
															} )
														}
													</Select>
													<FormHelperText style={ styles.displayHelpText( validationState.help_text ) }>{ validationState.help_text }</FormHelperText>
												</FormControl>
											</TableCell>

											<TableCell className="edition-league">{ this.renderLeagueSelector( edition, editionIndex ) }</TableCell>
											
											{ this.state.showFullTable ? <>
												<TableCell className="edition-encrypt"><Checkbox checked={ edition.encrypt } value={ edition.encrypt } onChange={ this.updateEditionValue.bind( this, 'encrypt', editionIndex ) } /></TableCell>
												<TableCell className="edition-auto-publish"><Checkbox checked={ edition.cerebro_auto_publish } value={ edition.cerebro_auto_publish } onChange={ this.updateEditionValue.bind( this, 'cerebro_auto_publish', editionIndex ) } /></TableCell>
												<TableCell className="edition-hold-for-review"><Checkbox checked={ edition.hold_for_review } value={ edition.hold_for_review } onChange={ this.updateEditionValue.bind( this, 'hold_for_review', editionIndex ) } /></TableCell>
												<TableCell><a><IconButton onClick={ this.handleDeleteEdition.bind( this, editionIndex ) }><ContentClear/></IconButton></a></TableCell>
											</> : null }

											<TableCell style={ { padding: '3px' } }>{ this.renderCMSTargetTable( editionIndex ) }</TableCell>
										</TableRow>
									)
								} )
							}
						</TableBody>
					</Table>
				</Paper>
			</div>
		)
	}
}


EditionsAndPublishingRules.propTypes = {
	groupConfig				: PropTypes.object.isRequired,
	selectableMetadata		: PropTypes.array.isRequired,
	updateGroupConfigState	: PropTypes.func.isRequired
}


export default EditionsAndPublishingRules
