import _cloneDeep from 'lodash/cloneDeep'
import React from 'react'
import PropTypes from 'prop-types'
import * as Redux from 'react-redux'
import { withRouter } from 'react-router'

import Analytics from '../../lib/analytics'

import helpers from '../../lib/helpers'

import PCC_METADATA from '../../models/pcc_metadata'

import Api from '../../dataSource/api'

import { callApi, reportApiError } from '../../actions/apiCallActions'
import { showNotification } from '../../actions/appNotificationActions'
import { loadCategories } from '../../actions/pccActions'

import ProgramMetadataEntry from './ProgramMetadataEntry'

import IconButton from '@material-ui/core/IconButton'
import BackIcon from '@material-ui/icons/ChevronLeft'
import SaveIcon from '@material-ui/icons/Save'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'


export class ProgramMetadataPage extends React.Component {
	constructor( props ) {
		super( props )
		this.state = Object.assign( this.initialState )
	}


	get initialState() {
		return {
			metadata	  	: null,
			mediaId 		: null,
			validationState	: null
		}
	}


	UNSAFE_componentWillMount() {
		this.getCategoriesList()
	}


	handleBackToProgramList() {
		Analytics.recordUserActivity()
		this.props.history.goBack()
	}


	getCategoriesList() {
		// only load the categories once
		if ( this.props.categories.length > 0 ) return this.loadMetadataForProgram( this.props.match.params.id )

		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser ) => {
						return Api.pccGetCategories( currentUser )
					} )( this.props.authorizationInfo.user, {} )
				},
				( err, categories ) => {
					if ( helpers.doesExist( err ) ) return this.props.dispatch( reportApiError( err ) )

					this.props.dispatch( loadCategories( categories ) )

					this.loadMetadataForProgram( this.props.match.params.id )
				}
			)
		)
	}


	loadMetadataForProgram( programId ) {
		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser ) => {
						return Api.pccGetProgram( currentUser, programId )
					} )( this.props.authorizationInfo.user, programId )
				},
				( err, res ) => {
					if ( helpers.doesExist( err ) ) return this.props.dispatch( reportApiError( err ) )
					if ( helpers.doesExist( res ) ) {
						this.setState( { metadata: PCC_METADATA.createFromPCCProgram( res ), programId: res.id } )
					}
				}
			)
		)
	}


	handleSubmitMetadata() {
		Analytics.recordUserActivity()

		this.props.dispatch(
			callApi(
				() => {
					return ( ( currentUser, programId, metadata ) => {
						return Api.pccUpdateProgramMetadata( currentUser, programId, metadata )
					} )( this.props.authorizationInfo.user, this.props.match.params.id, this.state.metadata )
				},
				( err, res ) => {
					if ( helpers.doesExist( err ) ) {
						this.props.dispatch( reportApiError( err ) )
					}
					else {
						this.props.dispatch( showNotification( 'Success', 'Metadata Saved', 'success' ) )
					}
				}
			)
		)
	}


	handleUpdateMetadataAndValidation( metadata, validationState ) {
		this.setState( { metadata: _cloneDeep( metadata ), validationState: validationState } )
	}


	renderLoadingMetadataPanel() {
		return (
			<Paper className="empty-metadata" elevation={ 2 }>
				<AppBar position="static" color="default" style={ { boxShadow: 'none' } }>
					<Toolbar>
						<Tooltip title={ 'Back to Program List' } style={ { marginRight: 20 } } enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton id="goBackBtn" edge="start" onClick={ () => this.handleBackToProgramList.bind( this ) }>
								<BackIcon />
							</IconButton>
						</Tooltip>

						<Typography variant="h6">Program Metadata</Typography>

					</Toolbar>
				</AppBar>

				<Card className="video-preview-panel">
					<CardHeader title="Metadata Information" titleTypographyProps={ { variant: 'subtitle1' } }></CardHeader>
					<CardContent>Loading metadata for program { this.props.match.params.id }</CardContent>
				</Card>
			</Paper>
		)
	}


	render() {
		if ( helpers.doesNotExist( this.state.metadata ) ) return this.renderLoadingMetadataPanel()

		return (
			<Paper className="metadata-details" elevation={ 0 }>
				<AppBar position="static" color="default" style={ { boxShadow: 'none' } }>
					<Toolbar>
						<Tooltip title={ 'Back to Program List' } style={ { marginRight: 20 } } enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton id="goBackBtn" edge="start" onClick={ this.handleBackToProgramList.bind( this ) }>
								<BackIcon />
							</IconButton>
						</Tooltip>

						<Typography variant="h6">Program Metadata</Typography>

						<Tooltip title="Save Changes" enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton id="saveBtn" style={ { marginLeft: 'auto' } } onClick={ this.handleSubmitMetadata.bind( this ) } disabled={ this.state.validationState && !this.state.validationState.is_valid }>
								<SaveIcon />
							</IconButton>
						</Tooltip>
					</Toolbar>
				</AppBar>

				<ProgramMetadataEntry
					metadata={ this.state.metadata }
					categories={ this.props.categories }
					updateMetadataAndValidation={ this.handleUpdateMetadataAndValidation.bind( this ) }
					submitMetadataCallback={ this.handleSubmitMetadata.bind( this ) }
				/>
			</Paper>
		)
	}
}


ProgramMetadataPage.propTypes = {
	authorizationInfo	: PropTypes.object.isRequired,
	categories			: PropTypes.array.isRequired,

	// injected automatically by react-router
	history				: PropTypes.object.isRequired,
	match				: PropTypes.object.isRequired
}


function mapStateToProps( state, ownProps ) {
	return {
		authorizationInfo	: state.authorizationInfo,
		categories			: state.pccCategories
	}
}


export default withRouter( Redux.connect( mapStateToProps )( ProgramMetadataPage ) )
