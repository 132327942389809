import React from 'react'
import PropTypes from 'prop-types'
const moment = require( 'moment' )

import GraphCleaner from './GraphCleaner'
import DashboardHelper from '../../lib/dashboard_helper'


class ShortstopSection3 extends React.Component {
	render() {
		if ( this.props.error !== null ) {
			return (
				DashboardHelper.showError( this.props.error )
			)
		}

		return (
			<div style={ { display: 'flex' } }>
				<div style={ { flexBasis: '50%' } }>
					<GraphCleaner
						documentType={ 'shortstop_live' }
						currentTimeFrame={ this.props.currentTimeFrame }
						timeFrameMoment={ this.props.timeFrameMoment }
						tableGraphType={ 'contributor' }
						data={ this.props.data.data }
						error={ this.props.error }
						overallGraphType={ 'table' }
						groupInfo={ this.props.groupInfo }
					/>
				</div>
			</div>
		)
	}
}


ShortstopSection3.propTypes = {
	data						: PropTypes.object.isRequired,
	currentTimeFrame			: PropTypes.string.isRequired,
	timeFrameMoment				: PropTypes.instanceOf( moment ).isRequired,
	error						: PropTypes.object,
	groupInfo					: PropTypes.object.isRequired
}


export default ShortstopSection3
