import _cloneDeep from 'lodash/cloneDeep'

import React from 'react'
import PropTypes from 'prop-types'
import validator from 'validator'

import Analytics from '../../lib/analytics'

import helpers from '../../lib/helpers'

import ShortstopDetail from './shortstop_detail'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Divider from '@material-ui/core/Divider'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'

import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'
import grey from '@material-ui/core/colors/grey'
import Tooltip from '@material-ui/core/Tooltip'

import ShortstopAvatar from '../common/shortstop_avatar'


class UserDetail extends React.Component {
	constructor( props, context ) {
		super( props, context )

		this.state = {
			user						: this.userInfoToState( props.user ),
			validation					: this.initialValidationState(),
		}

		this.saveUser = this.saveUser.bind( this )
		this.cancelEditing = this.cancelEditing.bind( this )

		this.updateUserState = this.updateUserState.bind( this )
		this.updateShortstopDetails = this.updateShortstopDetails.bind( this )
	}


	initialValidationState() {
		return {
			contact		: {
				name		: { state: null, help_text: null },
				email		: { state: null, help_text: null }
			}
		}
	}


	UNSAFE_componentWillReceiveProps( nextProps ) {
		// local scribe_columnist is initialized from the users page component and updated when the columnist id value changes
		// we need this state here as well due to how overall user state is managed

		let user = this.userInfoToState( nextProps.user )
		this.setState( { user: user } )
	}


	userInfoToState( userInfo ) {
		return _cloneDeep( userInfo )
	}


	cancelEditing() {
		Analytics.recordUserActivity()

		this.props.cancelCallback()
	}


	saveUser() {
		Analytics.recordUserActivity()

		let validationResult = this.validateUser( this.state.user )
		if ( validationResult.user_is_valid ) {
			return this.props.saveCallback( this.state.user )
		}

		this.setState( { validation: validationResult.validation_state } )
	}


	validateUser( user ) {
		let result = {
			user_is_valid: false,
			validation_state: this.initialValidationState()
		}

		let emailValid = false
		if ( helpers.doesNotExist( user.contact.email ) || user.contact.email === '' ) {
			result.validation_state.contact.email = {
				state		: 'error',
				help_text	: 'Email is required'
			}
		}
		else if ( !validator.isEmail( user.contact.email ) ) {
			result.validation_state.contact.email = {
				state		: 'error',
				help_text	: 'Email be a valid email address'
			}
		}
		else {
			emailValid = true
		}

		let nameValid = false
		if ( helpers.doesNotExist( user.contact.name ) || user.contact.name === '' ) {
			result.validation_state.contact.name = {
				state		: 'error',
				help_text	: 'Name is required'
			}
		}
		else {
			nameValid = true
		}

		result.user_is_valid = emailValid && nameValid

		return result
	}


	handleUpdate( fieldName, evt ) {
		Analytics.recordUserActivity()

		let newUser = _cloneDeep( this.state.user )
		let newValidation = _cloneDeep( this.state.validation )

		let newValue = evt.target.value

		if ( fieldName === 'contact.email' && helpers.doesExist( newValue ) ) {
			newValue = newValue.trim().toLowerCase()
		}

		if ( fieldName.indexOf( '.' ) > 0 ) {
			newUser[ fieldName.split( '.' )[ 0 ] ][ fieldName.split( '.' )[ 1 ] ] = newValue
		}
		else {
			newUser[ fieldName ] = newValue
		}

		// reset the validation state for the property that was just updated
		if ( fieldName === 'contact.name' || fieldName === 'contact.email' ) {
			newValidation[ fieldName ] = { state: null, help_text: null }

			// set the username equal to the email
			if ( fieldName === 'contact.email' ) {
				newUser.username = newValue
			}
		}

		this.setState( { user: newUser, validation: newValidation } )
	}


	updateUserState( evt ) {
		Analytics.recordUserActivity()

		let newUser = _cloneDeep( this.state.user )
		newUser.state = evt.target.value

		this.setState( { user: newUser } )
	}


	updateShortstopDetails( newUser ) {
		Analytics.recordUserActivity()

		this.setState( { user: newUser } )
	}


	renderEmptyDetail() {
		return (
			<Card>
				<CardHeader title="Select a user to see the details" titleTypographyProps={ { variant: 'subtitle1' } }></CardHeader>
			</Card>
		)
	}


	render() {
		if ( helpers.doesNotExist( this.state.user ) ) return this.renderEmptyDetail()

		let error = false
		if ( this.state.validation.contact.name.state === 'error' || this.state.validation.contact.email.state === 'error' ) {
			error = true
		}

		let nameFormHelperText = ( <div /> )
		if ( this.state.validation.contact.email.help_text ) {
			nameFormHelperText = ( <FormHelperText>{ this.state.validation.contact.name.help_text }</FormHelperText> )
		}

		let emailFormHelperText = ( <div /> )
		if ( this.state.validation.contact.email.help_text ) {
			emailFormHelperText = ( <FormHelperText>{ this.state.validation.contact.email.help_text }</FormHelperText> )
		}

		let avatar = ( <div /> )
		if ( this.state.user.group.type === 'shortstop' ) {
			avatar = (
				<ShortstopAvatar
					authorizationInfo={ this.props.authorizationInfo }
					currentUser={ this.state.user }
					cardAvatar={ true }
					getScribeColumnist={ this.props.getScribeColumnist }
				/>
			)
		}

		return (
			<div>
				<Card>
					<CardHeader
						avatar={ avatar }
						title={ this.state.user.contact.name }
						subheader={ this.state.user.contact.job_title }
					/>

					<Divider />

					<CardContent>
						<FormControl fullWidth>
							<TextField
								id="standard-username"
								label="Username"
								fullWidth
								disabled
								value={ this.state.user.username || '' }
								margin="dense"
							/>
							<FormHelperText>This will automatically set to the email address</FormHelperText>
						</FormControl>

						<FormControl error={ error } fullWidth style={ { marginTop: 10 } }>
							<TextField
								id="standard-name"
								label="Name"
								fullWidth
								value={ this.state.user.contact.name || '' }
								margin="dense"
								onChange={ ( e ) => { this.handleUpdate( 'contact.name', e ) } }
							/>
							{ nameFormHelperText }
						</FormControl>

						<FormControl error={ error } fullWidth style={ { marginTop: 10 } }>
							<TextField
								id="standard-title"
								label="Title"
								fullWidth
								value={ this.state.user.contact.job_title || '' }
								margin="dense"
								onChange={ ( e ) => { this.handleUpdate( 'contact.job_title', e ) } }
							/>
						</FormControl>

						<FormControl error={ error } fullWidth style={ { marginTop: 10 } }>
							<TextField
								id="standard-email"
								label="Email"
								fullWidth
								value={ this.state.user.contact.email || '' }
								margin="dense"
								onChange={ ( e ) => { this.handleUpdate( 'contact.email', e ) } }
							/>
							{ emailFormHelperText }
						</FormControl>

						<FormControl fullWidth style={ { marginTop: 10 } }>
							<TextField
								id="standard-phone"
								label="Phone"
								fullWidth
								value={ this.state.user.contact.phone || '' }
								margin="dense"
								onChange={ ( e ) => { this.handleUpdate( 'contact.phone', e ) } }
							/>
						</FormControl>

						<FormControl style={ { marginTop: 10, width: '50%' } }>
							<InputLabel>State</InputLabel>
							<Select
								value={ this.state.user.state }
								onChange={ this.updateUserState }
							>
								<MenuItem value="active">Active</MenuItem>
								<MenuItem value="staging">Staging</MenuItem>
								<MenuItem value="suspended">Suspended</MenuItem>
							</Select>
						</FormControl>


						<ShortstopDetail
							authorizationInfo={ this.props.authorizationInfo }
							user={ this.state.user }
							updateUser={ this.updateShortstopDetails }
						/>
					</CardContent>

					<CardActions>
						<Tooltip title="Save" enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton style={ { marginTop: 12, marginLeft: 8, color: grey[ 700 ] } } onClick={ this.saveUser }>
								<SaveIcon />
							</IconButton>
						</Tooltip>

						<Tooltip title="Cancel" enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton style={ { marginTop: 12, marginLeft: 'auto', color: grey[ 700 ] } } onClick={ this.cancelEditing }>
								<CancelIcon />
							</IconButton>
						</Tooltip>
					</CardActions>
				</Card>
			</div>
		)

	}
}


UserDetail.propTypes = {
	authorizationInfo		: PropTypes.object.isRequired,
	user					: PropTypes.object,
	saveCallback			: PropTypes.func.isRequired,
	cancelCallback			: PropTypes.func.isRequired,

	// test hook
	getScribeColumnist	: PropTypes.func,
}


export default UserDetail
