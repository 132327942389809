import _get from 'lodash/get'

import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import { toggleColumnHeaderName } from '../../lib/grid_utils'

import ErrorIcon from '@material-ui/icons/Error'
import WarningIcon from '@material-ui/icons/Warning'
import WrenchIcon from '@material-ui/icons/Build'
import DoneIcon from '@material-ui/icons/Done'
import HourglassFullIcon from '@material-ui/icons/HourglassFull'
import UploadIcon from '@material-ui/icons/CloudUpload'
import ReviewIcon from '@material-ui/icons/RateReview'
import ArchiveIcon from '@material-ui/icons/Archive'

import AutoRenewIcon from '@material-ui/icons/Autorenew'
import FilterListIcon from '@material-ui/icons/FilterList'

import config from '../../lib/config'
import helpers from '../../lib/helpers'


const COLUMNS = [
	{ id: 'status', width: '7%', numeric: false, padding: 'dense', sortable: false, label: '' },
	{ id: 'partner', width: '24%', numeric: false, padding: 'dense', sortable: true, label: 'Partner', column_name: 'group_name' },
	{ id: 'reqdate', width: '16%', numeric: false, padding: 'dense', sortable: true, label: 'Publish Date', column_name: 'created' },
	{ id: 'title', width: '43%', numeric: false, padding: 'dense', sortable: true, label: 'Video Title', column_name: 'headline' },
	{ id: 'image', width: '10%', numeric: false, padding: 'dense', sortable: false, label: '' }
]

const ICON_FONT_SIZE = 'small'

const LANGUAGES = {
	'en': 'English',
	'es': 'Spanish',
	'pt': 'Portuguese',
}


class ActivityGrid extends React.Component {
	constructor( props ) {
		super( props )

		this.columnHeaderSelected = this.columnHeaderSelected.bind( this )
	}


	getRowColor( activity ) {
		let color

		if ( helpers.doesExist( activity.error ) || ( activity.current_state === 'Failed' || activity.current_state === 'Rejected' ) ) {
			color = '#f2dede'
		}
		else if ( activity.current_state === 'Delivered' || ( activity.current_state === 'Publishing' && !activity.is_test ) ) {
			color = '#e4e2ff'
		}
		else if ( activity.current_state === 'Uploading' || activity.current_state === 'RetrievingAssets' ) {
			color = '#D1C4E9'
		}
		else if ( activity.current_state === 'RequiresReview' ) {
			color = '#d9edf7'
		}
		else if ( activity.current_state === 'Ignored' ) {
			color = '#fefeee'
		}
		else if ( activity.current_state === 'Published' || activity.current_state === 'Unpublished' ) {
			color = '#ffffff'
		}

		return color
	}


	getPrimaryStatusIcon( activity ) {
		let title = 'error'
		let icon = ( <ErrorIcon color="error" fontSize={ ICON_FONT_SIZE } /> )


		if ( activity.current_state === 'Delivered' || activity.current_state === 'Publishing' ) {
			icon = ( <HourglassFullIcon color="action" fontSize={ ICON_FONT_SIZE } /> )
			title = 'processing'
		}
		else if ( activity.current_state === 'Uploading' || activity.current_state === 'RetrievingAssets' ) {
			icon = ( <UploadIcon color="disabled" fontSize={ ICON_FONT_SIZE } /> )
			title = 'uploading'
		}
		else if ( activity.current_state === 'Published' ) {
			icon = ( <DoneIcon color="action" fontSize={ ICON_FONT_SIZE } /> )
			title = 'published'
		}
		else if ( activity.current_state === 'RequiresReview' ) {
			icon = ( <ReviewIcon color="action" fontSize={ ICON_FONT_SIZE } /> )
			title = 'in review'
		}
		else if ( activity.current_state === 'Ignored' ) {
			icon = ( <ArchiveIcon color="action" fontSize={ ICON_FONT_SIZE } /> )
			title = 'ignored'
		}

		return (
			<Tooltip title={ title } enterDelay={ 500 } leaveDelay={ 200 }>
				{ icon }
			</Tooltip>
		)
	}


	getSecondaryStatusIcon( activity ) {
		let icon = ( <div /> )
		let title = null

		if ( activity.warnings.length > 0 ) {
			icon = ( <WarningIcon color="action" fontSize={ ICON_FONT_SIZE } /> )
			title = 'warning'

			return (
				<Tooltip title={ title } enterDelay={ 500 } leaveDelay={ 200 }>
					{ icon }
				</Tooltip>
			)
		}

		return icon
	}


	getTestIcon( activity ) {
		let title
		let icon = <div />

		if ( helpers.doesNotExist( activity.error ) && activity.is_test ) {
			icon = ( <WrenchIcon color="disabled" fontSize={ ICON_FONT_SIZE } /> )
			title = 'test'

			return (
				<Tooltip title={ title } enterDelay={ 500 } leaveDelay={ 200 }>
					{ icon }
				</Tooltip>
			)
		}

		return icon
	}


	getThubnailImage( activity ) {
		if ( helpers.doesNotExist( activity.sort_fields ) ) return ( <div /> )

		let url = activity.sort_fields.thumbnail_url

		if ( helpers.doesNotExist( url ) ) return ( <div /> )

		return ( <img src={ url } style={ { width: '100%', height: 'auto', borderRadius: '10px', border: '1px solid grey' } } /> )
	}


	renderContributorLogo( activity ) {
		let groupName = _get( activity, 'sort_fields.group_name', null )
		let groupLogo = _get( activity, 'sort_fields.group_logo', null )

		if ( groupName === null || groupLogo === null ) {
			return null
		}

		return (
			<img alt={ groupName } src={ groupLogo } style={ { height: 35, width: 35 } } />
		)
	}


	getFilterDescription( filterSettings, filteredGroup ) {
		let descriptionStatus = { description: 'Filter:', is_first: true }

		if ( filterSettings.showTests ) {
			descriptionStatus.description = `${ descriptionStatus.description } Showing (`

			descriptionStatus = this.appendToggleDescription( filterSettings.showTests, 'Tests', descriptionStatus )

			descriptionStatus.description = `${ descriptionStatus.description })`
			descriptionStatus.is_first = false
		}

		if ( !filterSettings.showTests ) {
			let sectionText = ' Hiding ('
			if ( descriptionStatus.description.indexOf( 'Showing' ) > -1 ) sectionText = `,${ sectionText }`

			descriptionStatus = { description: `${ descriptionStatus.description }${ sectionText }`, is_first: true }

			if ( config.environment !== 'production' ) {
				descriptionStatus = this.appendToggleDescription( !filterSettings.showTests, 'Tests', descriptionStatus )
			}

			descriptionStatus.description = `${ descriptionStatus.description })`
			descriptionStatus.is_first = false
		}

		let dateDescription = this.getDateDescription( filterSettings.startDate, filterSettings.endDate )

		if ( dateDescription ) {
			descriptionStatus.description = `${ descriptionStatus.description }, ${ dateDescription }`
			descriptionStatus.is_first = false
		}

		if ( filterSettings.language && LANGUAGES[filterSettings.language] ) {
			let displayLanguage = LANGUAGES[filterSettings.language]
			descriptionStatus.description = `${ descriptionStatus.description }, Language: ${ displayLanguage }`
		}

		if ( filteredGroup.document ) {
			if ( descriptionStatus.is_first ) {
				descriptionStatus.description = `Group: ${ filteredGroup.document.name }`
				descriptionStatus.is_first = false
			}
			else {
				descriptionStatus.description = `${ descriptionStatus.description }, Group: ${ filteredGroup.document.name }`
			}
		}

		return descriptionStatus.description
	}


	appendToggleDescription( toggleValue, toggleDescription, currentDescriptionStatus ) {
		// if the supplied value is true, modify the filter description as appropriate
		// the current status is used to check if this is the first time the description is being
		// modified for a particular segment, used to determine if we need a leading ", " or not

		if ( !toggleValue ) return currentDescriptionStatus

		if ( currentDescriptionStatus.is_first ) {
			return { description: `${ currentDescriptionStatus.description }${ toggleDescription }`, is_first: false }
		}

		return { description: `${ currentDescriptionStatus.description }, ${ toggleDescription }` }
	}


	getDateDescription( startDate, endDate ) {
		let dateDescription
		let date

		if ( startDate && endDate ) {
			dateDescription = 'Posted Between'
			let emdash = '\u2014'
			date = `${ moment( startDate ).format( 'MMM Do, YYYY' ) } ${ emdash } ${ moment( endDate ).format( 'MMM Do, YYYY' ) }`
		}
		else {
			if ( startDate ) {
				dateDescription = 'Posted After'
				date = `${ moment( startDate ).format( 'MMM Do, YYYY' ) }`
			}
			if ( endDate ) {
				dateDescription = 'Posted Before'
				date = `${ moment( endDate ).format( 'MMM Do, YYYY' ) }`
			}
		}

		if ( dateDescription ) {
			return `${ dateDescription }: ${ date }`
		}

		return null
	}


	renderTableToolbar( filterSettings, filteredGroup ) {
		return (
			<AppBar position="static" color="default" style={ { boxShadow: 'none' } }>
				<Toolbar>
					<div style={ { minWidth: '85%' } }>
						<Typography variant="subtitle2" id="tableTitle">
							{ this.getFilterDescription( filterSettings, filteredGroup ) }
						</Typography>
					</div>
					<div style={ { flex: '1 1 100%' } } />
					<div style={ { display: 'flex', flexDirection: 'row' } }>
						<Tooltip title="Filter" enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton onClick={ this.props.onShowFilter }>
								<FilterListIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Refresh" enterDelay={ 500 } leaveDelay={ 200 }>
							<IconButton onClick={ this.props.onRefreshList }>
								<AutoRenewIcon />
							</IconButton>
						</Tooltip>
					</div>
				</Toolbar>
			</AppBar>
		)
	}


	renderTablePagination( total, currentPage, onPageChanged ) {
		if ( total === 0 ) return null

		return (
			<TablePagination
				rowsPerPageOptions={ [] }
				component="div"
				count={ total }
				rowsPerPage={ 10 }
				page={ currentPage }
				backIconButtonProps={ {
					'aria-label': 'Previous Page',
				} }
				nextIconButtonProps={ {
					'aria-label': 'Next Page',
				} }
				onChangePage={ ( e, pageNumber ) => { if ( !e ) return; onPageChanged( pageNumber ) } }
			/>
		)
	}


	columnHeaderSelected( header ) {
		this.props.onSortChanged( toggleColumnHeaderName( header, this.props.currentSort ) )
	}


	render() {
		return (
			<Paper elevation={ 2 }>
				{ this.renderTableToolbar( this.props.filterSettings, this.props.filteredGroup ) }
				<Table size="small">
					<TableHead>
						<TableRow>
							{ COLUMNS.map( ( column ) => {
								let header = ( <div>{ column.label }</div> )
								if ( column.sortable ) {
									header = (
										<Tooltip
											title="Sort"
											placement={ column.numeric ? 'bottom-end' : 'bottom-start' }
											enterDelay={ 500 } leaveDelay={ 200 }
										>
											<TableSortLabel
												className="sort_label"
												active={ this.props.currentSort.includes( column.column_name ) }
												direction={ this.props.currentSort.includes( '-' ) ? 'desc' : 'asc' }
												onClick={ ( e ) => { this.columnHeaderSelected( column.column_name ) } }
											>
												{ column.label }
											</TableSortLabel>
										</Tooltip>
									)
								}

								return (
									<TableCell
										key={ column.id }
										align={ column.numeric ? 'right' : 'left' }
										style={ { width: column.width } }
									>
										{ header }
									</TableCell>
								)
							} ) }
						</TableRow>
					</TableHead>

					<TableBody>
						{ this.props.activity.data.map( ( activity ) => {
							let primaryIcon = this.getPrimaryStatusIcon( activity )
							let secondaryIcon = this.getSecondaryStatusIcon( activity )
							let testIcon = this.getTestIcon( activity )

							let iconCell = (
								<div>
									{ primaryIcon }
									<br />
									<div style={ { display: 'flex', flexDirection: 'row' } }>
										{ secondaryIcon }
										{ testIcon }
									</div>
								</div>
							)

							let groupName = null
							let headline = null

							if ( helpers.doesExist( activity.sort_fields ) ) {
								groupName = activity.sort_fields.group_name
								headline = activity.sort_fields.headline
							}

							return (
								<TableRow className="activity-row" key={ activity._id } style={ { backgroundColor: this.getRowColor( activity ) } } onClick={ ( e ) => { this.props.onActivitySelected( activity ) } }>
									<TableCell>{ iconCell }</TableCell>
									<TableCell>
										<div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center' } }>
											{ this.renderContributorLogo( activity ) }
											<div style={ { marginLeft: 5 } }>{ groupName }</div>
										</div>
									</TableCell>
									<TableCell>{ moment( activity.created ).fromNow() }</TableCell>
									<TableCell>
										<div style={ { whiteSpace: 'pre-wrap', wordBreak: 'break-all' } }>
											{ headline }
										</div>
									</TableCell>
									<TableCell>{ this.getThubnailImage( activity ) }</TableCell>
								</TableRow>
							)
						} ) }
					</TableBody>
				</Table>

				{ this.renderTablePagination( this.props.activity.total, this.props.currentPage, this.props.onPageChanged ) }
			</Paper>
		)
	}
}


ActivityGrid.propTypes = {
	activity			: PropTypes.object.isRequired,
	currentSort			: PropTypes.string.isRequired,
	currentPage			: PropTypes.number.isRequired,
	onActivitySelected	: PropTypes.func.isRequired,
	onSortChanged		: PropTypes.func.isRequired,
	onPageChanged		: PropTypes.func.isRequired,
	onRefreshList		: PropTypes.func.isRequired,
	onShowFilter		: PropTypes.func.isRequired,
	filterSettings		: PropTypes.object.isRequired,
	filteredGroup		: PropTypes.object.isRequired
}


export default ActivityGrid
