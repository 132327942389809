import React from 'react'
import PropTypes from 'prop-types'
const moment = require( 'moment' )

import { LineGraph, PieGraph, TableComponent } from './graphs'
import DashboardHelper from '../../lib/dashboard_helper'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'


class GraphCleaner extends React.Component {
	makeTitleForTable() {
		let graphTypeTitle = DashboardHelper.prettyDateString( this.props.documentType.replace( '_', ' ' ) )

		if ( graphTypeTitle === 'Shortstop live' ) {
			graphTypeTitle = 'Clip transfer'
		}
		let title = `${ graphTypeTitle } category posts`

		if ( this.props.tableGraphType === 'contributor' ) {
			title = `${ graphTypeTitle } contributor posts`
		}
		else if ( this.props.documentType === 'partner' ) {
			title = `Top ${ graphTypeTitle }s`
		}

		return title
	}


	makeTableRows() {
		let data = this.props.data
		let trs = []

		if ( data.length !== 0 ) {
			let arrayOfObjects = data[0].contributors

			if ( this.props.tableGraphType === 'category' ) {
				arrayOfObjects = data[0].categories
			}
			else if ( this.props.tableGraphType === 'partner' ) {
				arrayOfObjects = data[0].partners
			}

			arrayOfObjects.sort( ( a, b ) => ( a.number_posts < b.number_posts ) ? 1 : ( ( b.number_posts < a.number_posts ) ? -1 : 0 ) )

			let i = 1
			for ( let obj of arrayOfObjects ) {
				trs.push(
					<TableRow key={ i }>
						<TableCell>{ obj.name }</TableCell>
						<TableCell>{ obj.number_posts }</TableCell>
					</TableRow>
				)
				i++
			}
		}
		else {
			trs.push(
				<TableRow key={ 1 }>
					<TableCell>No document</TableCell>
					<TableCell>0</TableCell>
				</TableRow>
			)
		}

		return trs
	}


	handleMissingData( documents, dateInfo, sortedData, property ) {
		for ( let i = 0; i < dateInfo.currentTimeFrameAsNumber; i++ ) {
			let expectedMetricsDate = dateInfo.beginTimeFrame.clone().add( i, dateInfo.timeToAdd ).format( dateInfo.formatToUse )

			let potentialMatch = documents.find( o => o.metrics_date === expectedMetricsDate )

			if ( potentialMatch != null ) {
				sortedData[`${ property }`].push( potentialMatch.total_posts )
			}
			else {
				sortedData[`${ property }`].push( 0 )
			}
		}
	}


	cleanLineGraphData() {
		let sortedData = {
			shortstop: [],
			partner_publishing: [],
			shortstop_live: []
		}

		let dataset = [
			{
				label: null,
				data: null,
				borderWidth: 1,
				borderColor: 'rgb(223, 93, 93)',
				fill: false,
				backgroundColor: 'rgb(223, 93, 93)',
			}
		]

		if ( this.props.documentType === 'shortstop' ) {
			this.handleMissingData( this.props.data, this.props.dateInfo, sortedData, 'shortstop' )
			dataset[0].label = 'Shortstop'
			dataset[0].data = sortedData.shortstop
		}
		else if ( this.props.documentType === 'shortstop_live' ) {
			this.handleMissingData( this.props.data, this.props.dateInfo, sortedData, 'shortstop_live' )
			// Have to change color since Shortstop and Clip transfer graphs are rendered next to each other.
			dataset[0].label = 'Clip Transfer'
			dataset[0].data = sortedData.shortstop_live
			dataset[0].borderColor = 'rgb(51,51,255)'
			dataset[0].backgroundColor = 'rgb(51,51,255)'
		}
		else if ( this.props.documentType === 'partner' ) {
			this.handleMissingData( this.props.data, this.props.dateInfo, sortedData, 'partner_publishing' )

			let labelToUse = this.props.groupInfo.name
			if ( this.props.groupInfo.type !== 'partner' ) {
				labelToUse = 'Partner Publishing posts'
			}
			dataset[0].label = labelToUse
			dataset[0].data = sortedData.partner_publishing
		}

		return {
			lineGraphData : {
				labels: DashboardHelper.getDateLabels( this.props.currentTimeFrame, this.props.timeFrameMoment ),
				datasets: dataset
			},
			lineOptions: DashboardHelper.getGraphOptions( { fontsize: 14, titleFontSize: 18 } )
		}
	}


	cleanPieData() {
		let rawData = this.props.data
		let cleanData = { labels: [ 'No document' ], data: [ 0 ] }

		if ( rawData.length !== 0 ) {
			if ( this.props.pieGraphType === 'platform' ) {
				cleanData = { labels: [ 'Ios', 'Web' ], data: [ rawData[0].platforms.ios, rawData[0].platforms.web ] }
			}
			else if ( this.props.pieGraphType === 'language' ) {
				cleanData = { labels: [ 'English', 'Spanish', 'Portuguese' ], data: [ rawData[0].language.en, rawData[0].language.es, rawData[0].language.pt ] }
			}
			else if ( this.props.pieGraphType === 'post_type' ) {
				cleanData = { labels: [ 'Text', 'Photo', 'Video' ], data: [ rawData[0].post_type.text, rawData[0].post_type.photo, rawData[0].post_type.video ] }
			}
		}

		let options = DashboardHelper.getGraphOptions( { fontsize: 14, titleFontSize: 18, title: `${ DashboardHelper.prettyDateString( this.props.pieGraphType.replace( '_', ' ' ) ) }` } )
		delete options.scales
		options.title.display = true

		let datasets = [
			{
				data: cleanData.data,
				backgroundColor: DashboardHelper.colorArray()
			}
		]
		return {
			data: {
				datasets: datasets,
				labels: cleanData.labels
			},
			options: options
		}
	}


	render() {
		let cleanDataError = null
		let data = []
		let graph = null

		let title = null
		let footnote = ''

		try {
			switch ( this.props.overallGraphType ) {

			case ( 'lineGraph' ):
				data = this.cleanLineGraphData()
				graph = <LineGraph data={ data }/>
				break
			case ( 'pieGraph' ):
				data = this.cleanPieData()
				graph = <PieGraph data={ data }/>
				break
			case ( 'table' ):
				data= this.makeTableRows( )
				title = this.makeTitleForTable()
				if ( data.length > 1 && this.props.documentType === 'partner' ) {
					footnote = '*Some partners are part of a larger organization and might not have made their own post.'
				}
				graph = <TableComponent data={ data } title={ title } footnote={ footnote }/>
			}

		}
		catch ( e ) {
			cleanDataError = e
		}

		if ( cleanDataError !== null ) {
			return (
				DashboardHelper.showError( cleanDataError )
			)
		}

		return (
			<div>
				{ graph }
			</div>
		)
	}
}


GraphCleaner.propTypes = {
	// Common
	currentTimeFrame	: PropTypes.string.isRequired,
	timeFrameMoment		: PropTypes.instanceOf( moment ).isRequired,
	overallGraphType	: PropTypes.string.isRequired,
	data				: PropTypes.array.isRequired,
	documentType		: PropTypes.string,

	// Line
	dateInfo			: PropTypes.object,
	groupInfo			: PropTypes.object,

	//Table
	tableGraphType		: PropTypes.string,

	// Pie
	pieGraphType		: PropTypes.string
}


export default GraphCleaner
