import _get from 'lodash/get'

import React from 'react'
import PropTypes from 'prop-types'

import { Player } from 'video-react'

import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import LinearProgress from '@material-ui/core/LinearProgress'

import ReceiptIcon from '@material-ui/icons/Receipt'
import SendIcon from '@material-ui/icons/Send'

import grey from '@material-ui/core/colors/grey'

import ShortstopAvatar from '../common/shortstop_avatar'
import JsonDetailDialog from '../common/json_detail_dialog'

import Analytics from '../../lib/analytics'


class IngestActivityDetails extends React.Component {
	constructor( props ) {
		super( props )

		this.state = { showDetails: false }

		this.handleOpenDetails = this.handleOpenDetails.bind( this )
		this.handleCloseDetails = this.handleCloseDetails.bind( this )
		this.handleRepublishArchive = this.handleRepublishArchive.bind( this )
	}


	handleOpenDetails() {
		Analytics.recordUserActivity()
		this.setState( { showDetails: true } )
	}


	handleCloseDetails() {
		Analytics.recordUserActivity()
		this.setState( { showDetails: false } )
	}


	handleRepublishArchive() {
		this.props.republishArchive( this.props.workItem )
	}


	renderErrorDetail( activity ) {
		let message = activity.error.message

		try {
			// the following will throw an exception if the message is not valid JSON
			let messageJSON = JSON.parse( message )
			message = JSON.stringify( messageJSON, null, 4 )
		}
		catch ( e ) {
			// do nothing there
		}

		return (
			<div style={ { marginTop: 15 } }>
				{ this.renderKeyValueLineItem( 'Activity ID', activity._id, true ) }
				{ this.renderKeyValueLineItem( 'Error Code', activity.error.code ) }
				{ this.renderKeyValueLineItem( 'Message', message ) }
			</div>
		)
	}


	renderContributor( selectedActivity ) {
		let groupName = _get( selectedActivity, 'sort_fields.group_name', null )
		let groupLogo = _get( selectedActivity, 'sort_fields.group_logo', null )

		if ( groupName === null || groupLogo === null ) {
			// this is a clip transfer post

			return (
				<ShortstopAvatar
					authorizationInfo={ this.props.authorizationInfo }
					activity={ selectedActivity }
					cardAvatar={ true }
					getScribeColumnist={ this.props.getScribeColumnist }
				/>
			)
		}

		// this is partner footage
		return (
			<img src={ selectedActivity.sort_fields.group_logo } style={ { height: 40, width: 40 } } />
		)
	}


	renderWarnings( activity ) {
		if ( activity.warnings.length === 0 ) return ( <div /> )

		let rowStyle = { display: 'flex', flexDirection: 'row', marginTop: 5 }
		let firstRowStyle = { display: 'flex', flexDirection: 'row' }

		return (
			<div style={ { marginTop: 15 } }>
				<div style={ firstRowStyle }><strong>Warnings</strong></div>
				{
					activity.warnings.map( ( w ) => {
						return (
							<Typography key={ w.details } style={ rowStyle } variant="subtitle2">{ w.details }</Typography>
						)
					} )
				}
			</div>
		)
	}


	renderActions( activity ) {
		let disabled = true
		let color = grey[ 300 ]

		if ( activity.current_state === 'Failed' ) {
			disabled = false
			color = grey[ 700 ]
		}

		let republishButton = (
			<Tooltip title="Republish" enterDelay={ 500 } leaveDelay={ 200 }>
				<IconButton style={ { color: color } } disabled={ disabled } onClick={ this.handleRepublishArchive }>
					<SendIcon />
				</IconButton>
			</Tooltip>
		)

		if ( disabled ) {
			republishButton = (
				<IconButton style={ { color: color } } disabled={ disabled } onClick={ this.handleRepublishArchive }>
					<SendIcon />
				</IconButton>
			)
		}

		return (
			<CardActions>
				{ republishButton }
				<Tooltip title="Post Details" enterDelay={ 500 } leaveDelay={ 200 }>
					<IconButton style={ { marginLeft: 'auto', color: grey[ 700 ] } } onClick={ this.handleOpenDetails }>
						<ReceiptIcon />
					</IconButton>
				</Tooltip>
			</CardActions>
		)
	}


	renderThumbnail( activity, workItem ) {
		if ( !activity || !workItem ) return ( <div /> )

		return (
			<Player
				playsInline
				poster={ workItem.getThumbnailUrl() }
				src={ workItem.getVideoUrl() }
			/>
		)
	}


	renderKeyValueLineItem( key, value, first=false ) {
		let style = { display: 'flex', flexDirection: 'row', marginTop: 2 }

		if ( first === true ) {
			style = { display: 'flex', flexDirection: 'row' }
		}

		return (
			<div style={ style }>
				<div style={ { flex: 1 } }><Typography variant="subtitle2">{ key }</Typography></div>
				<div style={ { flex: 2 } }><Typography variant="caption">{ value }</Typography></div>
			</div>
		)
	}


	renderIngestStandardAttributes( activity, workItem ) {
		if ( !workItem ) return ( <div /> )

		let idAttributeName = 'Clip Transfer ID'
		let uploadStatusDisplay = 'IN PROGRESS'

		if ( this.props.location.pathname.endsWith( 'footage' ) ) idAttributeName = 'Partner Footage ID'

		if ( activity.current_state !== 'Uploading' ) {
			uploadStatusDisplay = 'FAILED'

			let uploadStatus = _get( workItem, 'media_center.upload_status.current_state' )
			if ( uploadStatus === 'complete' || this.props.location.pathname.endsWith( 'footage' ) ) {
				uploadStatusDisplay = ( <a href={ this.props.workItem.getVideoUrl() } target="_blank" rel="noopener noreferrer">UPLOADED</a> )
			}
		}

		return (
			<div style={ { marginTop: 15 } }>
				{ this.renderKeyValueLineItem( idAttributeName, workItem._id, true ) }
				{ this.renderKeyValueLineItem( 'Upload Status', uploadStatusDisplay ) }
			</div>
		)
	}


	renderImportAttributes( activity, workItem ) {
		if ( !workItem ) return ( <div /> )

		return (
			<div style={ { marginTop: 15 } }>
				{ this.renderKeyValueLineItem( 'Import Status', _get( workItem, 'media_center.import.current_state', '' ), true ) }
				{ this.renderKeyValueLineItem( 'Job ID', _get( workItem, 'media_center.import.job_id', '' ) ) }
				{ this.renderKeyValueLineItem( 'Detail', _get( workItem, 'media_center.import.status_text', '' ) ) }
				{ this.renderKeyValueLineItem( 'Catalog URL', _get( workItem, 'media_center.import.catalog_url', '' ) ) }
			</div>
		)
	}


	renderQuantelAttributes( activity, workItem ) {
		if ( !workItem ) return ( <div /> )

		let quantelTransferProgress = ( <div /> )
		if ( _get( workItem, 'media_center.transfer.job_id' ) ) {
			let progressPct = _get( workItem, 'media_center.transfer.progress_pct', 0 )
			progressPct = progressPct * 100

			quantelTransferProgress = (
				<LinearProgress variant="determinate" max="1" value={ progressPct } />
			)
		}

		return (
			<div style={ { marginTop: 15 } }>
				{ this.renderKeyValueLineItem( 'Ingest Status', _get( workItem, 'media_center.transfer.current_state', '' ), true ) }
				{ this.renderKeyValueLineItem( 'Job ID', _get( workItem, 'media_center.transfer.job_id', '' ) ) }
				{ this.renderKeyValueLineItem( 'Detail', _get( workItem, 'media_center.transfer.status_text', '' ) ) }
				{ this.renderKeyValueLineItem( 'Progress', <div style={ { marginTop: 10 } }>{ quantelTransferProgress }</div> ) }
			</div>
		)
	}


	render() {
		if ( !this.props.activity ) {
			return (
				<Card>
					<CardHeader title="Select a post to preview" titleTypographyProps={ { variant: 'subtitle1' } }></CardHeader>
				</Card>
			)
		}

		let contributorName = _get( this.props.activity, 'owner.name', '' )

		let jobTitle = _get( this.props.activity, 'sort_fields.job_title', null )
		if ( jobTitle === null ) jobTitle = _get( this.props.activity, 'sort_fields.group_name', '' )

		let headline = _get( this.props.workItem, 'shortstop_live.headline', null )
		if ( headline === null ) headline = _get( this.props.workItem, 'partner_footage.headline', null )

		let caption = _get( this.props.workItem, 'shortstop_live.caption', null )
		if ( caption === null ) caption = _get( this.props.workItem, 'partner_footage.caption', null )

		let details = ( <div /> )
		let importDetails = ( <div /> )
		let quantelDetails = ( <div /> )
		let warnings = ( <div /> )

		if ( !this.props.workItem && this.props.activity.error ) {
			details = this.renderErrorDetail( this.props.activity )
		}
		else {
			details = this.renderIngestStandardAttributes( this.props.activity, this.props.workItem )
			importDetails = this.renderImportAttributes( this.props.activity, this.props.workItem )
			quantelDetails = this.renderQuantelAttributes( this.props.activity, this.props.workItem )
			warnings = this.renderWarnings( this.props.activity )
		}

		let dialogData = [
			{ label: 'Workflow', data: this.props.activity },
			{ label: 'Post', data: this.props.workItem }
		]

		return (
			<div>
				<Card>
					<CardHeader
						avatar={
							this.renderContributor( this.props.activity )
						}
						title={ contributorName }
						subheader={ jobTitle }
					/>

					<Divider />

					{ this.renderThumbnail( this.props.activity, this.props.workItem ) }

					<CardContent>
						<Typography style={ { whiteSpace: 'pre-wrap', wordBreak: 'break-all', marginBottom: 5 } } variant="subtitle2">
							{ headline }
						</Typography>

						<Typography variant="caption" style={ { whiteSpace: 'pre-wrap', wordBreak: 'break-all' } } component="p">
							{ caption }
						</Typography>

						{ details }
						{ importDetails }
						{ quantelDetails }
						{ warnings }
					</CardContent>

					<Divider />

					{ this.renderActions( this.props.activity ) }
				</Card>

				<JsonDetailDialog
					open={ this.state.showDetails }
					dialogData = { dialogData }
					closeDialog={ this.handleCloseDetails }
				/>
			</div>
		)
	}
}


IngestActivityDetails.propTypes = {
	authorizationInfo	: PropTypes.object.isRequired,
	workItem			: PropTypes.object,
	activity			: PropTypes.object,
	republishArchive	: PropTypes.func.isRequired,

	// typically injected automatically by react-router, this is passed by the grid component
	location			: PropTypes.object.isRequired,

	// test hook
	getScribeColumnist	: PropTypes.func,
}


export default IngestActivityDetails
