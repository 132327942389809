import React from 'react'
import PropTypes from 'prop-types'
import { Line, Pie } from 'react-chartjs-2'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'


export class LineGraph extends React.Component {
	render() {
		return (
			<Line
				data={ this.props.data.lineGraphData }
				options={ this.props.data.lineOptions }
			/>
		)
	}
}


export class PieGraph extends React.Component {
	render() {
		return (
			<Pie
				data={ this.props.data.data }
				options={ this.props.data.options }
			/>
		)
	}
}


export class TableComponent extends React.Component {
	render() {
		let tableHeight = '300px'
		// 9 because that is how many rows fit in 300px.
		if ( this.props.data.length < 9 ) {
			tableHeight = 'auto'
		}

		return (
			<div style={ { marginTop: '10px' } }>
				<Paper elevation={ 1 }>
					<AppBar position="static" color="default" style={ { boxShadow: 'none' } }>
						<Toolbar disableGutters variant="dense">
							<Typography variant="subtitle2" style={	{ flexGrow: 1, marginLeft: '10px' }	}>
								{ this.props.title }
							</Typography>
						</Toolbar>
					</AppBar>

					<div>
						<div style={ { height: tableHeight, overflowY: 'scroll' } }>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell>Number Posts</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{ this.props.data }
								</TableBody>
							</Table>
						</div>
					</div>
				</Paper>

				<div style={ { marginTop: '3px' } }>{ this.props.footnote }</div>
			</div>
		)
	}
}


PieGraph.propTypes = {
	data 	: PropTypes.object.isRequired
}


LineGraph.propTypes = {
	data 	: PropTypes.object.isRequired
}


TableComponent.propTypes ={
	data		: PropTypes.array.isRequired,
	title		: PropTypes.string.isRequired,
	footnote	: PropTypes.string.isRequired
}
