import _get from 'lodash/get'

const React = require( 'react' )
const PropTypes = require( 'prop-types' )

import moment from 'moment'

import helpers from '../../lib/helpers'

import Media from '../../models/media'

import { Player } from 'video-react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CardActions from '@material-ui/core/CardActions'
import Tooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'

import grey from '@material-ui/core/colors/grey'

import UploadIcon from '@material-ui/icons/Send'
import DefaultAvatarIcon from '@material-ui/icons/Person'

const LANGUAGES = {
	en: 'English',
	es: 'Spanish',
	pt: 'Portuguese'
}

class VideoDetails extends React.Component {
	constructor( props ) {
		super( props )
	}


	componentDidUpdate() {
		this.getImageSrc( this.props.videoDetails.thumbnail_url, 'thumbImage' )
		this.getImageSrc( this.props.videoDetails.thumbnail_url, 'smallerImage' )

		return
	}


	formatDate( date, name ) {
		if ( helpers.doesNotExist( this.props.videoDetails ) ) return null
		if ( name === 'exp' ) {
			date = this.props.videoDetails.expiration_date
		}
		else {
			date = this.props.videoDetails.publish_date
		}

		if ( helpers.doesNotExist( date ) ) return null

		return moment( date ).format( 'L' )
	}


	emptyVideoUploadDetail() {
		return (
			<Card className="video-preview-panel">
				<CardHeader title="Please enter a user to impersonate" titleTypographyProps={ { variant: 'subtitle1' } }></CardHeader>
			</Card>
		)
	}


	translateMediaType( mediaTypeValue ) {
		let mediaTypeIndex = Media.MEDIA_TYPES.findIndex( ( t ) => { return t.value === mediaTypeValue } )
		if ( mediaTypeIndex < 0 ) return null

		return Media.MEDIA_TYPES[ mediaTypeIndex ].name
	}


	getImageSrc( src, id ) {
		let image = document.getElementById( id )

		if ( helpers.doesExist( image ) && helpers.doesExist( src ) && src !== '' ) {
			image.src = src

			image.onload = () => {
				return src
			}

			image.onerror = ( e ) => {
				image.src = ''

				//needed to delay aws image src request for the thumbnail url, avoiding showing broken link image
				setTimeout( () => {
					this.getImageSrc( src, id )
				}, 500 )
			}
		}
		else {
			return ''
		}
	}


	renderKeyValueLineItem( key, value, first=false ) {
		let style = { display: 'flex', flexDirection: 'row', marginTop: 2 }

		if ( first === true ) {
			style = { display: 'flex', flexDirection: 'row' }
		}

		return (
			<div style={ style }>
				<div style={ { flex: 1 } }><Typography variant="subtitle2">{ key }</Typography></div>
				<div style={ { flex: 2 } }><Typography variant="caption">{ value }</Typography></div>
			</div>
		)
	}

	
	renderThumbnail( videoUrl, thumbnail ) {
		if ( !videoUrl ) return ( <div /> )

		return (
			<Player
				playsInline
				poster={ thumbnail }
				src={ videoUrl }
			/>
		)
	}

	
	renderAdditionalInformation( partnerFootage, uploadVideoInfo ) {
		if ( !partnerFootage ) {
			return (
				<div>
					<Typography variant="h6" style={ { marginTop: 10 } }>
						Additional Information
					</Typography>

					{ this.renderKeyValueLineItem( 'Type', this.translateMediaType( uploadVideoInfo.media_type ) ) }
					{ this.renderKeyValueLineItem( 'Sport', uploadVideoInfo.sport ) }
					{ this.renderKeyValueLineItem( 'League', uploadVideoInfo.league ) }
					{ this.renderKeyValueLineItem( 'Team(s)', uploadVideoInfo.teams.join( ', ' ) ) }
					{ this.renderKeyValueLineItem( 'Game ID', uploadVideoInfo.game_id ) }
					{ this.renderKeyValueLineItem( 'Keywords', uploadVideoInfo.keywords ) }
					{ this.renderKeyValueLineItem( 'Publish date', this.formatDate( uploadVideoInfo.publish_date, 'pub' ) ) }
					{ this.renderKeyValueLineItem( 'Expire date', this.formatDate( uploadVideoInfo.expiration_date, 'exp' ) ) }
				</div>
			)
		}

		return <div />

	}


	renderCardHeader( authorizationInfo, impersonatedUser ) {
		let avatar_src = null
		let title = null
		let subheader = null

		if ( this.props.authorizationInfo.user.isInternal ) {
			avatar_src = _get( impersonatedUser, 'group.logo_url' )
			title = _get( impersonatedUser, 'contact.name' )
			subheader = _get( impersonatedUser, 'contact.job_title' )
		}
		else {
			avatar_src = _get( authorizationInfo, 'group.logo_url', null )
			title = _get( authorizationInfo, 'user.contact.name', null )
			subheader = _get( authorizationInfo, 'group.name', null )
		}

		let avatar = <Avatar alt={ title }><DefaultAvatarIcon /></Avatar>

		if ( avatar_src ) {
			avatar = <img alt={ title } src={ avatar_src } style={ { height: 40, width: 40 } } />
		}

		return (
			<CardHeader
				avatar={ avatar }
				title={ title }
				subheader={ subheader }
			>
			</CardHeader>
		)
	}


	render() {
		if ( this.props.authorizationInfo && this.props.authorizationInfo.user.isInternal && !this.props.impersonatedUser ) {
			return this.emptyVideoUploadDetail()
		}

		let uploadVideoInfo = this.props.videoDetails

		let isValid = this.props.validationState.is_valid

		let displayLanguage = uploadVideoInfo.language in LANGUAGES ? LANGUAGES[uploadVideoInfo.language] : uploadVideoInfo.language

		return (
			<Card className="video_detail_panel">
				{ this.renderCardHeader( this.props.authorizationInfo, this.props.impersonatedUser ) }

				<img id="smallerImage" style={ { width: '100%', height: 'auto' } } />

				<Divider />

				<CardContent>

					<Typography style={ { whiteSpace: 'pre-wrap', marginBottom: 5 } } variant="h6">
						{ uploadVideoInfo.headline }
					</Typography>

					<Typography variant="body1" style={ { whiteSpace: 'pre-wrap' } }>
						{ uploadVideoInfo.caption }
					</Typography>

					<Typography variant="h6" style={ { marginTop: 10 } }>
						Information
					</Typography>
					
					{ this.renderKeyValueLineItem( 'Sources', (
						<span>
							{ ( uploadVideoInfo.local_video_file )
								? <span>{ uploadVideoInfo.local_video_file }</span>
								: <a href={ uploadVideoInfo.video_url } target="_blank" rel="noopener noreferrer">Video</a> }
							&nbsp; | <a href={ uploadVideoInfo.thumbnail_url }>Image</a>
						</span>
					) ) }

					{ !this.props.partnerFootage ? this.renderKeyValueLineItem( 'Language', displayLanguage ) : '' }

					{ this.renderAdditionalInformation( this.props.partnerFootage, uploadVideoInfo ) }

				</CardContent>

				<Divider />

				<CardActions>
					<Tooltip
						title={ !isValid ? 'Fill out required fields to upload video' : 'Upload Video' }
						enterDelay={ 500 }
						leaveDelay={ 200 }
					>
						<div style={ { marginLeft: 'auto' } }>
							<IconButton
								disabled={ !isValid }
								style={ { marginLeft: 'auto', color: !isValid ? grey[500] : grey[700] } }
								onClick={ this.props.handleSubmit }
							>
								<UploadIcon />
							</IconButton>
						</div>
					</Tooltip>
				</CardActions>
			</Card>
		)
	}
}


VideoDetails.propTypes = {
	videoDetails		: PropTypes.object,
	partnerFootage		: PropTypes.bool,
	impersonatedUser	: PropTypes.object,
	authorizationInfo	: PropTypes.object,
	handleSubmit		: PropTypes.func,
	validationState		: PropTypes.object
}


export default VideoDetails
